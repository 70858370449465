import { useQuery } from 'vue-query';
import { getToken } from '@/services/jwt.service';
import readAndDownloadFile from '@/utils/downloadFile';
import apiAxios from '../apiAxios';

const viewInvoice = async ({ id }, details = 0) => {
  const token = getToken();
  if (!token || !id) {
    throw new Error('Missing token or id');
  }
  /* eslint-disable */
  let params = {};
  token ? params.access_token = token : ''
  details !=  0 ? params.details = details : '';
  const res = await apiAxios.get(`/invoices/${id}`, { params, responseType: 'blob' });

  readAndDownloadFile(res.data);

  return res;
};

const fetchInvoices = async () => {
  const { data } = await apiAxios.get('/invoices');
  return data;
};

// queries
const useInvoicesQuery = () => useQuery(['invoices'], fetchInvoices);

export default {
  fetchInvoices,
  viewInvoice,
  // queries
  useInvoicesQuery,
};
