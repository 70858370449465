<template>
  <AdminLayout>
    <MainIndex />
    <div class="pb-16"></div>
  </AdminLayout>
</template>

<script>
// @ is an alias to /src
import AdminLayout from '@/layouts/AdminLayout.vue';
import MainIndex from '@/components/admin/membersManagement/MainIndex.vue';

export default {
  name: 'MembersManagementView',
  components: {
    AdminLayout,
    MainIndex,
  },
};
</script>
