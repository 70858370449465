<template>
  <div>
  <div class="mt-4 w-full" v-if='!isView' >
    <tabs v-model:activeKey="activeKey" class="w-full" v>
      <tab-pane key="1" tab="Customize my Program">
        <SimpleProgram v-if='!isRender' :number_of_employees="employeeNumbers" :program="program" :radioVal='isRadioVal' :is-disbaled='isDisbaled'/>
        <div v-else >
          <Skeleton active style='height: 1119px'/>
        </div>
      </tab-pane>

      <tab-pane v-if="isDev" key="2" tab="Build my Program">
        <BudgetProgram v-if='!isRender' :number_of_employees="employeeNumbers" :program="program" :radioVal='isRadioVal' :is-disbaled='isDisbaled'/>
        <div v-else >
          <Skeleton active style='height: 1119px'/>
        </div>
      </tab-pane>
    </tabs>
    </div>
  <div v-else-if='isView'>
    <ViewBudgetProgram v-if='!isRender && isBudget' :number_of_employees="employeeNumbers" :program="program" :radioVal='isRadioVal' :is-disbaled='isDisbaled'/>
    <ViewSimpleProgram v-else-if='!isRender && !isBudget' :number_of_employees="employeeNumbers" :program="program" :radioVal='isRadioVal' :is-disbaled='isDisbaled'/>
    <Skeleton active v-else style='height: 1119px'/>
  </div>
  </div>
</template>

<script>
import { Tabs, TabPane, Skeleton } from 'ant-design-vue';

import SimpleProgram from './simpleProgram/SimpleProgram.vue';
import BudgetProgram from './budgetProgram/BudgetProgram.vue';
import ViewBudgetProgram from './budgetProgram/ViewBudgetProgram.vue';
import ViewSimpleProgram from './simpleProgram/ViewSimpleProgram.vue';

export default {
  components: { Tabs, TabPane, SimpleProgram, BudgetProgram, Skeleton, ViewBudgetProgram, ViewSimpleProgram },
  props: {
    number_of_employees: {
      type: Number,
      default: null,
    },
    program: {
      type: Object,
      default: null,
    },
    radioVal: {
      type: Number,
      default: 0,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeKey: '1',
      render: false,
      numberOfEmp: 0,
    };
  },
  created() {
    // check query params for active tab
    const { tab } = this.$route.query;
    if (tab) {
      this.activeKey = tab;
    }
    const numberOfEmp = this.program?.quantity ?? ((this.$store.state.auth.user?.community_size !== 0 ? this.$store.state.auth.user?.community_size : 1));
    this.numberOfEmp = numberOfEmp;
  },
  computed: {
    isDev() {
      return process.env.VUE_APP_DEV === 'development' || process.env.NODE_ENV === 'development';
    },
    isRender() {
      return this.render;
    },
    isRadioVal() {
      return this.radioVal;
    },
    isView() {
      return this.viewOnly;
    },
    isBudget() {
      if (this.program && this.program.type_data && this.program.type_data.fixed_cost_type === 'budget') {
        return true;
      }
      return false;
    },
    employeeNumbers() {
      return this.numberOfEmp;
    },
    isDisbaled() {
      const { status } = this.program;
      if (status === 'active') return false;
      if (status === 'new') return false;
      return true;
    },
  },
  watch: {
    program(NewVal) {
      console.log('NewVal =>', NewVal);
      this.numberOfEmp = NewVal?.quantity ?? (this.$store.state.auth.user?.community_size ?? 1);
      this.render = true;
      setTimeout(() => {
        this.render = false;
      }, 500);
    },
  },

};
</script>
