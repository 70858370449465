import apiAxios from '../apiAxios';
/* eslint-disable */
const getSubscriptions = async (params) => {
  const { data } = await apiAxios.get('/subscriptions',{params:params});
  return data;
};

const launchNewProgram = async (payload,callback='') => {
  let url = getCurrentDomain()
  const { data } = await apiAxios.post(`/subscriptions?callback_url=${url}`, payload);
  return data;
};
const cancelFutureSubscriptions = async (payload) =>{
  const { data } = await apiAxios.post(`/subscriptions/cancel-subscription/${payload}?force=1`);
  return data;
}
const upgradeNumberOfEmployees = async (payload) =>{
  let url = getCurrentDomain()
  const { data } = await apiAxios.post(`/subscriptions/increase-employees?callback_url=${url}`,payload);
  return data;
}
const downgradeNumberOfEmployees = async (payload) =>{
  let url = getCurrentDomain()
  const { data } = await apiAxios.post(`/subscriptions/decrease-employees?callback_url=${url}`,payload);
  return data;
}
const updateEndDate = async (payload) =>{
  let url = getCurrentDomain()
  const { data } = await apiAxios.put(`/subscriptions/active?callback_url=${url}`,payload);
  return data;
}
const getCurrentDomain = () =>{
  let url =  String(String(window.location.origin) + '/dashboard/account')
  return  url;
}
export default {
  launchNewProgram,
  getSubscriptions,
  cancelFutureSubscriptions,
  upgradeNumberOfEmployees,
  downgradeNumberOfEmployees,
  updateEndDate
};
