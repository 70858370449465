<template>
  <div class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5">
    <div class="flex justify-between">
      <Checkbox v-model:checked="checked" :disabled="isOngoing && hasStarted">
        <h3 class="text-xl text-primary-dark">Reward System (Gamification or fixed benefits)</h3>
      </Checkbox>

      <SaveBtn
        :disabled="!isShowCheckedSaved"
        :loading="isFetching"
        @click="handleSave"
        class="mt-0"
      />
    </div>
    <div v-if="checked">
      <div class="mt-6 bg-bg-dark p-3 rounded">
        <div class="flex flex-wrap items-end justify-between gap-3 mb-2">
          <div>
            <h4>Please upload your employee addresses using the following template to calculate cost :</h4>
            <small>(please follow the template format)</small>
          </div>
          <Button type="link" class="px-0" :disabled="isOngoing && hasStarted">
            <span class="!flex items-center gap-2">
              <span>Download addresses template</span>
              <PhDownload weight="duotone" size="18" />
            </span>
          </Button>
        </div>
        <CustumFileInput
          :disabled="isOngoing && hasStarted"
          name="file"
          :defaultData="defaultData? defaultData.file : null"
          placeholder="choose file"
          accept=".csv"
          :rules="{
                required: false,
                min: 1,
                max: 1
              }"
        >
          <!-- :isTouched="isAllTouched"
          @setInput="setInput"-->
          <PhFileCsv :size="22" weight="duotone" />
        </CustumFileInput>
      </div>

      <div class="mt-8">
        <div class="flex flex-col md:flex-row items-center justify-center md:justify-between gap-4">
          <div class="text-center flex items-center justify-center gap-2">
            <span class="font-bold">Number of Employees:</span>

            <InputNumber
              class="w-28"
              :defaultValue="rewardData.number_of_employees"
              disabled
              @change="setEmployeesNum"
              :min="1"
            />
          </div>

          <div class="text-center flex items-center justify-center gap-2">
            <span class="font-bold">Max monthly rewards per employee:</span>
            <InputNumber
              :disabled="isOngoing && hasStarted"
              class="w-28"
              :defaultValue="rewardData.max_reward_per_employee"
              v-model:value="max_reward_per_employee"
              :min="1"
              prefix="$"
            />
            <!-- @change="setMaxRewardNum" -->
          </div>
        </div>

        <Spin :spinning="settingsQuery?.isFetching">
          <div class="my-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <RewardCard
              title="Mode-Based"
              description="Each employee receives points based on the trip mode and allocated points in the system"
              :selected=" scoring_policy_type === 'mode_based'"
              :id="'mode_based'"
              @click="() => handleSelectReward('mode_based')"
              @openModal="handleModalOpen"
            >
              <img src="@/assets/icons/reward-1.png" alt="icon" class="w-32 h-16 object-contain" />
            </RewardCard>
            <RewardCard
              :disabled="isOngoing && hasStarted"
              title="Distance-Based (Non-SOV)"
              description="Each employee receives points based on reduced SOV traveled miles"
              :selected=" scoring_policy_type === 'mile_based'"
              :id="'mile_based'"
              @click="() => handleSelectReward('mile_based')"
              @openModal="handleModalOpen"
            >
              <img src="@/assets/icons/reward-2.png" alt="icon" class="w-32 h-16 object-contain" />
            </RewardCard>
            <RewardCard
              :disabled="isOngoing && hasStarted"
              title="Emission-Based"
              description="Each employee receives points based on reduced emissions accounting for vehicle type"
              :selected=" scoring_policy_type === 'emission_based'"
              :id="'emission_based'"
              @click="() => handleSelectReward('emission_based')"
              @openModal="handleModalOpen"
            >
              <img src="@/assets/icons/reward-3.png" alt="icon" class="w-32 h-16 object-contain" />
            </RewardCard>
            <RewardCard
              :disabled="isOngoing && hasStarted"
              title="Fixed Benefits"
              description="Each employee receives incentives based on their commute mode choice, fully flexible to employees"
              :selected=" scoring_policy_type === 'fixed_benfit'"
              :id="'fixed_benfit'"
              @click="() => handleSelectReward('fixed_benfit')"
              @openModal="handleModalOpen"
            >
              <img src="@/assets/icons/reward-4.png" alt="icon" class="w-32 h-16 object-contain" />
            </RewardCard>
          </div>
        </Spin>
      </div>

      <div class="w-full flex justify-center md:justify-end">
        <SaveBtn :disabled="!isValueChanged" :loading="isFetching" @click="handleSave" />
      </div>
    </div>

    <Modal
      :visible="visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal  "
      width="350px"
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <ModeBasedModal
        v-if="activeId === 'mode_based'"
        title="Specify Points per trip"
        :id="activeId"
        :key="activeId"
        @close="handleModalClose"
        :isViewOnly='isOngoing && hasStarted'
      />

      <DistanceBasedModal
        v-else-if="activeId === 'mile_based'"
        title="Specify Points per mile"
        :id="activeId"
        :key="activeId"
        @close="handleModalClose"
      />

      <EmissionBasedModal
        v-else-if="activeId === 'emission_based'"
        title="Specify Points"
        :id="activeId"
        :key="activeId"
        @close="handleModalClose"
      />
      <FixedBenefitsModal
        v-else-if="activeId === 'fixed_benfit'"
        :title="'Mode Benefits per workday'"
        :id="activeId"
        :key="activeId"
        @close="handleModalClose"
      />
    </Modal>
  </div>
</template>

<script>
import { Checkbox, Button, Modal, InputNumber, Spin } from 'ant-design-vue';

import CustumFileInput from '@/components/shared/form/CustumFileInput.vue';
import { PhDownload, PhFileCsv } from 'phosphor-vue';
import { mapMutations } from 'vuex';

import debounce from 'lodash.debounce';

import RewardCard from '../shared/RewardCard.vue';
import ModeBasedModal from '../shared/ModeBasedModal.vue';
import DistanceBasedModal from '../shared/DistanceBasedModal.vue';
import EmissionBasedModal from '../shared/EmissionBasedModal.vue';
import FixedBenefitsModal from '../shared/FixedBenefitsModal.vue';
import { REWARD } from '../../../../store/types';
import SaveBtn from '../shared/SaveBtn.vue';

export default {
  components: {
    Checkbox,
    Button,
    CustumFileInput,
    PhDownload,
    PhFileCsv,
    InputNumber,
    RewardCard,
    FixedBenefitsModal,
    Modal,
    ModeBasedModal,
    DistanceBasedModal,
    EmissionBasedModal,
    Spin,
    SaveBtn,
  },
  props: {
    isOngoing: {
      type: Boolean,
      default: false,
    },
    hasStarted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
      activeId: 'mile_based',
      visible: false,
      max_reward_per_employee: 0,
      scoring_policy_type: 'mile_based',
    };
  },

  created() {
    if (this.rewardData) {
      this.max_reward_per_employee = this.rewardData.max_reward_per_employee;
      this.scoring_policy_type = this.rewardData.scoring_policy_type;
      this.checked = this.rewardData.scoring_policy_enabled;
    }
  },
  computed: {
    settingsQuery() {
      return this.$store.state.reward.rewardSettingsQuery;
    },
    rewardData() {
      return this.$store.getters.rewardSettingsData;
    },
    todayDate() {
      return '16-05-2022';
    },
    maxReward() {
      return this.$store.state.reward.max_reward;
    },
    employeesNumber() {
      return this.$store.state.reward.employees_number;
    },
    selectedReward() {
      return this.$store.state.reward.active_mode;
    },
    // checked() {
    //   return this.$store.state.reward.is_reward_system_selected;
    // },
    isValueChanged() {
      if (this.checked && this.checked !== this.rewardData.scoring_policy_enabled) return true;
      let isChanged = false;
      if (this.max_reward_per_employee !== this.rewardData.max_reward_per_employee) {
        isChanged = true;
      }
      if (this.scoring_policy_type !== this.rewardData.scoring_policy_type) {
        isChanged = true;
      }
      return isChanged;
    },
    isShowCheckedSaved() {
      return !this.checked && this.checked !== this.rewardData.scoring_policy_enabled;
    },
  },
  methods: {
    ...mapMutations([
      REWARD.SET_EMPLOYEES_NUMBER_DATA,
      REWARD.SET_MAX_REWARD_NUMBER_DATA,
      REWARD.SET_ACTIVE_MODE_DATA,
      REWARD.SET_SELECTED_SYSTEM,
    ]),
    setInput() {},
    handleSelectReward(id) {
      // this.selectedReward = id;
      // this.SET_ACTIVE_MODE_DATA(id);

      if (this.isOngoing) return;

      this.scoring_policy_type = id;

      // if (this.rewardData.scoring_policy_type === id) return;
      // this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
      //   scoring_policy_type: id,
      // });
    },
    handleModalClose() {
      this.visible = false;
    },
    handleModalOpen(id) {
      this.activeId = id;
      this.visible = true;
    },
    setEmployeesNum(val) {
      this.SET_EMPLOYEES_NUMBER_DATA(val);
    },
    handleSave() {
      this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        scoring_policy_type: this.scoring_policy_type,
        max_reward_per_employee: this.max_reward_per_employee,
        scoring_policy_enabled: this.checked,
      });
    },
    setMaxRewardNum(val) {
      // this.SET_MAX_REWARD_NUMBER_DATA(val);
      this.debounceSetMaxRewardNum(val, this);
    },
    handleSelectSystem(e) {
      this.$store.dispatch(REWARD.ACTION_SELECT_REWARD_SYS, {
        scoring_policy_enabled: e.target.checked,
      });
      // this.SET_SELECTED_SYSTEM({
      //   type: 'is_reward_system_selected',
      //   value: e.target.checked,
      // });
    },
    debounceSetMaxRewardNum: debounce((val, app) => {
      app.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        max_reward_per_employee: val,
      });
    }, 600),
  },
};
</script>
