<template>
  <div class="py-3" v-if="query && !query.isLoading  && query.error">
    <Alert type="error" v-if="error" :message="error" show-icon />
  </div>
</template>

<script>
import { Alert } from 'ant-design-vue';

export default {
  components: { Alert },
  props: {
    query: Object,
  },
  computed: {
    error() {
      if (!this.query || !this.query.error) {
        return null;
      }
      let err = "some thing wen't wrong, please contact us";
      if (
        // eslint-disable-next-line operator-linebreak
        this.query.error.response &&
        // eslint-disable-next-line operator-linebreak
        this.query.error.response.data &&
        this.query.error.response.data.message
      ) {
        err = this.query.error.response.data.message;
      } else if (this.query.error.message) {
        err = this.query.error.message;
      }

      return err;
    },
  },
};
</script>
