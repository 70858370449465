import { ADMIN_TRANSACTIONS } from '../../types';

export default {
  state: {
    walletTransactionsQuery: null,

  },
  getters: {
    walletTransactionsData(state) {
      if (!state.walletTransactionsQuery) {
        return null;
      }
      return state.walletTransactionsQuery.data || null;
    },
  },
  mutations: {
    [ADMIN_TRANSACTIONS.SET_TRANSACTIONS_QUERY](state, payload) {
      state.walletTransactionsQuery = payload;
    },
  },
  actions: {

  },

};
