<template>
  <CustumLabel
    :name="name"
    :label="label"
    :error="touched && error"
    :required="rules && rules.required"
    :hideErrors="hideErrors"
    :labelTooltip="labelTooltip"
  >
    <Input
      :value="formatedValue"
      :placeholder="placeholder"
      :type="type"
      @change="handleChange"
      @blur="handleBlur"
    >
      <template #prefix>
        <PhCreditCard weight="duotone" size="20" class="text-primary" />
      </template>
    </Input>
  </CustumLabel>
</template>

<script>
import validation from '@/services/validation';
import { Input } from 'ant-design-vue';
import { PhCreditCard } from 'phosphor-vue';
import CustumLabel from './CustumLabel.vue';

export default {
  components: { CustumLabel, Input, PhCreditCard },
  props: {
    name: String,
    label: String,
    defaultError: String,
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    hideErrors: Boolean,
    defaultData: Number,
    labelTooltip: String,
    rules: {
      type: Object,
      default: null,
    },
    isTouched: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      formatedValue: null,
      error: null,
      touched: false,
      isValid: false,
    };
  },
  created() {
    if (this.defaultData && this.defaultData.length === 16) {
      this.value = this.defaultData;
      this.formatedValue = this.formateInputValue(this.defaultData);
      this.touched = true;
    }
    this.handleValidate();
    this.setInputValues();
  },
  watch: {
    isTouched(val) {
      if (val) {
        this.touched = true;
      }
    },
    defaultError(val) {
      if (val) {
        this.touched = true;
        this.error = val[0].length > 1 ? val[0] : val;
      }
    },
  },
  methods: {
    handleBlur() {
      this.touched = true;
      this.handleValidate();
      this.setInputValues();
    },
    handleChange(e) {
      let val = e.target.value || null;
      // eslint-disable-next-line eqeqeq
      if (!val && val != 0) {
        this.value = null;
        this.formatedValue = null;
      }

      val = val.replace(/ /g, '');

      if (!this.isNumeric(val)) {
        return;
      }

      if (val.toString().length > 16) {
        val = +val.toString().slice(0, 16);
      }

      this.error = null;
      this.value = val;
      this.formatedValue = this.formateInputValue(val);
    },
    handleValidate() {
      const { error, isValid } = validation.validateRules(this.rules, this.value);
      this.error = error;
      this.isValid = isValid;
    },
    setInputValues() {
      const isRequired = this.rules && this.rules.required;
      if (!isRequired && !this.value) {
        return;
      }
      this.$emit('setInput', {
        [this.name]: {
          name: this.name,
          value: this.value,
          error: this.error,
          touched: this.touched,
          isValid: this.isValid,
        },
      });
    },
    formateInputValue(val) {
      if (!val) {
        return '';
      }

      const valStr = val.toString();
      const len = valStr.length;
      let formated = '';
      valStr.split('').forEach((el, ix) => {
        const inx = ix + 1;
        if (inx && inx % 4 === 0 && inx !== len) {
          formated += `${el} `;
        } else {
          formated += el;
        }
      });
      return formated;
    },
    isNumeric(str) {
      if (typeof str !== 'string') return false;
      return (
        // eslint-disable-next-line no-restricted-globals
        !isNaN(str) && !isNaN(parseFloat(str))
      );
    },
  },
};
</script>
