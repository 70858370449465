import { DASH } from '../types';

export default {
  state: {
    dashStatsQuery: null,
  },
  getters: {
    dashStatsData(state) {
      if (!state.dashStatsQuery) {
        return null;
      }
      return state.dashStatsQuery.data?.result || null;
    },
  },
  mutations: {
    [DASH.SET_DASH_STATS_QUERY](state, payload) {
      state.dashStatsQuery = payload;
    },

  },
  actions: {

  },

};
