<template>
  <div>
    <div
      class="bg-gradient-to-r from-primary to-primary-light text-white text-lg px-3 py-2 text-center font-bold rounded-t-md"
    >Send a private email to the seleted member</div>
    <form @submit.prevent="handleSubmit" v-if="member" class="p-6 flex flex-col gap-3">
      <h3>To: {{member.name}} ({{member.email}})'</h3>

      <CustumInput
        name="subject"
        :defaultData="defaultData? defaultData.subject : null"
        :defaultError="responseErrors.subject || null"
        placeholder="Subject"
        :rules="{
            required: true,
            min: 3,
          }"
        :isTouched="isAllTouched"
        @setInput="setInput"
      />
      <CustumTextArea
        name="message"
        :defaultData="defaultData? defaultData.message : null"
        :defaultError="responseErrors.message || null"
        placeholder="Type your message"
        :rules="{
        required: true,
        min: 5,
      }"
        :isTouched="isAllTouched"
        @setInput="setInput"
      />

      <Button
        type="primary"
        class="btn-gradient"
        :loading="loading"
        @click="handleSubmit"
      >Send Email</Button>
    </form>
  </div>
</template>

<script>
import { Button, message } from 'ant-design-vue';
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumTextArea from '@/components/shared/form/CustumTextArea.vue';
import validation from '../../../services/validation';
import membersApi from '../../../services/apis/members.api';

export default {
  components: {
    Button,
    CustumInput,
    CustumTextArea,
  },
  props: {
    member: Object,
  },
  data() {
    return {
      loading: false,
      isAllTouched: false,
      formData: {},
      responseErrors: {},
    };
  },
  methods: {
    setInput(val) {
      this.formData = { ...this.formData, ...val };
    },
    async handleSubmit() {
      this.isAllTouched = true;
      if (this.loading) {
        return;
      }

      const { data, isValid } = validation.validateAllData(this.formData);

      if (!isValid) {
        return;
      }

      this.loading = true;
      this.responseErrors = {};

      try {
        const res = await membersApi.postSendMessage({ ...data, email: this.member.email });
        message.success(res.result);
        this.loading = false;
        this.$emit('close');
      } catch (error) {
        const errorMsg = error.response?.data?.message;
        if (errorMsg) {
          this.responseErrors = errorMsg;
        }
        this.loading = false;
      }
    },
  },
};
</script>
