<template>
  <ModalCard title="Public Transit Pass">
    <div class="flex flex-col gap-8">
      <div>
        <h3 class="mb-3">
          How much is the public transit cost per employee from public transit agency?
          <Tooltip>
            <template #title>
              <div>message ....</div>
            </template>

            <PhInfo weight="fill" class="text-primary cursor-pointer" size="16" />
          </Tooltip>
        </h3>

        <div class="flex items-center gap-4">
          <CustumNumberInput
            name="savinngs"
            class="flex-1"
            :defaultData="0"
            placeholder=" "
            prefix="$"
            :rules="{min:0}"
            @setInput="setInput"
          />
          <CustumSelect
            name="spots"
            placeholder="select type "
            :data="spacesData"
            :defaultData="2"
            :rules="{
            required: false,
          }"
            @setInput="setInput"
          />
        </div>
      </div>

      <div>
        <h3 class="mb-3">How much is the employee portion for public transit pass ($0 if none)?</h3>

        <div class="flex items-center gap-4">
          <CustumNumberInput
            name="savinngs"
            class="flex-1"
            :defaultData="0"
            placeholder=" "
            prefix="$"
            :rules="{min:0}"
            @setInput="setInput"
          />
          <CustumSelect
            name="spots"
            placeholder="select type "
            :data="spacesData"
            :defaultData="2"
            :rules="{
            required: false,
          }"
            @setInput="setInput"
          />
        </div>
      </div>

      <div>
        <h3 class="mb-3">Do you offer public transit pass to all employees?</h3>

        <RadioGroup :value="1">
          <Radio :style="radioStyle" :value="2" class="w-full">
            <h4 class="text-base font-normal">Yes</h4>
          </Radio>
          <Radio :style="radioStyle" :value="1">
            <div class="w-full flex items-center gap-4">
              <h4 class="text-base font-normal">No, how many:</h4>
              <CustumNumberInput
                class="flex-1"
                name="savinngs"
                :defaultData="0"
                placeholder=" "
                :rules="{min:0}"
                @setInput="setInput"
              />
            </div>
          </Radio>
        </RadioGroup>
      </div>

      <div>
        <h3 class="mb-1">Program start date:</h3>
        <CustumCalender
          name="expiration"
          :defaultData="defaultDate"
          :minDate="defaultDate"
          defaultFormate="MMM, DD YYYY"
          placeholder="Program start date"
          :rules="{
            required: false,
            }"
          @setInput="setInput"
        />
      </div>
    </div>
  </ModalCard>
</template>

<script>
import { PhInfo } from 'phosphor-vue';
import { Tooltip, Radio, RadioGroup } from 'ant-design-vue';

import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import CustumCalender from '@/components/shared/form/CustumCalender.vue';

import ModalCard from './ModalCard.vue';

export default {
  components: {
    ModalCard,
    PhInfo,
    Tooltip,
    Radio,
    RadioGroup,
    CustumSelect,
    CustumNumberInput,
    CustumCalender,
  },
  data() {
    return {
      spacesData: [
        { id: 1, name: 'per day' },
        { id: 2, name: 'per month' },
        { id: 3, name: 'per year' },
      ],
    };
  },
};
</script>
