<template>
  <div class="p-4">
    <h3 class="text-base text-center mb-6">{{title}}</h3>

    <div v-if="modeData" class="w-full flex flex-col gap-4">
      <!-- start  -->
      <div class="flex items-center gap-2">
        <span class="text-primary-dark">
          <PhCar weight="duotone" size="22" />
        </span>
        <p class="flex-1">Drive-Alone</p>
        <InputNumber disabled class="w-16" :value="data?.drive" :min="0" />
      </div>
      <!-- / end  -->

      <!-- start  -->
      <div class="flex items-center gap-2">
        <span class="text-primary-dark">
          <PhLightning weight="duotone" size="22" />
        </span>
        <p class="flex-1">Drive alone using Electric Vehicle</p>
        <InputNumber disabled class="w-16" :value="data?.drive_ev" :min="0" />
      </div>
      <!-- / end  -->

      <!-- start  -->
      <div class="flex items-center gap-2">
        <span class="text-primary-dark">
          <PhBus weight="duotone" size="22" />
        </span>
        <p class="flex-1">Public Transit</p>
        <InputNumber disabled class="w-16" :value="data?.public_transit" :min="0" />
      </div>
      <!-- / end  -->

      <!-- start  -->
      <div class="flex items-center gap-2">
        <span class="text-primary-dark">
          <PhPersonSimpleWalk weight="duotone" size="22" />
        </span>
        <p class="flex-1">Walk</p>
        <InputNumber disabled class="w-16" :value="data?.walk" :min="0" />
      </div>
      <!-- / end  -->

      <!-- start  -->
      <div class="flex items-center gap-2">
        <span class="text-primary-dark">
          <PhBicycle weight="duotone" size="22" />
        </span>
        <p class="flex-1">Bike</p>
        <InputNumber disabled class="w-16" :value="data?.bike" :min="0" />
      </div>
      <!-- / end  -->

      <!-- start  -->
      <div class="flex items-center gap-2">
        <span class="text-primary-dark">
          <PhUsersFour weight="duotone" size="22" />
        </span>
        <p class="flex-1">Carpool</p>
        <InputNumber disabled class="w-16" :value="data?.carpool" :min="0" />
      </div>
      <!-- / end  -->

      <!-- start  -->
      <div class="flex items-center gap-2">
        <span class="text-primary-dark">
          <PhBus weight="duotone" size="22" />
        </span>
        <p class="flex-1">Park and Ride</p>
        <InputNumber disabled class="w-16" :value="data?.transit_drive" :min="0" />
      </div>
      <!-- / end  -->

      <!-- start  -->
      <div class="flex items-center gap-2">
        <span class="text-primary-dark">
          <PhHouse weight="duotone" size="22" />
        </span>
        <p class="flex-1">Telecommute</p>
        <InputNumber disabled class="w-16" :value="data?.telecommute" :min="0" />
      </div>
      <!-- / end  -->

      <!-- start  -->
      <div class="flex items-center gap-2">
        <p class="flex-1">Monetary incentives for each 1000 points</p>
        <InputNumber
          disabled
          class="w-16"
          :value="data?.thousand_points_incentives"
          prefix="$"
          :min="0"
        />
      </div>
      <!-- / end  -->
    </div>

    <div class="w-full flex flex-col gap-2 mt-6">
      <!-- <Button block @click="chooseDefault" :disabled="loading">Choose Default</Button>
      <Button type="primary" size="large" block @click="handleSave" :loading="loading">Save Benefits</Button>-->
      <Button
        v-if="defaultRewardData"
        type="primary"
        class="btn-light"
        block
        :disabled="loading"
        @click="() => $emit('close')"
      >Cancel</Button>
    </div>
  </div>
</template>

<script>
import { Button, InputNumber } from 'ant-design-vue';
import {
  PhCar,
  PhLightning,
  PhBus,
  PhPersonSimpleWalk,
  PhBicycle,
  PhUsersFour,
  PhHouse,
} from 'phosphor-vue';
import { mapMutations } from 'vuex';
import { REWARD } from '@/store/types';

export default {
  components: {
    Button,

    PhCar,
    PhLightning,
    PhBus,
    PhPersonSimpleWalk,
    PhBicycle,
    PhUsersFour,
    PhHouse,
    InputNumber,
  },
  props: {
    title: String,
    id: Number,
    data: Object,
  },
  data() {
    return {
      vals: {
        // drive: 20,
        // drive_ev: 100,
        // transit_drive: 100,
        // telecommute: 100,
        // walk: 250,
        // thousand_points_incentives: 10,
        // public_transit: 100,
        // bike: 250,
        // carpool: 150,
      },
    };
  },

  computed: {
    rewardData() {
      return this.$store.getters.rewardSettingsData;
    },
    defaultRewardData() {
      return this.$store.getters.rewardDefaultsData;
    },
    loading() {
      return this.$store.state.reward.rewardSettingsQuery?.isFetching;
    },
    modeData() {
      return this.$store.state.reward.modeData?.mode_base || null;
    },
    defaultData() {
      return this.$store.state.reward.modeDefaultData?.mode_base || null;
    },
  },
  created() {
    if (this.rewardData?.scoring_policies?.mode_based) {
      this.vals = { ...this.rewardData?.scoring_policies?.mode_based };
    }
  },

  methods: {
    ...mapMutations([REWARD.SET_MODE_DATA]),
    chooseDefault() {
      if (!this.defaultRewardData || !this.defaultRewardData.mode_based) return;
      this.vals = { ...this.vals, ...this.defaultRewardData.mode_based };
    },
    async handleSave() {
      const defaultData = { ...this.rewardData.scoring_policies, mode_based: { ...this.vals } };
      await this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        scoring_policies: defaultData,
      });

      this.$emit('close');
    },
  },
};
</script>
