/* eslint-disable */
<template>
  <div class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5">
    <div class="flex justify-between">
      <Checkbox v-model:checked="checked" :disabled="isOngoing && hasStarted">
        <h3 class="text-xl text-primary-dark">Weekly credit to employees (random selection )</h3>
      </Checkbox>

      <SaveBtn
        :disabled="!isShowCheckedSaved"
        :loading="isFetching"
        @click="handleSave"
        class="mt-0"
      />
    </div>
    <div v-if="rewardData && checked">
      <div class='text-end'> <span v-if='lengthOfWeeklyStructure < 3' @keyup='()=>{console.log("Add Weekly Credit")}' @click='addWeeklyCredit' style='font-size: 12px;font-weight: bolder;display: inline-block;padding: 3px 6px 3px 6px;border-radius: 10px;background-color: #278C64;color: white;cursor:pointer;'>+</span> </div>
      <div class="mt-6" v-for='(weekly_credit_structure,index) in weekly_credit_structures' :key='index'>
        <div class='text-end'> <span v-if='index == (lengthOfWeeklyStructure - 1) && index != 0' @keyup='()=>{console.log("Remove Weekly Credit")}' @click='removeWeeklyCredit' style='font-size: 12px;font-weight: bolder;display: inline-block;padding: 3px 6px 3px 6px;border-radius: 10px;background-color: #C7CBCD; color: black;cursor:pointer;'>-</span> </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
          <div class="text-center flex flex-wrap items-center justify-center gap-2">
            <span class="font-bold">Amount</span>
            <InputNumber
              :disabled="isOngoing && hasStarted"
              class="w-20"
              :value="weekly_credit_structure.value"
              @change="(val) => setVal(+val, 'value',index)"
              :min="0"
              prefix="$"
              :step="0.5"
            />
            <span class="font-bold">~ 4 winners per month</span>
          </div>

          <div>
            <Spin :spinning="isFetching">
              <RadioGroup
                :disabled="isOngoing && hasStarted"
                :value="weekly_credit_structure.type"
                @change="(e) => setVal(e.target.value, 'type',index)"
                class="flex flex-col gap-1"
                v-model='weekly_credit_structure.type'
              >
                <Radio value="random_max_trips_reporter">Commuters that report
                  <InputNumber
                    class="w-20"
                    :value='validateNumberOfTrips(weekly_credit_structure)'
                    :min="0"
                    :step="1"
                    @change="(e) => setVal(e, 'config',index)"
                  />
                  or more trips to the office</Radio>
                <Radio value="random_reporter">Commuters that report any commute trips</Radio>
                <Radio value="random_green_reporter">Commuters that report only green commute trips</Radio>
                <Radio
                  value="random_full_week_reporter"
                >Commuters that report full week of commute trips</Radio>
              </RadioGroup>
            </Spin>
          </div>
        </div>
        <Divider />
      </div>
      <div class="w-full flex justify-center md:justify-end">
        <SaveBtn :disabled="!isValueChanged" :loading="isFetching" @click="handleSave" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Checkbox, InputNumber, Radio, RadioGroup, Spin, Divider } from 'ant-design-vue';
import { mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import { REWARD } from '../../../../store/types';
import SaveBtn from '../shared/SaveBtn.vue';

export default {
  components: {
    Checkbox,
    InputNumber,
    Radio,
    RadioGroup,
    Spin,
    SaveBtn,
    Divider,
  },
  props: {
    isOngoing: {
      type: Boolean,
      default: false,
    },
    hasStarted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
      radioValue: 1,
      weekly_credit_structures: [
        {
          value: 20,
          type: 'random_reporter',
        }]
      ,
    };
  },
  created() {
    if (this.rewardData) {
      // // console.log("this.rewardData.weekly_credits => ", this.rewardData.weekly_credits);
      // validate config to get away from any errors

      const data = this.rewardData.weekly_credits ?? this.weekly_credit_structures;
      this.weekly_credit_structures = data;
      this.checked = this.rewardData.weekly_credit_enabled;
    }
  },
  computed: {
    rewardData() {
      return this.$store.getters.rewardSettingsData;
    },
    defaultRewardData() {
      return this.$store.getters.rewardDefaultsData;
    },
    isFetching() {
      return this.$store.state.reward.rewardSettingsQuery?.isFetching;
    },
    signupIncentives() {
      return this.$store.state.reward.signup_incentives;
    },
    isValueChanged() {
      // console.log("are we here ?")
      if (this.checked && this.checked !== this.rewardData.weekly_credit_enabled) return true;
      let isChanged = false;
      let validateIfWeeklyCredits = this.rewardData.weekly_credits ?? this.weekly_credit_structures;
      if (JSON.stringify(validateIfWeeklyCredits) !== JSON.stringify(this.weekly_credit_structures)) {
        isChanged = true;
      }
      return isChanged;
    },
    // checked() {
    //   return this.$store.state.reward.is_signup_incentives_selected;
    // },
    isShowCheckedSaved() {
      return !this.checked && this.checked !== this.rewardData.weekly_credit_enabled;
    },
    lengthOfWeeklyStructure() {
      return this.weekly_credit_structures.length;
    },
  },
  methods: {
    ...mapMutations([REWARD.SET_SIGNUP_INCENTIVES_DATA, REWARD.SET_SELECTED_SYSTEM]),
    handleSave() {
      // console.log("this.weekly_credit_structures =>",this.weekly_credit_structures)
      let weekly_credit_structures = this.weekly_credit_structures
      this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        weekly_credits: {
          ...weekly_credit_structures,
        },
        weekly_credit_enabled: this.checked,
      });
    },
    setVal(val, key, index) {
      // console.log("val => ", val)
      let ourObject = {}
      if(key == 'config'){
        ourObject = {...this.weekly_credit_structures[index]}
        ourObject.config={
          number_of_trips:val
        }
      }else if(key == 'value'){
        ourObject = {...this.weekly_credit_structures[index]}
        ourObject['value'] = val
      }
      else{
        ourObject = {...this.weekly_credit_structures[index]}
        ourObject[key] = val
      }
      let newArray=[];
      // console.log("ourObject => ",ourObject)
      // console.log("weekly_credit_structures before map ",this.weekly_credit_structures)
      this.weekly_credit_structures.map((item,indexMatch) => {
        let config = {}
        if(!item.config) {item.config={number_of_trips:3}}

        if (index == indexMatch){
          newArray.push(ourObject)
        }else{
          newArray.push(item)
        }
      })

      // console.log("newArray =>",newArray)
      this.weekly_credit_structures = newArray;
      // console.log("newArray this.weekly_credit_structures=>",this.weekly_credit_structures)
      this.debounceSetVal(val, key, this);
    },
    debounceSetVal: debounce((value, key, app) => {
      app.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        weekly_credits: {
          ...this.weekly_credit_structure
        },
      });
    }, 600),

    handleSelect(e) {
      this.$store.dispatch(REWARD.ACTION_SELECT_REWARD_SYS, {
        weekly_credit_enabled: e.target.checked,
      });
    },
    addWeeklyCredit() {
      // console.log(this.weekly_credit_structures.length);
      const length = this.weekly_credit_structures;
      if (length >= 3) return;
      // console.log('after if ! ');
      let newArray = []
      this.weekly_credit_structures.map(item => {
        newArray.push(item)
      })
      newArray.push({
        value: 25,
        type: 'random_reporter',
      });
      this.weekly_credit_structures = newArray;
      // console.log(' this.weekly_credit_structures', this.weekly_credit_structures);
    },
    removeWeeklyCredit() {
      let newArray = []
      this.weekly_credit_structures.map(item => {
        newArray.push(item)
      })
      newArray.pop();
      this.weekly_credit_structures = newArray;
      // this.weekly_credit_structures.pop();
    },
    validateNumberOfTrips(weekly_credit_structure){
      if(weekly_credit_structure.config && weekly_credit_structure.config.number_of_trips){
        return weekly_credit_structure.config.number_of_trips
      }
      return 3
    }
  },
  watch:{
    weekly_credit_structures:function (val){
      // console.log("watchVal => ", val)
    }
  }
};
</script>
