<template>
  <div class="flex items-center justify-center flex-col gap-2">
    <div
      class="w-36 min-h-[9rem] rounded-full overflow-hidden flex flex-col items-stretch bg-bg-dark"
    >
      <div
        class="rounded-t-full text-center flex-1 flex items-center justify-center py-1"
        :style="{color: color}"
      >
        <slot />
      </div>
      <div
        class="text-center rounded-b-full flex-1 text-bg-light flex flex-col items-start justify-start py-1"
        :style="{background: color}"
      >
        <small class="rounded-b-full leading-tight w-2/3 mx-auto mt-1">{{ text.slice(0 , 30) }}</small>

        <small
          class="rounded-b-full tracking-tight leading-tight w-2/5 mx-auto"
        >{{ text.slice( 30) }}</small>
      </div>
    </div>
    <div class="text-center">
      <slot name="input"></slot>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    text: String,
    cost: Number,
    color: String,
  },
};
</script>
