/* eslint-disable */
<template>
  <section class="members-data">
    <div class="w-full mt-4 flex flex-wrap gap-4 items-center justify-between">
      <div class="flex items-center gap-3 flex-wrap">
        <Select
          class="text-center min-w-[250px]"
          @change="handleEmployeeChange"
          @search="handleEmployeeSearch"
          allowClear
          placeholder="Search Employee"
          :default-active-first-option="false"
          :show-arrow="false"
          show-search
          :options="employeesSearchData"
          :filter-option="false"
          :not-found-content="null"
          :loading="employeesQuery?.isLoading"
        />

        <Spin :spinning="employersQuery && employersQuery.isLoading">
          <Select
            class="text-center min-w-[250px]"
            :value="employer_id"
            @change="handleSelectBusiness"
            allowClear
            placeholder="Select business"
          >
            <select-option v-for="buss in employersData" :key="buss._id" :value="buss._id"
              >{{ buss.community_name }} , {{ buss.name }}</select-option
            >
          </Select>
        </Spin>
        <!-- <Button type="primary" @click="handleShowCreditModal">Withdraw Credit</Button> -->
      </div>

      <div class="flex items-center gap-3">
        <CustumSelect
          name="transactionType"
          placeholder="select type "
          :data="typeData"
          :defaultData="transactionType"
          :rules="{
            required: false,
          }"
          @setInput="setInput"
          class="min-w-[120px] mr-6"
        />

        <div>
          <date-picker v-if="type !== 'all'" :picker="type" v-model:value="dateVal" />
        </div>

        <CustumSelect
          name="type"
          placeholder="select type "
          :data="dateData"
          :defaultData="type"
          :rules="{
            required: false,
          }"
          @setInput="setInput"
        />
      </div>
    </div>

    <QueryAlert :query="employersQuery" />
    <QueryAlert :query="walletTransactionsQuery" />

    <Alert
      v-if="!employee_id"
      message="Please select an employee"
      type="warning"
      show-icon
      class="mt-5"
    />
    <!-- add credit modal  -->
    <Modal
      :visible="creditModal"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="creditModal = false"
      @cancel="creditModal = false"
    >
      <div class="p-4">
        <h2>Withdraw credit from : {{ activeEmployer?.name }}</h2>
        <Row>
          <Col :span="24">
            <CustumNumberInput
              name="credit_value"
              label="Amount"
              :rules="{
                required: true,
                min: 1,
              }"
              @setInput="setInput2"
            />
          </Col>
        </Row>
        <Button
          type="primary"
          block
          class="mt-4"
          :loading="addCreditLoading"
          @click="handleAddCredit"
          >Withdraw Credit</Button
        >
      </div>
    </Modal>
    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table
        :data-source="tableFormatedData"
        :columns="columns"
        :loading="
          walletTransactionsQuery &&
          (walletTransactionsQuery.isFetching || walletTransactionsQuery.isLoading)
        "
        :pagination="false"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'report_date'">
            <span v-if="record.report_date" class="whitespace-nowrap">{{
              getDate(record.report_date)
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'trip_date'">
            <span v-if="record.trip_date" class="whitespace-nowrap">{{
              getDate(record.trip_date)
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'trip_mode'">
            <span v-if="record?.trip_mode" class="whitespace-nowrap">{{ record?.trip_mode }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'type'">
            <span v-if="record.type" class="whitespace-nowrap">{{ record.type }}</span>
            <span v-else class="whitespace-nowrap text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'status'">
            <span v-if="record.status" class="whitespace-nowrap">{{ record.status }}</span>
            <span v-else class="whitespace-nowrap text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'amount'">
            <span v-if="record.amount" class="whitespace-nowrap">${{ record.amount }}</span>
            <span v-else class="whitespace-nowrap text-yellow-600">N/A</span>
          </template>
          <template v-if="column.key === 'trip_time'">
            <span v-if="record.report_date" class="whitespace-nowrap">{{
              formatTimestamp(record?.report_date)
            }}</span>
            <span v-else class="whitespace-nowrap text-yellow-600">N/A</span>
          </template>
          <template v-if="column.key === 'action'">
            <span v-if="record?.status == 'on_hold'" class="whitespace-nowrap">
              <Row class="gap-1">
                <Popconfirm
                  title="Are you sure you want to delete?(with trip)"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="
                    () => {
                      handleConfirm(record?.key, true);
                    }
                  "
                >
                  <Button
                    type="primary"
                    size="small"
                    title="Delete - with trip"
                    class="btn-error rounded shadow flex items-center gap-2"
                  >
                    <PhTrash weight="duotone" :size="16" />
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure you want to delete ?(without trip)"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="
                    () => {
                      handleConfirm(record?.key, false);
                    }
                  "
                >
                  <Button
                    type="primary"
                    size="small"
                    title="Delete - without trip"
                    class="btn-error rounded shadow flex items-center gap-2"
                  >
                    <PhTrash weight="duotone" :size="16" />

                  </Button>
                </Popconfirm>
              </Row>
            </span>
            <span v-else class="whitespace-nowrap text-yellow-600">N/A</span>
          </template>
        </template>
      </Table>
      <div
        v-if="walletTransactionsData && walletTransactionsData.meta && employee_id"
        class="mt-6 flex items-center justify-center"
      >
        <Pagination
          :current="walletTransactionsData?.meta?.current_page"
          :total="walletTransactionsData?.meta?.total"
          show-less-items
          :page-size="walletTransactionsData?.meta?.per_page"
          @change="handlePaginationChange"
        />
      </div>
    </div>

    <!-- query apis  -->
    <WalletTransactionsQuery
      :page="page"
      :per_page="15"
      for_employee="1"
      :employer_id="employer_id"
      :employee_id="employee_id"
      :type="transactionType"
      :start_date="dates[0]"
      :end_date="dates[1]"
      :key="`${page}-${15}-${employer_id}-${dates[0]}-${
        dates[1]
      }-${employee_id}-${transactionType}`"
    />
    <EmployersQuery />

    <EmployeesQuery
      :page="1"
      :per_page="20"
      :keyword="filterkey"
      :employer_id="employer_id"
      :key="`${1}-${20}-${filterkey}-${employer_id}`"
      :all="true"
    />
  </section>
</template>
<script>
/* eslint-disable */
import {
  Table,
  Select,
  SelectOption,
  Pagination,
  DatePicker,
  Spin,
  Alert,
  Modal,
  Row,
  Col,
  Button,
  message,
  Popconfirm,
} from 'ant-design-vue';

import dayjs from 'dayjs';

import EmployersQuery from '@/services/queries/admin/employers/EmployersQuery.vue';
import EmployeesQuery from '@/services/queries/admin/employees/EmployeesQuery.vue';
import WalletTransactionsQuery from '@/services/queries/admin/transactions/WalletTransactionsQuery.vue';
import QueryAlert from '@/components/shared/QueryAlert.vue';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import debounce from 'lodash.debounce';
import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import { PhProhibit, PhTrash } from 'phosphor-vue';
import transactionsApi from '@/services/apis/admin/transactions.api';

export default {
  components: {
    Table,
    Row,
    Col,
    Button,
    Select,
    SelectOption,
    Spin,
    QueryAlert,
    EmployersQuery,
    WalletTransactionsQuery,
    PhProhibit,
    Pagination,
    DatePicker,
    CustumSelect,
    EmployeesQuery,
    Modal,
    message,
    PhTrash,
    CustumNumberInput,
    Popconfirm,
    Alert,
  },
  data() {
    return {
      columns: [
        // {
        //   title: '#',
        //   key: 'key',
        //   dataIndex: 'key',
        // },
        {
          title: 'Commuter',
          dataIndex: 'name',
          key: 'name',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.name).getTime();
          //   const bDate = new Date(b.name).getTime();
          //   return bDate - aDate;
          // },
        },

        {
          title: 'Transaction report Date',
          dataIndex: 'report_date',
          key: 'report_date',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.report_date).getTime();
          //   const bDate = new Date(b.report_date).getTime();
          //   return bDate - aDate;
          // },
        },

        {
          title: 'Transaction trip/credit Date',
          dataIndex: 'trip_date',
          key: 'trip_date',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.trip_date).getTime();
          //   const bDate = new Date(b.trip_date).getTime();
          //   return bDate - aDate;
          // },
        },

        {
          title: 'Transaction Type',
          dataIndex: 'type',
          key: 'type',
          // sorter: (a, b) => {
          //   if (a.type > b.type) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Trip / Transportation',
          dataIndex: 'trip_mode',
          key: 'trip_mode',
          // sorter: (a, b) => {
          //   if (a.type > b.type) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: 'Trip / Transportation Time',
          dataIndex: 'trip_time',
          key: 'trip_time',
          // sorter: (a, b) => {
          //   if (a.type > b.type) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: 'Transaction amount',
          dataIndex: 'amount',
          key: 'amount',
          // sorter: (a, b) => {
          //   if (a.amount > b.amount) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          // sorter: (a, b) => {
          //   if (a.status > b.status) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          // sorter: (a, b) => {
          //   if (a.status > b.status) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
      ],

      tableData: [
        {
          key: '1',
          transaction_date: 1681478087456,
          name: 'Transaction Name',
          transaction_type: 't type',
          payment_type: 'payment type',
          payment_status: 'payment status',
        },

        ...Array(50)
          .fill('dd')
          .map((_, ix) => ({
            key: ix + 2,
            name: `Name ${ix + 2}`,
            transaction_date: this.getRandomInt(1420063200000, 1681478087456),
            transaction_type: 't type',
            payment_type: 'payment type',
            payment_status: 'payment status',
          })),
      ],
      page: 1,
      pageSize: 10,
      employer_id: null,
      employee_id: null,
      type: 'month',
      addCreditLoading: false,
      years: [],
      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'year', name: 'Yearly' },
        // { id: 'all', name: 'All Data' },
      ],
      typeData: [
        // weekly_reward', 'monthly_reward', 'trip_reward', 'redeem_credit
        { id: 'weekly_reward', name: 'Weekly reward' },
        { id: 'monthly_reward', name: 'Monthly reward' },
        { id: 'trip_reward', name: 'Trip reward' },
        { id: 'redeem_credit', name: 'Redeem credit' },
      ],
      dateVal: null,
      filterkey: null,
      creditModal: false,

      transactionType: null,
    };
  },
  computed: {
    dates() {
      if (!this.dateVal) return [null, null];
      if (this.type === 'month') {
        const start = dayjs(this.dateVal).startOf('month').format('YYYY-MM-DD');
        const end = dayjs(this.dateVal).endOf('month').format('YYYY-MM-DD');
        return [start, end];
      }

      if (this.type === 'year') {
        const start = dayjs(this.dateVal).startOf('year').format('YYYY-MM-DD');
        const end = dayjs(this.dateVal).endOf('year').format('YYYY-MM-DD');
        return [start, end];
      }

      return [null, null];
    },
    employersQuery() {
      return this.$store.state.adminEmployers.employersQuery;
    },
    employersData() {
      return this.$store.getters.employersData?.data || [];
    },
    walletTransactionsQuery() {
      return this.$store.state.walletTransactios.walletTransactionsQuery;
    },
    walletTransactionsData() {
      return this.$store.getters.walletTransactionsData;
    },
    tableFormatedData() {
      if (!this.walletTransactionsData || !this.walletTransactionsData.data || !this.employee_id) {
        return [];
      }

      const data = this.walletTransactionsData.data.map((d, ix) => ({
        // eslint-disable-next-line no-underscore-dangle
        key: d._id || d.id || ix,
        name: d.description,
        report_date: d.datetime,
        trip_date: d.source ? d.source.trip_date : null,
        type: d.source_type,
        amount: d.value,
        status: d.status,
        trip_mode: d?.source?.trip_mode,
      }));
      return data;
    },

    employeesQuery() {
      return this.$store.state.adminEmployees.employeesQuery;
    },
    employeesData() {
      return this.$store.getters.employeesData;
    },
    employeesSearchData() {
      const data = this.employeesData?.data || [];
      return data.map((d) => ({
        ...d,
        name: d.name,
        label: d.name,
        // eslint-disable-next-line no-underscore-dangle
        id: d._id || d.id,
        // eslint-disable-next-line no-underscore-dangle
        value: d._id || d.id,
      }));
    },
  },
  methods: {
    async handleConfirm(id, withTrip) {
      try {
        let res;

        res = await transactionsApi.deleteTransaction(id, withTrip);

        if (res) {
          message.success('Transaction deleted successfully');
        }
        if (this.walletTransactionsQuery) {
          await this.walletTransactionsQuery.refetch();
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      } finally {
      }
    },
    handleShowCreditModal() {
      if (!this.employee_id) {
        message.error('Please select a Employee');
        return;
      }
      this.creditModal = true;
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);

      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        // timeZoneName: 'short',
      };

      return date.toLocaleString('en-US', options);
    },

    async handleAddCredit() {
      if (!this.credit_value || this.credit_value <= 0) {
        message.error('Please enter credit value');
        return;
      }

      if (!this.employer_id) {
        message.error('Please select a business');
        return;
      }
      this.addCreditLoading = true;

      try {
        await transactionsApi.postAddCredit({
          employer_id: this.employer_id,
          value: this.credit_value,
          invoice_balance: true,
        });

        message.success('Credit added successfully');
        if (this.walletTransactionsQuery) {
          this.walletTransactionsQuery.refetch();
        }
        this.addCreditLoading = false;
        this.creditModal = false;
      } catch (error) {
        message.error(handleResErrors(error));
      } finally {
        this.addCreditLoading = false;
      }
    },
    handlePaginationChange(page, pageSize) {
      // , filters, sorter, data
      this.page = page;
      this.pageSize = pageSize;
    },
    getRandomInt(min, max) {
      const xmin = Math.ceil(min);
      const xmax = Math.floor(max);
      return Math.floor(Math.random() * (xmax - xmin + 1)) + min;
    },
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMM DD, YYYY');
    },

    handleSelectBusiness(val) {
      this.page = 1;
      this.employer_id = val;
    },
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
      if (val.transactionType && val.transactionType.value) {
        this.transactionType = val.transactionType.value;
      }
    },
    setInput2(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
      if (val.credit_value && val.credit_value.value) {
        this.credit_value = val.credit_value.value;
      }
    },
    handleEmployeeSearch(val) {
      this.debounceEmployeeSearch(val, this);
    },
    debounceEmployeeSearch: debounce((search, app) => {
      // eslint-disable-next-line no-param-reassign
      app.filterkey = search;
    }, 400),
    handleEmployeeChange(val) {
      this.employee_id = val;
    },
  },
};
</script>
