<template>
  <div class="bg-bg rounded-lg shadow-lg flex flex-col items-center mt-8">
    <div
      class="bg-bg w-16 h-16 rounded-full border border-solid flex items-center justify-center -mt-8 shadow-sm"
      :style="{borderColor: `var(--color-${color})`}"
    >
      <slot name="icon"></slot>
    </div>
    <div class="w-full">
      <slot />
    </div>

    <div v-if="!noBtn" class="w-full flex items-center justify-center pb-4 mt-auto">
      <Button type="primary" class="btn-warning" @click="handleModalOpen">Set Program</Button>
    </div>

    <Modal
      :visible="!!visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal  "
      center
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <ParkingModal v-if="modal === 'parking'" @close="handleModalClose" />
      <TransitPassModal v-if="modal === 'transit-pass'" @close="handleModalClose" />
      <ParkingCashOutModal v-if="modal === 'parking-cash-out'" @close="handleModalClose" />
      <VanPoolModal v-if="modal === 'vanpool-shuttle'" @close="handleModalClose" />
      <GuaranteedRideHomeModal v-if="modal === 'guaranteed-ride-home'" @close="handleModalClose" />
      <GenericTransportationBenefitsModal
        v-if="modal === 'generic-benefits'"
        @close="handleModalClose"
      />
      <CarpoolingRideSharingModal v-if="modal === 'ride-sharing'" @close="handleModalClose" />
      <BikingModal v-if="modal === 'bike'" @close="handleModalClose" />
      <WalkingModal v-if="modal === 'walk'" @close="handleModalClose" />
    </Modal>
  </div>
</template>

<script>
import { Button, Modal } from 'ant-design-vue';

import ParkingModal from '../modals/ParkingModal.vue';
import TransitPassModal from '../modals/TransitPassModal.vue';
import ParkingCashOutModal from '../modals/ParkingCashOutModal.vue';
import VanPoolModal from '../modals/VanPoolModal.vue';
import GuaranteedRideHomeModal from '../modals/GuaranteedRideHomeModal.vue';
import GenericTransportationBenefitsModal from '../modals/GenericTransportationBenefitsModal.vue';
import CarpoolingRideSharingModal from '../modals/CarpoolingRideSharingModal.vue';
import BikingModal from '../modals/BikingModal.vue';
import WalkingModal from '../modals/WalkingModal.vue';

export default {
  components: {
    Button,
    Modal,
    ParkingModal,
    TransitPassModal,
    ParkingCashOutModal,
    VanPoolModal,
    GuaranteedRideHomeModal,
    GenericTransportationBenefitsModal,
    CarpoolingRideSharingModal,
    BikingModal,
    WalkingModal,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    noBtn: Boolean,
    modal: String,
  },
  data() {
    return {
      visible: false,
      currentModals: [
        'parking',
        'transit-pass',
        'parking-cash-out',
        'vanpool-shuttle',
        'guaranteed-ride-home',
        'generic-benefits',
        'ride-sharing',
        'bike',
        'walk',
      ],
    };
  },
  methods: {
    handleModalClose() {
      this.visible = false;
    },
    handleModalOpen() {
      if (this.modal && this.currentModals.includes(this.modal)) {
        this.visible = true;
      }
    },
  },
};
</script>
