<template>
  <div v-if="data" class="mt-6 lg:mt-8">
    <Table
      :data-source="data"
      :columns="columns"
      :loading="scoresQuery && (scoresQuery.isFetching || scoresQuery.isLoading)"
      @change="handlePaginationChange"
      :pagination="pagination"
    >
      <!-- :pagination="false" -->
      <template #headerCell="{ column }">
        <template v-if="column.key === 'name'">
          <div class="flex flex-col items-center gap-2 lg:flex-row">
            <PhUserCircle weight="duotone" size="28" class="flex-shrink-0" />
            <p>{{ column.title }}</p>
          </div>
        </template>
        <template v-if="column.key === 'community_points'">
          <div class="flex flex-col justify-center items-center gap-2 lg:flex-row">
            <PhGift weight="duotone" size="28" class="flex-shrink-0" />
            <p>{{ column.title }}</p>
          </div>
        </template>

        <template v-if="column.key === 'savings'">
          <div class="flex flex-col justify-center items-center gap-2 lg:flex-row">
            <PhBank weight="duotone" size="28" class="flex-shrink-0" />
            <p>{{ column.title }}</p>
          </div>
        </template>

        <template v-if="column.key === 'non-sov'">
          <div class="flex flex-col justify-center items-center gap-2 lg:flex-row">
            <PhLeaf weight="duotone" size="28" class="flex-shrink-0" />
            <p>{{ column.title }}</p>
          </div>
        </template>

        <template v-if="column.key === 'monetary_incentives'">
          <div class="flex flex-col justify-center items-center gap-2 lg:flex-row">
            <PhCoins weight="duotone" size="28" class="flex-shrink-0" />
            <p>{{ column.title }}</p>
          </div>
        </template>
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <div
            class="flex flex-col justify-center items-center gap-2 lg:flex-row"
            :title="record.name"
          >
            <!-- <img
              v-if="record.image"
              :src="record.image"
              alt="user image"
              class="w-8 h-8 rounded-full border border-solid border-primary-dark"
            />

            <img
              v-else
              src="@/assets/images/user-placeholder.webp"
              alt="user image"
              class="w-8 h-8 rounded-full border border-solid border-primary-dark"
            />-->
            <h4 class="w-full truncate">{{ record.name }}</h4>
          </div>
        </template>

        <template v-if="column.key === 'community_points'">
          <div class="flex flex-col justify-center items-center gap-2 lg:flex-row">
            <p>{{ formatNum(record.community_points.toFixed(2)) }}</p>
          </div>
        </template>

        <template v-if="column.key === 'savings'">
          <div class="flex flex-col justify-center items-center gap-2 lg:flex-row">
            <p>${{ formatNum(record.savings) }}</p>
          </div>
        </template>

        <template v-if="column.key === 'non-sov'">
          <div class="flex flex-col justify-center items-center gap-2 lg:flex-row">
            <p>{{ formatNum(record['non-sov']) }}</p>
          </div>
        </template>

        <template v-if="column.key === 'monetary_incentives'">
          <div class="flex flex-col justify-center items-center gap-2 lg:flex-row">
            <p>${{ formatNum(record?.monetary_incentives?.toFixed(2)) }}</p>
          </div>
        </template>
      </template>
    </Table>
  </div>
</template>

<script>
import { Table } from 'ant-design-vue';
import { PhCoins, PhGift, PhLeaf, PhBank, PhUserCircle } from 'phosphor-vue';
import formatNumbers from '../../../utils/formatNumbers';

export default {
  components: { Table, PhCoins, PhGift, PhLeaf, PhBank, PhUserCircle },
  props: {
    data: Array,
    meta: Object,
    pageNum: Number,
    pageSize2: Number,
  },
  data() {
    return {
      columns: [
        {
          title: 'Commuter',
          key: 'name',
          dataIndex: 'name',
          sorter: (a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            return -1;
          },
        },
        {
          title: 'Community Points',
          dataIndex: 'community_points',
          key: 'community_points',
          sorter: (a, b) => b.community_points - a.community_points,
          defaultSortOrder: 'ascend',
        },
        {
          title: 'Commute Savings',
          dataIndex: 'savings',
          key: 'savings',
          sorter: (a, b) => Number(b.savings) - Number(a.savings),
        },
        {
          title: 'Number of non-SOV trips',
          key: 'non-sov',
          dataIndex: 'non-sov',
          sorter: (a, b) => Number(b['non-sov']) - Number(a['non-sov']),
        },
        {
          title: 'Monetary Incentives',
          key: 'monetary_incentives',
          dataIndex: 'monetary_incentives',
          sorter: (a, b) => Number(b.monetary_incentives) - Number(a.monetary_incentives),
        },
      ],
    };
  },

  computed: {
    scoresQuery() {
      return this.$store.state.scores.scoresQuery;
    },
    pagination() {
      return {
        defaultPageSize: this?.pageSize2,
        total: (this?.pageSize2 ?? 0) * (this?.pageNum ?? 0) + 1,
        pageSizeOptions: this?.pageSizeOptionsArr,
        showSizeChanger: true,
        defaultCurrent: this?.pageNum,
      };
    },
    pageSizeOptionsArr() {
      const dataLen = this.data?.length || 0;
      const pArr = [10];
      pArr.push(20);
      pArr.push(50);
      pArr.push(100);
      pArr.push(500);
      pArr.push(1000);
      pArr.push(dataLen);

      return [...pArr];
    },
  },
  methods: {
    formatNum(val) {
      return formatNumbers(val);
    },
    handlePaginationChange(pag) {
      this.$emit('setPageNum', pag.current);
      this.$emit('setPageSize', Math.floor(pag.pageSize));
    },
  },
};
</script>
