<template>
  <div v-if='!isLoading'>
    <div class="flex items-center gap-4 justify-between">
      <p
        class="text-sm"
      >Select and Customize your transportation program. The tool will calculate your monthly cost per employee.</p>
    </div>
    <div class="flex items-center gap-1 mt-5">
      <h3 class="text-base text-center text-primary-dark">Number of Employees:</h3>
      <div>
        <!-- <p class="text-sm text-center bg-bg border border-solid border-bg-form mx-auto py-2 px-4">
          100
        </p>-->
        <InputNumber class="w-20" v-model:value="numberOfEmpData" :min="1"  :disabled="isDisbaled"/>
      </div>
      <div style='align-self: center' v-if='isProgramStatusActive' >
        <upDownGradeButtons  @upgradeEmployees='upgradeEmployeesDialog' @downgradeEmployees='downgradeEmployeesDialog'  :program='program'/>
      </div>
    </div>
    <div class="flex items-center justify-center md:justify-between gap-4 mt-6">
      <div class="flex items-center gap-2">
        <h3>Monthly program cost per employee:</h3>
        <CustumNumberInput
          class="w-20"
          name="monthCost"
          :defaultData="cost_per_employee"
          :rules="{
            min: 1,
          }"
          @setInput="setInput"
        />
      </div>
        <div>
          <ProgramDates key="simple" :program="program"  @launchTheProgram='launchTheProgram' />
      </div>

<!--      <div>-->
<!--        <Button type="primary" class="px-6" size="large" :disabled='radioVal != 2'>Launch Program</Button>-->
<!--      </div>-->
    </div>
    <div>
      <RewardSystem :mode_base_vals="mode_base_vals" :max_monthly_reward="max_monthly_reward" />
      <WeeklyCredit :weekly_credit_cost="weekly_credit_cost" />
      <MonthlyPrizes />
      <div class="text-center flex flex-col items-center gap-2 mt-6">
        <Button
          type="primary"
          class="btn-info px-8"
          size="large"
          @click="handleModalOpen"
        >Notify All Employees</Button>
        <small>Employees are not notified yet</small>
      </div>
    </div>
    <Modal
      :visible="visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <NotifyModal @close="handleModalClose" />
    </Modal>
    <Modal
      :visible="upgradeGradeMode"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <upDownGrade :number_of_employees='numberOfEmpData' @cancelUpDownGrade='cancelUpDownGrade' upgrade='true' />
    </Modal>
    <Modal
      :visible="downGradeMode"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <upDownGrade :number_of_employees='numberOfEmpData' @cancelUpDownGrade='cancelUpDownGrade' downgrade='true' />
    </Modal>
  </div>
  <Skeleton v-else active ></Skeleton>
</template>

<script>
/* eslint-disable */
import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import { Button, Modal , Skeleton, InputNumber} from 'ant-design-vue';

import RewardSystem from './RewardSystem.vue';
import WeeklyCredit from './WeeklyCredit.vue';
import MonthlyPrizes from './MonthlyPrizes.vue';
import NotifyModal from './shared/NotifyModal.vue';
import ProgramDates from '../shared/ProgramDates.vue';
import subscriptionsApi from '@/services/apis/subscriptions.api';
import upDownGrade from '@/components/dashboard/account/UpDownGrade.vue';
import upDownGradeButtons from '@/components/dashboard/account/UpDownGradeButtons.vue';

export default {
  components: {
    CustumNumberInput,
    Button,
    RewardSystem,
    WeeklyCredit,
    MonthlyPrizes,
    NotifyModal,
    Modal,
    ProgramDates,
    Skeleton,
    InputNumber,
    upDownGrade,
    upDownGradeButtons,
  },
  props: {
    number_of_employees: {
      type: Number,
      default: 0,
    },
    program: {
      type: Object,
      default: () => ({}),
    },
    radioVal: {
      type: Number,
      default: 0,
    },
    isDisbaled:{
      type:Boolean,
      default: true
    },
  },
  data() {
    return {
      visible: false,
      loading:false,
      cost_per_employee: 10,
      // number_of_employees: 100,
      // employer_budget: 1000,
      // prizes_cost: 100,
      // weekly_credit_cost: 100,
      // max_monthly_reward: 1000,

      // mode_base_vals: {
      //   drive: 25,
      //   drive_ev: 25,
      //   public_transit: 25,
      //   walk: 25,
      //   bike: 25,
      //   carpool: 25,
      //   transit_drive: 25,
      //   telecommute: 25,
      //   thousand_points_incentives: 25,
      // },

      // weekly_credit_structure: {
      //   value: 20,
      //   type: 'random_reporter',
      // },

      // monthlyPrizes: {
      //   most_miles_biked: 25,
      //   most_miles_walked: 25,
      //   most_transit_trips: 25,
      //   most_carpool_trips: 25,
      //   biggest_shift_from_driving_to_green_trips: 0,
      //   most_green_trips: 0,
      // },
      numberOfEmpData:0,
      downGradeMode: false,
      upgradeGradeMode: false,
    };
  },

  computed: {
    employer_budget() {
      return this.cost_per_employee * this.numberOfEmpData;
    },
    prizes_cost() {
      return this.employer_budget * 0.1 > 100 ? 100 : 0;
    },
    weekly_credit_cost() {
      return Math.round(this.employer_budget * 0.05 > 80 ? 80 : 0);
    },
    max_monthly_reward() {
      return (this.employer_budget * 1.25) / this.numberOfEmpData;
    },
    mode_base_vals() {
      //   thousand_points_incentives: 25,
      const thousand_points_incentives = 10;
      //   drive: 25,

      const drive =
        ((Math.min(
          (((this.employer_budget -
            (0.1 * this.employer_budget > 100 ? 100 : 0) -
            (0.05 * this.employer_budget > 80 ? 80 : 0)) /
            this.numberOfEmpData) *
            0.4066) /
            0.6676,
          (this.employer_budget * 1.625) / this.numberOfEmpData,
        ) /
          ((30 * 5 * 2) / 7)) *
          1000) /
        thousand_points_incentives /
        1.3;

      const $B$4 = this.employer_budget;
      const $B$3 = this.numberOfEmpData;
      const $B$17 = thousand_points_incentives;

      //   drive_ev: 25,
      const drive_ev =
        ((Math.min(
          ((($B$4 - (0.1 * $B$4 > 100 ? 100 : 0) - (0.05 * $B$4 > 80 ? 80 : 0)) / $B$3) * 0.00805) /
            0.0066,
          ($B$4 * 1.625) / $B$3,
        ) /
          ((30 * 5 * 2) / 7)) *
          1000) /
        $B$17 /
        1.3;

      //   public_transit: 25,
      const public_transit =
        ((Math.min(
          ((($B$4 - (0.1 * $B$4 > 100 ? 100 : 0) - (0.05 * $B$4 > 80 ? 80 : 0)) / $B$3) *
            0.131313) /
            0.0462,
          ($B$4 * 1.625) / $B$3,
        ) /
          ((30 * 5 * 2) / 7)) *
          1000) /
        $B$17 /
        1.3;
      //   walk: 25,
      const walk =
        ((Math.min(
          ((($B$4 - (0.1 * $B$4 > 100 ? 100 : 0) - (0.05 * $B$4 > 80 ? 80 : 0)) / $B$3) *
            0.05725156) /
            0.047,
          ($B$4 * 1.625) / $B$3,
        ) /
          ((30 * 5 * 2) / 7)) *
          1000) /
        $B$17 /
        1.3;
      //   bike: 25,
      const bike =
        ((Math.min(
          ((($B$4 - (0.1 * $B$4 > 100 ? 100 : 0) - (0.05 * $B$4 > 80 ? 80 : 0)) / $B$3) *
            0.025093) /
            0.0206,
          ($B$4 * 1.625) / $B$3,
        ) /
          ((30 * 5 * 2) / 7)) *
          1000) /
        $B$17 /
        1.3;
      //   carpool: 25,
      const carpool =
        ((Math.min(
          ((($B$4 - (0.1 * $B$4 > 100 ? 100 : 0) - (0.05 * $B$4 > 80 ? 80 : 0)) / $B$3) * 0.17687) /
            0.0726,
          ($B$4 * 1.625) / $B$3,
        ) /
          ((30 * 5 * 2) / 7)) *
          1000) /
        $B$17 /
        1.3;
      //   transit_drive: 25,
      const transit_drive =
        ((Math.min(
          ((($B$4 - (0.1 * $B$4 > 100 ? 100 : 0) - (0.05 * $B$4 > 80 ? 80 : 0)) / $B$3) *
            0.043771) /
            0.0154,
          ($B$4 * 1.625) / $B$3,
        ) /
          ((30 * 5 * 2) / 7)) *
          1000) /
        $B$17 /
        1.3;
      //   telecommute: 25,
      const telecommute =
        ((Math.min(
          ((($B$4 - (0.1 * $B$4 > 100 ? 100 : 0) - (0.05 * $B$4 > 80 ? 80 : 0)) / $B$3) *
            0.1510467) /
            0.124,
          ($B$4 * 1.625) / $B$3,
        ) /
          ((30 * 5 * 2) / 7)) *
          1000) /
        $B$17 /
        1.3;

      return {
        thousand_points_incentives: Math.round(thousand_points_incentives),
        drive: Math.round(drive),
        drive_ev: Math.round(drive_ev),
        public_transit: Math.round(public_transit),
        walk: Math.round(walk),
        bike: Math.round(bike),
        carpool: Math.round(carpool),
        transit_drive: Math.round(transit_drive),
        telecommute: Math.round(telecommute),
      };
    },

    sign_up_incentives() {
      return Math.min(this.cost_per_employee / 5, 5);
    },

    monthlyPrizes() {
      return {
        most_miles_biked: 25,
        most_miles_walked: 25,
        most_transit_trips: 25,
        most_carpool_trips: 25,
        biggest_shift_from_driving_to_green_trips: 0,
        most_green_trips: 0,
      };
    },
    isLoading(){
      return this.loading
    },
    isProgramStatusActive(){
      return this.program.status == 'active'
    }
  },
  methods: {
    handleModalClose() {
      this.visible = false;
    },
    handleModalOpen() {
      this.visible = true;
    },
    setInput(vals) {
      if (vals?.monthCost?.value) {
        this.cost_per_employee = vals.monthCost.value;
      }
    },
    launchTheProgram(dates) {
      this.loading=true
      subscriptionsApi.launchNewProgram({
        start_date:dates.start_date,
        end_date:dates.end_date,
        subscription_type:'fixed_cost',
        number_of_employees:this.numberOfEmpData,
        type_data: {
          fixed_cost_type: "budget",
          cost_per_employee: this.cost_per_employee
        }
      }).then(response=>{
        console.log(response)
        this.loading=false
        window.location.replace(response.url ?? '/dashboard/account');
      }).catch(error=>{
        this.loading=false
        console.log({ error })
      })
    },
    upgradeEmployeesDialog() {
      console.log("are we working upgrade ??");
      this.downGradeMode = false;
      this.upgradeGradeMode = true;
    },
    downgradeEmployeesDialog() {
      console.log("are we working downgrade ??");
      this.upgradeGradeMode = false;
      this.downGradeMode = true;
    },
    cancelUpDownGrade() {
      console.log("are we working cancel ??");
      this.upgradeGradeMode = false;
      this.downGradeMode = false;
    },
  },
  watch: {
    program(oldVal, newVal) {
      console.log({ oldVal }, { newVal });
    },
  },
  created() {
    this.numberOfEmpData = this.number_of_employees
  }
};
</script>
