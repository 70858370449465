<template>
  <!-- Dashboard / Stats / Members. / Scores / Account & subscription => only account tab -->
  <div class="main-side-menu">
    <Menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed && !isSm"
    >
      <div v-if="isSm" class="bg-bg text-text flex items-center justify-between px-3 py-3">
        <img src="@/assets/images/logo.png" alt="Grand Community logo" class="h-10 w-" />

        <span
          class="text-lg cursor-pointer opacity-70 hover:opacity-100"
          @click="this.closeSideDrawer"
        >
          <PhX weight="bold" />
        </span>
      </div>
      <menu-item key="/dashboard" @click="() => goTo('/dashboard')">
        <template #icon>
          <PhHouse weight="duotone" size="18" />
        </template>
        <span>Dashboard</span>
      </menu-item>

      <menu-item
        v-if="isDev"
        key="/dashboard/transportation-programs"
        @click="() => goTo('/dashboard/transportation-programs')"
      >
        <template #icon>
          <PhTrain weight="duotone" size="18" />
        </template>
        <span>Transportation Programs</span>
      </menu-item>

      <menu-item
        :disabled='isFixedCostPlan'
        key="/dashboard/reward-system"
        @click="() => goTo('/dashboard/reward-system')"
      >
        <template #icon>
          <PhGift weight="duotone" size="18" />
        </template>
        <span>Reward System</span>
      </menu-item>

      <menu-item key="/dashboard/statistics" @click="() => goTo('/dashboard/statistics')">
        <template #icon>
          <PhChartBar weight="duotone" size="18" />
        </template>
        <span>Stats</span>
      </menu-item>

      <menu-item
        v-if="isDev"
        key="/dashboard/commute-calculator"
        @click="() => goTo('/dashboard/commute-calculator')"
      >
        <template #icon>
          <PhCalculator weight="duotone" size="18" />
        </template>
        <span>Commute Calculator</span>
      </menu-item>

      <menu-item key="/dashboard/members" @click="() => goTo('/dashboard/members')">
        <template #icon>
          <PhUsers weight="duotone" size="18" />
        </template>
        <span>Members</span>
      </menu-item>

      <menu-item key="/dashboard/scores" @click="() => goTo('/dashboard/scores')">
        <template #icon>
          <PhTarget weight="duotone" size="18" />
        </template>
        <span>Scores</span>
      </menu-item>

      <menu-item key="/dashboard/credit-details" @click="() => goTo('/dashboard/credit-details')">
        <template #icon>
          <PhCreditCard weight="duotone" size="18" />
        </template>
        <!-- <span>Account and Subscription</span> -->
        <span>Credit Details</span>
      </menu-item>

      <menu-item key="/dashboard/account" @click="() => goTo('/dashboard/account')">
        <template #icon>
          <PhGear weight="duotone" size="18" />
        </template>
        <!-- <span>Account and Subscription</span> -->
        <span>Account</span>
      </menu-item>

      <!-- <menu-item v-if="isDev" key="/dashboard/payments" @click="() => goTo('/dashboard/payments')">
        <template #icon>
          <PhCreditCard weight="duotone" size="18" />
        </template>
        <span>Payments</span>
      </menu-item>-->

      <menu-item v-if="isDev" key="/dashboard/contact" @click="() => goTo('/dashboard/contact')">
        <template #icon>
          <PhChatCenteredDots weight="duotone" size="18" />
        </template>
        <span>Contact us</span>
      </menu-item>

      <!-- <menu-item    key="3">
        <template #icon>
          <PhCar />
        </template>
        <span>Option 3</span>
      </menu-item>
      <sub-menu key="sub1">
        <template #icon>
          <PhCar />
        </template>
        <template #title>Navigation One</template>
        <menu-item key="5">Option 5</menu-item>
        <menu-item key="6">Option 6</menu-item>
        <menu-item key="7">Option 7</menu-item>
        <menu-item key="8">Option 8</menu-item>
      </sub-menu>
      <sub-menu key="sub2">
        <template #icon>
          <PhCar />
        </template>
        <template #title>Navigation Two</template>
        <menu-item key="9">Option 9</menu-item>
        <menu-item key="10">Option 10</menu-item>
        <sub-menu key="sub3" title="Submenu">
          <menu-item key="11">Option 11</menu-item>
          <menu-item key="12">Option 12</menu-item>
        </sub-menu>
      </sub-menu>-->
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuItem } from 'ant-design-vue';
import {
  PhHouse,
  PhChartBar,
  PhGift,
  PhTrain,
  PhUsers,
  PhTarget,
  PhGear,
  PhCreditCard,
  PhChatCenteredDots,
  PhX,
  PhCalculator,
} from 'phosphor-vue';
import { mapMutations } from 'vuex';
import { LAYOUT } from '../../../store/types';

export default {
  components: {
    Menu,
    // SubMenu,
    MenuItem,

    PhHouse,
    PhChartBar,

    PhGift,
    PhTrain,
    PhUsers,
    PhTarget,
    PhGear,
    PhCreditCard,
    PhChatCenteredDots,
    PhX,
    PhCalculator,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    isSm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedKeys: ['1'],
      openKeys: ['sub1'],
      preOpenKeys: ['sub1'],
      isDev: false,
    };
  },
  created() {
    this.isDev =
      process.env.VUE_APP_DEV === 'development' || process.env.NODE_ENV === 'development';
  },
  mounted() {
    this.selectedKeys = [this.$route.path];
  },
  watch: {
    $route: {
      handler(val) {
        if (val && val.path) {
          this.selectedKeys = [val.path];
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations([LAYOUT.SET_TOGGOLE_SIDEMENU]),
    goTo(url) {
      this.$router.push(url);
      this.SET_TOGGOLE_SIDEMENU(false);
    },
    closeSideDrawer() {
      this.SET_TOGGOLE_SIDEMENU(false);
    },
  },
  computed: {
    isFixedCostPlan() {
      return this.$store.state.auth.user.subscription?.type === 'fixed_cost';
    },
  },
};
</script>
