<template>
  <div
    class="border-2 border-solid border-transparent p-3 py-5 rounded-md flex flex-col text-center items-center gap-3 relative"
  >
    <h5 class="text-base">{{title}}</h5>
    <slot />
    <Button type="primary" class="btn-light" @click="handleOpenModal">Customize</Button>

    <small>{{description}}</small>
  </div>
</template>

<script>
import { Button } from 'ant-design-vue';

export default {
  components: { Button },
  props: {
    title: String,
    description: String,
    selected: Boolean,
    id: Number,
  },
  methods: {
    handleOpenModal() {
      this.$emit('openModal', this.id);
    },
  },
};
</script>
