<template>
  <div class="flex flex-wrap items-center" :class="vertical ? 'flex-col gap-4' : ' gap-3'">
    <h4
      v-if="!noInfo"
      class="text-primary flex items-center gap-1"
      :class="vertical ? 'text-xl' : ''"
    >
      <span class="hidden md:inline-block">Balance:</span>
      ${{ user.balance }}
      <Tooltip v-if="!vertical">
        <template #title>
          <p>{{infoText}}</p>
        </template>

        <PhInfo weight="fill" class="text-primary-dark cursor-pointer" size="16" />
      </Tooltip>
    </h4>
    <Button
      type="primary"
      title="Add Credit"
      :size="vertical ? 'large': 'middle'"
      :loading="loading"
      @click="handleAddCredit"
    >
      <div class="flex items-center justify-center" :class="vertical ? 'px-3' : ''">
        <span class="hidden md:inline-block">Add Credit</span>
        <PhPlus weight="bold" size="16" class="md:hidden" />
      </div>
    </Button>

    <div v-if="vertical && !noInfo" class="flex flex-col text-center items-center">
      <small>Add credit to launch the above transportation programs</small>
      <small>Unused credit is rolled over to the next month</small>
      <!-- or refunded -->
    </div>

    <Modal
      :visible="!!visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal  "
      center
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <PayCardModal title="Add Credit" @close="handleModalClose" />
    </Modal>
    <Modal
      :visible="addCreditModal"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <add-credit-modal @cancelAddCreditModal='cancelAddCreditModal' />
    </Modal>
  </div>
</template>

<script>
import { Button, Tooltip, Modal, message } from 'ant-design-vue';
import { PhInfo, PhPlus } from 'phosphor-vue';

import PayCardModal from '@/components/dashboard/payments/shared/PayCardModal.vue';

// import PaymentsApi from '@/services/apis/payments.api';
import AddCreditModal from '@/components/dashboard/CreditDetails/addCreditModal.vue';
import handleResErrors from '../../../utils/handleResErrors';

export default {
  components: { AddCreditModal, Button, Tooltip, PhInfo, PhPlus, PayCardModal, Modal },
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    noInfo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user || null;
    },
  },
  data() {
    return {
      visible: false,
      infoText:
        'This balance is used to issue credit for commuters that use transportation programs in “Reward System”',
      loading: false,
      addCreditModal: false,
    };
  },
  methods: {
    handleModalOpen() {
      this.visible = true;
    },
    handleModalClose() {
      this.visible = false;
    },
    async handleAddCredit() {
      //
      // this.loading = true;
      try {
        // const currentUrl = window.location.href;
        // const res = await PaymentsApi.postAddCredit({ url: currentUrl });
        // if (res && res.url) {
        //   // redirect to payment page
        //   window.location.href = res.url;
        // }
        this.addCreditModal = true;
      } catch (error) {
        message.error(handleResErrors(error));
      }
      this.loading = false;
    },
    cancelAddCreditModal() {
      this.addCreditModal = false;
    },
  },
};
</script>
