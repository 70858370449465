<template>
  <div class='upgradedowngrade' style='background-color: #C9C9C9'>
<!--    title -->
    <div class='title mb-3 p-1 title-upgrade' v-if='upgrade'>
<!--      Changeable titles -->
        <h2 class='upgrade'>Upgrade subscription plan </h2>
    </div>
    <div class='title mb-3 p-1 title-downgrade' v-if='downgrade'>
      <h2  class='downgrade'>Downgrade subscription plan </h2>
    </div>
<!--    Numbers -->
    <div >
      <Row class='mb-5'>
<!--        Original Number -->
        <Col :md='11' class='align-self-center'>
          <h3 class="text-base text-end text-primary-dark">Current number of Employees:</h3>
        </Col>
        <Col :md='2'>
        </Col>
        <Col :md='11' >
          <InputNumber class="w-40 apply-align" v-model:value="currentNumberofEmployees" :min="1" disabled/>
        </Col>
      </Row>
      <Row >
<!--        Added Number -->
        <Col :md='11' class='align-self-center'>
          <h3 v-if='upgrade' class="text-base text-end text-primary-dark">Add Employees:</h3>
          <h3 v-if='downgrade' class="text-base text-end text-primary-dark">Remove Employees:</h3>
        </Col>
        <Col :md='2'>
        </Col>
        <Col :md='11' v-if='upgrade'>
          <InputNumber   @change='validateNewNumber($event)' class="w-40 apply-align cst-back" v-model:value="newAddedNumberOfEmployees" :min="1" />
        </Col>
        <Col :md='11' v-if='downgrade'>
          <InputNumber   @change='validateNewNumber($event)' class="w-40 apply-align cst-back" v-model:value="newAddedNumberOfEmployees" :min="1" :max='currentNumberofEmployees'/>
        </Col>
      </Row>
    </div>
    <Divider />
<!--    Results -->
    <div class='mb-8'>
      <Row  class='mb-5'>
        <Col :md='11' class='align-self-center'>
          <h3 class="text-base text-end text-primary-dark">New number of employees:</h3>
        </Col>
        <Col :md='2'>
        </Col>
        <Col :md='11'>
          <InputNumber class="w-40 apply-align" v-model:value="newNumberOfEmp" :min="1" disabled />
        </Col>
      </Row>
      <Row>
        <Col :md='11' class='align-self-center'>
          <h3 class="text-base text-end text-primary-dark">Effective date:</h3>
        </Col>
        <Col :md='2'>
        </Col>
        <Col :md='11' class='align-self-center'>
          <span class="w-40 block p-1 text-center" style='border: 1px solid #ccc;background-color: #F0F0F0' disabled >
          <b v-if='upgrade'>  {{ currentDate }}</b>
          <b v-if='downgrade'> {{nextCycleDate}}</b>
          </span>
        </Col>
      </Row>
    </div>
<!--    Buttons -->
    <div class='mb-5'>
      <Row>
        <Col :md='11' class='text-end'>
          <Button
            type="primary"
            class="btn-upgrade "
            size="medium"
            v-if='upgrade'
            @click='upgradeNumberOfEmployees'
            :disabled='disableUpDowngradeComp'
          >Upgrade</Button>
          <Button
            type="primary"
            class="btn-upgrade "
            size="medium"
            v-if='downgrade'
            :disabled='disableUpDowngradeComp'
            @click='downgradeNumberOfEmployees'
          >Downgrade</Button>
        </Col>
        <Col :md='2'> </Col>
        <Col :md='11'>
          <Button
            type="primary"
            class="btn-downgrade"
            size="medium"
            @click='cancelUpDownGrade'
          >Cancel</Button>
        </Col>
      </Row>
    </div>

<!--    Message -->
    <div class='mb-8'>
      <p class='text-center msg-adjust' v-if='upgrade' >
        For ongoing programs, you will be charged prorated amount for the added employees for the current billing cycle.
      </p>
      <p class='text-center msg-adjust' v-if='downgrade'>
        For ongoing programs, you will be charged subscription in the next billing cycle based on the new (downgraded) number of employees.
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Row, Col, Divider, InputNumber, Button, notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import subscriptionsApi from '@/services/apis/subscriptions.api';

export default {
  components: {
    Row,
    Col,
    Divider,
    InputNumber,
    Button,
    notification,
  },
  created() {
    this.currentNumberofEmployees = this.number_of_employees;
  },
  mounted() {
  },
  props: {
    number_of_employees: {
      type: Number,
      default: 1,
    },
    upgrade: {
      type: Boolean,
      default: false,
    },
    downgrade: {
      type: Boolean,
      default: false,
    },
    nextBillingDate:{
      default:null
    }
  },
  data() {
    return {
      newAddedNumberOfEmployees: 1,
      currentNumberofEmployees: 0,
      disableUpDowngrade: false,
    };
  },
  computed: {
    numberOfEmp() {
      return this.number_of_employees;
    },
    currentDate() {
      return dayjs().format('DD MMM YYYY');
    },
    newNumberOfEmp() {
      if (this.upgrade) return this.number_of_employees + this.newAddedNumberOfEmployees;
      if (this.downgrade) return this.number_of_employees - this.newAddedNumberOfEmployees;
      return 1;
    },
    disableUpDowngradeComp() {
      return this.disableUpDowngrade;
    },
    nextCycleDate(){
      return dayjs(this.nextBillingDate, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY');
    }
  },
  methods: {
    upgradeEmployees() {
      console.log('are we working ??');
      this.$emit('upgradeEmployees');
    },
    downgradeEmployees() {
      this.$emit('downgradeEmployees');
    },
    cancelUpDownGrade() {
      this.$emit('cancelUpDownGrade');
    },
    validateNewNumber(e) {
      const value = e;
      // eslint-disable-next-line no-unused-expressions
      if (value == null) {
        this.disableUpDowngrade = true;
      } else {
        this.disableUpDowngrade = false;
      }
    },
    upgradeNumberOfEmployees() {
      this.loading = true;
      subscriptionsApi.upgradeNumberOfEmployees({
        number_of_employees: this.newAddedNumberOfEmployees,
      }).then((response) => {
        this.loading = false;
        console.log({ response });
        notification.open({
          type: 'success',
          message: 'Success Operation.',
          description: 'You\'re going to be redirect to the payment page if needed.',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
        setTimeout(() => { window.location.replace(response.url ?? '/dashboard/account'); }, 3000);
      }).catch((error) => {
        this.loading = false;
        console.log({ error });
      });
    },
    downgradeNumberOfEmployees() {
      this.loading = true;
      subscriptionsApi.downgradeNumberOfEmployees({
        number_of_employees: this.newAddedNumberOfEmployees,
      }).then((response) => {
        this.loading = false;
        notification.open({
          type: 'success',
          message: 'Success Operation.',
          description: 'Your new subscription has scheduled.',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
        setTimeout(()=>{window.location.replace(response.url ?? '/dashboard/account')},2000);
      }).catch((error) => {
        this.loading = false;
        console.log({ error });
      });
    },
  },
  watch: {

  },

};

</script>

<style>
.title{
  text-align: center;
}
.align-self-center{
  align-self: center;
}
.apply-align .ant-input-number .ant-input-number-input-wrap input.ant-input-number-input{
  background-color: #00b34f;
  text-align: center !important;
}
.apply-align.ant-input-number.ant-input-number-disabled .ant-input-number-input,
.apply-align.ant-input-number .ant-input-number-input{
  text-align: center !important;
}

.apply-align.ant-input-number.cst-back .ant-input-number-input{
  background-color: white;
}
.btn-upgrade.ant-btn-primary{
  background-color: #A5CD93;
  color: black;
  border-color: #A5CD93 !important;
}
.btn-downgrade.ant-btn-primary{
  background-color:#F3A988 ;
  color: black;
  border-color: #DA9678 !important;
  transition: all 0.5s;
}
.btn-downgrade.ant-btn-primary:hover{
  background-color:  #DA9678;
  color: white;
  border-color: #DA9678 !important;
}
.btn-downgrade.ant-btn-primary:focus{
  background-color:  #DA9678;
  color: white;
  border-color: #DA9678 !important;
}
.title ,.title h2{
  /*border-radius: 8px;*/
}
.title-upgrade{
  background-color: #63A537;
  color: white !important;
}

.title-downgrade{
  background-color: #DA9678;
  color: white !important;
}

.title{
  border-radius: 10px 10px 0 0 !important;
}
.msg-adjust{
  padding: 5px 30px 15px 30px;
}
/*.upgradedowngrade{*/
/*  border-radius: 15px;*/
/*}*/
</style>
