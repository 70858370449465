/* eslint-disable */
import { useQuery } from 'vue-query';
import apiAxios from '../../apiAdminAxios';

const fetchWalletTransactions = async ({ page, per_page, keyword, for_employer, employer_id, for_employee, employee_id, start_date, end_date, type }) => {
  let URL = `?page=${page || 1}`;

  if (per_page) {
    URL += `&per_page=${per_page}`;
  }
  if (keyword) {
    URL += `&keyword=${keyword}`;
  }

  if (employer_id) {
    URL += `&employer_id=${employer_id}`;
  }

  if (employee_id) {
    URL += `&employee_id=${employee_id}`;
  }

  if (for_employer) {
    URL += `&for_employer=1`;
  }

  if (for_employee) {
    URL += `&for_employee=1`;
  }

  if (type) {
    // 'weekly_reward', 'monthly_reward', 'trip_reward', 'redeem_credit'
    URL += `&type=${type}`;
  }

  if (start_date && end_date) {
    URL += `&start_date=${start_date}&end_date=${end_date}`;
  }
  const { data } = await apiAxios.get(`/admin/wallet-transactions${URL}`);
  return data;
};

const postAddCredit = async (data) => {
  const res = await apiAxios.post('/admin/wallets/add-credit', data);
  return res.data;
};
const postAddPayment = async (data) => {
  const res = await apiAxios.post('/admin/wallets/add-payment', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};
const deleteTransaction = async (id, withTrip)=> {
  const res = await apiAxios.delete('/admin/wallet-transactions/'+id+'?withTrip='+ (withTrip ? 1 : 0) );
  return res.data;
}
// queries
const useWalletTransactionsQuery = ({ page, per_page, keyword, for_employer, employer_id, for_employee, employee_id, start_date, end_date, type }) => useQuery(['admin/wallet-transactions', page, per_page, keyword, employer_id, employee_id, start_date, end_date, type], () => fetchWalletTransactions({ page, per_page, keyword, for_employer, employer_id, for_employee, employee_id, start_date, end_date, type }), { refetchOnMount: false });

export default {
  fetchWalletTransactions,
  postAddCredit,
  postAddPayment,
  // queries
  useWalletTransactionsQuery,
  deleteTransaction,

};
