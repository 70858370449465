const AUTH = {
  SET_ADMIN_AUTH: 'SET_ADMIN_AUTH',
  SET_ADMIN_ACTIVE_USER: 'SET_ADMIN_ACTIVE_USER',
  SET_ADMIN_USER_DATA: 'SET_ADMIN_USER_DATA',

  // actions
  ACTION_ADMIN_LOGIN: 'ACTION_ADMIN_LOGIN',
  ACTION_ADMIN_LOGOUT: 'ACTION_ADMIN_LOGOUT',
  ACTION_ADMIN_SET_USER_DATA: 'ACTION_ADMIN_SET_USER_DATA',
  ACTION_ADMIN_VALIDATE_TOKEN: 'ACTION_ADMIN_VALIDATE_TOKEN',

};
export default AUTH;
