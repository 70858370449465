<template>
  <section class="members-data" >
    <div v-if="!membersQuery  ">
      <CardLoading />
    </div>
    <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
      <div class="w-full mt-4 flex flex-wrap items-center justify-between">
        <h2 class>Employees</h2>
        <Row>
          <Col >
          <CustumCalender
            name="start_date"
            :defaultData="defaultDate"
            defaultFormate="MMM, D, YYYY"
            placeholder="Select Start Date"
            :rules="{
                required: false,
              }"
            @setInput="setInput"
            @handleChange='changedStartDate'
          />
          </Col>
          <Col class='mr-1 ml-1'>
          <CustumCalender
            name="end_date"
            :defaultData="defaultDate"
            defaultFormate="MMM, D, YYYY"
            placeholder="Select End Date"
            :rules="{
                required: false,
              }"
            @setInput="setInput"
            @handleChange='changedStartDate'
          />
          </Col>
          <Col>
            <InputSearch
              class="rounded-md min-w-[320px]"
              placeholder="Search for employee name or email"
              v-model:value="search"
              clear
              @change="handleSearch"
            />
          </Col>
        </Row>
      </div>

      <QueryAlert :query="membersQuery" />

      <div class="mt-2 md:mt-3 lg:mt-4">
        <Table
          :data-source="membersData?.data || []"
          :columns="columns"
          :loading="membersQuery && (membersQuery.isFetching || membersQuery.isLoading)"
          :pagination="pagination"
          @change="handlePaginationChange"
        >
          <!-- -->
          <template #headerCell="{ column  }">
            <template v-if="column.key === 'name'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhUser weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'email'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhEnvelopeSimple weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'joined'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhIntersect weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'filled_survey'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhCheckSquare weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'automatic'">
              <span class="font-bold flex items-center gap-2 min-w-[170px]">
                <PhArrowsClockwise weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>

            <template v-if="column.key === 'last_trip_time'">
              <span class="font-bold flex items-center gap-2 min-w-[150px]">
                <PhClock weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'total_benfits'">
              <span class="font-bold flex items-center gap-2 min-w-[150px]">
                <PhBank weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'redeemed_balance'">
              <span class="font-bold flex items-center gap-2 min-w-[150px]">
                <PhBank weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>

            <template v-if="column.key === 'last_login'">
              <span class="font-bold flex items-center gap-2 min-w-[150px]">
                <PhSignIn weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'last_reminder_time'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhMapPinLine weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'trips'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhMapPinLine weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'action'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhGear weight="duotone" size="18" class="flex-shrink-0" />Actions
              </span>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'name'">
              <TypographyParagraph
                ellipsis
                :content="record.name"
                class="text-text whitespace-nowrap !mb-0"
              />
            </template>

            <template v-if="column.key === 'joined'">
              <span v-if="record.joined" class="whitespace-nowrap">{{getDate(record.joined)}}</span>
              <span v-else class="w-full flex justify-center text-yellow-600">
                <PhHourglassSimpleMedium weight="duotone" size="18" title="pending" />
              </span>
            </template>

            <!-- <template v-if="column.key === 'total_benfits'">
              <span v-if="record.total_benfits" class="whitespace-nowrap">
                 {{ record.total_benfits.toFixed(2) }}
               </span>
              <span v-else class="w-full flex justify-center text-yellow-600">
                0.00
              </span>
            </template>

            <template v-if="column.key === 'redeemed_balance'">
              <span v-if="record.redeemed_balance" class="whitespace-nowrap">
                 {{ record.redeemed_balance.toFixed(2) }}
              </span>
              <span v-else class="w-full flex justify-center text-yellow-600">
               0.00
              </span>
            </template> -->

            <template v-if="column.key === 'last_trip_time'">
              <span v-if="record.last_trip_time" class="whitespace-nowrap">{{getDate(record.last_trip_time)}}</span>
              <span v-else class="w-full flex justify-center text-yellow-600">
                <PhHourglassSimpleMedium weight="duotone" size="18" title="pending" />
              </span>
            </template>
            <template v-if="column.key === 'last_login'">
              <span v-if="record.last_login" class="whitespace-nowrap">{{getDate(record.last_login)}}</span>
              <span v-else class="w-full flex justify-center text-yellow-600">
                <PhHourglassSimpleMedium weight="duotone" size="18" title="pending" />
              </span>
            </template>
            <template v-if="column.key === 'filled_survey'">
              <span v-if="record.filled_survey" class="w-full flex justify-center text-green-600">
                <PhCheckCircle weight="duotone" size="18" />
              </span>
              <span v-else class="w-full flex justify-center text-red-600">
                <PhXCircle weight="duotone" size="18" />
              </span>
            </template>

            <template v-if="column.key === 'automatic'">
              <span v-if="record.automatic_trip_recording" class="w-full flex justify-center text-green-600">
                <PhCheckCircle weight="duotone" size="18" />
              </span>
              <span v-else class="w-full flex justify-center text-red-600">
                <PhXCircle weight="duotone" size="18" />
              </span>
            </template>

            <template v-if="column.key === 'waiting'">
              <span v-if="record.waiting" class="w-full flex justify-center text-green-600">
                <PhCheckCircle weight="duotone" size="18" />
              </span>
              <span v-else class="w-full flex justify-center text-yellow-600">
                <PhHourglassSimpleMedium weight="duotone" size="18" title="pending" />
              </span>
            </template>

            <template v-if="column.key === 'accepted_commute'">
              <span
                v-if="record.accepted_commute"
                class="w-full flex justify-center text-green-600"
              >
                <PhCheckCircle weight="duotone" size="18" />
              </span>
              <span v-else class="w-full flex justify-center text-red-600">
                <PhXCircle weight="duotone" size="18" />
              </span>
            </template>
            <template v-if="column.key === 'last_reminder_time'">
              <span v-if="record.last_reminder_time" class="whitespace-nowrap">{{getDate(record.last_reminder_time)}}</span>
              <span v-else class="w-full flex justify-center text-yellow-600">
                <PhHourglassSimpleMedium weight="duotone" size="18" title="pending" />
              </span>
            </template>
            <template v-if="column.key === 'action'">
              <div class="flex items-center gap-2">
                <Button
                  type="primary"
                  size="small"
                  class="rounded shadow flex items-center gap-2"
                  @click="() => handleModalOpen(record)"
                >
                  <PhEnvelopeSimple weight="duotone" :size="16" />
                </Button>

                <Popconfirm
                  title="Are you sure delete this user?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="() => handleDeleteMember(record)"
                >
                  <Button
                    type="primary"
                    size="small"
                    class="btn-error rounded shadow flex items-center gap-2"
                    :loading="delLoading === record.id"
                  >
                    <PhTrash weight="duotone" :size="16" />
                  </Button>
                </Popconfirm>
              </div>
            </template>
          </template>

          <template #summary>
            <table-summary-row v-if="membersStatsData">
              <table-summary-cell col-span="3">
                <span class="w-full flex items-center justify-center text-xl font-bold">Total</span>
              </table-summary-cell>
              <table-summary-cell>
                <span class="flex items-center gap-2">
                  <PhCheckCircle weight="duotone" size="18" />
                  {{membersStatsData.total_joined_count}} Joined
                </span>
                <span class="flex items-center gap-2">
                  <PhHourglassSimpleMedium weight="duotone" size="18" />
                  {{membersStatsData.total_pending_invites}} Pending invite
                </span>
              </table-summary-cell>

              <table-summary-cell>
                <span class="flex items-center gap-2">
                  <PhCheckCircle weight="duotone" size="18" />
                  {{membersStatsData.total_completed_count}} Complete
                </span>
                <span class="flex items-center gap-2">
                  <PhXCircle weight="duotone" size="18" />
                  {{membersStatsData.total_incomplete_count}} Incomplete
                </span>
              </table-summary-cell>
<!--              Fill in only -->
              <table-summary-cell>
                <span class="flex items-center gap-2">
                </span>
                <span class="flex items-center gap-2">
                </span>
              </table-summary-cell>
              <table-summary-cell>
                <span class="flex items-center gap-2">
                </span>
                <span class="flex items-center gap-2">
                </span>
              </table-summary-cell>
              <table-summary-cell>
                <span class="flex items-center gap-2">
                </span>
                <span class="flex items-center gap-2">
                </span>
              </table-summary-cell>
              <table-summary-cell>
                <span class="flex items-center gap-2">
                </span>
                <span class="flex items-center gap-2">
                </span>
              </table-summary-cell>
<!--End of fill in only -->
              <table-summary-cell>
                <div class="w-full flex flex-wrap items-center justify-center gap-1">
                  <PhDownload weight="duotone" size="18" />
                  <span>{{membersStatsData.total_logged_trips_count}}</span>
                  Logged trips
                </div>
              </table-summary-cell>
              <table-summary-cell></table-summary-cell>
            </table-summary-row>
          </template>
        </Table>
      </div>
    </div>

    <!-- send message modal  -->
    <Modal
      :visible="modalMemb"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <SendMessageModal :member="modalMemb" :key="modalMemb" @close="handleModalClose" />
    </Modal>

    <!-- query apis  -->
    <MembersQuery
      :page="page"
      :per_page="pageSize"
      :name="name"
      :email="email"
      :start_date="start_date_text"
      :end_date="end_date_text"
      :key="`${page}-${2000}-${name}-${email}-${start_date_text}-${end_date_text}-${pageSize}`"
    />
    <MembersStatsQuery />
  </section>
</template>
<script>
import {
  Table,
  TypographyParagraph,
  Popconfirm,
  Button,
  TableSummaryRow,
  TableSummaryCell,
  InputSearch,
  Modal,
  message,
  Row,
  Col,
} from 'ant-design-vue';
import {
  PhUser,
  PhHourglassSimpleMedium,
  PhCheckCircle,
  PhXCircle,
  PhTrash,
  PhDownload,
  PhEnvelopeSimple,
  PhIntersect,
  PhCheckSquare,
  PhMapPinLine,
  PhGear,
  PhClock,
  PhArrowsClockwise,
  PhSignIn,
  PhBank,
} from 'phosphor-vue';
import dayjs from 'dayjs';
import QueryAlert from '@/components/shared/QueryAlert.vue';

import MembersQuery from '@/services/queries/members/MembersQuery.vue';
import MembersStatsQuery from '@/services/queries/members/MembersStatsQuery.vue';

import CardLoading from '@/components/shared/CardLoading.vue';

import debounce from 'lodash.debounce';
import CustumCalender from '@/components/shared/form/CustumCalender.vue';
import validation from '../../../services/validation';

import SendMessageModal from './SendMessageModal.vue';
import membersApi from '../../../services/apis/members.api';
import handleResErrors from '../../../utils/handleResErrors';

export default {
  components: {
    CustumCalender,
    Table,
    Modal,
    Button,
    TypographyParagraph,
    Popconfirm,
    PhUser,
    PhHourglassSimpleMedium,
    PhCheckCircle,
    PhXCircle,

    PhTrash,
    TableSummaryRow,
    TableSummaryCell,
    PhDownload,
    InputSearch,
    PhEnvelopeSimple,
    PhIntersect,
    PhCheckSquare,
    PhMapPinLine,
    PhGear,
    MembersQuery,
    CardLoading,
    MembersStatsQuery,
    SendMessageModal,
    QueryAlert,
    PhClock,
    PhArrowsClockwise,
    PhSignIn,
    Row,
    Col,
    PhBank,
  },
  data() {
    return {
      columns: [
        {
          title: '#',
          key: 'key',
          dataIndex: 'key',
        },

        {
          title: 'Name',
          key: 'name',
          dataIndex: 'name',
          sorter: (a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            return -1;
          },
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          sorter: (a, b) => {
            if (a.email > b.email) {
              return 1;
            }
            return -1;
          },
        },
        {
          title: 'Invited/Joined',
          dataIndex: 'joined',
          key: 'joined',
          sorter: (a, b) => {
            const aDate = new Date(a.joined).getTime();
            const bDate = new Date(b.joined).getTime();
            return bDate - aDate;
          },
        },
        {
          title: 'Filled Surveys',
          key: 'filled_survey',
          dataIndex: 'filled_survey',
          sorter: (a, b) => {
            if (a.filled_survey && b.filled_survey) return -1;
            return 1;
          },
        },
        {
          title: 'Automatic trip recording',
          key: 'automatic',
          dataIndex: 'automatic',
          sorter: (a, b) => {
            if (a.automatic_trip_recording && b.automatic_trip_recording) return -1;
            return 1;
          },
        },
        {
          title: 'Last logged trip',
          key: 'last_trip_time',
          dataIndex: 'last_trip_time',
          sorter: (a, b) => {
            const aDate = new Date(a.last_trip_time).getTime();
            const bDate = new Date(b.last_trip_time).getTime();
            return bDate - aDate;
          },
        },
        {
          title: 'Last login',
          key: 'last_login',
          dataIndex: 'last_login',
          sorter: (a, b) => {
            const aDate = new Date(a.last_login).getTime();
            const bDate = new Date(b.last_login).getTime();
            return bDate - aDate;
          },
        },
        {
          title: 'Last Email Reminder',
          key: 'last_reminder_time',
          dataIndex: 'last_reminder_time',
          sorter: (a, b) => b.last_reminder_time - a.last_reminder_time,
          defaultSortOrder: 'ascend',
        },
        {
          title: 'Logged trips',
          key: 'trips',
          dataIndex: 'trips',
          sorter: (a, b) => b.trips - a.trips,
          defaultSortOrder: 'ascend',
        },
        {
          title: '',
          key: 'action',
          fixed: 'right',
        },
      ],

      tableData: [
        {
          key: '1',
          name: 'Daniel Martin',
          email: 'danielmartin@gmail.com',
          joined: 1664882267,
          filled_survey: true,
          waiting: true,
          accepted_commute: true,
          trips: 123,
        },

        {
          key: '2',
          name: 'Sara Doe',
          email: 'Sara@gmail.com',
          joined: null,
          filled_survey: false,
          waiting: false,
          accepted_commute: false,
          trips: 0,
        },
        // 1664882267 1601810267
        ...Array(50)
          .fill('dd')
          .map((_, ix) => ({
            key: ix + 3,
            name: 'John Doe',
            email: 'johndoe@gmail.com',
            joined: this.getRandomInt(1664882267, 1601810267),
            filled_survey: true,
            waiting: true,
            accepted_commute: true,
            trips: 123 + ix,
          })),
      ],
      search: '',
      searchData: [],
      page: 1,
      pageSize: 10,
      name: '',
      email: '',
      modalMemb: null,
      delLoading: false,
      start_date_text: null,
      end_date_text: null,
      start_date: null,
      end_date: null,
    };
  },
  computed: {
    membersQuery() {
      return this.$store.state.members.membersQuery;
    },
    membersData() {
      return this.$store.getters.membersData;
    },
    membersStatsData() {
      return this.$store.getters.membersStatsData;
    },
    pagination() {
      if (!this.membersData) {
        return {
          defaultPageSize: this.pageSize,
          total: 0,
        };
      }

      return {
        defaultPageSize: this.membersData?.meta?.per_page || this.pageSize,
        total: this.membersData?.meta?.total || 0,
        pageSizeOptions: this.pageSizeOptionsArr,
        showSizeChanger: true,
      };
    },
    pageSizeOptionsArr() {
      const dataLen = this.membersData?.data?.length || 0;
      const pArr = [10];
      pArr.push(20);
      pArr.push(50);
      pArr.push(100);
      pArr.push(500);
      pArr.push(1000);
      pArr.push(dataLen);

      return [...pArr];
    },
    defaultDate() {
      return null;
    },
  },
  methods: {
    handlePaginationChange(pagination) {
      // , filters, sorter, data
      this.page = pagination.current;
      this.pageSize = pagination.pageSize;
    },
    getRandomInt(min, max) {
      const xmin = Math.ceil(min);
      const xmax = Math.floor(max);
      return Math.floor(Math.random() * (xmax - xmin + 1)) + min;
    },
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMMM DD, YYYY, hh:mm a');
    },
    handleSearch(e) {
      this.debounceSearch(e.target.value, this);
    },
    debounceSearch: debounce((search, app) => {
      if (validation.validateEmail(search)) {
        // eslint-disable-next-line no-param-reassign
        app.email = search;
        // eslint-disable-next-line no-param-reassign
        app.name = '';
      } else {
        // eslint-disable-next-line no-param-reassign
        app.email = '';
        // eslint-disable-next-line no-param-reassign
        app.name = search;
      }
    }, 400),
    handleModalClose() {
      this.modalMemb = null;
    },
    handleModalOpen(id) {
      this.modalMemb = id;
    },
    async handleDeleteMember(data) {
      if (!data || !data.id) return;
      this.delLoading = data.id;
      try {
        const res = await membersApi.deleteMember(data.id);

        if (res?.result && res?.result === 'member deletion failed') {
          message.error(res?.result);
        } else {
          message.success(`${data.email} deleted!`);
        }
        if (this.membersQuery) {
          this.membersQuery.refetch({ refetchPage: (page) => page === this.page });
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
      this.delLoading = null;
      return;
    },
    setInput(val) {
      console.log('Val !!!!,Val', val);
      if (val && val.start_date && val.start_date.value) {
        this.start_date = dayjs(val.start_date.value, 'MMM, D, YYYY');
        this.start_date_text = dayjs(val.start_date.value, 'MMM, D, YYYY').format('YYYY-MM-DD');
      }
      if (val && val.end_date && val.end_date.value) {
        this.end_date = dayjs(val.end_date.value, 'MMM, D, YYYY');
        this.end_date_text = dayjs(val.end_date.value, 'MMM, D, YYYY').format('YYYY-MM-DD');
      }
      console.log(this.start_date_text, this.end_date_text);
      this.membersQuery.refetch({
        page: this.page,
        per_page: 2000,
        name: this.name,
        email: this.email,
        start_date: this.start_date_text,
        end_date: this.end_date_text,
      });
    },
    changedStartDate(val) {
      console.log(val);
      // this.setInput(val);
    },
  },
};
</script>
