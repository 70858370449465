<template>
<!--  eslint-disable-->
  <div class="flex items-end gap-4 lg:gap-5" style='position: relative'>
    <RadiusUprightOutlined />

    <div style='position:fixed;z-index: 10000000000000000;top: 20px; right: 10px'  v-if='isVisible'>
      <Alert
        message="Error"
        :description="errorMsgCom"
        type="error"
        closable
        onclose='closeAlert'
      >
      </Alert>
    </div>
    <div>
      <div class="mb-2 flex items-center gap-1">
        <h3>{{programTitle}}</h3>
        <Tooltip v-if="message">
          <template #title>{{message}}</template>
          <ph-question :size="22" class="text-primary" weight="duotone" />
        </Tooltip>
      </div>

      <div class="flex items-center gap-3" v-if='!isHandleBlur'>
        <div class="flex items-center gap-1">
          <span>From :</span>
          <CustumCalender
            name="fromDate"
            :defaultData="fromDate"
            :value='fromDate'
            picker="month"
            placeholder="Select Month"
            :rules="{
                required: false,
              }"
            @setInput="setFromDate"
            :min-date='dynamicMinDate'
            :disabled='program.status == "active" || program.dates_disabled || program.status == "future" '
          />
        </div>
        <div class="flex items-center gap-1">
          <span>To :</span>
          <CustumCalender
            v-if='program.status == "new" && dynamicMinDate'
            name="endDate"
            :defaultData="dynamicMinDate"
            :value='endDate'
            picker="month"
            placeholder="Select Month"
            :rules="{
                required: false,
              }"
            @setInput="setEndDate"
            @blur='handleblur'
            :min-date='dynamicMinDate'
            :disabled='program.dates_disabled || program.status == "future"'
          />
          <CustumCalender
            v-else
            name="endDate"
            :defaultData="endDate"
            :value='endDate'
            picker="month"
            placeholder="Select Month"
            :rules="{
                required: false,
              }"
            @setInput="setEndDate"
            @blur='handleblur'
            :min-date='dynamicUpdateEndDate'
            :disabled='program.dates_disabled || program.status == "future"'
          />
        </div>
      </div>
      <div v-else></div>
    </div>

    <Button
      v-if="programTitle === 'Active Program'"
      type="primary"
      class="px-6"
      @click='updateEndData'
    >
      Update End Date
    </Button>
    <Button
      v-if="programTitle === 'Pending Upgrade'"
      type="primary"
      class="px-6"
      :disabled='true'
    >
      Pay to upgrade
    </Button>
    <Button   @click='launchTheProgram' v-if="programTitle === 'New Program'" type="primary" class="px-6">Lunch program</Button>
    <Button   @click='showDanger = true' v-if="programTitle === 'Scheduled Program'" type="primary" class="px-6">Cancel Future Program</Button>
      <Modal
        :visible="showDangerModel"
        :destroyOnClose="true"
        title="Delete Future Program!"
        wrap-class-name="empty-modal"
        width="600px"
      >
        <template #footer>
          <Button key="back" @click='showDanger = false'>Return</Button>
          <Button key="submit" type="danger" :loading="isLoading" @click="cancelTheProgram">Delete</Button>
        </template>
        <div style='padding: 20px'>
          <h2>Are you sure you want delete this subscription !</h2>
          <p>All saved Data related to this program will be erased</p>
        </div>
      </Modal>
  </div>
</template>

<script>
import { Button, Tooltip, DatePicker, Alert, notification, Modal } from 'ant-design-vue';
import { PhQuestion } from 'phosphor-vue';
import dayjs from 'dayjs';
import CustumCalender from '@/components/shared/form/CustumCalender.vue';
import subscriptionsApi from '@/services/apis/subscriptions.api';

/* eslint-disable */
export default {
  components: { Button, Tooltip, PhQuestion, DatePicker, CustumCalender, Alert, Modal },
  props: {
    program: {
      type: Object,
      default: () => ({}),
    },
    radioVal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dateValue: null,
      picker: 'month',
      valueDate: null,
      defaultData: 'Jan 2023',
      isLoading:false,
      programDate:{
        start_date:null,
        end_date:null,
      },
      errorMsg:"",
      visible:false,
      handleBlurVar:false,
      showDanger:false
    };
  },
  computed: {
    fromDate() {
      if(this.program && this.program.custom_program){
        if (!this.program || !this.program.start_date) return dayjs().format('MMMM YYYY')
        return dayjs(this.program.start_date, 'YYYY-MM-DDTHH:mm:ssZ').format('MMMM YYYY');
      }
      if (!this.program || !this.program.start_date) return dayjs().format('MMMM YYYY')
      return dayjs(this.program.start_date, 'YYYY-DD-MM').format('MMMM YYYY');
    },

    endDate() {
      if(this.program && this.program.custom_program){
        if (!this.program || !this.program.end_date) return this.program.start_date ? null : null;
        return dayjs(this.program.end_date, 'YYYY-MM-DDTHH:mm:ssZ').format('MMMM YYYY');
      }

      if (!this.program || !this.program.end_date) return this.program.start_date ?  dayjs(this.program.start_date, 'YYYY-DD-MM').add(1,'year').format('MMMM YYYY') : null;
      return dayjs(this.program.end_date, 'YYYY-DD-MM').format('MMMM YYYY');
      },
    programTitle() {
      // Active Program - New Program - Scheduled Program - Inactive Program
      console.log("????? =>",this.program)
      const p = this.program;

      if (!p) return '';
      if(p.custom_program){
        const current = dayjs().valueOf();
        const start = dayjs(p.start_date, 'YYYY-MM-DDTHH:mm:ssZ').valueOf();
        const end = dayjs(p.end_date, 'YYYY-MM-DDTHH:mm:ssZ').valueOf();
        if(p.status == 'active') return 'Active Program'
        if(p.status == 'new') return 'New Program'
        if(p.status == 'future') return 'Scheduled Program'
        if(p.status == 'pending_upgrade') return 'Pending Upgrade'
        if (current > end) {
          return 'Inactive Program';
        }
        return ''
       }else{
        const current = dayjs().valueOf();
        const start = dayjs(p.start_date, 'YYYY-DD-MM').valueOf();
        const end = dayjs(p.end_date, 'YYYY-DD-MM').valueOf();
      }
      if (!p.start_date && !p.end_date) return 'New Program';

      if (current > start && current < end) return 'Active Program';

      if (current < start) return 'Scheduled Program';
      if (current > end) {
        return 'Inactive Program';
      }

      return '';
    },
    message() {
      if(this.program.custom_program){
        switch (this.program.status) {
          case 'active':
            return 'You can schedule program end by specify the month where the program should end. Program will end by the end of the specified month. Notifications will be sent to all members of the community.';
          case 'new':
            return 'Subscription payment is needed to activate the program.  Subscription is charged at the beginning of each month.';
          case 'future':
            return 'Program is schedule to launch.';
          default:
            return null;
        }
      }
      switch (this.programTitle) {
        case 'Active Program':
          return 'You can schedule program end by specify the month where the program should end. Program will end by the end of the specified month. Notifications will be sent to all members of the community.';
        case 'New Program':
          return 'Subscription payment is needed to activate the program.  Subscription is charged at the beginning of each month.';
        case 'Scheduled Program':
          return 'Program is schedule to launch.';
        default:
          return null;
      }

      // return `Are you sure you want to launch this program?`;
    },
    dateFormate() {
      return 'MMM YYYY';
    },
    renderDate(){
      return this.isLoading
    },
    dynamicMinDate(){
      if(this.program.status == 'new' && this.program.active_program_end_date != null){
        let value = dayjs(this.program.active_program_end_date, 'YYYY-MM-DDTHH:mm:ssZ').add(1,'month').format('MMMM YYYY') ;
        return value
      }
      return false;
    },
    dynamicUpdateEndDate(){
      if(this.program.status == 'active' ){
        return  dayjs().add(1,'month').format('MMMM YYYY')
      }
      return false
    },
    isVisible(){
      return this.visible;
    },
    errorMsgCom(){
      return this.errorMsg
    },
    isHandleBlur(){
      return this.handleBlurVar
    },
    showDangerModel(){
      return this.showDanger
    }
  },
  methods: {
    handleChange(date) {
      this.dateValue = date;
      this.value = date ? dayjs(date).format(this.dateFormate) : null;
      // this.handleBlur();
    },
    setFromDate(date) {
     this.programDate.start_date =dayjs(date.fromDate.dateValue).format( 'YYYY-MM-DD');

      },
    setEndDate(date){
      this.programDate.end_date = dayjs(date.endDate.dateValue).format( 'YYYY-MM-DD');
     },
    launchTheProgram(){
      if(this.programDate.start_date > this.programDate.end_date){
        notification.open({
          type:"error",
          message: 'Dates Error',
          description: 'Start Date Can\'t Be Greater Than End Date',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        })
          //  console.log("are we working ")
          // this.visible = true
          // this.errorMsg="Start Date Can't Be Greater Than End Date"
          return ;
      }
      this.$emit('launchTheProgram',{
        ...this.programDate
      });
    },
    closeAlert(){
      this.visible=false
      this.errorMsg=""
    },
    handleblur(){
      this.handleBlurVar = true
      setTimeout(()=>{this.handleBlurVar=false},1)
    },
    cancelTheProgram(){
      this.loading=true
      subscriptionsApi.cancelFutureSubscriptions(this.program._id).then(response=>{
        this.loading=false
        console.log({ response })
        window.location.reload();
      }).catch(error=>{
        this.loading=false
        console.log({ error })
      })
    },
    showDangerModalFunction(){
      this.showDanger = true
    },
    updateEndData(){
      this.loading=true
      subscriptionsApi.updateEndDate({
        ...this.programDate
      }).then(response=>{
        this.loading=false
        console.log({ response })
        window.location.replace(response.url ?? '/dashboard/account');
      }).catch(error=>{
        this.loading=false
        console.log({ error })
      })
    }
  },
  watch: {
    program(NewVal) {
    },
    // "programDate.start_date"(NewVal,oldValue){
    //   if(this.programDate.start_date < this.programDate.end_date){
    //     this.visible = true
    //     this.errorMsg="End Date Can't Be Greater Than Start Date"
    //     this.program.start_date = oldValue
    //     this.programDate.start_date = oldValue
    //   }
    //
    //   console.log("yes you can watch computed value")
    // },
    // "programDate.end_date"(NewVal,oldValue){
    //   if(this.programDate.start_date < this.programDate.end_date){
    //     this.visible = true
    //     this.errorMsg="End Date Can't Be Greater Than Start Date"
    //     this.program.end_date = oldValue
    //     this.programDate.end_date = oldValue
    //   }
    //
    // },
  },
  created() {
    this.programDate = {
      start_date: this.program.start_date,
      end_date: this.program.end_date
    }
  }

};

</script>
