/* eslint-disable */
<template>
  <section class="members-data">
    <div class="w-full mt-4 flex flex-wrap items-center justify-between">
      <div class="flex flex-wrap items-center gap-6">
        <router-link to="/admin/members-management">
          <Button type="primary" class="btn-light">back</Button>
        </router-link>

        <div class="bg-bg px-4 py-1 border border-border-form border-solid">{{ employeeName }}</div>
        <Button type="primary" @click="handleSignAs">Sign in as {{ employeeName }}</Button>

        <!-- <Select class="text-center min-w-[300px]" value="1">
          <select-option value="1">John Doe</select-option>
          <select-option value="2">Mark Doe</select-option>
        </Select>-->
      </div>
      <div v-if="passKeyData" class="flex flex-wrap flex-col items-center">
        <a :href="(isDev ? devLink : prodLink) + employeeId + '/' + passKeyData.passkey" target="_blank">Click to sign as</a>
        <span class="text-[14px]">{{ passKeyData.master_password }}</span>
      </div>
      <div class="flex flex-wrap items-center gap-6">
        <Button type="primary">log a new trip</Button>

        <div class="flex items-center gap-3">
          <div>
            <date-picker v-if="type !== 'all'" :picker="type" v-model:value="dateVal" />
          </div>

          <CustumSelect
            name="type"
            placeholder="select type "
            :data="dateData"
            :defaultData="type"
            :rules="{
              required: false,
            }"
            @setInput="setInput"
          />
        </div>
      </div>
      <div class="hidden">
        <InputSearch
          class="rounded-md min-w-[320px]"
          placeholder="Search for email / member name"
          v-model:value="search"
          clear
          @change="handleSearch"
        />
      </div>
    </div>

    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table
        :data-source="tableFormatedData"
        :columns="columns"
        :loading="
          employeeRewardQuery && (employeeRewardQuery.isFetching || employeeRewardQuery.isLoading)
        "
        :pagination="false"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'report_date'">
            <span v-if="record.report_date" class="whitespace-nowrap">{{
              getDate(record.report_date)
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'trip_date'">
            <span v-if="record.trip_date" class="whitespace-nowrap">{{
              getDate(record.trip_date)
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'credit_cost'">
            <span class="whitespace-nowrap text-center">${{ record.credit_cost }}</span>
          </template>

          <template v-if="column.key === 'trip_cost'">
            <span class="whitespace-nowrap text-center">${{ record.trip_cost }}</span>
          </template>

          <template v-if="column.key === 'action'">
            <div class="flex items-center gap-2">
              <!-- Remove/Reverse Trip  -->
              <Popconfirm
                title="Are you sure you want to Remove/Reverse Trip?"
                ok-text="Yes"
                cancel-text="No"
                :loading="delLoading"
                @confirm="() => handleRemoveTrip(record.key)"
              >
                <Button
                  type="primary"
                  size="small"
                  title="Remove/Reverse Trip"
                  class="btn-warning rounded shadow flex items-center gap-2"
                >
                  <PhX weight="duotone" :size="16" />
                </Button>
              </Popconfirm>

              <!-- Approve  -->
              <Popconfirm
                title="Are you sure you want to approve?"
                ok-text="Yes"
                cancel-text="No"
                :loading="approveLoading"
                @confirm="() => handleApprove(record.key)"
              >
                <Button
                  type="primary"
                  size="small"
                  title="Approve"
                  class="rounded shadow flex items-center gap-2"
                >
                  <PhCheck weight="duotone" :size="16" />
                </Button>
              </Popconfirm>
            </div>
          </template>
        </template>
      </Table>

      <div
        v-if="employeeRewardData && employeeRewardData.meta"
        class="mt-6 flex items-center justify-center"
      >
        <Pagination
          :current="employeeRewardData?.meta?.current_page"
          :total="employeeRewardData?.meta?.total"
          show-less-items
          :page-size="employeeRewardData?.meta?.per_page"
          @change="handlePaginationChange"
        />
      </div>
    </div>

    <!-- query apis  -->

    <!-- employeeId -->
    <EmployeeRewardQuery
      :employee_id="employeeId"
      :page="page"
      :per_page="15"
      :start_date="dates[0]"
      :end_date="dates[1]"
      :key="`${page}-${15}-${employeeId}-${dates[0]}-${dates[1]}`"
    />
  </section>
</template>
<script>
/* eslint-disable */
import {
  Table,
  Popconfirm,
  Button,
  InputSearch,
  Pagination,
  DatePicker,
  message,
} from 'ant-design-vue';
import { PhCheck, PhX } from 'phosphor-vue';
import dayjs from 'dayjs';

import EmployeeRewardQuery from '@/services/queries/admin/employees/EmployeeRewardQuery.vue';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';

import debounce from 'lodash.debounce';
import validation from '@/services/validation';
import employeesApi from '@/services/apis/admin/employees.api';

import handleResErrors from '@/utils/handleResErrors';

export default {
  components: {
    Table,
    Button,
    Popconfirm,
    InputSearch,

    PhCheck,
    PhX,

    Pagination,
    EmployeeRewardQuery,
    DatePicker,
    CustumSelect,
  },
  data() {
    return {
      columns: [
        // {
        //   title: '#',
        //   key: 'key',
        //   dataIndex: 'key',
        // },

        {
          title: 'Report date',
          dataIndex: 'report_date',
          key: 'report_date',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.report_date).getTime();
          //   const bDate = new Date(b.report_date).getTime();
          //   return bDate - aDate;
          // },
        },

        {
          title: 'Trip or credit date',
          dataIndex: 'trip_date',
          key: 'trip_date',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.trip_date).getTime();
          //   const bDate = new Date(b.trip_date).getTime();
          //   return bDate - aDate;
          // },
        },

        {
          title: 'Trip or credit type',
          key: 'trip_type',
          dataIndex: 'trip_type',
          // sorter: (a, b) => {
          //   if (a.trip_type > b.trip_type) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Credit',
          dataIndex: 'credit_cost',
          key: 'credit_cost',
          // sorter: (a, b) => {
          //   if (a.credit_cost > b.credit_cost) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Trip cost',
          dataIndex: 'trip_cost',
          key: 'trip_cost',
          // sorter: (a, b) => {
          //   if (a.trip_cost > b.trip_cost) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          // sorter: (a, b) => {
          //   if (a.status > b.status) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: '',
          key: 'action',
          fixed: 'right',
        },
      ],

      tableData: [
        {
          key: '1',
          report_date: 1681478087456,
          trip_date: 1681478087456,
          trip_type: 'Transit',
          credit_cost: 3,
          trip_cost: 2,
          status: 'Pending',
        },

        ...Array(50)
          .fill('dd')
          .map((_, ix) => ({
            key: ix + 2,
            report_date: this.getRandomInt(1420063200000, 1681478087456),
            trip_date: this.getRandomInt(1420063200000, 1681478087456),
            trip_type: 'Transit',
            credit_cost: this.getRandomInt(1, 10),
            trip_cost: this.getRandomInt(1, 10),
            status: 'Pending',
          })),
      ],
      search: '',
      passKeyData: null,
      isDev:false,
      searchData: [],
      page: 1,
      devLink: "https://dev.commutrics.com/passkey/",
      prodLink: "https://tool.commutrics.com/passkey/",
      pageSize: 15,
      name: '',
      email: '',
      modalMemb: null,
      delLoading: false,
      approveLoading: false,
      type: 'month',
      years: [],
      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'year', name: 'Yearly' },
        // { id: 'all', name: 'All Data' },
      ],
      dateVal: null,
    };
  },
  created() {
    this.isDev =
      process.env.VUE_APP_DEV === 'development' || process.env.NODE_ENV === 'development';
  },
  computed: {
    dates() {
      if (!this.dateVal) return [null, null];
      if (this.type === 'month') {
        const start = dayjs(this.dateVal).startOf('month').format('YYYY-MM-DD');
        const end = dayjs(this.dateVal).endOf('month').format('YYYY-MM-DD');
        return [start, end];
      }

      if (this.type === 'year') {
        const start = dayjs(this.dateVal).startOf('year').format('YYYY-MM-DD');
        const end = dayjs(this.dateVal).endOf('year').format('YYYY-MM-DD');
        return [start, end];
      }

      return [null, null];
    },
    employeeId() {
      return this.$route.params.id;
    },
    employeeName() {
      return this.$route.query.name;
    },

    employeeRewardQuery() {
      return this.$store.state.adminEmployees.employeeRewardQuery;
    },
    employeeRewardData() {
      return this.$store.getters.employeeRewardData;
    },
    tableFormatedData() {
      return this.employeeRewardData?.data?.map((item, ix) => ({
        // eslint-disable-next-line no-underscore-dangle
        key: item._id || item.id || ix,
        report_date: item.date,
        trip_date: item.trip_date,
        trip_type: item.trip_type,
        credit_cost: item.credit_cost,
        trip_cost: item.value,
        status: item.status,
      }));
    },

    membersData() {
      return this.$store.getters.membersData;
    },
    membersStatsData() {
      return this.$store.getters.membersStatsData;
    },
    pagination() {
      if (!this.membersData) {
        return {
          defaultPageSize: this.pageSize,
          total: 0,
        };
      }

      return {
        defaultPageSize: this.membersData?.meta?.per_page || this.pageSize,
        total: this.membersData?.meta?.total || 0,
      };
    },
    pageSizeOptionsArr() {
      const dataLen = this.membersData?.data?.length || 0;
      const pArr = [10];
      if (dataLen > 10) pArr.push(20);
      if (dataLen > 20) pArr.push(50);
      if (dataLen > 50) pArr.push(100);
      if (dataLen > 100) pArr.push(500);
      if (dataLen > 500) pArr.push(1000);
      if (dataLen > 1000) pArr.push(dataLen);

      return [...pArr];
    },
  },
  methods: {
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
    },
    getRandomInt(min, max) {
      const xmin = Math.ceil(min);
      const xmax = Math.floor(max);
      return Math.floor(Math.random() * (xmax - xmin + 1)) + min;
    },
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMM DD, YYYY');
    },
    handleSearch(e) {
      this.debounceSearch(e.target.value, this);
    },
    debounceSearch: debounce((search, app) => {
      if (validation.validateEmail(search)) {
        // eslint-disable-next-line no-param-reassign
        app.email = search;
        // eslint-disable-next-line no-param-reassign
        app.name = '';
      } else {
        // eslint-disable-next-line no-param-reassign
        app.email = '';
        // eslint-disable-next-line no-param-reassign
        app.name = search;
      }
    }, 400),

    handlePaginationChange(page, pageSize) {
      // , filters, sorter, data
      this.page = page;
      this.pageSize = pageSize;
    },

    async handleRemoveTrip(id) {
      this.delLoading = true;
      try {
        await employeesApi.cancelReward(id);

        message.success('Trip removed successfully');
        if (this.employeeRewardQuery) {
          this.employeeRewardQuery.refetch();
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      } finally {
        this.delLoading = false;
      }
    },
    async handleSignAs() {
      this.delLoading = true;
      try {
        const data = await employeesApi.signAsEmployee(this.employeeId);
        this.passKeyData = data;
      } catch (error) {
        message.error(handleResErrors(error), 10);
      } finally {
        this.delLoading = false;
      }
    },
    async handleApprove(id) {
      this.approveLoading = true;
      try {
        await employeesApi.approveReward(id);

        message.success('approved successfully');
        if (this.employeeRewardQuery) {
          this.employeeRewardQuery.refetch();
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      } finally {
        this.approveLoading = false;
      }
    },
    // handleApprove
  },
};
</script>
