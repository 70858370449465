<template>
  <section>
    <div>
      <div class="flex flex-wrap items-center gap-4 mb-4 justify-center md:justify-start">
        <h2 class="text-primary-dark">Compliance With Policies</h2>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 xl:gap-6 text-center">
        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhCheck weight="bold" size="40" class="text-primary" />
          </template>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">HB 22-1026</h3>
          </div>
        </TransCard>

        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhCheck weight="bold" size="40" class="text-primary" />
          </template>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">HB22-1138</h3>
          </div>
        </TransCard>

        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhCheck weight="bold" size="40" class="text-primary" />
          </template>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">ETRP</h3>
          </div>
        </TransCard>
      </div>
    </div>
  </section>
</template>

<script>
import { PhCheck } from 'phosphor-vue';
// import { Tooltip } from 'ant-design-vue';
import TransCard from './shared/TransCard.vue';

export default {
  components: {
    TransCard,
    PhCheck,
  },
};
</script>
