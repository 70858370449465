<template>
  <div class="flex items-center gap-2">
    <span
      v-if="!auth"
      class="cursor-pointer opacity-75 hover:opacity-100 md:hidden"
      @click="this.showSideMenu"
    >
      <PhList size="22" />
    </span>
    <img src="@/assets/images/logo.png" alt="logo" class="h-10" />
  </div>
</template>

<script>
import { PhList } from 'phosphor-vue';
import { mapMutations } from 'vuex';
import { LAYOUT } from '../../../store/types';

export default {
  components: { PhList },
  props: {
    auth: Boolean,
  },
  methods: {
    ...mapMutations([LAYOUT.SET_TOGGOLE_SIDEMENU]),
    showSideMenu() {
      this.SET_TOGGOLE_SIDEMENU(true);
    },
  },
};
</script>
