<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { DASH } from '@/store/types';
import dashApi from '../../apis/dash.api';

export default {
  props: {},
  setup() {
    const store = useStore();
    const query = ref(null);
    query.value = dashApi.useDashStatsQuery();
    if (query.value) {
      store.commit(DASH.SET_DASH_STATS_QUERY, query);
    }
    return { query };
  },
};
</script>
