<template>
  <div class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5">
    <div class="flex flex-wrap items-center justify-center gap-4 pb-5 text-sm">
      <div class="bg-bg-form py-2 px-5 rounded border border-gray-300 border-solid flex gap-2">
        <strong>Signup cost :</strong>
        <span>${{formatNum(totalSignupCosts)}}</span>
      </div>
      <div class="bg-bg-form py-2 px-5 rounded border border-gray-300 border-solid flex gap-2">
        <strong>Monthly rewards cost :</strong>
        <span>${{formatNum(totalRewardCosts)}}</span>
      </div>
      <div class="bg-bg-form py-2 px-5 rounded border border-gray-300 border-solid flex gap-2">
        <strong>Monthly credit cost :</strong>
        <span>${{formatNum(totalMonthlyCreditCosts)}}</span>
      </div>

      <div class="bg-bg-form py-2 px-5 rounded border border-gray-300 border-solid flex gap-2">
        <strong>Monthly prizes cost :</strong>
        <span>${{formatNum(totalPrizesCosts)}}</span>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center mt-5">
      <div class="flex flex-wrap gap-8">
        <div
          class="text-center flex flex-col flex-wrap items-center justify-center md:justify-start gap-2"
        >
          <span class="font-bold text-lg">Estimated total monthly cost:</span>
          <div
            class="bg-bg-form py-2 px-8 rounded text-xl border border-gray-300 border-solid"
          >${{formatNum(totalMonthlyRewardCost)}}</div>
        </div>

        <div
          class="hidden flexx text-center flex-col flex-wrap items-center justify-center md:justify-start gap-2"
        >
          <span class="font-bold text-lg flex items-center gap-2">
            Estimated tax credit in Colorado:
            <Tooltip v-if="!vertical">
              <template #title>
                <ul>
                  <li v-for="(txt, ix) in infoText" :key="ix">{{txt}}</li>
                </ul>
              </template>

              <PhInfo weight="fill" class="text-primary-dark cursor-pointer" size="16" />
            </Tooltip>
          </span>
          <div
            class="bg-bg-form py-2 px-8 rounded text-xl border border-gray-300 border-solid"
          >${{formatNum(totalTaxCreditCost)}}</div>
        </div>

        <!-- <div class="text-center flex flex-wrap items-center justify-center md:justify-start gap-2">
          <span class="font-bold text-primary text-xl">Equivalent Savings</span>
          <div
            class="bg-bg-form py-2 px-5 rounded text-xl border border-gray-300 border-solid"
          >${{formatNum(1234)}}</div>
        </div>-->
      </div>
      <!-- <p
        class="mt-2 text-center"
      >You will be billed monthly based on actual cost (benefits used by employees)</p>-->

      <div class="grid grid-cols-1 md:grid-cols-1 mt-6 md:mt-10">
        <div>
          <div class="flex items-center gap-6 flex-wrap flex-col lg:flex-row justify-center">
            <div class="text-center flex flex-col flex-wrap justify-center items-center gap-2">
              <span class="font-bold">Program Start Date</span>
              <div class="w-40" @click="this.handleCheckSubscription">
                <CustumCalender
                  name="start_date"
                  :defaultData="defaultDate"
                  :minDate="minDate"
                  defaultFormate="MMM, D, YYYY"
                  placeholder="Select Date"
                  :disabled="!isSubscribedUser || isStartDatePassed"
                  :rules="{
                required: false,
              }"
                  @setInput="setInput"
                  @handleChange='changedStartDate'
                />
              </div>
            </div>

            <!-- v-if="!ongoingChecked" -->
            <div class="text-center flex flex-col flex-wrap justify-center items-center gap-2">
              <span class="font-bold">Program End Date</span>
              <div class="w-40" @click="this.handleCheckSubscription">
                <CustumCalender
                  name="end_date"
                  :defaultData="defaultEndDate"
                  :minDate="minNextDate"
                  defaultFormate="MMM, D, YYYY"
                  :disabled="!isSubscribedUser || isEndDatePassed"
                  placeholder="Select Month"
                  :rules="{
                required: false,
              }"
                  @setInput="setInput"
                />
              </div>
            </div>
          </div>
          <!-- <div class="mt-4 text-center">
            <Checkbox :checked="ongoingChecked" @change="handleOngoingChecked">
              <h3 class="text-xl">Ongoing Benefits/Challenge</h3>
            </Checkbox>
          </div>-->
          <div class="w-full flex justify-center">
            <SaveBtn :disabled="!isValueChanged" :loading="isFetching" @click="handleSave" />
          </div>

          <div class="text-center flex flex-col items-center gap-2 mt-6">
            <Button
              type="primary"
              class="btn-info px-8"
              size="large"
              @click="handleModalOpen"
            >Notify All Employees</Button>
            <small>Employees are not notified yet</small>
          </div>
        </div>

        <div class="flex justify-center mt-6">
          <HeaderBalance vertical />
        </div>
      </div>
    </div>

    <Modal
      :visible="visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <NotifyModal @close="handleModalClose" />
    </Modal>

    <Modal
      :visible="subscriptionModal"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="subscriptionModal = false"
      @cancel="subscriptionModal = false"
    >
      <SubscriptionWarnModal @close="subscriptionModal = false" />
    </Modal>
  </div>
</template>

<script>
/* eslint-disable */
import { Button, Modal, Tooltip } from 'ant-design-vue';
import formatNumbers from '@/utils/formatNumbers';
import HeaderBalance from '@/components/dashboard/CreditDetails/HeaderBalance.vue';

import CustumCalender from '@/components/shared/form/CustumCalender.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { PhInfo } from 'phosphor-vue';

import NotifyModal from '../shared/NotifyModal.vue';
import SubscriptionWarnModal from '../shared/SubscriptionWarnModal.vue';
import { REWARD } from '../../../../store/types';
import SaveBtn from '../shared/SaveBtn.vue';

dayjs.extend(utc);

export default {
  components: {
    CustumCalender,
    Button,
    NotifyModal,
    SubscriptionWarnModal,
    Modal,
    // Checkbox,
    HeaderBalance,
    SaveBtn,
    PhInfo,
    Tooltip,
  },
  data() {
    return {
      checked: true,
      visible: false,
      ongoingChecked: true,
      start_date: null,
      end_date: null,
      infoText: [
        'This is ESTIMATED cost based on average mode split in Denver, you will be charged based actual transportation benefits provided employees (most likely is lower than the estimated monthly cost)',
        'This is ESTIMATED credit based on average mode split in Denver, Detailed report will be generated for tax reporting based on actual usage of transportation benefits by employees.',
      ],
      subscriptionModal: false,
    };
  },
  created() {
    if (this.rewardData?.end_date) {
      this.ongoingChecked = false;
      this.end_date = dayjs(this.rewardData?.end_date, 'YYYY-MM-DD');
    }

    if (this.rewardData?.start_date) {
      this.start_date = dayjs(this.rewardData?.start_date, 'YYYY-MM-DD');
    }
  },
  watch: {
    ongoingChecked() {
      if (this.ongoingChecked) {
        this.end_date = null;
      }
    },
  },
  computed: {
    rewardData() {
      return this.$store.getters.rewardSettingsData;
    },
    minDate() {
      const startDate = this.rewardData?.start_date
        ? dayjs(this.rewardData?.start_date, 'YYYY-MM-DD')
        : null;
      const nextMonth = dayjs().month(dayjs().month() + 1);
      return startDate || dayjs(nextMonth).startOf('month');
    },
    minNextDate() {
      const nextMonth = dayjs().month(dayjs().month() + 2);
      return dayjs(nextMonth).startOf('month');
    },
    defaultDate() {
      const startDate = this.rewardData?.start_date
        ? dayjs(this.rewardData?.start_date, 'YYYY-MM-DD')
        : null;
      // const nextMonth = dayjs().month(dayjs().month() + 1);
      // return startDate || dayjs(nextMonth).startOf('month');
      return startDate;
    },
    defaultEndDate() {
      const endDate = this.rewardData?.end_date
        ? dayjs(this.rewardData?.end_date, 'YYYY-MM-DD')
        : null;
      // const nextMonth = dayjs().month(dayjs().month() + 2);
      // return endDate || dayjs(nextMonth).startOf('month');
      return endDate;
    },

    totalSignupCosts() {
      return this.$store.getters.totalSignupCosts;
    },

    totalRewardCosts() {
      return this.$store.getters.totalRewardCosts;
    },
    totalMonthlyCreditCosts() {
      return this.$store.getters.totalMonthlyCreditCosts;
    },

    totalPrizesCosts() {
      return this.$store.getters.totalPrizesCosts;
    },

    totalMonthlyRewardCost() {
      return this.$store.getters.totalMonthlyRewardCost;
    },
    totalTaxCreditCost() {
      return this.$store.getters.totalTaxCreditCost;
    },
    isValueChanged() {
      let isChanged = false;

      if (
        (dayjs(this.rewardData?.start_date).format('YYYY-MM-DD') !==
        dayjs(this.start_date).format('YYYY-MM-DD')) || (dayjs(this.rewardData?.end_date).format('YYYY-MM-DD') !==
          dayjs(this.end_date).format('YYYY-MM-DD'))
      ) {
        isChanged = true;
      } else if (!this.rewardData?.end_date && !this.end_date) {
        isChanged = false;
      }

      return isChanged;
    },

    isSubscribedUser() {
      const { user } = this.$store.state.auth;

      if (!user || !user.balance || user.balance <= 0) return false;

      if (!user.subscription) return false;
      // user.subscription.current_period_end

      return true;
    },
    isStartDatePassed() {
      if (!this.start_date) return false;
      return dayjs(this.start_date).isBefore(dayjs(), 'day');
    },
    isEndDatePassed() {
      if (!this.end_date) return false;
      return dayjs(this.end_date).isBefore(dayjs(), 'day');
    },
  },
  methods: {
    handleCheckSubscription() {
      if (this.isSubscribedUser) return;
      this.subscriptionModal = true;
    },
    setInput(val) {
      if (!this.rewardData) return;
      if (val && val.start_date && val.start_date.value) {
        // const start_date = dayjs(val.start_date.value, 'MMM, D, YYYY').format('YYYY-MM-DD');
        this.start_date = dayjs(val.start_date.value, 'MMM, D, YYYY');
        // if (this.rewardData.start_date === start_date) return;
        // this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        //   start_date,
        // });
      }

      if (val && val.end_date && val.end_date.value) {
        // const end_date = dayjs(val.end_date.value, 'MMM, D, YYYY').format('YYYY-MM-DD');
        this.end_date = dayjs(val.end_date.value, 'MMM, D, YYYY');
        // if (this.rewardData.end_date === end_date) return;
        // this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        //   end_date,
        // });
      }
    },
    handleOngoingChecked(e) {
      this.ongoingChecked = e.target.checked;
      if (this.rewardData.end_date && e.target.checked) {
        this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
          end_date: null,
        });
      }
    },
    handleSave() {
      const start_date = this.start_date ? dayjs(this.start_date).format('YYYY-MM-DD') : null;
      const end_date = this.end_date ? dayjs(this.end_date).format('YYYY-MM-DD') : null;

      this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        start_date,
        end_date,
      });
    },
    handleModalClose() {
      this.visible = false;
    },
    handleModalOpen() {
      this.visible = true;
    },
    formatNum(val) {
      return formatNumbers(val);
    },
    changedStartDate(val){
      this.setInput(val)
    }
  },
};
</script>
