<template>
  <!-- Dashboard / Stats / Members. / Scores / Account & subscription => only account tab -->
  <div class="main-side-menu">
    <Menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed && !isSm"
    >
      <div v-if="isSm" class="bg-bg text-text flex items-center justify-between px-3 py-3">
        <img src="@/assets/images/logo.png" alt="Grand Community logo" class="h-10 w-" />

        <span
          class="text-lg cursor-pointer opacity-70 hover:opacity-100"
          @click="this.closeSideDrawer"
        >
          <PhX weight="bold" />
        </span>
      </div>
      <menu-item key="/admin" @click="() => goTo('/admin')">
        <template #icon>
          <PhHouse weight="duotone" size="18" />
        </template>
        <span>Home</span>
      </menu-item>

      <menu-item key="/admin/business-management" @click="() => goTo('/admin/business-management')">
        <template #icon>
          <PhPresentationChart weight="duotone" size="18" />
        </template>
        <span>Business Management</span>
      </menu-item>

      <menu-item key="/admin/members-management" @click="() => goTo('/admin/members-management')">
        <template #icon>
          <PhUsersThree weight="duotone" size="18" />
        </template>
        <span>Members Management</span>
      </menu-item>

      <menu-item
        key="/admin/business-transactions"
        @click="() => goTo('/admin/business-transactions')"
      >
        <template #icon>
          <PhCoins weight="duotone" size="18" />
        </template>
        <span>Business Transactions</span>
      </menu-item>

      <menu-item
        key="/admin/members-transactions"
        @click="() => goTo('/admin/members-transactions')"
      >
        <template #icon>
          <PhCoinVertical weight="duotone" size="18" />
        </template>
        <span>Members Transactions</span>
      </menu-item>

      <menu-item key="/admin/invoices" @click="() => goTo('/admin/invoices')">
        <template #icon>
          <PhReceipt weight="duotone" size="18" />
        </template>
        <span>Invoices</span>
      </menu-item>
      <menu-item key="/admin/tax-pay-approval" @click="() => goTo('/admin/tax-pay-approval')">
        <template #icon>
          <PhFile :size="18" />
        </template>
        <span>Taxes approval</span>
      </menu-item>

      <menu-item
        v-if="isDev"
        key="/admin/terms-and-conditions"
        @click="() => goTo('/admin/terms-and-conditions')"
      >
        <template #icon>
          <PhChatCenteredDots weight="duotone" size="18" />
        </template>
        <span>Terms and Conditions</span>
      </menu-item>
      <menu-item v-if="isDev" key="/admin/admin-users" @click="() => goTo('/admin/admin-users')">
        <template #icon>
          <PhChatCenteredDots weight="duotone" size="18" />
        </template>
        <span>Admin Users</span>
      </menu-item>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuItem } from 'ant-design-vue';
import {
  PhHouse,
  PhPresentationChart,
  PhUsersThree,
  PhCoinVertical,
  PhCoins,
  PhReceipt,
  PhChatCenteredDots,
  PhFile,
} from 'phosphor-vue';
import { mapMutations } from 'vuex';
import { LAYOUT } from '../../../store/types';

export default {
  components: {
    Menu,
    MenuItem,
    PhHouse,

    PhPresentationChart,
    PhUsersThree,
    PhCoinVertical,
    PhCoins,
    PhReceipt,
    PhChatCenteredDots,
    PhFile,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    isSm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedKeys: ['1'],
      openKeys: ['sub1'],
      preOpenKeys: ['sub1'],
      isDev: false,
    };
  },
  created() {
    this.isDev =
      process.env.VUE_APP_DEV === 'development' || process.env.NODE_ENV === 'development';
  },
  mounted() {
    this.selectedKeys = [this.getActivePath()];
  },
  watch: {
    $route: {
      handler(val) {
        if (val && val.path) {
          this.selectedKeys = [this.getActivePath()];
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations([LAYOUT.SET_TOGGOLE_SIDEMENU]),
    getActivePath() {
      const { matched } = this.$route;
      const lastPath = matched[matched.length - 1].path;
      let path = lastPath;

      if (lastPath.includes('/:')) {
        // eslint-disable-next-line prefer-destructuring
        path = lastPath.split('/:')[0];
      }
      return path;
    },
    goTo(url) {
      this.$router.push(url);
      this.SET_TOGGOLE_SIDEMENU(false);
    },
    closeSideDrawer() {
      this.SET_TOGGOLE_SIDEMENU(false);
    },
  },
};
</script>
