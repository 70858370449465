/* eslint-disable */
import { useQuery } from 'vue-query';
import apiAxios from '../../apiAdminAxios';

const fetchEmployees = async ({ page, per_page, keyword, employer_id, order_by, order_direction, start_date, end_date, all }) => {
  console.log('are we here !');
  console.log(page, per_page, keyword, employer_id, order_by, order_direction);
  let URL = `?page=${page || 1}`;

  if (per_page) {
    URL += `&per_page=${per_page}`;
  }
  if (keyword) {
    URL += `&keyword=${keyword}`;
  }

  if (employer_id) {
    URL += `&employer_id=${employer_id}`;
  }

  if (order_by) {
    URL += `&order_by=${order_by}`;
  }

  if (order_direction) {
    URL += `&order_direction=${order_direction}`;
  }

  if (start_date && end_date) {
    URL += `&start_date=${start_date}&end_date=${end_date}`;
  }

  if (all) {
    URL += `&all=${1}`;
  }

  const { data } = await apiAxios.get(`/admin/employees${URL}`);
  return data;
};

const fetchEmployeeReward = async ({ page, per_page, keyword, start_date, end_date, employee_id }) => {
  let URL = `?page=${page || 1}`;

  if (per_page) {
    URL += `&per_page=${per_page}`;
  }
  if (keyword) {
    URL += `&keyword=${keyword}`;
  }
  if (start_date && end_date) {
    URL += `&start_date=${start_date}&end_date=${end_date}`;
  }

  if (employee_id) {
    URL += `&employee_id=${employee_id}`;
  }
  const { data } = await apiAxios.get(`/admin/rewards${URL}`);
  return data;
};

const approveReward = async (id) => {
  const { data } = await apiAxios.put(`/admin/rewards/approve/${id}`);
  return data;
};

const cancelReward = async (id) => {
  const { data } = await apiAxios.put(`/admin/rewards/cancel/${id}`);
  return data;
};
const deleteEmployee = async (id) => {
  const { data } = await apiAxios.delete(`/admin/employees/${id}`);
  return data;
}
const signAsEmployee = async (id) => {
  const { data } = await apiAxios.post(`/admin/employees/${id}/access`, {user_id:id});
  return data;
}

// queries
const useEmployeesQuery = ({ page, per_page, keyword, employer_id, order_by, order_direction, start_date, end_date, all  }) => useQuery(['admin/employees', page, per_page, keyword, employer_id, order_by, order_direction, start_date, end_date, all], () => fetchEmployees({ page, per_page, keyword, employer_id, order_by, order_direction, start_date, end_date, all }), { refetchOnMount: false });

const useEmployeeRewardQuery = ({ page, per_page, keyword, start_date, end_date, employee_id }) => useQuery(['admin/rewards', page, per_page, keyword, start_date, end_date, employee_id], () => fetchEmployeeReward({ page, per_page, keyword, start_date, end_date, employee_id }), { refetchOnMount: false });

const updateEmployeeStatus = async (URL, isApprove = false, isDecline = false) => {
  if (isApprove) {
    const { data } = await apiAxios.put(`/admin/employees/${URL}/approve`);
    return data;
  } if (isDecline) {
    const { data } = await apiAxios.put(`/admin/employees/${URL}/disable-email`);
    return data;
  }
  const { data } = await apiAxios.put(`/admin/employees/${URL}/disable`);
  return data;
};
export default {
  fetchEmployees,
  fetchEmployeeReward,
  approveReward,
  cancelReward,
  // queries
  useEmployeesQuery,
  useEmployeeRewardQuery,
  updateEmployeeStatus,
  deleteEmployee,
  signAsEmployee

};
