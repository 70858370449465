<template>
  <section>
    <div>
      <div class="flex flex-wrap items-center gap-4 mb-4 justify-center md:justify-start">
        <h2 class="text-primary-dark">Potential Benefits and Savings</h2>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 xl:gap-6 text-center">
        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhCoins weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Renting Parking Spaces</h3>
          </div>
        </TransCard>

        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhSmiley weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Reduced Turnover</h3>
          </div>
        </TransCard>

        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhTrendUp weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Employee Productivity</h3>
          </div>
        </TransCard>

        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhPercent weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Transportation Savings</h3>
          </div>
        </TransCard>

        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhFileText weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Tax Credit</h3>
          </div>
        </TransCard>

        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhPercent weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Total Equivalent Savings</h3>
          </div>
        </TransCard>

        <TransCard color="primary" noBtn>
          <template v-slot:icon>
            <PhFlame weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Reduction in Emissions</h3>
          </div>
        </TransCard>
      </div>
    </div>
  </section>
</template>

<script>
import { PhCoins, PhSmiley, PhTrendUp, PhPercent, PhFileText, PhFlame } from 'phosphor-vue';
// import { Tooltip } from 'ant-design-vue';
import TransCard from './shared/TransCard.vue';

export default {
  components: {
    TransCard,
    PhCoins,
    PhSmiley,
    PhTrendUp,
    PhPercent,
    PhFileText,
    PhFlame,
  },
};
</script>
