import apiAxios from '../apiAxios';

const postContact = async ({ email, message, subject }) => {
  const { data } = await apiAxios.post(`/contact-us?email=${email}&message=${message}&subject=${subject}`);
  return data;
};

export default {
  postContact,

};
