<script setup>
/* eslint-disable */
import apiAdminAxios from '@/services/apiAdminAxios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { computed, ref, onMounted, watch } from 'vue';
import AdminLayout from '@/layouts/AdminLayout.vue';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import {
  Button, message,
} from 'ant-design-vue';
import apiAxios from '@/services/apiAdminAxios';
import handleResErrors from '@/utils/handleResErrors';
import dayjs from 'dayjs';

const editor = ClassicEditor;
const CKToolbar = {
  items: [
    'undo',
    'redo',
    '|',
    'heading',
    '|',
    'fontfamily',
    'fontsize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'strikethrough',
    'subscript',
    'superscript',
    'code',
    '|',
    'link',
    'blockQuote',
    'codeBlock',
    '|',
    'bulletedList',
    'numberedList',
    'todoList',
    'outdent',
    'indent',
  ],
  shouldNotGroupWhenFull: false,
};
const description = ref('');
const agreements = ref([]);
const modeFilter = ref(null);
const filteredAgreements = ref([]);
const currentVersion = ref(null)
const rerenderKey = ref(1)
const rerenderKeyTwo = ref(1)

const header = ref('Terms And Conditions')
const current_type = ref(null)
const isPublished = ref(false)
const forceRerenderKey = () => {
  rerenderKey.value += 1
}
const forceRerenderKeyTwo = () => {
  rerenderKeyTwo.value += 1
}
const shapeData = () => {
  let shapedData = [];
  shapedData = agreements.value.map((item) => ({
    id: item.id,
    name: item.id,
  }));
  return shapedData;
};
const getListOfTermsAndConditions = async () => {
  console.log('getListOfTermsAndConditions');
  const { data } = await apiAdminAxios.get('/admin/agreements');
  agreements.value = data.data;
};

const setModeInput = (data) => {
  console.log(data)
  modeFilter.value = data.mode_filter.value;
  current_type.value = data.mode_filter.value ? data.mode_filter.value : false
  let title = data.mode_filter.value ? data.mode_filter.value.replaceAll('_',' ') : "Terms and conditions";
  if(data.mode_filter.value == 'terms_and_conditions' || data.mode_filter.value == 'privacy_policy' || data.mode_filter.value == 'redeem_credit' ){
    title = 'Commuter '+title
  }
  header.value = title

};
const setVersionInput = (data) => {
  currentVersion.value = data.version_filter.value;
};


const agreementsFiltered = computed(() => shapeData(agreements));

const CreateNewVersion  = () => {
  currentVersion.value = null
  forceRerenderKey()
}

onMounted(() => {
  getListOfTermsAndConditions();
});
const ifPublished = (value)=>{
  if(value) {console.log("if =>",value)
    return ' ( published )'}
  return  ''
}

watch(() => modeFilter.value, (currentValue) => {
  if(currentValue){
  const listOfArray = [];
  forceRerenderKey()
  currentVersion.value = null
  description.value = ""
  // eslint-disable-next-line array-callback-return
  agreements.value.map((item) => {
    // eslint-disable-next-line eqeqeq
    if (item.type == currentValue) {
      listOfArray.push({
        id: item.id,
        name:getDate(item.updated_at) + ifPublished(item.published_at)
      });
    }
  });
  filteredAgreements.value = listOfArray;
  }else{
    filteredAgreements.value = agreements.value
  }
});

watch(() => currentVersion.value, (currentValue) => {
  if(currentValue){
  let currentSelectedVersion = null;
  // eslint-disable-next-line array-callback-return
  agreements.value.map((item) => {
    // eslint-disable-next-line eqeqeq
    if (item.id == currentValue) {
      currentSelectedVersion = item
    }
  });
  if(currentSelectedVersion){
    isPublished.value = currentSelectedVersion.published_at == null ? false :true;
      description.value = currentSelectedVersion.content
    return
  }
    description.value = ""
    isPublished.value = false
  }else{
    // description.value = ""
    isPublished.value = false
  }
});


// Actions

const publishAnAgreement = async (id) => {
  if(description.value == "" || description.value == null || description.value == " "){
    message.error("Please Add Content", 10);
    return
  }
  try {
    const res = await apiAxios.put(`/admin/agreements/${id}/publish`);
    if (res.status) {
      message.success('Updated successfully');
      await getListOfTermsAndConditions()
    }
    forceRerenderKey()
    forceRerenderKeyTwo()
  }
  catch (error) {
    const errorMsg = error.response?.data?.message;
    if (errorMsg) {
      this.responseErrors = errorMsg;
    }
    forceRerenderKey()
    forceRerenderKeyTwo()
    message.error(handleResErrors(error), 10);
  }
}

const saveAnAgreement = async () => {
  if(description.value == "" || description.value == null || description.value == " "){
    message.error("Please Add Content", 10);
    return
  }
  try {
    let formData = {
      "content":description.value,
      "type":current_type.value
    }
    // console.log("currentVersion =>", currentVersion)
    let res = null
    if(!currentVersion.value) {
      console.log(" if !currentVersion")
       res = await apiAdminAxios.post(`/admin/agreements`,formData);
    }else if(currentVersion.value && !isPublished.value){
      console.log(" else if currentVersion && !isPublished")
       res = await apiAdminAxios.put(`/admin/agreements/${currentVersion.value}`,formData);
    }else{
      console.log(" else")
       res = await apiAdminAxios.post(`/admin/agreements`,formData);
    }
    if (res.status) {
      message.success('Updated successfully');
      await getListOfTermsAndConditions()
    }
    forceRerenderKey()
    forceRerenderKeyTwo()
    console.log("*****************")
    console.log(res.data)
    console.log("*****************")
    return res.data
  }
  catch (error) {
    const errorMsg = error.response?.data?.message;
    if (errorMsg) {
      this.responseErrors = errorMsg;
    }
    message.error(handleResErrors(error), 10);
    forceRerenderKey()
    forceRerenderKeyTwo()
  }
}

const publishClicked = async () =>{
  if(currentVersion.value){
  await publishAnAgreement(currentVersion.value)
  }else{
    const data = await saveAnAgreement()
    await publishAnAgreement(data.data.id)
  }
}

const getDate = (d) =>  {
  // February 18, 2022, 4:43 am
  return dayjs(d).format('MMM DD, YYYY');
}
</script>

<template>
  <AdminLayout>
  <div class="reward-page py-4 mt-4">
    <div class="container">
      <div class="card-container">
        <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
          <div class="flex items-center gap-3" style='margin-bottom: 3px'>
            <div style='width: 35%'>
              <h2 class='qcont'>{{header}}</h2>
            </div>
            <div style='width: 65%;text-align: end;justify-content: end' class='flex items-end gap-3'>
              <CustumSelect
                style='width: 250px;text-align: left;'
                name="mode_filter"
                placeholder="select mode"
                :key='rerenderKeyTwo'
                clearable
                :data="[
                  { id: 'terms_and_conditions', name: 'Commuter terms and condition', },
                  { id: 'privacy_policy', name: 'Commuter privacy policy'},
                  { id: 'redeem_credit', name: 'Commuter Redeem  Credit'},
                  { id: 'employer_terms_and_conditions', name: 'Employer terms and condition'},
                  { id: 'employer_privacy_policy', name: 'Employer privacy policy'},
                ]"
                :rules="{
                    required: false,
                  }"
                @setInput='setModeInput'
                :allowClear='true'
              />
              <CustumSelect
                name="version_filter"
                style='width: 250px;text-align: left;'
                placeholder="select version "
                :data="filteredAgreements"
                :key='rerenderKey'
                :rules="{
                    required: false,
                  }"
                :disabled='modeFilter != null ? false : true'
                :allowClear='true'
                @setInput='setVersionInput'
              />
              <Button type="primary" :disabled='!current_type' @click='CreateNewVersion'>Create a new one</Button>
            </div>
          </div>

            <Ckeditor
              v-model="description"
              :editor="editor"
              :config="{
                  'placeholder': 'Enter Your Terms and Conditions',
                  'language': 'en',
                  'toolbar': CKToolbar,
                }"
            />
          <br><br>
            <div>
              <div v-html='description'>
              </div>
            </div>
          <div class="flex items-center gap-3">
            <div style='width: 100%;text-align: end;justify-content: end' class='flex items-end gap-3'>
              <Button class='btn-draft' type="secondary" :disabled='!current_type'      @click='saveAnAgreement'>Save</Button>
              <Button v-if='currentVersion' type="primary" :disabled='isPublished'      @click='publishClicked'>Publish</Button>
              <Button v-if='!currentVersion' type="primary" :disabled='!current_type'   @click='publishClicked'>Publish</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </AdminLayout>

</template>

<style scoped>
.btn-draft.ant-btn-secondary{
  background-color: #DEDAD9FF;
  color: black;
  border-color: #DEDAD9FF !important;
  transition: all 0.5s;
}
.btn-draft.ant-btn-secondary:hover{
  background-color:  #DEDAD9FF;
  color: black;
  border-color: #DEDAD9FF !important;
}
.btn-draft.ant-btn-secondary:focus{
  background-color:  #DEDAD9FF;
  color: black;
  border-color: #DEDAD9FF !important;
}
.qcont:first-letter{
  text-transform: capitalize
}
</style>
