<template>
  <div class="p-4">
    <h3 class="text-base text-center mb-6">{{title}}</h3>

    <div v-if="modeData" class="w-full flex flex-col gap-4">
      <!-- start  -->
      <div class="flex items-center gap-2">
        <p class="flex-1">For every 1 kg reduced CO2 emissions</p>
        <InputNumber class="w-16" v-model:value="vals.point_per_kg_co2_reduction" :min="0" />
      </div>
      <!-- / end  -->

      <!-- start  -->
      <div class="flex items-center gap-2">
        <p class="flex-1">Monetary incentives for each 1000 points</p>
        <InputNumber
          class="w-16"
          v-model:value="vals.thousand_points_incentives"
          prefix="$"
          :min="0"
        />
      </div>
      <!-- / end  -->
    </div>

    <div class="w-full flex flex-col gap-2 mt-6">
      <Button block @click="chooseDefault" :disabled="loading">Choose Default</Button>
      <Button type="primary" size="large" block @click="handleSave" :loading="loading">Save Benefits</Button>
      <Button
        v-if="defaultRewardData"
        type="primary"
        class="btn-light"
        block
        :disabled="loading"
        @click="() => $emit('close')"
      >Cancel</Button>
    </div>
  </div>
</template>

<script>
import { Button, InputNumber } from 'ant-design-vue';

import { mapMutations } from 'vuex';
import { REWARD } from '../../../../store/types';

export default {
  components: {
    Button,

    InputNumber,
  },
  props: {
    title: String,
    id: Number,
  },
  data() {
    return {
      vals: {
        // point_per_kg_co2_reduction: 100,
        // thousand_points_incentives: 10,
      },
    };
  },

  computed: {
    rewardData() {
      return this.$store.getters.rewardSettingsData;
    },
    defaultRewardData() {
      return this.$store.getters.rewardDefaultsData;
    },
    loading() {
      return this.$store.state.reward.rewardSettingsQuery?.isFetching;
    },
    modeData() {
      return this.$store.state.reward.modeData?.emission_base || null;
    },
    defaultData() {
      return this.$store.state.reward.modeDefaultData?.emission_base || null;
    },
  },
  created() {
    if (this.rewardData?.scoring_policies?.emission_based) {
      this.vals = { ...this.rewardData?.scoring_policies?.emission_based };
    }
  },

  methods: {
    ...mapMutations([REWARD.SET_MODE_DATA]),
    chooseDefault() {
      if (!this.defaultRewardData || !this.defaultRewardData.emission_based) return;
      this.vals = { ...this.vals, ...this.defaultRewardData.emission_based };
    },
    async handleSave() {
      const defaultData = { ...this.rewardData.scoring_policies, emission_based: { ...this.vals } };
      await this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        scoring_policies: defaultData,
      });
      this.$emit('close');
    },
  },
};
</script>
