import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

const fetchScores = async ({ page, per_page, type, custumDate }) => {
  let URL = `?page=${page || 1}`;

  if (per_page) {
    URL += `&per_page=${per_page}`;
  }

  // if (type && type !== 'all') {
  //   URL += `&type=${type}`;
  // }
  if (type === 'all') {
    URL += '&from_year=2016-01-01&to_year=2123-01-01';
  } else if (custumDate && custumDate.from && custumDate.to) {
    URL += `&from_year=${custumDate.from}&to_year=${custumDate.to}`;
  }

  // else if (years && years.from_year && years.to_year) {
  //   URL += `&from_year=${years.from_year}-01-01&to_year=${years.to_year}-01-01`;
  // }

  const { data } = await apiAxios.get(`/scores${URL}`);
  return data;
};

// queries
const useScoreQuery = ({ page, per_page, years, type, custumDate }) => useQuery(['scores', page, per_page, years, type, custumDate], () => fetchScores({ page, per_page, years, type, custumDate }));

export default {
  fetchScores,
  // queries
  useScoreQuery,

};
