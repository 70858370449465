<template>
  <div class="w-full p-2">
    <div class="flex items-center justify-end gap-4">
      <div>
        <CustumSelect
          name="spots"
          placeholder="select type "
          :data="spacesData"
          :defaultData="type"
          :rules="{
        required: false,
      }"
          @setInput="setInput"
        />
      </div>
      <div>
        <!-- <CustumSelect
          name="year"
          placeholder="select type "
          :data="yearsData"
          :defaultData="1"
          :rules="{
        required: false,
      }"
          @setInput="setInput"
        />-->
        <date-picker v-model:value="year" picker="year" :allowClear="false" />
      </div>
    </div>

    <div
      v-if="query && (query.isFetching || query.isLoading)"
      class="flex items-center justify-center py-16"
    >
      <Spin spinning size="large" />
    </div>

    <QueryAlert :query="query" />

    <div class="mt-6" v-if="data && data.length">
      <BarChart :key="componentKey" :chartData="chartData" />
      <p class="text-center font-bold mt-3">{{mainSpot.name}}</p>
    </div>

    <div v-if="query && !query.isLoading && (!data || !data.length )" class="py-12 lg:py-16">
      <Empty />
    </div>

    <StatsCommuterBenefitsQuery :type="type" :year="getYear" :key="type+getYear" />
  </div>
</template>

<script>
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import StatsCommuterBenefitsQuery from '@/services/queries/stats/StatsCommuterBenefitsQuery.vue';
import { Spin, Empty, DatePicker } from 'ant-design-vue';
import dayjs from 'dayjs';
import QueryAlert from '@/components/shared/QueryAlert.vue';
import BarChart from '../shared/BarChart.vue';
import { sortAndNameMonthes } from '../../../../utils/monthes';

export default {
  components: {
    CustumSelect,
    BarChart,
    StatsCommuterBenefitsQuery,
    Spin,
    Empty,
    DatePicker,
    QueryAlert,
  },
  data() {
    return {
      type: 'community_points',
      year: dayjs(),
      spacesData: [
        { id: 'community_points', name: 'Community Points', label: 'Points' },
        { id: 'commuter_monetary', name: 'Commuter Monetary Incentives', label: '$' },
        { id: 'commute_cost', name: 'Commute Cost', label: '$' },
        { id: 'commute_savings', name: 'Commute Savings', label: '$' },
      ],
      yearsData: [
        {
          id: 1,
          name: '2022',
        },
        {
          id: 2,
          name: '2023',
        },
        {
          id: 3,
          name: '2024',
        },
      ],
      checkPrevYearMounted: false,
      componentKey: 0,
    };
  },
  computed: {
    getYear() {
      return dayjs(this.year).year();
    },
    mainSpot() {
      return this.spacesData.find((d) => d.id === this.type);
    },
    query() {
      return this.$store.state.stats.commuterBenifitsStatsQuery;
    },
    data() {
      return this.$store.getters.commuterBenifitsStatsData;
    },
    chartData() {
      if (!this.data || !this.data.length) return null;
      const defaultData = sortAndNameMonthes(this.data);

      const data = {
        labels: defaultData.map((d) => Object.keys(d)[0]),
        datasets: [
          {
            label: this.mainSpot.label,
            data: defaultData.map((d) => Object.values(d)[0]),
            backgroundColor: ['#278c64'],
          },
        ],
      };
      this.forceRerender();
      return data;
    },
  },
  watch: {
    data() {
      // check if no data and get previous year data
      const prevYear = dayjs(this.year).subtract(1, 'year');
      if (
        this.data &&
        this.data.length === 0 &&
        prevYear.year() >= 2020 &&
        !this.checkPrevYearMounted
      ) {
        this.year = prevYear;
        this.checkPrevYearMounted = true;
      }
    },
  },
  methods: {
    setInput(val) {
      if (val && val.spots && val.spots.value) {
        this.type = val.spots.value;
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
