<template>
  <div class="account-page py-4 mt-4">
    <div class="container">
      <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
        <TableData />
      </div>
    </div>
  </div>
</template>
<script>
import TableData from './TableData.vue';

export default {
  components: {
    TableData,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
};
</script>
