<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { REWARD } from '@/store/types';
import rewardApi from '@/services/apis/reward.api';

export default {
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
  },
  setup(props) {
    const store = useStore();
    const query = ref(null);
    query.value = rewardApi.useRewardsListQuery({
      startDate: props.startDate,
      endDate: props.endDate,
    });
    if (query.value) {
      store.commit(REWARD.SET_REWARDS_LIST_QUERY, query);
    }
    return { query };
  },
};
</script>
