<template>
  <section class="metrics_data">
    <div class="container">
      <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
        <div class="w-full flex flex-wrap items-center justify-between">
          <h2 class="text-primary">Metrics</h2>
          <div></div>
        </div>

        <div class="w-full mt-4 metrics_data-tabs">
          <tabs v-model:activeKey="activeKey" class="w-full">
            <tab-pane key="1" tab="Employer Benefits">
              <EmployerBenefits />
            </tab-pane>
            <tab-pane key="2" tab="Commuter Benefits">
              <CommuterBenefits />
            </tab-pane>
            <tab-pane key="3" tab="Community Footprint">
              <CommunityFootprint />
            </tab-pane>
            <tab-pane key="4" tab="Mode Share">
              <ModeShare />
            </tab-pane>
            <tab-pane key="5" tab="Mile Share">
              <MileShare />
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Tabs, TabPane } from 'ant-design-vue';
import EmployerBenefits from './metrics/EmployerBenefits.vue';
import CommuterBenefits from './metrics/CommuterBenefits.vue';
import CommunityFootprint from './metrics/CommunityFootprint.vue';
import ModeShare from './metrics/ModeShare.vue';
import MileShare from './metrics/MileShare.vue';

export default {
  components: {
    Tabs,
    TabPane,
    EmployerBenefits,
    CommuterBenefits,
    CommunityFootprint,
    ModeShare,
    MileShare,
  },
  data() {
    return {
      activeKey: '1',
    };
  },
};
</script>

<style   >
.metrics_data-tabs .ant-tabs-tab-btn {
  font-size: 1.25rem;
  font-weight: 500;
}

.metrics_data-tabs .ant-tabs-nav-wrap {
  background-color: var(--color-bg);
  padding-inline: 0.5rem;
}

.metrics_data-tabs .ant-tabs-tabpane {
  overflow-x: auto;
}
/* .metrics_data-tabs .ant-tabs-bottom > .ant-tabs-nav:before,
.metrics_data-tabs .ant-tabs-bottom > div > .ant-tabs-nav:before,
.metrics_data-tabs .ant-tabs-top > .ant-tabs-nav:before,
.metrics_data-tabs .ant-tabs-top > div > .ant-tabs-nav:before {
  border-bottom-color: var(--color-primary);
}

.metrics_data-tabs .ant-tabs-tab-active {
  background-color: var(--color-primary);
}

.metrics_data-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-bg) !important;
  padding: 0 1rem;
} */
</style>
