<template>
  <div>
    <div class='overlay display-show display-none' id='custom-overlay'></div>
    <MainHeader />
    <div class="flex layout-container">
      <Drawer
        v-if="isSm"
        :visible="showSideMenu"
        :destroyOnClose="true"
        :headerStyle="{ display: 'none' }"
        :bodyStyle="{ padding: 0 }"
        placement="left"
        width="fit-content"
      >
        <SideMenu :collapsed="collapsed" :isSm="isSm" />
      </Drawer>

      <SideMenu v-if="!isSm" :collapsed="collapsed" />
      <main>
        <slot></slot>
      </main>
    </div>
  </div>
</template>
<script>
import MainHeader from '@/components/layout/headers/MainHeader.vue';
import SideMenu from '@/components/layout/SideMenu/SideMenu.vue';
import { mapMutations } from 'vuex';
import { Drawer } from 'ant-design-vue';
import { LAYOUT } from '../store/types';
// import { pagesGurd } from '@/middleware/gurds';

export default {
  components: { MainHeader, SideMenu, Drawer },
  data() {
    return {
      collapsed: false,
      isSm: false,
    };
  },
  computed: {
    showSideMenu() {
      return this.$store.state.layout.showSideMenu;
    },
  },
  created() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapMutations([LAYOUT.SET_TOGGOLE_SIDEMENU]),
    handleResize() {
      if (window.innerWidth && window.innerWidth < 950) {
        this.collapsed = true;
      } else {
        this.collapsed = false;
      }
      if (window.innerWidth && window.innerWidth < 767) {
        this.isSm = true;
      } else {
        this.isSm = false;
      }
    },
    toggleDawer(val) {
      this.SET_TOGGOLE_SIDEMENU(val || false);
    },
  },
};
</script>

<style  >
.layout-container {
  height: calc(100vh - 65px);
}

.layout-container > main {
  max-height: 100%;
  overflow-y: auto;
  flex: 1;
}
.main-side-menu,
.main-side-menu .ant-menu {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: var(--color-bg-invert);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: var(--color-bg-invert-dark);
}
</style>
