import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

const fetchMembers = async ({ page, per_page, name, email, start_date, end_date }) => {
  let URL = `?page=${page || 1}`;

  if (per_page) {
    URL += `&per_page=${per_page}`;
  }
  if (name) {
    URL += `&name=${name}`;
  }
  if (email) {
    URL += `&email=${email}`;
  }
  if (start_date) {
    URL += `&start_date=${start_date}`;
  }
  if (end_date) {
    URL += `&end_date=${end_date}`;
  }
  const { data } = await apiAxios.get(`/members${URL}`);
  return data;
};

const deleteMember = async (id) => {
  const { data } = await apiAxios.delete('/member', { data: { member_id: id } });
  return data;
};

const updateMember = async ({ id, active }) => {
  const { data } = await apiAxios.put('/member', { member_id: id, is_active: active });
  return data;
};

const fetchPendingMembers = async ({ page, per_page, name, email }) => {
  let URL = `?page=${page || 1}`;

  if (per_page) {
    URL += `&per_page=${per_page}`;
  }
  if (name) {
    URL += `&name=${name}`;
  }
  if (email) {
    URL += `&email=${email}`;
  }

  const { data } = await apiAxios.get(`/members-pending${URL}`);
  return data;
};

const fetchMembersStats = async () => {
  const { data } = await apiAxios.get('/members-stats');
  return data;
};

const postInviteMemberStats = async ({ email, name }) => {
  // let URL = `?email=${email}`;
  // if (name) {
  //   URL += `&name=${name}`;
  // }
  // const { data } = await apiAxios.post(`/members/invite${URL}`);

  const { data } = await apiAxios.post('/reward-settings/notify', {
    email, name,
  });

  return data;
};

const postSendMessage = async ({ email, message, subject }) => {
  const { data } = await apiAxios.post(`/contact-us?email=${email}&message=${message}&subject=${subject}`);
  return data;
};

// queries
const useMembersQuery = ({ page, per_page, name, email, start_date, end_date }) => useQuery(['members', page, per_page, name, email, start_date, end_date], () => fetchMembers({ page, per_page, name, email, start_date, end_date }), { refetchOnMount: false });

const useMembersStatsQuery = () => useQuery(['members-stats'], fetchMembersStats, { refetchOnMount: false });

const usePendingMembersQuery = ({ page, per_page, name, email }) => useQuery(['members-pending', page, per_page, name, email], () => fetchPendingMembers({ page, per_page, name, email }), { refetchOnMount: false });

const cancelMemberInvitation = async (invation_id) => {
  const { data } = await apiAxios.put(`/invitations/${invation_id}/cancel`);
  return data;
};
const acceptMemberInvitation = async (invation_id) => {
  const { data } = await apiAxios.put(`/invitations/${invation_id}/accept`);
  return data;
};

export default {
  fetchMembers,
  fetchMembersStats,
  fetchPendingMembers,
  postInviteMemberStats,
  postSendMessage,
  deleteMember,
  updateMember,
  // queries
  useMembersQuery,
  useMembersStatsQuery,
  usePendingMembersQuery,
  cancelMemberInvitation,
  acceptMemberInvitation,
};
