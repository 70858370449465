/* eslint-disable */
// import { setAxiosHeader } from '@/services/apiAxios';
// import { destroyToken, saveToken } from '@/services/jwt.service';
import authApi from '@/services/apis/auth.api';
import { destroyToken } from '@/services/jwt.service';
import { message } from 'ant-design-vue';
import { AUTH } from '../types';

export default {
  state: {
    user: null,
    userQuery: null,
  },
  getters: {},
  mutations: {
    [AUTH.SET_ACTIVE_USER](state, payload) {
      state.userQuery = payload;
    },
    [AUTH.SET_USER_DATA](state, payload) {
      state.user = payload;
    },

  },
  actions: {
    async [AUTH.ACTION_SET_USER_DATA]({ state, commit }, payload) {
      if (state.user && payload?.refetch !== true) {
        return state.user;
      }
      try {
        const data = await authApi.getUser();
        commit(AUTH.SET_USER_DATA, data);
        return data;
      } catch (error) {
        message.error(error?.response?.data?.message || error.message || 'Something went wrong');
        return { error: 'incorrect email or password' };
      }
    },
    async [AUTH.RELOAD]({ state, commit }, payload) {
      try {
        const data = await authApi.getUser();
        commit(AUTH.SET_USER_DATA, data);
        return data;
      } catch (error) {
        message.error(error?.response?.data?.message || error.message || 'Something went wrong');
        return { error: 'incorrect email or password' };
      }
    },
    async [AUTH.ACTION_LOGOUT]({ commit }) {
      destroyToken();
      commit(AUTH.SET_USER_DATA, null);
      commit(AUTH.SET_ACTIVE_USER, null);
    },
  },

};
