<template>
  <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </div>
</template>

<script>
import { Skeleton } from 'ant-design-vue';

export default {
  components: { Skeleton },
};
</script>
