import { PAYMENTS } from '../types';

export default {
  state: {
    subscriptionsQuery: null,
    plans: [
      {
        id: 'month',
        title: 'Monthly',
        price: null,
        offer: 'Pay as you go',
        note: 'Plan is renewed every month until cancelled ',

      },
      // {
      //   id: 'half_year',
      //   title: 'Semi-annual',
      //   price: null,
      //   offer: '33% savings',
      //   note: 'Plan is renewed every 6 months until cancelled ',

      // },
      {
        id: 'year',
        title: 'Annual',
        price: null,
        offer: '42% savings',
        note: 'Plan is renewed every year until cancelled',

      },
    ],
  },
  getters: {
    subscriptionsData(state) {
      if (!state.subscriptionsQuery) {
        return null;
      }
      return state.subscriptionsQuery.data || null;
    },
    plansData(state) {
      if (!state.subscriptionsQuery || !state.subscriptionsQuery?.data) {
        return state.plans;
      }
      const plans = [];
      const subData = { ...state.subscriptionsQuery?.data };

      const monthPrice = subData.month?.price;

      Object.entries(subData).forEach(([key, value]) => {
        const foundPlan = state.plans.find((p) => p.id === key);
        if (foundPlan) {
          foundPlan.price = value.price;

          if (key === 'half_year' && monthPrice) {
            foundPlan.sale = 100 - ((value.price / (monthPrice * 6)) * 100);
          }

          if (key === 'year' && monthPrice) {
            foundPlan.sale = 100 - ((value.price / (monthPrice * 12)) * 100);
          }
        }
        if (key !== 'half_year') {
          plans.push(foundPlan);
        }
      });

      return plans;
    },
  },
  mutations: {
    [PAYMENTS.SET_SUBSCRIPTIONS_QUERY](state, payload) {
      state.subscriptionsQuery = payload;
    },

  },
  actions: {

  },

};
