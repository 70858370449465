<template>
  <ModalCard title="Carpooling/RideSharing
">
    <div class="flex flex-col gap-8">
      <div>
        <h3 class="mb-3">
          Do you offer carpooling/ridesharing program to employees?
          <Tooltip>
            <template #title>
              <div>message...</div>
            </template>

            <PhInfo weight="fill" class="text-primary cursor-pointer" size="16" />
          </Tooltip>
        </h3>

        <RadioGroup :value="1">
          <Radio :style="radioStyle" :value="1">
            <h4 class="text-base font-normal">Yes</h4>
          </Radio>

          <Radio :style="radioStyle" :value="2" class="w-full">
            <h4 class="text-base font-normal">No</h4>
          </Radio>
        </RadioGroup>
      </div>

      <div>
        <h3 class="mb-3">How much is the monetary incentives for carpooling/ridesharing?</h3>
        <div class="flex flex-wrap items-center gap-3">
          <CustumNumberInput
            class="flex-1 min-w-[80px]"
            name="savinngs"
            :defaultData="0"
            prefix="$"
            placeholder=" "
            :rules="{min:0}"
            @setInput="setInput"
          />
          <small>Per day per employee</small>
        </div>
      </div>

      <div>
        <h3 class="mb-1">Program start date:</h3>
        <CustumCalender
          name="expiration"
          :defaultData="defaultDate"
          :minDate="defaultDate"
          defaultFormate="MMM, DD YYYY"
          placeholder="Program start date"
          :rules="{
            required: false,
            }"
          @setInput="setInput"
        />
      </div>
    </div>
  </ModalCard>
</template>

<script>
import { PhInfo } from 'phosphor-vue';
import { Tooltip, Radio, RadioGroup } from 'ant-design-vue';

import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import CustumCalender from '@/components/shared/form/CustumCalender.vue';

import ModalCard from './ModalCard.vue';

export default {
  components: {
    ModalCard,
    PhInfo,
    Tooltip,
    Radio,
    RadioGroup,

    CustumNumberInput,
    CustumCalender,
  },
  data() {
    return {
      spacesData: [
        { id: 1, name: 'per day' },
        { id: 2, name: 'per month' },
        { id: 3, name: 'per year' },
      ],
    };
  },
};
</script>
