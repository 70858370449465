<template>
  <div class="gggg">
    <div
      class="bg-gradient-to-r from-primary to-primary-light text-white text-lg px-3 py-2 text-center font-bold rounded-t-md"
    >{{title}}</div>
    <div class="p-4">
      <form @submit.prevent="()=>{}" class="flex flex-col gap-3">
        <CustumNumberInput
          name="amount"
          label="Amount"
          :defaultData="amount"
          placeholder="Amount"
          prefix="$"
          :disabled="!!amount"
          :rules="{
            required: true,
              min: 0,
            }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />

        <CustumInput
          name="card_name"
          label="Name On Card"
          :defaultData="defaultData? defaultData.card_name : null"
          placeholder="Name On Card"
          :rules="{
            required: true,
            min: 3,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />

        <CustumPaymentCard
          name="card_number"
          label="Card Number"
          :defaultData="defaultData? defaultData.card_number : null"
          placeholder="#### #### #### ####"
          :rules="{
            required: true,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
          <CustumCalender
            class="flex-1"
            name="expiration"
            :defaultData="defaultDate"
            :minDate="defaultDate"
            picker="month"
            defaultFormate="MMM, YYYY"
            label="Expiration date:"
            placeholder="Expiration date:"
            :rules="{
                required: true,
              }"
            @setInput="setInput"
          />
          <CustumNumberInput
            name="card_cvv"
            label="Security Code"
            :defaultData="defaultData? defaultData.card_cvv : null"
            placeholder="CVV"
            :rules="{
            required: true,
              min: 100,
              max: 999
            }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
        </div>

        <CustumInput
          name="address"
          label="Billing address"
          :defaultData="defaultData? defaultData.address : null"
          placeholder="Billing address"
          :rules="{
            required: true,
            min: 3,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />

        <CustumInput
          name="city"
          label="City"
          :defaultData="defaultData? defaultData.city : null"
          placeholder="City"
          :rules="{
            required: true,
            min: 3,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <CustumSelect
            name="state"
            :defaultData="defaultData? defaultData.state : null"
            label="State"
            placeholder="State"
            :data="statesData"
            :rules="{
              required: true,
            }"
            search
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumNumberInput
            name="zip_code"
            label="Zip code"
            :defaultData="defaultData? defaultData.zip_code : null"
            placeholder="Zip code"
            :rules="{
                required: true,
                min: 0,
              }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
        </div>

        <div class="mt-3 flex flex-wrap items-center justify-center gap-4">
          <Button size="large" class="px-8" @click="() => $emit('close')">Cancel</Button>
          <Button class="btn-gradient px-10" type="primary" size="large">Pay</Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumPaymentCard from '@/components/shared/form/CustumPaymentCard.vue';

import CustumCalender from '@/components/shared/form/CustumCalender.vue';
import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';

import { Button } from 'ant-design-vue';

export default {
  components: {
    CustumInput,
    CustumPaymentCard,
    CustumCalender,
    CustumNumberInput,
    CustumSelect,
    Button,
  },
  props: {
    title: String,
    amount: Number,
  },
  data() {
    return {
      statesData: [
        {
          id: 'Alabama',
          name: 'Alabama',
        },
        {
          id: 'Alaska',
          name: 'Alaska',
        },
        {
          id: 'American Samoa',
          name: 'American Samoa',
        },
        {
          id: 'Arizona',
          name: 'Arizona',
        },
        {
          id: 'Arkansas',
          name: 'Arkansas',
        },
        {
          id: 'California',
          name: 'California',
        },
        {
          id: 'Colorado',
          name: 'Colorado',
        },
        {
          id: 'Connecticut',
          name: 'Connecticut',
        },
        {
          id: 'Delaware',
          name: 'Delaware',
        },
        {
          id: 'District of Columbia',
          name: 'District of Columbia',
        },
        {
          id: 'Federated States of Micronesia',
          name: 'Federated States of Micronesia',
        },
        {
          id: 'Florida',
          name: 'Florida',
        },
        {
          id: 'Georgia',
          name: 'Georgia',
        },
        {
          id: 'Guam',
          name: 'Guam',
        },
        {
          id: 'Hawaii',
          name: 'Hawaii',
        },
        {
          id: 'Idaho',
          name: 'Idaho',
        },
        {
          id: 'Illinois',
          name: 'Illinois',
        },
        {
          id: 'Indiana',
          name: 'Indiana',
        },
        {
          id: 'Iowa',
          name: 'Iowa',
        },
        {
          id: 'Kansas',
          name: 'Kansas',
        },
        {
          id: 'Kentucky',
          name: 'Kentucky',
        },
        {
          id: 'Louisiana',
          name: 'Louisiana',
        },
        {
          id: 'Maine',
          name: 'Maine',
        },
        {
          id: 'Marshall Islands',
          name: 'Marshall Islands',
        },
        {
          id: 'Maryland',
          name: 'Maryland',
        },
        {
          id: 'Massachusetts',
          name: 'Massachusetts',
        },
        {
          id: 'Michigan',
          name: 'Michigan',
        },
        {
          id: 'Minnesota',
          name: 'Minnesota',
        },
        {
          id: 'Mississippi',
          name: 'Mississippi',
        },
        {
          id: 'Missouri',
          name: 'Missouri',
        },
        {
          id: 'Montana',
          name: 'Montana',
        },
        {
          id: 'Nebraska',
          name: 'Nebraska',
        },
        {
          id: 'Nevada',
          name: 'Nevada',
        },
        {
          id: 'New Hampshire',
          name: 'New Hampshire',
        },
        {
          id: 'New Jersey',
          name: 'New Jersey',
        },
        {
          id: 'New Mexico',
          name: 'New Mexico',
        },
        {
          id: 'New York',
          name: 'New York',
        },
        {
          id: 'North Carolina',
          name: 'North Carolina',
        },
        {
          id: 'North Dakota',
          name: 'North Dakota',
        },
        {
          id: 'Northern Mariana Islands',
          name: 'Northern Mariana Islands',
        },
        {
          id: 'Ohio',
          name: 'Ohio',
        },
        {
          id: 'Oklahoma',
          name: 'Oklahoma',
        },
        {
          id: 'Oregon',
          name: 'Oregon',
        },
        {
          id: 'Palau',
          name: 'Palau',
        },
        {
          id: 'Pennsylvania',
          name: 'Pennsylvania',
        },
        {
          id: 'Puerto Rico',
          name: 'Puerto Rico',
        },
        {
          id: 'Rhode Island',
          name: 'Rhode Island',
        },
        {
          id: 'South Carolina',
          name: 'South Carolina',
        },
        {
          id: 'South Dakota',
          name: 'South Dakota',
        },
        {
          id: 'Tennessee',
          name: 'Tennessee',
        },
        {
          id: 'Texas',
          name: 'Texas',
        },
        {
          id: 'Utah',
          name: 'Utah',
        },
        {
          id: 'Vermont',
          name: 'Vermont',
        },
        {
          id: 'Virgin Island',
          name: 'Virgin Island',
        },
        {
          id: 'Virginia',
          name: 'Virginia',
        },
        {
          id: 'Washington',
          name: 'Washington',
        },
        {
          id: 'West Virginia',
          name: 'West Virginia',
        },
        {
          id: 'Wisconsin',
          name: 'Wisconsin',
        },
        {
          id: 'Wyoming',
          name: 'Wyoming',
        },
      ],
    };
  },
};
</script>
