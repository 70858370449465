<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { STATS } from '@/store/types';
import statsApi from '../../apis/stats.api';

export default {
  props: {
    type: {
      type: String,
      // default: 'mode_share_month',
      default: 'mile_share_commuters',
    },
    from: String,
    to: String,
  },

  setup(props) {
    const store = useStore();
    const query = ref(null);
    query.value = statsApi.useMileShareQuery({
      type: props.type,
      from: props.from || '2010-01-01',
      to: props.to || '2110-01-01',
    });
    if (query.value) {
      store.commit(STATS.SET_MILE_SHARE_INITIAL_STATS_QUERY, query);
    }
    return { query };
  },
};
</script>
