<template>
  <div v-if="data">
    <div class="flex justify-end mb-4">
      <div class="flex items-center gap-3">
        <div>
          <date-picker v-if="type !== 'all'" :picker="type" v-model:value="dateVal" />
        </div>

        <CustumSelect
          name="type"
          placeholder="select type "
          :data="dateData"
          :defaultData="type"
          :rules="{
                  required: false,
                }"
          @setInput="setInput"
        />
      </div>
    </div>
    <Table :data-source="queryData" :columns="columns" :loading="query && query.isLoading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'date'">
          <span v-if="record.date" class="whitespace-nowrap">{{getDate(record.date)}}</span>
        </template>

        <template v-if="column.key === 'amount'">
          <div>${{record?.amount}}</div>
        </template>

        <template v-if="column.key === 'trip_cost'">
          <div>${{record?.trip_cost}}</div>
        </template>

        <template v-if="column.key === 'mode'">
          <div>{{record.mode}}</div>
        </template>
        <template v-if="column.key === 'status'">
          <span
            v-if="record.pending == true"
            class="w-full flex justify-center text-yellow-600"
          >
             <PhHourglassSimpleMedium weight="duotone" size="18" /> Pending
          </span>
          <span
            v-if="record.pending == false"
          class="w-full flex justify-center text-green-600"
          >
            <PhCheckCircle weight="duotone" size="18" /> Approved
          </span>
        </template>
      </template>

      <template #summary>
        <table-summary-row>
          <table-summary-cell col-span="4">
            <span class="font-bold">Total</span>
          </table-summary-cell>

          <table-summary-cell>
            <span class="flex items-center gap-2">$ {{total}}</span>
          </table-summary-cell>
          <table-summary-cell>
            <span class="flex items-center gap-2">$ {{tripTotalCost }}</span>
          </table-summary-cell>
          <table-summary-cell>
            <span class="flex items-center gap-2"></span>
          </table-summary-cell>
        </table-summary-row>
      </template>
    </Table>

    <div class="mt-4 lg:mt-6">
      <Button
        @click="downloadPdf"
        :loading="downLoading"
        type="primary"
        class="btn-gradient"
        size="large"
      >Download CSV file</Button>
    </div>

    <RewardsListQuery :key="queryKey" :startDate="dates[0]" :endDate="dates[1]" />
  </div>
</template>

<script>
import {
  Table,
  TableSummaryRow,
  TableSummaryCell,
  DatePicker,
  Button,
  message,
} from 'ant-design-vue';
import {
  PhHourglassSimpleMedium,
  PhCheckCircle,
} from 'phosphor-vue';
import dayjs from 'dayjs';

import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import RewardsListQuery from '@/services/queries/reward/RewardsListQuery.vue';
import rewardApi from '@/services/apis/reward.api';
import handleResErrors from '../../../utils/handleResErrors';

export default {
  components: {
    Table,
    TableSummaryRow,
    TableSummaryCell,
    CustumSelect,
    DatePicker,
    RewardsListQuery,
    Button,
    PhHourglassSimpleMedium,
    PhCheckCircle,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      columns: [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          sorter: (a, b) => {
            const aDate = new Date(a.date).getTime();
            const bDate = new Date(b.date).getTime();
            return bDate - aDate;
          },
        },

        {
          title: 'Commuter Name',
          key: 'name',
          dataIndex: 'name',
          sorter: (a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            return -1;
          },
        },

        {
          title: 'Credit type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Transportation Mode',
          dataIndex: 'mode',
          key: 'mode',
        },
        {
          title: 'Credit Amount',
          dataIndex: 'amount',
          key: 'amount',
          sorter: (a, b) => Number(b.amount) - Number(a.amount),
        },
        {
          title: 'Trip Cost',
          dataIndex: 'trip_cost',
          key: 'trip_cost',
          sorter: (a, b) => Number(b.trip_cost) - Number(a.trip_cost),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          sorter: (a, b) => Number(b.pending) - Number(a.pending),
        },
      ],
      type: 'month',
      years: [],

      dateVal: dayjs(),

      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'year', name: 'Yearly' },
        // { id: 'all', name: 'All Data' },
      ],
      downLoadings: false,
    };
  },
  computed: {
    total() {
      if (!this.queryData) return 0;
      const t = this.queryData.reduce((acc, cur) => acc + Number(cur.amount), 0);
      return t.toFixed(2);
    },
    tripTotalCost() {
      if (!this.queryData) return 0;
      const t = this.queryData.reduce((acc, cur) => acc + Number(cur.trip_cost), 0);
      return t.toFixed(2);
    },

    dates() {
      if (this.type === 'month') {
        const start = dayjs(this.dateVal)
          .startOf('month')
          .format('YYYY-MM-DD');
        const end = dayjs(this.dateVal)
          .endOf('month')
          .format('YYYY-MM-DD');
        return [start, end];
      }

      if (this.type === 'year') {
        const start = dayjs(this.dateVal)
          .startOf('year')
          .format('YYYY-MM-DD');
        const end = dayjs(this.dateVal)
          .endOf('year')
          .format('YYYY-MM-DD');
        return [start, end];
      }

      return [null, null];
    },

    queryKey() {
      return `${this.dates[0] || 'start'}-${this.dates[1] || 'end'}`;
    },
    query() {
      return this.$store.state.reward.rewardsListQuery;
    },
    queryData() {
      // id: '1',
      // date: 1672587404456,
      // name: 'John Brown',
      // type: 'Reward points',
      // mode: 'Transit',
      // modes: 'Transit, Bike, Drive',
      // amount: 1.5,
      // trip_cost: 1.5,

      if (!this.query?.data) return [];

      const qData = this.query?.data?.data ? this.query?.data?.data : this.query?.data || [];
      const data = qData.map((item) => ({
        // eslint-disable-next-line dot-notation
        id: item['_id'],
        date: item.trip_date ? new Date(item.trip_date) : '',
        name: item.user?.name || '',
        // Credit type
        type: this.translateType(item.category),
        // Transportation Mode
        mode: item?.trip_mode?.replaceAll('_', ' ') || '',
        // Credit Amount
        amount: Number(item?.value)?.toFixed(2) || 0,
        // Trip Cost
        trip_cost: item.trip ? Number(item?.trip?.commuteCost)?.toFixed(2) : 0,
        pending: item.pending,
      }));

      return data;
    },
  },
  methods: {
    getDate(d) {
      return dayjs(d).format('MMM DD, YYYY');
    },
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
    },
    translateType(type) {
      const translations = {
        weekly_gift_reward: 'Weekily Reward',
        monthly_reward: 'Monthly Reward',
        trip_incentive_reward: 'Trip Reward',
      };
      return translations[type] ? translations[type] : type;
    },

    async downloadPdf() {
      this.downLoadings = true;
      try {
        await rewardApi.downloadRewardsList({
          startDate: this.dates[0],
          endDate: this.dates[1],
        });
      } catch (error) {
        message.error(handleResErrors(error));
      }
      this.downLoadings = false;
    },
  },
};
</script>
