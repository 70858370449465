<template>
  <CustumLabel
    :name="name"
    :label="label"
    :error="touched && error"
    :required="rules && rules.required"
    :hideErrors="hideErrors"
  >
    <Upload
      class="text-text camp-upload"
      :class="icon ? 'w-auto' : ' w-full block'"
      :accept="accept"
      :max-count="rules.max || 1"
      :file-list="value"
      :before-upload="beforeUpload"
      @remove="handleRemove"
    >
      <div
        class="text-text-light px-2 py-1 flex items-center justify-between bg-bg-form border border-solid border-border-form rounded"
        :class="icon ? 'w-auto' : ' w-full'"
      >
        <span v-if="!icon" class="inline-block">{{placeholder}}</span>
        <!-- icon  -->
        <slot></slot>
      </div>
    </Upload>
  </CustumLabel>
</template>

<script>
import validation from '@/services/validation';
import { Upload } from 'ant-design-vue';
import CustumLabel from './CustumLabel.vue';

export default {
  components: { CustumLabel, Upload },
  props: {
    name: String,
    label: String,
    placeholder: String,
    accept: String,
    hideErrors: Boolean,
    defaultData: String,
    icon: Boolean,
    rules: {
      type: Object,
      default: null,
    },
    isTouched: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: [],
      error: null,
      touched: false,
      isValid: false,
    };
  },
  created() {
    if (this.defaultData) {
      this.value = this.defaultData;
      this.touched = true;
    }
    this.handleValidate();
    this.setInputValues();
  },
  watch: {
    isTouched(val) {
      if (val) {
        this.touched = true;
      }
    },
    defaultData(val) {
      if (val) {
        this.value = [val];
        this.touched = true;
      } else {
        this.value = [];
        this.touched = false;
      }
    },
  },
  methods: {
    beforeUpload(file) {
      const files = [file];
      this.value = files;
      this.touched = true;
      this.handleValidate(files);
      this.setInputValues(files);
      return false;
    },
    handleRemove() {
      this.value = [];
      this.handleValidate([]);
      this.setInputValues([]);
    },

    handleValidate(files) {
      const { error, isValid } = validation.validateRules({ ...this.rules, file: true }, files);
      this.error = error;
      this.isValid = isValid;
    },
    setInputValues(files) {
      const isRequired = this.rules && this.rules.required;
      if (isRequired && files && !files.length) {
        return;
      }
      this.$emit('setInput', {
        [this.name]: {
          name: this.name,
          value: files,
          error: this.error,
          touched: this.touched,
          isValid: this.isValid,
        },
      });
    },
  },
};
</script>
