<template>
  <div class="mt-6 bg-bg-dark p-3 rounded">
    <div class="flex flex-wrap items-end justify-between gap-3 mb-2">
      <div>
        <h4>Please upload your employee addresses using the following template to enable commute calculator</h4>
        <small>(please follow the template format)</small>
      </div>
      <Button type="link" class="px-0">
        <span class="!flex items-center gap-2">
          <span>Download addresses template</span>
          <PhDownload weight="duotone" size="18" />
        </span>
      </Button>
    </div>
    <CustumFileInput
      name="file"
      :defaultData="defaultData? defaultData.file : null"
      placeholder="choose file"
      accept=".csv"
      :rules="{
                required: false,
                min: 1,
                max: 1
              }"
    >
      <!-- :isTouched="isAllTouched"
      @setInput="setInput"-->
      <PhFileCsv :size="22" weight="duotone" />
    </CustumFileInput>
    <small class="text-center">Uploaded on Nov. 10th, 2022</small>
  </div>
</template>

<script>
import CustumFileInput from '@/components/shared/form/CustumFileInput.vue';

import { PhDownload, PhFileCsv } from 'phosphor-vue';
import { Button } from 'ant-design-vue';

export default {
  components: { CustumFileInput, PhDownload, PhFileCsv, Button },
};
</script>
