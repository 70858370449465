<template>
  <div v-if="data">
    <div class="flex justify-end mb-4">
      <div class="flex items-center gap-3">
        <div>
          <date-picker v-if="type !== 'all'" :picker="type" v-model:value="dateVal" />
        </div>

        <CustumSelect
          name="type"
          placeholder="select type "
          :data="dateData"
          :defaultData="type"
          :rules="{
                  required: false,
                }"
          @setInput="setInput"
        />
      </div>
    </div>
    <Table :data-source="queryData" :columns="columns" :loading="query && query.isLoading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <div
            class="flex flex-col justify-center items-center gap-2 lg:flex-row"
            :title="record.name"
          >
            <h4 class="w-full truncate">{{record.name}}</h4>
          </div>
        </template>

        <template v-if="column.key === 'amount'">
          <div>${{record.amount.toFixed(2)}}</div>
        </template>
        <template v-if="column.key === 'modes'">
          <div v-if='record.modes && record.categories'>
            {{record.modes}}, {{record.categories}}
          </div>
          <div v-else-if='record.modes && !record.categories'>
            {{record.modes}}
          </div>
          <div v-else-if='!record.modes && record.categories'>
            {{record.categories}}
          </div>
        </template>
      </template>

      <template #summary>
        <table-summary-row>
          <table-summary-cell col-span="2">
            <span class="font-bold">Total</span>
          </table-summary-cell>

          <table-summary-cell>
            <span class="flex items-center gap-2">$ {{total}}</span>
          </table-summary-cell>
        </table-summary-row>
      </template>
    </Table>

    <div class="mt-4 lg:mt-6">
      <Button
        @click="downloadPdf"
        :loading="downLoading"
        type="primary"
        class="btn-gradient"
        size="large"
      >Download CSV file</Button>
    </div>

    <RewardsSummaryQuery :key="queryKey" :startDate="dates[0]" :endDate="dates[1]" />
  </div>
</template>

<script>
import {
  Table,
  TableSummaryRow,
  TableSummaryCell,
  DatePicker,
  Button,
  message,
} from 'ant-design-vue';
import dayjs from 'dayjs';

import CustumSelect from '@/components/shared/form/CustumSelect.vue';

import RewardsSummaryQuery from '@/services/queries/reward/RewardsSummaryQuery.vue';
import rewardApi from '@/services/apis/reward.api';
import handleResErrors from '../../../utils/handleResErrors';

export default {
  components: {
    Table,
    TableSummaryRow,
    TableSummaryCell,
    DatePicker,
    CustumSelect,
    RewardsSummaryQuery,
    Button,
  },
  props: {
    datad: Array,
  },
  data() {
    return {
      columns: [
        {
          title: 'Commuter Name',
          key: 'name',
          dataIndex: 'name',
          sorter: (a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            return -1;
          },
        },
        {
          title: 'Modes, Rewards, and Prizes',
          dataIndex: 'modes',
          key: 'modes',
        },
        {
          title: 'Credit Amount',
          dataIndex: 'amount',
          key: 'amount',
          sorter: (a, b) => Number(b.amount) - Number(a.amount),
        },
      ],
      type: 'month',
      years: [],

      data: [
        {
          id: '1',
          name: 'John Brown',
          modes: 'Transit, Bike, Drive',
          amount: 1.5,
          categories: '',
        },
        {
          id: '2',
          name: 'John2 Brown',
          modes: 'Transit, Bike, Drive',
          amount: 2.5,
          categories: '',
        },
      ],

      dateVal: dayjs(),

      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'year', name: 'Yearly' },
        // { id: 'all', name: 'All Data' },
      ],
      downLoadings: false,
    };
  },
  computed: {
    total() {
      if (!this.queryData) return 0;
      const t = this.queryData.reduce((acc, cur) => acc + Number(cur.amount), 0);
      return t.toFixed(2);
    },
    dates() {
      if (this.type === 'month') {
        const start = dayjs(this.dateVal)
          .startOf('month')
          .format('YYYY-MM-DD');
        const end = dayjs(this.dateVal)
          .endOf('month')
          .format('YYYY-MM-DD');
        return [start, end];
      }

      if (this.type === 'year') {
        const start = dayjs(this.dateVal)
          .startOf('year')
          .format('YYYY-MM-DD');
        const end = dayjs(this.dateVal)
          .endOf('year')
          .format('YYYY-MM-DD');
        return [start, end];
      }

      return [null, null];
    },
    queryKey() {
      return `${this.dates[0] || 'start'}-${this.dates[1] || 'end'}`;
    },
    query() {
      return this.$store.state.reward.rewardsSummaryQuery;
    },
    queryData() {
      // id: '1',
      //   name: 'John Brown',
      //   modes: 'Transit, Bike, Drive',
      //   amount: 1.5,

      if (!this.query?.data) return [];

      const qData = this.query?.data?.data ? this.query?.data?.data : this.query?.data || [];
      const data = qData.map((item) => ({
        // eslint-disable-next-line dot-notation
        id: item['_id'],
        name: item.user?.name || 'N/A',
        modes: item.modes?.length ? item.modes.join(', ').replaceAll('monthly_reward', 'monthly prize').replaceAll('_', ' ') : 'N/A',
        categories: item.categories?.length ? item.categories.filter((item2) => item2 !== 'trip_incentive_reward').join(', ').replaceAll('monthly_reward', 'monthly prize').replaceAll('_', ' ') : '',
        amount: item.total,
      }));

      return data;
    },
  },
  methods: {
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
    },
    async downloadPdf() {
      this.downLoadings = true;
      try {
        await rewardApi.downloadRewardsSummaryList({
          startDate: this.dates[0],
          endDate: this.dates[1],
        });
      } catch (error) {
        message.error(handleResErrors(error));
      }
      this.downLoadings = false;
    },
  },
};
</script>
