<template>
  <div class="bar-chart" v-if="chartData">
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartPureData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="[textPlugin , yaxixLabelText , insideTextPlugin]"
      :css-classes="cssClasses"
      :styles="styles"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { COLORS, CHART_COLORS } from './COLORS';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    commutersData: Object,
    label: String,
    filter: String,
    chartData: Object,
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => ({
        height: '550px',
      }),
    },
    isPercentage: {
      type: Boolean,
      default: false,
    },
    // plugins: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  created() {},
  data() {
    return {
      chartDataOld: {
        labels: [
          'initial Comm..',
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        datasets: [
          {
            label: ['Drive alone'],
            data: [41, 41, 43, 41, 41, 41, 41, 41, 41, 41, 41, 41, 42],
            backgroundColor: [COLORS[0]],
            barPercentage: 0.5,
          },
          {
            label: ['Carpool'],
            data: [30, 30, 29, 30, 30, 30, 30, 30, 30, 30, 30, 30, 29],
            backgroundColor: [COLORS[1]],
            barPercentage: 0.5,
          },
          {
            label: ['Transit'],
            data: [15, 14, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 17],
            backgroundColor: [COLORS[2]],
            barPercentage: 0.5,
          },
          {
            label: ['Walk'],
            data: [6, 5, 6, 5, 6, 6, 6, 6, 6, 6, 6, 6, 7],
            backgroundColor: [COLORS[3]],
            barPercentage: 0.5,
          },
          {
            label: ['Bike'],
            data: [8, 10, 7, 9, 8, 8, 8, 8, 8, 8, 8, 8, 5],
            backgroundColor: [COLORS[4]],
            barPercentage: 0.5,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
            ticks: {},
          },
          y: {
            stacked: true,
            text: ' helll ',
            ticks: {
              callback: (value) => (this.isPercentage ? `${value}%` : value),
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              // eslint-disable-next-line no-unused-vars
              label: (context) => {
                let bare = context.formattedValue;

                if (bare && typeof bare === 'string' && bare.includes(',')) {
                  bare = +bare.replace(/,/g, '');
                }

                if (bare) {
                  bare = Math.round(bare * 100) / 100;
                }

                const label = context?.dataset?.label[0] || '';

                return ` ${label} : ${bare}${this.isPercentage ? '%' : ''}`;
              },
            },
          },
        },
        layout: {
          padding: {
            top: 70,
            bottom: 20,
            left: 40,
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
        },
      },
    };
  },

  computed: {
    textPlugin() {
      const app = this;

      //
      return {
        id: 'bars-above-circle',
        afterDraw(chart) {
          const {
            ctx,
            // eslint-disable-next-line no-unused-vars
            chartArea: { top, bottom, left, right, width, height },
          } = chart;
          const dataIndex = chart.data.datasets.length - 1;

          chart.getDatasetMeta(dataIndex).data.forEach((datapoints, inx) => {
            const { x, y: defY } = datapoints.tooltipPosition();

            let y = defY;

            // eslint-disable-next-line eqeqeq
            if (!datapoints.height) {
              //
              for (let i = dataIndex - 1; i >= 0; i -= 1) {
                const cdp = chart.getDatasetMeta(i).data[inx];
                // eslint-disable-next-line eqeqeq
                if (cdp.height) {
                  y = cdp.y;
                  break;
                }
              }
            }

            let count = 0;

            // commutersData

            chart.data.datasets.forEach((d) => {
              // count += +d.defaultData[inx] || 0;
              count += +d.commutersVals[inx] || 0;
            });

            if (app.commutersData) {
              count = app.commutersData[inx + 1]?.commuters_count || 0;
            }

            // console.log('COUNT', count);
            ctx.fillStyle = 'gray';
            ctx.fillRect(x, y, 2, 2);

            // draw line
            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(x, y - 10);
            ctx.strokeStyle = 'gray';
            ctx.stroke();
            // ctx.lineTo(x, yLine);
            ctx.beginPath();
            ctx.arc(x, y - 28, 20, 0, 2 * Math.PI, false);

            ctx.strokeStyle = 'gray';
            ctx.stroke();
            // const isFar = x / width < 0.3 || x / width > 0.7;

            ctx.font = '0.85rem sans-serif';
            ctx.fillStyle = 'black';
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.fillText(count, x, y - 26);
          });

          ctx.restore();

          ctx.save();
        },
      };
    },
    yaxixLabelText() {
      const app = this;
      return {
        id: 'yaxixLabelText',
        afterDraw(chart) {
          const {
            ctx,
            chartArea: { top, height, width },
            // eslint-disable-next-line no-unused-vars
            scales: { x, y },
          } = chart;

          ctx.save();
          ctx.font = 'bold 0.9rem  sans-serif';
          ctx.fontWeight = 'bold';
          ctx.fillStyle = 'black';
          ctx.textBaseline = 'middle';
          // ctx.textAlign = 'center';
          ctx.fillText('Number of', 40, top - 50);
          ctx.fillText('Commuters', 40, top - 30);
          ctx.restore();
          // VERTICAL TEXT

          let vertecalText = app.filter;
          let transY = 1.4;

          if (app.isPercentage) {
            if (app.filter === 'Trips') {
              vertecalText = 'Mode Share Percentage';
            } else {
              vertecalText = 'Mile Share Percentage';
            }
            transY = 1.2;
          }

          ctx.save();
          ctx.textAlign = 'left';
          ctx.font = '0.9rem  sans-serif';
          // ctx.fontWeight = 'regular';
          ctx.fontWeight = 'bold';
          ctx.fillStyle = 'black';
          ctx.fillStyle = 'gray';

          const translx = width < 800 ? -160 : -180;

          ctx.translate(translx, height / transY);
          ctx.rotate((-90 * Math.PI) / 180);
          ctx.fillText(vertecalText, 0, height / 2);
          ctx.restore();
        },
      };
    },
    insideTextPlugin() {
      const isPer = this.isPercentage;
      return {
        id: 'insideTextPlugin',
        afterDraw(chart) {
          const {
            ctx,
            // eslint-disable-next-line no-unused-vars
            chartArea: { top, bottom, left, right, width, height },
          } = chart;

          chart.data.datasets.forEach((dataset, ix) => {
            chart.getDatasetMeta(ix).data.forEach((datapoints, inx) => {
              const { x, y } = datapoints.tooltipPosition();

              const topPos = datapoints.height / 2;

              // eslint-disable-next-line no-unused-vars
              const color = dataset.backgroundColor[inx];
              const textNum = dataset.data[inx] ? Math.round(dataset.data[inx] * 10) / 10 : 0;

              const text = isPer ? `%${textNum}` : textNum;

              // eslint-disable-next-line eqeqeq
              if (textNum != 0 && datapoints.height && datapoints.height > 14) {
                ctx.font = '0.85rem sans-serif';
                ctx.fillStyle = 'white';
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';
                ctx.fillText(text, x, y + topPos);
              }
            });
          });

          ctx.restore();

          ctx.save();
        },
      };
    },

    chartPureData() {
      // const dataSets = [...this.chartData.datasets];
      const dataSets = this.chartData.datasets.map((ds) => {
        const label = ds.label.map((l) => l.replace('_', ' '));
        return {
          ...ds,
          label,
        };
      });

      if (dataSets && Array.isArray(dataSets)) {
        dataSets.forEach((el, ix) => {
          const color = CHART_COLORS[el?.label[0]] || COLORS[ix];
          dataSets[ix].backgroundColor = [color];
        });
      }

      return { ...this.chartData, datasets: dataSets };
    },

    // labelPlugin() {
    //   const text = '123';
    //   const cWidth = document.body.clientWidth;
    //   let txtoffsety = 5 * 2;
    //   let fontSize = 1.5;
    //   if (cWidth < 400) {
    //     txtoffsety = 10 * 2;
    //     fontSize = 0.85;
    //   } else if (cWidth < 900) {
    //     txtoffsety = 6 * 2;
    //     fontSize = 1.2;
    //   }
    //   return {
    //     id: 'custom_canvas_label',
    //     beforeDraw(chart) {
    //       const { width } = chart;
    //       const { height } = chart;
    //       const { ctx } = chart;
    //       ctx.restore();
    //       // const fontSize = (height / 114).toFixed(2);
    //       ctx.font = `${fontSize}em sans-serif`;
    //       ctx.textBaseline = 'middle';
    //       const textX = Math.round((width - ctx.measureText(text).width) / 2);
    //       const textY = height / 2 + txtoffsety;
    //       ctx.fillText(text, textX, textY);
    //       ctx.save();
    //     },
    //   };
    // },
  },
};
</script>

<style scoped>
.bar-chart canvas {
}
</style>
