import { ADMIN_INVOICES } from '../../types';

export default {
  state: {
    adminInvoicesQuery: null,

  },
  getters: {
    adminInvoicesData(state) {
      if (!state.adminInvoicesQuery) {
        return null;
      }
      return state.adminInvoicesQuery.data || null;
    },
  },
  mutations: {
    [ADMIN_INVOICES.SET_INVOICES_QUERY](state, payload) {
      state.adminInvoicesQuery = payload;
    },
  },
  actions: {

  },

};
