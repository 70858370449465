<template>
  <div class="flex items-center gap-3" :class="active ? '' : 'opacity-70'">
    <p class="min-w-[210px]">{{title}}</p>
    <InputNumber
      :class="`w-full max-w-[80px]`"
      v-model:value="val"
      :min="min"
      :max="max"
      :step="step"
      prefix="$"
      :disabled="!active"
    />
    <div class="w-40 flex gap-1 items-center">
      <span
        class="text-primary flex items-center cursor-pointer hover:opacity-75"
        :class="val <= min ? 'opacity-50 cursor-not-allowed':''"
        @click="this.handleDecrease"
      >
        <PhMinusCircle weight="duotone" size="22" />
      </span>
      <div class="min-w-[150px]">
        <Slider v-model:value="val" :min="min" :max="max" :step="step" :disabled="!active" />
      </div>
      <span
        class="text-primary flex items-center cursor-pointer hover:opacity-75"
        :class="val >= max ? 'opacity-50 cursor-not-allowed':''"
        @click="this.handleIncrease"
      >
        <PhPlusCircle weight="duotone" size="22" />
      </span>
    </div>
  </div>
</template>

<script>
import { Slider, InputNumber } from 'ant-design-vue';
import { PhPlusCircle, PhMinusCircle } from 'phosphor-vue';

export default {
  components: { Slider, InputNumber, PhPlusCircle, PhMinusCircle },
  props: {
    title: String,
    defaultValue: Number,
    active: {
      type: Boolean,
      default: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 20,
    },
    step: {
      type: Number,
      default: 0.5,
    },
  },
  data() {
    return {
      val: 0,
    };
  },
  watch: {
    active(val) {
      if (!val) {
        this.val = 0;
      }
    },
  },
  created() {
    if (this.defaultValue && this.active) {
      this.val = this.defaultValue;
    }
  },
  methods: {
    handleDecrease() {
      if (this.val <= this.min || !this.active) {
        return;
      }
      this.val -= this.step;
    },
    handleIncrease() {
      if (this.val >= this.max || !this.active) {
        return;
      }
      this.val += this.step;
    },
  },
};
</script>
