<template>
  <div>
    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table :data-source="queryData" :columns="columns" :loading="query && query.isLoading">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'createdAt'">
            <span v-if="record.createdAt" class="whitespace-nowrap">{{record.createdAt}}</span>
          </template>

          <template v-if="column.key === 'prossesed'">
            <span v-if="record.prossesed" class="whitespace-nowrap">{{record.prossesed}}</span>
          </template>

          <template v-if="column.key === 'amount'">
            <span v-if="record.amount" class="whitespace-nowrap">${{ record.amount.toFixed(2) }}</span>
            <span v-else> $0 </span>
          </template>

          <template v-if="column.key === 'remaining'">
            <div class="w-full text-center">
              <span v-if="record.remaining" class="whitespace-nowrap">${{ record.remaining.toFixed(2) }}</span>
              <span v-else> $0 </span>
            </div>
          </template>

          <template v-if="column.key === 'status'">
            <div class="w-full text-center">
              <span
                v-if="record.status === 'paid'"
                class="py-1 px-2 bg-green-600 bg-opacity-20 rounded-md inline-block"
              >{{ record.status }}</span>
              <span
                v-else-if="record.status === 'pending'"
                class="py-1 px-2 bg-amber-600 bg-opacity-20 rounded-md inline-block"
              >{{ record.status }}</span>
              <span
                v-else-if="record.status === 'failed'"
                class="py-1 px-2 bg-red-600 bg-opacity-20 rounded-md inline-block"
              >{{ record.status }}</span>

              <span
                v-else
                class="py-1 px-2 bg-amber-600 bg-opacity-5 rounded-md inline-block"
              >{{ record.status }}</span>
            </div>
          </template>

          <template v-if="column.key === 'pdf'">
            <div class="flex items-center gap-2 justify-center">
              <Button
                type="primary"
                ghost
                @click="() => viewInvoicePdf(record.id)"
                :loading="record.id === loadingId && !detailsLoading"
              >
                <template #icon>
                  <div class="flex items-center justify-center">
                    <PhFileArrowDown weight="duotone" size="20" />
                  </div>
                </template>
              </Button>
            </div>
          </template>
          <template v-if="column.key === 'detailed_pdf'">
            <div class="flex items-center gap-2 justify-center">
              <Button
                type="primary"
                ghost
                @click="() => viewInvoicePdf(record.id,  1)"
                :loading="record.id === loadingId && detailsLoading"
              >
                <template #icon>
                  <div class="flex items-center justify-center">
                    <PhFileArrowDown weight="duotone" size="20" />
                  </div>
                </template>
              </Button>
            </div>
          </template>

          <template v-if="column.key === 'action'">
            <div v-if="record.remaining" class="flex items-center gap-2">
              <Button
                type="primary"
                class="btn-gradient"
                @click="() => handleModalOpen(record.remaining,record.key)"
              >Pay</Button>
            </div>
          </template>
        </template>
      </Table>
    </div>

<!--    <Modal-->
<!--      :visible="!!visible"-->
<!--      :footer="null"-->
<!--      :destroyOnClose="true"-->
<!--      wrap-class-name="empty-modal  "-->
<!--      center-->
<!--      @ok="handleModalClose"-->
<!--      @cancel="handleModalClose"-->
<!--    >-->
<!--      <PayCardModal title="Make a payment" @close="handleModalClose" :amount="amount" />-->
<!--    </Modal>-->
    <Modal
      :visible="!!visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <payCardModal2 @cancelAddCreditModal='handleModalClose' :input-amount-prop='currentAmountComp' :invoice='currentInvoiceComp'/>
    </Modal>
    <InvoicesQuery />

    <div v-if="queryData"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { Table, Button, Modal, message } from 'ant-design-vue';
import dayjs from 'dayjs';
import { PhFileArrowDown } from 'phosphor-vue';

import InvoicesQuery from '@/services/queries/invoices/InvoicesQuery.vue';
import payCardModal2 from '@/components/dashboard/payments/shared/PayCardModal2.vue';
import PayCardModal from './shared/PayCardModal.vue';
import invoicesApi from '../../../services/apis/invoices.api';
import handleResErrors from '../../../utils/handleResErrors';
import { getAdminToken, getToken } from '@/services/jwt.service';
import CONFIG from '@/services/config';

export default {
  components: {
    Table,
    Button,
    PhFileArrowDown,
    Modal,
    PayCardModal,
    InvoicesQuery,
    payCardModal2,
  },
  data() {
    return {
      columns: [
        // {
        //   title: '#',
        //   key: 'key',
        //   dataIndex: 'key',
        // },

        {
          title: 'Invoice Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
        },
        {
          title: 'Service',
          dataIndex: 'service',
          key: 'service',
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
        },
        // {
        //   title: 'Processed on',
        //   key: 'prossesed',
        //   dataIndex: 'prossesed',
        // },
        {
          title: 'Amount',
          key: 'amount',
          dataIndex: 'amount',
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
        },

        {
          title: 'Remaining amount',
          key: 'remaining',
          dataIndex: 'remaining',
        },

        {
          title: 'Invoice (PDF file)',
          key: 'pdf',
          // fixed: 'right',
        },
        // {
        //   title: 'Detailed Invoice (PDF file)',
        //   key: 'detailed_pdf',
        //   // fixed: 'right',
        // },

        {
          title: 'Action',
          key: 'action',
          // fixed: 'right',
        },
      ],

      tableData: [
        {
          key: '1',
          createdAt: 1664882267,
          amount: 1200,
          type: 'Subscription',
          service: '01/01/23 - 01/31/23',
          remaining: 1000,
          prossesed: 1664882267,
          status: 'Partial payment',
        },
        {
          key: '2',
          createdAt: 1664882267,
          amount: 1500,
          type: 'Program cost',
          service: '01/01/23 - 01/31/23',
          remaining: 0,
          prossesed: 1664882267,
          status: 'Pending',
        },
        {
          key: '3',
          createdAt: 1664882267,
          amount: 4500,
          type: 'Subscription',
          service: 'N/A',
          remaining: 0,
          prossesed: 1664882267,
          status: 'Paid',
        },
        {
          key: '4',
          createdAt: 1664882267,
          amount: 3300,
          type: 'Program cost',
          service: '01/01/23 - 01/31/23',
          remaining: 0,
          prossesed: 1664882267,
          status: 'Failed',
        },
      ],
      visible: false,
      amount: 0,
      loadingId: null,
      detailsLoading: false,
      currentInvoice:null,
    };
  },
  computed: {
    query() {
      return this.$store.state.invoices.invoicesQuery;
    },
    queryData() {
      if (!this.query?.data) return [];

      const qData = this.query?.data?.data ? this.query?.data?.data : [];
      const data = qData.map((item) => ({
        // eslint-disable-next-line dot-notation
        id: item['_id'],
        // eslint-disable-next-line dot-notation
        key: item['_id'],

        createdAt: this.getDate(item.created_at),
        amount: item.value,
        type: item.type || 'N/A',
        service: `${this.getDate(item.start_date)} - ${this.getDate(item.end_date)}`,
        remaining: item.remaining || 0,
        prossesed: this.getDate(item.datetime),
        status: item.status,
      }));

      return data;
    },
    currentInvoiceComp(){
      return this.currentInvoice;
    },
    currentAmountComp(){
      return this.amount || 0;
    }
  },
  methods: {
    getDate(t) {
      // February 18, 2022, 4:43 am
      return dayjs(t).format('MMMM DD, YYYY');
    },
    handleModalClose() {
      this.visible = false;
    },
    handleModalOpen(amount,invoice=null) {
      this.currentInvoice = invoice
      this.visible = true;
      this.amount = amount || 0;
    },
    async viewInvoicePdf(id, details = 0) {
      this.loadingId = id;
      if (details) this.detailsLoading = true;
      try {
        const token = getToken();
        let url = '';
        if(details !=  0) url += '&detailed=1'
        window.open(CONFIG.API_URL+`/invoices/${id}?access_token=${token}${url}`, "_blank") || window.location.replace(CONFIG.API_URL+`/invoices/${id}?access_token=${token}${url}`);
        return ;

        // await invoicesApi.viewInvoice({ id }, details);
      } catch (error) {
        message.error(handleResErrors(error));
      } finally {
        this.loadingId = null;
        this.detailsLoading = false;
      }
    },
    openPdf(id,details = 0){
      const token = getToken();
      if (!token || !id) {
        throw new Error('Missing token or id');
      }
      /* eslint-disable */
      let params = {};
      token ? params.access_token = token : ''
      details !=  0 ? params.details = details : '';
      let url = '';
      if(details !=  0)
        url += '&detailed=1'
      console.log("invoices")
      window.open(CONFIG.API_URL+`/invoices/${id}?access_token=${token}${url}`, "_blank") || window.location.replace(CONFIG.API_URL+`/invoices/${id}?access_token=${token}${url}`);
      return ;
    }
  },
};
</script>
