<template>
  <Button
    v-if="!disabled"
    type="primary"
    :disabled="disabled || loading"
    :loading="loading"
    class="!h-auto mt-8 btn-gradient"
  >
    <div v-if="!loading" class="flex items-center gap-1 px-4 md:px-6">
      <PhCheck size="18" weight="bold" />save
    </div>
    <span v-else>save</span>
  </Button>
</template>

<script>
import { Button } from 'ant-design-vue';
import { PhCheck } from 'phosphor-vue';

export default {
  components: {
    Button,
    PhCheck,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
