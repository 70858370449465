<template>
  <div class="w-full bg-bg p-4 pt-7">
    <h3 class="text-center mb-4 text-lg text-primary-dark">Potential Mode Share Changes</h3>
    <div class="flex flex-wrap w-full justify-center gap-4">
      <div class="flex flex-col items-center justify-center gap-1 px-4 py-3">
        <img class="h-12" src="@/assets/images/commute/Picture1.png" alt="icon" />
        <span class="text-lg mt-2">-18%</span>
      </div>

      <div class="flex flex-col items-center justify-center gap-1 px-4 py-3">
        <img class="h-12" src="@/assets/images/commute/Picture2.png" alt="icon" />
        <span class="text-lg mt-2">+10%</span>
      </div>

      <div class="flex flex-col items-center justify-center gap-1 px-4 py-3">
        <img class="h-12" src="@/assets/images/commute/Picture3.png" alt="icon" />
        <span class="text-lg mt-2">+3%</span>
      </div>

      <div class="flex flex-col items-center justify-center gap-1 px-4 py-3">
        <img class="h-12" src="@/assets/images/commute/Picture4.png" alt="icon" />
        <span class="text-lg mt-2">+2%</span>
      </div>

      <div class="flex flex-col items-center justify-center gap-1 px-4 py-3">
        <img class="h-12" src="@/assets/images/commute/Picture5.png" alt="icon" />
        <span class="text-lg mt-2">+3%</span>
      </div>
    </div>
  </div>
</template>
