<template>
  <CustumLabel
    :name="name"
    :label="label"
    :error="touched && error"
    :required="rules && rules.required"
    :hideErrors="hideErrors"
  >
    <DatePicker
      class="w-full"
      :value="dateValue"
      :placeholder="placeholder"
      :picker="picker"
      :disabled="disabled"
      :disabled-date="disabledDate"
      :format="dateFormate"
      @change="handleChange"
      @blur="handleBlur"
      :allowClear="true"
    />
  </CustumLabel>
</template>

<script>
import validation from '@/services/validation';
import { DatePicker } from 'ant-design-vue';
import dayjs from 'dayjs';
import CustumLabel from './CustumLabel.vue';

export default {
  components: { CustumLabel, DatePicker },
  props: {
    name: String,
    label: String,
    placeholder: String,
    hideErrors: Boolean,
    defaultData: String,
    defaultFormate: String,
    minDate: String,
    disabled: Boolean,
    picker: {
      type: String,
      default: 'date',
    },
    rules: {
      type: Object,
      default: null,
    },
    isTouched: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      dateValue: null,
      error: null,
      touched: false,
      isValid: false,
    };
  },
  computed: {
    dateFormate() {
      let formate = 'MM-DD-YYYY';
      if (this.picker === 'month') {
        formate = 'MMMM YYYY';
      }
      if (this.defaultFormate) {
        formate = this.defaultFormate;
      }
      return formate;
    },
  },
  created() {
    if (this.name === 'fromDate' && this.minDate) {
      this.dateValue = dayjs(this.minDate, this.dateFormate);
      this.value = this.minDate;
      this.touched = true;
    } else if (this.defaultData) {
      this.dateValue = dayjs(this.defaultData, this.dateFormate);
      this.value = this.defaultData;
      this.touched = true;
    }
    this.handleValidate();
    this.setInputValues();
  },
  watch: {
    isTouched(val) {
      if (val) {
        this.touched = true;
      }
    },
  },
  methods: {
    handleBlur() {
      this.touched = true;
      this.handleValidate();
      this.setInputValues();
    },
    handleChange(date) {
      this.error = null;
      this.dateValue = date;
      this.value = date ? dayjs(date).format(this.dateFormate) : null;
      this.handleBlur();
    },
    handleValidate() {
      const { error, isValid } = validation.validateRules(this.rules, this.value);
      this.error = error;
      this.isValid = isValid;
    },
    setInputValues() {
      const isRequired = this.rules && this.rules.required;
      if (!isRequired && !this.value) {
        return;
      }
      this.$emit('setInput', {
        [this.name]: {
          name: this.name,
          value: this.value,
          error: this.error,
          touched: this.touched,
          isValid: this.isValid,
          dateValue: this.dateValue,
        },
      });
    },
    disabledDate(d) {
      if (!this.minDate) {
        return false;
      }

      return d < dayjs(this.minDate);
    },
  },
};
</script>
<style scoped>
div.ant-picker{
  min-width:150px !important;
}
</style>
