<template>
  <Modal
    :visible="creditModal"
    :footer="null"
    v-if="!user?.otp_enabled"
    :destroyOnClose="true"
    wrap-class-name="empty-modal"
    @ok="creditModal = false"
    @cancel="creditModal = false"
  >
    <div class="p-4">
      <h2>Enable 2-Step Verification</h2>
      <Row>
        <Col :span="24">
          <p class="text-gray-500">Protect your account with an extra layer of security.</p>
          <h4>What is 2-Step Verification?</h4>
          <p class="text-gray-500">
            2-Step Verification adds an additional security measure to your login process. After
            entering your password, you'll need to provide a second form of verification, typically
            a code sent to your phone or email.
          </p>
        </Col>
      </Row>
      <Row class="gap-1">
        <Col >
          <Button
            type="primary"
            block
            class="mt-4"
            @click="() => {this.$router.push('/dashboard/account?tab=7')}"
            >Proceed</Button
          >
        </Col>
        <Col >
          <Button
            type="secondary"
            block
            class="mt-4"
            @click="() => {creditModal = false}"
            >Cancel</Button
          >
        </Col>
      </Row>
    </div>
  </Modal>
  <div class="reward-page py-4 mt-4">
    <div class="container">
      <div class="card-container">
        <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
          <Tabs size="large" v-model:activeKey="activeKey" class="w-full">
            <TabPane key="1" tab="Engagement and Recommendations">
              <BarChart></BarChart>
            </TabPane>
            <TabPane key="2" id="metricsTab" tab="Commute Metrics">
              <EmployerMetrics />
              <div class="mt-16"></div>
              <MembersMetrics />
              <DashStatsQuery />
              <QueryAlert :query="query" />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashStatsQuery from '@/services/queries/dash/DashStatsQuery.vue';
import BarChart from '@/components/dashboard/charts/BarChart.vue';
import { Tabs, TabPane, Modal, Row, Col, Button } from 'ant-design-vue';
import EmployerMetrics from './EmployerMetrics.vue';
import MembersMetrics from './MembersMetrics.vue';

export default {
  components: {
    EmployerMetrics,
    MembersMetrics,
    DashStatsQuery,
    BarChart,
    Tabs,
    TabPane,
    Modal,
    Row,
    Button,
    Col,
  },
  data() {
    return {
      activeKey: '1',
      creditModal: false,
    };
  },
  computed: {
    query() {
      return this.$store.state.dash.dashStatsQuery;
    },
    data() {
      return this.$store.getters.dashStatsData;
    },
    user() {
      return this.$store.state.auth.user || null;
    },
  },
};
</script>
<style scoped>
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100% !important;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  width: 50% !important;
  text-align: center;
}
</style>
