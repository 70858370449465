<template>
  <section class="members-data">
    <div class="w-full mt-4 flex flex-wrap items-center justify-between gap-4">
      <div class="flex items-center gap-3 flex-wrap">
        <Spin :spinning="employersQuery && employersQuery.isLoading">
          <Select
            class="text-center min-w-[300px]"
            :value="employer_id"
            @change="handleSelectBusiness"
            allowClear
            placeholder="Select business"
          >
            <select-option
              v-for="buss in employersData"
              :key="buss._id"
              :value="buss._id"
            >{{ buss.community_name }}</select-option>
          </Select>
        </Spin>
      </div>
      <div class="flex flex-wrap items-center gap-6">
        <div>
          <InputSearch
            class="rounded-md min-w-[320px]"
            placeholder="Search ..."
            v-model:value="search"
            clear
            @change="handleSearch"
          />
        </div>

        <div class="flex items-center gap-3">
          <div>
            <date-picker v-if="type !== 'all'" :picker="type" v-model:value="dateVal" />
          </div>

          <CustumSelect
            name="type"
            placeholder="select type "
            :data="dateData"
            :defaultData="type"
            :rules="{
                  required: false,
                }"
            @setInput="setInput"
          />
        </div>
      </div>
    </div>

    <QueryAlert :query="employersQuery" />
    <QueryAlert :query="adminInvoicesQuery" />

    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table
        :data-source="tableFormatedData"
        :columns="columns"
        :loading="adminInvoicesQuery && (adminInvoicesQuery.isFetching || adminInvoicesQuery.isLoading)"
        :pagination="false"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'invoice_date'">
            <span
              v-if="record.invoice_date"
              class="whitespace-nowrap"
            >{{getDate(record.invoice_date)}}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'type'">
            <span
              v-if="record.type !== undefined "
              class="whitespace-nowrap capitalize"
            >{{record.type.replace("_" , " ")}}</span>
            <span v-else class="whitespace-nowrap">n/a</span>
          </template>

          <template v-if="column.key === 'invoice_amount'">
            <span v-if="record.invoice_amount !== undefined " class="whitespace-nowrap">${{record.invoice_amount.toFixed(2)}}</span>
            <span v-else class="whitespace-nowrap">n/a</span>
          </template>

          <template v-if="column.key === 'paid_amount'">
            <span v-if="record.paid_amount !== undefined " class="whitespace-nowrap">${{record.paid_amount.toFixed(2)}}</span>
            <span v-else class="whitespace-nowrap">n/a</span>
          </template>

          <template v-if="column.key === 'remaining_amount'">
            <span v-if="record.remaining_amount !== undefined " class="whitespace-nowrap">${{record.remaining_amount.toFixed(2)}}</span>
            <span v-else class="whitespace-nowrap">n/a</span>
          </template>

          <template v-if="column.key === 'amount_status'">
            <span
              v-if="record.amount_status !== undefined "
              class="whitespace-nowrap"
            >{{record.amount_status}}</span>
            <span v-else class="whitespace-nowrap">n/a</span>
          </template>

          <template v-if="column.key === 'invoice'">
            <div class="flex items-center gap-2">
              <Button
                type="primary"
                size="small"
                ghost
                @click="() => handleGeneratePdf(record.key)"
                :loading="pdfLoading === record.key && !detailsLoading"
              >
                <div class="flex items-center gap-2" v-if=" pdfLoading !==  record.key">
                  <PhFilePdf weight="duotone" :size="16" />PDF File
                </div>
              </Button>
            </div>
          </template>
          <template v-if="column.key === 'detailed_invoice'">
            <div class="flex items-center gap-2">
              <Button
                type="primary"
                size="small"
                ghost
                @click="() => handleGeneratePdf(record.key,1)"
                :loading="pdfLoading === record.key && detailsLoading"
              >
                <div class="flex items-center gap-2" v-if=" pdfLoading !==  record.key">
                  <PhFilePdf weight="duotone" :size="16" />PDF File
                </div>
              </Button>
            </div>
          </template>
        </template>
      </Table>

      <div
        v-if="adminInvoicesData && adminInvoicesData.meta"
        class="mt-6 flex items-center justify-center"
      >
        <Pagination
          :current="adminInvoicesData?.meta?.current_page"
          :total="adminInvoicesData?.meta?.total"
          show-less-items
          :page-size="adminInvoicesData?.meta?.per_page"
          @change="handlePaginationChange"
        />
      </div>
    </div>

    <!-- query apis  -->
    <AdminInvoicesQuery
      :page="page"
      :per_page="15"
      :employer_id="employer_id"
      :start_date="dates[0]"
      :end_date="dates[1]"
      :keyword="keyword"
      :key="`${page}-${15}-${employer_id}-${dates[0]}-${dates[1]}-${keyword}`"
    />
    <EmployersQuery />
  </section>
</template>
<script>
import {
  Table,
  Button,
  InputSearch,
  Select,
  SelectOption,
  Spin,
  DatePicker,
  Pagination,
  message,
} from 'ant-design-vue';
import { PhFilePdf } from 'phosphor-vue';
import dayjs from 'dayjs';

import EmployersQuery from '@/services/queries/admin/employers/EmployersQuery.vue';
import AdminInvoicesQuery from '@/services/queries/admin/invoices/AdminInvoicesQuery.vue';

import debounce from 'lodash.debounce';

// import handleResErrors from '@/utils/handleResErrors';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';

import { getAdminToken } from '@/services/jwt.service';
import CONFIG from '@/services/config';
import handleResErrors from '../../../utils/handleResErrors';

export default {
  components: {
    Table,
    Button,

    PhFilePdf,
    InputSearch,

    Select,
    SelectOption,

    AdminInvoicesQuery,
    EmployersQuery,
    Spin,
    DatePicker,
    CustumSelect,
    Pagination,
  },
  data() {
    return {
      columns: [
        // {
        //   title: '#',
        //   key: 'key',
        //   dataIndex: 'key',
        // },
        {
          title: 'Invoice Date',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.invoice_date).getTime();
          //   const bDate = new Date(b.invoice_date).getTime();
          //   return bDate - aDate;
          // },
        },

        {
          title: 'Service',
          dataIndex: 'service',
          key: 'service',
          // sorter: (a, b) => {
          //   if (a.service > b.service) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          // sorter: (a, b) => {
          //   if (a.type > b.type) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Amount Status',
          dataIndex: 'amount_status',
          key: 'amount_status',
          // sorter: (a, b) => {
          //   if (a.amount_status > b.amount_status) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Invoice Amount',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount',
          // sorter: (a, b) => {
          //   if (a.amount > b.amount) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Paid',
          dataIndex: 'paid_amount',
          key: 'paid_amount',
          // sorter: (a, b) => {
          //   if (a.amount > b.amount) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Remaining',
          dataIndex: 'remaining_amount',
          key: 'remaining_amount',
          // sorter: (a, b) => {
          //   if (a.amount > b.amount) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: 'Invoice',
          dataIndex: 'invoice',
          key: 'invoice',
          // sorter: (a, b) => {
          //   if (a.invoice > b.invoice) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Detailed Invoice',
          dataIndex: 'detailed_invoice',
          key: 'detailed_invoice',
          // sorter: (a, b) => {
          //   if (a.invoice > b.invoice) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        // {
        //   title: '',
        //   key: 'action',
        //   fixed: 'right',
        // },
      ],

      tableData: [
        {
          key: '1',
          invoice_date: 1681478087456,
          service: 'service 1',
          type: 'type',
          amount_status: 'amount_status',
          amount: 22,
          invoice: 'invoice',
        },

        ...Array(50)
          .fill('dd')
          .map((_, ix) => ({
            key: ix + 2,
            name: `Name ${ix + 2}`,
            invoice_date: this.getRandomInt(1420063200000, 1681478087456),
            service: `service  ${ix + 2}`,
            type: 'type',
            amount_status: 'amount_status',
            amount: this.getRandomInt(11, 111),
            invoice: `invoice ${ix + 2}`,
          })),
      ],
      search: '',
      searchData: [],
      page: 1,
      pageSize: 10,
      employer_id: null,
      type: 'month',
      years: [],
      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'year', name: 'Yearly' },
        // { id: 'all', name: 'All Data' },
      ],
      dateVal: null,
      keyword: '',
      pdfLoading: false,
      detailsLoading: false,
    };
  },
  computed: {
    dates() {
      if (!this.dateVal) return [null, null];
      if (this.type === 'month') {
        const start = dayjs(this.dateVal)
          .startOf('month')
          .format('YYYY-MM-DD');
        const end = dayjs(this.dateVal)
          .endOf('month')
          .format('YYYY-MM-DD');
        return [start, end];
      }

      if (this.type === 'year') {
        const start = dayjs(this.dateVal)
          .startOf('year')
          .format('YYYY-MM-DD');
        const end = dayjs(this.dateVal)
          .endOf('year')
          .format('YYYY-MM-DD');
        return [start, end];
      }

      return [null, null];
    },
    employersQuery() {
      return this.$store.state.adminEmployers.employersQuery;
    },
    employersData() {
      return this.$store.getters.employersData?.data || [];
    },
    adminInvoicesQuery() {
      return this.$store.state.adminInvoices.adminInvoicesQuery;
    },
    adminInvoicesData() {
      return this.$store.getters.adminInvoicesData;
    },
    tableFormatedData() {
      if (!this.adminInvoicesData || !this.adminInvoicesData.data) return [];

      const getFromTo = (from, to) => {
        const f = from ? dayjs(from).format('YYYY-MM-DD') : '';
        const t = to ? dayjs(to).format('YYYY-MM-DD') : '';
        return `${f} - ${t}`;
      };

      const data = this?.adminInvoicesData?.data.map((d, ix) => ({
        // eslint-disable-next-line no-underscore-dangle
        key: d._id || d.id || ix,
        invoice_date: d.datetime,
        service: getFromTo(d.start_date, d.end_date),
        type: d.type || 'Transportation program Invoice',
        amount_status: d.status,
        invoice_amount: d.value,
        // eslint-disable-next-line no-nested-ternary
        paid_amount: d.status === 'paid' ? d.value : (d.value - (d.remaining >= 0 ? d.remaining : 0)),
        // eslint-disable-next-line no-nested-ternary
        remaining_amount: d.status === 'paid' ? 0 : (d.remaining >= 0 ? d.remaining : 0),
      }));
      return data ?? [];
    },
  },
  methods: {
    handlePaginationChange(page, pageSize) {
      // , filters, sorter, data
      this.page = page;
      this.pageSize = pageSize;
    },
    getRandomInt(min, max) {
      const xmin = Math.ceil(min);
      const xmax = Math.floor(max);
      return Math.floor(Math.random() * (xmax - xmin + 1)) + min;
    },
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMM DD, YYYY');
    },
    handleSearch(e) {
      this.debounceSearch(e.target.value, this);
    },
    debounceSearch: debounce((search, app) => {
      // eslint-disable-next-line no-param-reassign
      app.keyword = search;
      // eslint-disable-next-line no-param-reassign
      app.page = 1;
    }, 400),
    handleSelectBusiness(val) {
      this.page = 1;
      this.employer_id = val;
    },
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
    },
    async handleGeneratePdf(id, details = 0) {
      this.pdfLoading = id;
      if (details) this.detailsLoading = true;
      try {
        this.openPdf(id, this.employer_id, details);
        // await adminInvoicesApi.viewAdminInvoice({ id, employer_id: this.employer_id, details });
      } catch (error) {
        message.error(handleResErrors(error));
      } finally {
        this.pdfLoading = false;
        this.detailsLoading = false;
      }
    },
    openPdf(id, employer_id = null, details = 0) {
      const token = getAdminToken();
      if (!token || !id) {
        throw new Error('Missing token or id');
      }
      /* eslint-disable */
      let url = '';
      if(details !=  0)
        url += '&detailed=1'
      if (employer_id) url += `&employer_id=${employer_id}`;
      console.log("invoices")
      window.open(CONFIG.API_URL+`/admin/invoices/pdf/${id}?access_token=${token}${url}`, "_blank") || window.location.replace(CONFIG.API_URL+`/admin/invoices/pdf/${id}?access_token=${token}${url}`);
      return ;
    }
  },
};
</script>
