<template>
  <section>
    <div>
      <div class="flex flex-wrap items-center gap-4 mb-4">
        <h2 class="text-primary-dark">Employer Metrics</h2>
        <small>
          Since {{ sinceDate }} ~ {{ totalDays }} days
        </small>
      </div>
      <CardsLoader v-if="query && (query.isFetching || query.isLoading)" />
      <div v-if="data" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 xl:gap-6">
        <HomeCard v-for="(emp , ix) in empData" :key="ix" :data="emp" />
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import HomeCard from './shared/HomeCard.vue';
import CardsLoader from './shared/CardsLoader.vue';

export default {
  components: {
    HomeCard,
    CardsLoader,
  },
  data() {
    return {};
  },
  computed: {
    query() {
      return this.$store.state.dash.dashStatsQuery;
    },
    data() {
      return this.$store.getters.dashStatsData;
    },
    empData() {
      if (!this.data) return null;
      return [
        [
          {
            id: 1,
            img: 'car.png',
            value: this.data.cumulative_saved_sov,
            type: '',
            title: 'Reduced SOV Trips (Non-SOV Trips)',
          },
          {
            id: 2,
            img: 'no-parking.png',
            value: this.data.cumulative_parking_spaces,
            type: '',
            title: 'Saved Parking Spaces',
          },
        ],
        [
          {
            id: 1,
            img: 'no-emission.png',
            value: this.data.cumulative_reduced_co2,
            type: 'kg',
            title: 'Cumulative CO2 Emissions Reduced',
          },
          {
            id: 2,
            img: 'tree.png',
            value: this.data.trees_planted,
            type: '',
            title: 'Trees Planted',
          },
        ],
        [
          {
            id: 1,
            img: 'dollar.png',
            value: this.data.cumulative_transportation_cost,
            type: '$',
            title: 'Cumulative Transportation Program Costs',
            info: 'See details in Transportation Programs',
          },
        ],
        [
          {
            id: 1,
            img: 'save.png',
            value: '...',
            type: '$',
            title: 'Cumulative Equivalent Savings',
            info: 'See details in Transportation Programs',
            tooltip: [
              'Telecommute savings are due to increased productivity, reduced office space, absenteeism, and turnovers.',
              'Reduced parking space savings are due to reduced costs of maintenance, insurance, utilities, security, and supplies.',
            ],
          },
        ],
      ];
    },
    sinceDate() {
      return dayjs(this.$store.state.auth.user?.created_at, 'YYYY-MM-DDTHH:mm:ssZ').format('MMMM YYYY');
    },
    totalDays() {
      const startDate = dayjs(this.$store.state.auth.user?.created_at, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMMM YYYY');
      const currentDate = dayjs();
      return Math.round(currentDate.diff(startDate, 'day', true));
    },
  },
};
</script>
