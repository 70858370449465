<template>
  <CustumLabel
    :name="name"
    :label="label"
    :error="touched && error  && error"
    :required="rules && rules.required"
    :hideErrors="hideErrors"
  >
    <Select
      v-if="data"
      :mode="multiple ? 'multiple' : 'single' "
      :value="value"
      :show-search="search"
      :placeholder="placeholder"
      :filter-option="filterOption"
      :loading="loading"
      @change="handleChange"
      @blur="handleClick"
      :allowClear='allowClear'
      :disabled='disabled'
    >
      <select-option v-for="item in data" :key="item.id" :value="item.id">
        <span class="flex items-center gap-2">
          <AsImage v-if="item.image" :src="item.image" alt="item image" class="w-5 h-5" />
          {{item.name}}
        </span>
      </select-option>
    </Select>
  </CustumLabel>
</template>

<script>
import validation from '@/services/validation';
import { Select, SelectOption } from 'ant-design-vue';
import CustumLabel from './CustumLabel.vue';

// data : [ {id: 0 , name: 'some title'}]

export default {
  components: { CustumLabel, Select, SelectOption },
  props: {
    name: String,
    loading: Boolean,
    label: String,
    placeholder: String,
    defaultError: String,
    hideErrors: Boolean,
    defaultData: [String, Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: null,
    },
    isTouched: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      error: null,
      touched: false,
      isValid: false,
    };
  },
  created() {
    if (this.multiple) {
      this.value = [];
    }

    if (this.defaultData !== 'undefined' && this.defaultData !== null) {
      this.value = this.defaultData;
      this.touched = true;
    }
    this.handleValidate();
    this.setInputValues();
  },
  watch: {
    isTouched(val) {
      if (val) {
        this.touched = true;
      }
    },
    defaultError(val) {
      if (val) {
        this.touched = true;
        this.error = val[0].length > 1 ? val[0] : val;
      }
    },
  },
  methods: {
    handleClick() {
      this.touched = true;
    },
    handleChange(val) {
      this.error = null;
      this.value = val;
      this.touched = true;
      this.handleValidate();
      this.setInputValues();
    },
    handleValidate() {
      const { error, isValid } = validation.validateRules(this.rules, this.value);

      // if (this.name === 'color') {
      // }
      this.error = error;
      this.isValid = isValid;
    },
    setInputValues() {
      const isRequired = this.rules && this.rules.required;
      if (!isRequired && (this.value === 'undefined' || this.value === null)) {
        return;
      }
      this.$emit('setInput', {
        [this.name]: {
          name: this.name,
          value: this.value,
          error: this.error,
          touched: this.touched,
          isValid: this.isValid,
        },
      });
    },
    filterOption(input, option) {
      const val = this.data.find((item) => item.id === option.value);
      return val.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
