<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { MEMBERS } from '@/store/types';
import membersApi from '../../apis/members.api';

export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    per_page: {
      type: Number,
      default: 10,
    },
    name: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    start_date: {
      type: String,
      default: null,
    },
    end_date: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    console.log('fired');
    const store = useStore();
    const query = ref(null);
    query.value = membersApi.useMembersQuery({
      page: props.page,
      per_page: props.per_page,
      name: props.name,
      email: props.email,
      start_date: props.start_date,
      end_date: props.end_date,
    });
    if (query.value) {
      store.commit(MEMBERS.SET_MEMBERS_QUERY, query);
    }
    return { query };
  },
};
</script>
