<template>
  <div class="flex items-center gap-1 mt-5">
    <h3 class="text-base text-center text-primary-dark">Number of Employees:</h3>
    <div>
      <!-- <p class="text-sm text-center bg-bg border border-solid border-bg-form mx-auto py-2 px-4">
        100
      </p>-->
      <InputNumber class="w-20" v-model:value="numberOfEmp" :min="1" :disabled='isProgramStatusActive'/>
    </div>
    <div style='align-self: center;margin-left: 5px' v-if='isProgramStatusActive'>
      <upDownGradeButtons @upgradeEmployees='upgradeEmployeesDialog' @downgradeEmployees='downgradeEmployeesDialog'  :program='programData'/>
    </div>
  </div>
  <div v-if="plans && plans.length" class="mt-6 pt-3">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
      <PlanCard v-for="plan in plans" :key="plan.id" :data="plan"  :employeesNumber='employeesNumber'/>
    </div>

    <div v-if="activePlan" class="mt-4 flex flex-col items-center justify-center gap-3">
      <!-- <h4 class="text-xl text-center">Estimated Plan Cost per Cycle:</h4>

      <div
        class="max-w-xl mb-3 text-lg py-2 px-12 bg-bg-form border border-solid border-bg-dark rounded"
      >

        ${{activePlan?.price}} - {{activePlan?.title}}
      </div>-->
    </div>
    <div v-if="subscriptionsData"></div>
    <Modal
      :visible="upgradeGradeMode"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <upDownGrade :number_of_employees='numberOfEmp' @cancelUpDownGrade='cancelUpDownGrade' upgrade='true' />
    </Modal>
    <Modal
      :visible="downGradeMode"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <upDownGrade :number_of_employees='numberOfEmp' @cancelUpDownGrade='cancelUpDownGrade' downgrade='true' :nextBillingDate='nextBillingDate'/>
    </Modal>
  </div>
</template>

<script>
import { InputNumber, Modal } from 'ant-design-vue';
import upDownGrade from '@/components/dashboard/account/UpDownGrade.vue';
import upDownGradeButtons from '@/components/dashboard/account/UpDownGradeButtons.vue';
import PlanCard from '../PlanCard.vue';

export default {
  components: { PlanCard, Modal, InputNumber, upDownGrade, upDownGradeButtons },

  data() {
    return {
      numberOfEmp: 0,
      downGradeMode: false,
      upgradeGradeMode: false,
      nextBillingDate: null,
    };
  },
  computed: {
    plans() {
      return this.$store.getters.plansData;
    },
    plansTotalCost() {
      return this.$store.getters.plansTotalCost;
    },
    // activePlan() {
    //   const actP = this.$store.state.plans.activePlan;
    //   return this.plans.find((p) => p.id === actP);
    // },
    activePlan() {
      // subscriptions
      const { user } = this.$store.state.auth;
      if (user?.subscriptions) {
        return user.subscriptions;
      }
      return this.$store.state.auth.user?.subscription_plan;
      // return this.$store.state.plans.activePlan;
    },
    subscriptionsData() {
      return this.$store.getters.subscriptionsData;
    },
    employeesNumber() {
      return this.numberOfEmp;
    },
    isProgramStatusActive() {
      return this.$store.state.auth.user?.subscription_plan ?? false;
    },
    programData() {
      return this.$store.state.auth.user?.active_subscription;
    },
  },
  created() {
    const numberOfEmp = this.program?.quantity ?? ((this.$store.state.auth.user?.community_size !== 0 ? this.$store.state.auth.user?.community_size : 1));
    this.numberOfEmp = numberOfEmp;
    this.nextBillingDate = this.$store.state.auth.user?.subscription?.next_billing_date ?? null;
  },
  methods: {
    upgradeEmployeesDialog() {
      console.log('are we working upgrade ??');
      this.downGradeMode = false;
      this.upgradeGradeMode = true;
    },
    downgradeEmployeesDialog() {
      console.log('are we working downgrade ??');
      this.upgradeGradeMode = false;
      this.downGradeMode = true;
    },
    cancelUpDownGrade() {
      console.log('are we working cancel ??');
      this.upgradeGradeMode = false;
      this.downGradeMode = false;
    },
  },
};
</script>
<style>
.upgrade-downgrade .ant-modal-content{
  border-radius: 15px !important;
}
</style>
