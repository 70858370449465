<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { ADMIN_EMPLOYERS } from '@/store/types';
import employersApi from '@/services/apis/admin/employers.api';

export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    per_page: {
      type: Number,
      default: 15,
    },
    keyword: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore();
    const query = ref(null);
    query.value = employersApi.useEmployersQuery({
      keyword: props.keyword,
      page: props.page,
      per_page: props.per_page,
    });
    if (query.value) {
      store.commit(ADMIN_EMPLOYERS.SET_EMPLOYErS_QUERY, query);
    }
    return { query };
  },
};
</script>
