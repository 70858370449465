<template>
  <div v-if="data">
    <!-- <p
      v-if="label"
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-lg text-center max-w-[150px] max-h-[150px] text-ellipsis"
    >{{label}}</p>-->
    <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="[...plugins , labelPlugin , textPlugin  ]"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { Doughnut } from 'vue-chartjs';

import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';
import { CHART_COLORS, COLORS } from './COLORS';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  components: {
    Doughnut,
  },
  props: {
    labelText: String,
    data: Array,
    chartId: {
      type: String,
      default: 'doughnut-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultColors: [],
      chartDefaultData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              '#41B883',
              '#F9DC5C',
              '#963A41',
              '#2F3061',
              '#FF7F51',
              '#3E505B',
              '#DBEFBC',
              '#241E4E',
              '#F5EE9E',
            ],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '70%',
        layout: {
          padding: 40,
        },

        plugins: {
          legend: false,
          tooltip: {
            callbacks: {
              label: (context) => {
                const defaultVal = this.data[context.dataIndex].default_value;
                let label = ` ${context.label}` || '';
                if (label) {
                  label += ': ';
                }

                if (defaultVal !== undefined) {
                  label += `${defaultVal}`;
                }
                if (context.formattedValue) {
                  label += ` (${Math.round(context.formattedValue * 10) / 10}%)`;
                }
                return label;
              },
            },
          },
        },
      },
    };
  },

  computed: {
    labelPlugin() {
      const text = this.labelText;
      const dataLng = this.data.length || 4;
      const cWidth = document.body.clientWidth;

      let txtoffsety = 5 * dataLng;
      let fontSize = 1.5;

      if (cWidth < 400) {
        txtoffsety = 10 * dataLng;
        fontSize = 0.85;
      } else if (cWidth < 900) {
        txtoffsety = 6 * dataLng;
        fontSize = 1.2;
      }
      return {
        id: 'custom_canvas_label',
        beforeDraw(chart) {
          const { width } = chart;
          const { height } = chart;
          const { ctx } = chart;

          ctx.restore();
          // const fontSize = (height / 114).toFixed(2);
          ctx.font = `${fontSize}em sans-serif`;
          ctx.textBaseline = 'middle';

          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 2 + txtoffsety;

          ctx.fillText(text, textX, textY - textY * 0.13);
          ctx.save();
        },
      };
    },

    chartData() {
      const data = this.chartDefaultData;
      const labels = [];
      const dataSet = [];
      this.data.forEach((el) => {
        let label = el.label.replace('_', ' ');
        if (label.includes('count')) {
          label = label.replace('count', '');
        }
        labels.push(label);
        dataSet.push(el.value);
      });

      let colors = [];
      if (this.data && Array.isArray(this.data)) {
        // CHART_COLORS
        this.data.forEach((el, ix) => {
          if (el?.label) {
            let key = el?.label;

            if (key.includes('_count')) {
              key = key.replace('_count', '');
            }

            colors.push(CHART_COLORS[key] || COLORS[ix]);
          } else {
            colors.push(COLORS[ix]);
          }
        });
      }
      if (!colors.length) {
        colors = [...COLORS];
      }

      data.labels = labels;
      data.datasets[0].data = dataSet;
      data.datasets[0].backgroundColor = colors;

      return data;
    },

    textPlugin() {
      //
      return {
        id: 'doughnut-outer-text',
        afterDraw(chart) {
          const {
            ctx,
            // eslint-disable-next-line no-unused-vars
            chartArea: { top, bottom, left, right, width, height },
          } = chart;
          // const { data } = config;

          chart.data.datasets.forEach((dataset, ix) => {
            chart.getDatasetMeta(ix).data.forEach((datapoints, inx) => {
              const { x, y } = datapoints.tooltipPosition();
              const val = dataset.data[inx] ? Math.round(dataset.data[inx] * 10) / 10 : 0;

              if (val && val >= 1) {
                ctx.fillStyle = 'gray';
                ctx.fillRect(x, y, 2, 2);

                // draw line
                const halfW = width / 2;
                const halfH = height / 2;

                const lineH = 25;
                const lineEx = 25;
                const xLine = x >= halfW ? x + lineH : x - lineH;
                const yLine = y >= halfH ? y + lineH : y - lineH;
                const extraLine = x >= halfW ? lineEx : -lineEx;

                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.lineTo(xLine, yLine);
                ctx.lineTo(xLine + extraLine, yLine);
                ctx.strokeStyle = 'gray';
                ctx.stroke();

                const isFar = x / width < 0.5 || x / width > 0.7;

                ctx.font = '0.85rem sans-serif';
                ctx.fillStyle = 'black';
                ctx.textBaseline = 'middle';
                ctx.textAlign = x >= halfW ? 'left' : 'right';
                const extraPad = x >= halfW ? 5 : -5;

                // const txtW = ctx.measureText(txt).width;

                // Update by Ahmed Osama

                const capitalizeFirstLetter = (str) => {
                  if (typeof str !== 'string') {
                    throw new Error('Input is not a string');
                  }

                  if (str.length === 0) {
                    return str;
                  }

                  return str.charAt(0).toUpperCase() + str.slice(1);
                };
                let secondTxt = capitalizeFirstLetter(chart.data.labels[inx]);
                const txt = `${val}% ${secondTxt}`;
                ctx.fillText(txt, xLine + extraLine + extraPad, yLine);
                return ;
                if (isFar) {
                  const perc = `${val}% `;
                  ctx.fillText(perc, xLine + extraLine + extraPad, yLine - 14);

                  const txt = capitalizeFirstLetter(`${chart.data.labels[inx]}`);

                  let prevTxt = '';
                  let tinx = 0;
                  txt.split(' ').forEach((tx, tix) => {
                    let ltxt = tx;
                    // if (tix === 0) {
                    //   ltxt = perc + ltxt;
                    // }
                    ltxt = prevTxt ? `${prevTxt} ${ltxt}` : ltxt;
                    if (prevTxt) {
                      ctx.fillText(
                        ltxt,
                        xLine + extraLine + extraPad * (tinx + 1),
                        yLine + tinx * 15,
                      );
                      tinx += 1;
                    } else {
                      // eslint-disable-next-line no-lonely-if
                      if (ltxt.length < 2) {
                        prevTxt = ltxt;
                      } else {
                        ctx.fillText(
                          ltxt,
                          xLine + extraLine + extraPad * (tix + 1),
                          yLine + tix * 15,
                        );
                        tinx += 1;
                      }
                    }
                  });
                } else {
                  // text
                  const txt = `${val}% ${chart.data.labels[inx]}`;
                  // const txtW = ctx.measureText(txt).width;

                  ctx.fillText(txt, xLine + extraLine + extraPad, yLine);
                }
              }
            });
          });

          ctx.restore();
          // const fontSize = (height / 114).toFixed(2);
          // ctx.font = '0.8em sans-serif';
          // ctx.textBaseline = 'middle';

          // const textX = Math.round((width - ctx.measureText(text).width) / 2);
          // const textY = height / 2 + txtoffsety;

          // ctx.fillText('helll', metadata.x, metadata.y);
          ctx.save();
        },
      };
    },
  },
};
</script>
