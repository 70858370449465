<template>
  <div class="payment-page py-4 mt-4">
    <div class="container">
      <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
        <tabs v-model:activeKey="activeKey" class="w-full">
          <tab-pane key="1" tab="Payment Methods">
            <PaymentMethods />
          </tab-pane>
          <tab-pane key="2" tab="Invoices">
            <PaymentInvoices />
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from 'ant-design-vue';

import PaymentMethods from './PaymentMethods.vue';
import PaymentInvoices from './PaymentInvoices.vue';

export default {
  components: { Tabs, TabPane, PaymentMethods, PaymentInvoices },
  data() {
    return {
      activeKey: '1',
    };
  },
};
</script>

<style  >
.payment-page .ant-tabs-tab {
  font-size: 1.25rem;
}
</style>
