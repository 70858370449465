<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { PAYMENTS } from '@/store/types';
import paymentsApi from '@/services/apis/payments.api';

export default {
  props: {},
  setup() {
    const store = useStore();
    const query = ref(null);
    query.value = paymentsApi.useSubscriptionsQuery();
    if (query.value) {
      store.commit(PAYMENTS.SET_SUBSCRIPTIONS_QUERY, query);
    }
    return { query };
  },
};
</script>
