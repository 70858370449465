<template>
  <section>
    <div class="max-w-5xl mx-auto">
      <FileTemplate />
    </div>
    <div class="mt-6">
      <p class="text-center font-bold text-lg">Mode Share (%)</p>
      <BarChart :chartData="chartData" />
    </div>
    <div class="mt-6 pt-6 border-solid border-0 border-t border-gray-200">
      <IncentivesStructure />
    </div>
  </section>
</template>

<script>
import BarChart from './shared/BarChart.vue';
import COLORS from './shared/COLORS';

import IncentivesStructure from './generic/IncentivesStructure.vue';
import FileTemplate from './generic/FileTemplate.vue';

export default {
  components: { BarChart, IncentivesStructure, FileTemplate },

  computed: {
    chartData() {
      const data = {
        labels: ['Current Modal Share', 'Potential Modal Share'],
        datasets: [
          {
            label: 'Drive Alone',
            data: [38, 20],
            backgroundColor: [COLORS[0]],
          },
          {
            label: 'Carpool',
            data: [0, 10],
            backgroundColor: [COLORS[1]],
          },

          {
            label: 'Transit',
            data: [9, 12],
            backgroundColor: [COLORS[2]],
          },
          {
            label: 'Walk',
            data: [5, 7],
            backgroundColor: [COLORS[3]],
          },
          {
            label: 'Telecommute',
            data: [35, 35],
            backgroundColor: [COLORS[4]],
          },
          {
            label: 'Bike',
            data: [13, 16],
            backgroundColor: [COLORS[5]],
          },
        ],
      };

      return data;
    },
  },
};
</script>
