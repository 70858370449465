const readAndDownloadFile = (file) => {
  if (typeof URL.createObjectURL !== 'undefined') {
    const fileUrl = URL.createObjectURL(file);
    if (fileUrl) {
      window.open(fileUrl);
      return;
    }
  }

  const reader = new FileReader();

  reader.onload = function (e) {
    const url = e.target.result;
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;

    // Programmatically trigger the click event
    link.click();

    // Clean up the dynamically created link
    document.body.removeChild(link);
  };

  reader.readAsDataURL(file);
};

export default readAndDownloadFile;
