<template>
  <div class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5">
    <div class="flex justify-between">
      <Checkbox v-model:checked="checked" :disabled="isOngoing && hasStarted">
        <h3 class="text-xl text-primary-dark">Sign up incentives</h3>
      </Checkbox>

      <SaveBtn
        :disabled="!isShowCheckedSaved"
        :loading="isFetching"
        @click="handleSave"
        class="mt-0"
      />
    </div>
    <div v-if="checked">
      <div class="mt-6">
        <div class="text-center flex flex-wrap items-center justify-center gap-2">
          <span class="font-bold">Each employee receives one-time monetary incentives for joining</span>
          <InputNumber
            class="w-20"
            :defaultValue="rewardData.signup_incentives"
            :disabled="isOngoing && hasStarted"
            v-model:value="value"
            :min="0"
            prefix="$"
            :step="0.5"
          />
          <!-- @change="setVal" -->
          <span class="font-bold">/employee</span>
        </div>
      </div>
      <div class="w-full flex justify-center md:justify-end">
        <SaveBtn :disabled="!isValueChanged" :loading="isFetching" @click="handleSave" />
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, InputNumber } from 'ant-design-vue';
import { mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import SaveBtn from '../shared/SaveBtn.vue';
import { REWARD } from '../../../../store/types';

export default {
  components: {
    Checkbox,
    InputNumber,
    SaveBtn,
  },
  props: {
    isOngoing: {
      type: Boolean,
      default: false,
    },
    hasStarted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
      value: 0,
    };
  },

  created() {
    if (this.rewardData) {
      this.value = this.rewardData.signup_incentives;
      this.checked = this.rewardData.signup_incentives_enabled;
    }
  },
  computed: {
    rewardData() {
      return this.$store.getters.rewardSettingsData;
    },
    defaultRewardData() {
      return this.$store.getters.rewardDefaultsData;
    },
    isFetching() {
      return this.$store.state.reward.rewardSettingsQuery?.isFetching;
    },
    signupIncentives() {
      return this.$store.state.reward.signup_incentives;
    },
    // checked() {
    //   return this.$store.state.reward.is_signup_incentives_selected;
    // },
    isValueChanged() {
      if (this.checked && this.checked !== this.rewardData.signup_incentives_enabled) return true;
      // eslint-disable-next-line eqeqeq
      return this.rewardData.signup_incentives != this.value;
    },
    isShowCheckedSaved() {
      return !this.checked && this.checked !== this.rewardData.signup_incentives_enabled;
    },
  },
  methods: {
    ...mapMutations([REWARD.SET_SIGNUP_INCENTIVES_DATA, REWARD.SET_SELECTED_SYSTEM]),
    handleSave() {
      this.setVal(this.value);
    },
    setVal(val) {
      // this.SET_SIGNUP_INCENTIVES_DATA(val);
      this.debounceSetVal(+val, this);
    },
    debounceSetVal: debounce((val, app) => {
      app.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        signup_incentives: val,
        signup_incentives_enabled: app.checked,
      });
    }, 600),
    handleSelectSystem(e) {
      this.$store.dispatch(REWARD.ACTION_SELECT_REWARD_SYS, {
        signup_incentives_enabled: e.target.checked,
      });
      // this.SET_SELECTED_SYSTEM({
      //   type: 'is_signup_incentives_selected',
      //   value: e.target.checked,
      // });
    },
  },
};
</script>
