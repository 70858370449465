<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { ADMIN_EMPLOYEES } from '@/store/types';
import employeesApi from '@/services/apis/admin/employees.api';

export default {
  props: {
    employee_id: {
      type: String,
      default: null,
    },
    page: {
      type: Number,
      default: 1,
    },
    per_page: {
      type: Number,
      default: 10,
    },
    keyword: {
      type: String,
      default: '',
    },
    start_date: {
      type: String,
      default: null,
    },
    end_date: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const query = ref(null);
    query.value = employeesApi.useEmployeeRewardQuery({
      employee_id: props.employee_id,
      page: props.page,
      per_page: props.per_page,
      keyword: props.keyword,
      start_date: props.start_date,
      end_date: props.end_date,
    });
    if (query.value) {
      store.commit(ADMIN_EMPLOYEES.SET_EMPLOYEE_QUERY, query);
    }
    return { query };
  },
};
</script>
