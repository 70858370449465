<template>
  <div class="css-loader">
    <span class="css-loader-line"></span>
  </div>
</template>

<style scoped>
@keyframes loader {
  0% {
    -webkit-transform: scale3d(0, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(0, 1, 1) translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1) translate3d(100%, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(100%, 0, 0);
  }
}

.css-loader {
  width: 100%;
  padding: 0 1rem;
  /* position: fixed;
  bottom: 1px; */
  z-index: 999;
}

.css-loader .css-loader-line {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: opacity 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0, 1);
  height: 2px;
  border-radius: 50px;
}

.css-loader .css-loader-line:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-primary-light, #f6703b);
  transform: scale3d(0, 1, 1) translate3d(0, 0, 0);
  animation: loader 2s cubic-bezier(0.4, 0, 0, 1) infinite;
  transform-origin: left center;
}
</style>
