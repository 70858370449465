import { createRouter, createWebHistory } from 'vue-router';
import HomeRouter from '@/views/HomeRouter.vue';
import HomeView from '@/views/HomeView.vue';

import DashboardView from '@/views/dashboard/DashboardView.vue';
import DashboardRouter from '@/views/dashboard/DashboardRouter.vue';
import MembersView from '@/views/dashboard/MembersView.vue';
import MembersScoresView from '@/views/dashboard/MembersScoresView.vue';
import StatesView from '@/views/dashboard/StatesView.vue';
import AccountView from '@/views/dashboard/AccountView.vue';
import ContactDashView from '@/views/dashboard/ContactDashView.vue';
import PaymentsView from '@/views/dashboard/PaymentsView.vue';
import RewardSystemView from '@/views/dashboard/RewardSystemView.vue';
import TransportationView from '@/views/dashboard/TransportationView.vue';
import CommuteCalcView from '@/views/dashboard/CommuteCalcView.vue';
import CreditDetailsView from '@/views/dashboard/CreditDetailsView.vue';

import AuthRouter from '@/views/auth/AuthRouter.vue';
import LoginView from '@/views/auth/LoginView.vue';
import RegisterView from '@/views/auth/RegisterView.vue';
import ForgetPasswordView from '@/views/auth/ForgetPasswordView.vue';

import { authGurd, pagesGurd, AdminPagesGurd, adminAuthGurd } from '@/middleware/gurds';

import AdminRouterVue from '@/views/admin/AdminRouter.vue';
import AdminView from '@/views/admin/AdminView.vue';
import BusinessManagementView from '@/views/admin/BusinessManagementView.vue';
import BusinessTransactionsView from '@/views/admin/BusinessTransactionsView.vue';
import InvoicesView from '@/views/admin/InvoicesView.vue';
import MembersManagementView from '@/views/admin/MembersManagementView.vue';
import MembersTransactions from '@/views/admin/MembersTransactions.vue';

import MembersManagementMemberView from '@/views/admin/MembersManagementMemberView.vue';

import AdminAuthRouter from '@/views/admin-auth/AdminAuthRouter.vue';
import AdminLoginView from '@/views/admin-auth/AdminLoginView.vue';
import TermsAndConditions from '@/views/admin/TermsAndConditions.vue';
import AdminUsers from '@/components/admin/users/MainIndex.vue';
import TermsAndConditionsView from '@/views/TermsAndConditionsView.vue';
import TermsAndConditionsAcceptanceView from '@/views/TermsAndConditionsAcceptanceView.vue';
import TaxesApprovalView from '@/views/admin/TaxesApprovalView.vue';

const routes = [
  {
    path: '/',
    component: HomeRouter,
    // beforeEnter: (to, from, next) => {
    //   // gurds
    //   pagesGurd(next);
    // },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        redirect: '/dashboard',
      },
    ],
  },

  {
    path: '/dashboard',
    component: DashboardRouter,
    beforeEnter: (to, from, next) => {
      // gurds
      pagesGurd(next);
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'statistics',
        name: 'statistics',
        component: StatesView,
      },
      {
        path: 'members',
        name: 'members',
        component: MembersView,
      },
      {
        path: 'scores',
        name: 'scores',
        component: MembersScoresView,
      },
      {
        path: 'account',
        name: 'account',
        component: AccountView,
      },
      {
        path: 'contact',
        name: 'das-contact',
        component: ContactDashView,
      },
      {
        path: 'payments',
        name: 'payments',
        component: PaymentsView,
      },
      {
        path: 'reward-system',
        name: 'reward-system',
        component: RewardSystemView,
      },
      {
        path: 'transportation-programs',
        name: 'transportation-programs',
        component: TransportationView,
      },
      {
        path: 'commute-calculator',
        name: 'commute-calculator',
        component: CommuteCalcView,
      },
      {
        path: 'credit-details',
        name: 'credit-details',
        component: CreditDetailsView,
      },
      {
        path: 'terms-and-conditions/accept/:id',
        name: 'accept-terms-and-condition',
        component: TermsAndConditionsAcceptanceView,
      },

    ],
  },

  {
    path: '/admin',
    component: AdminRouterVue,
    beforeEnter: (to, from, next) => {
      // gurds
      AdminPagesGurd(next);
    },
    children: [
      {
        path: '',
        name: 'admin',
        component: AdminView,
      },
      {
        path: 'business-management',
        name: 'business-management',
        component: BusinessManagementView,
      },
      {
        path: 'members-management',
        name: 'members-management',
        component: MembersManagementView,
      },
      {
        path: 'members-management/:id',
        name: 'members-management-member',
        component: MembersManagementMemberView,
      },
      {
        path: 'business-transactions',
        name: 'business-transactions',
        component: BusinessTransactionsView,
      },
      {
        path: 'members-transactions',
        name: 'members-transactions',
        component: MembersTransactions,
      },
      {
        path: 'invoices',
        name: 'invoices',
        component: InvoicesView,
      },
      {
        path: 'tax-pay-approval',
        name: 'tax-pay-approval',
        component: TaxesApprovalView,
      },
      {
        path: 'terms-and-conditions',
        name: 'terms-and-conditions',
        component: TermsAndConditions,
      },
      {
        path: 'admin-users',
        name: 'admin-users',
        component: AdminUsers,
      },
    ],
  },

  {
    path: '/admin',
    component: AdminAuthRouter,
    beforeEnter: (to, from, next) => {
      // gurds
      // pagesGurd(next);
      adminAuthGurd(next);
    },
    children: [
      {
        path: 'login',
        name: 'admin-login',
        component: AdminLoginView,
      },
    ],
  },

  {
    path: '/',
    component: AuthRouter,
    beforeEnter: (to, from, next) => {
      // gurds
      authGurd(next);
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginView,
      },
      {
        path: 'register',
        name: 'register',
        component: RegisterView,
      },

      {
        path: 'forget-password',
        name: 'forget-password',
        component: ForgetPasswordView,
      },
      {
        path: 'terms-and-conditions',
        name: 'terms-and-conditions-view',
        component: TermsAndConditionsView,
      },

    ],
  },

  // {
  //   path: '/auth',
  //   name: 'auth',
  //   redirect: '/auth/login',
  //   component: AuthView,
  //   beforeEnter: (to, from, next) => {
  //     authGurd(next);
  //   },
  //   children: [
  //     {
  //       path: 'login',
  //       name: 'login',
  //       component: LoginView,
  //     },
  //     {
  //       path: '*',
  //       redirect: '/auth/login',
  //     },
  //   ],
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
