<template>
  <div class="members-page py-4 mt-4">
    <div class="container">
      <MembersData />
      <MemberInvitation />
      <MembersRequests />
    </div>
  </div>
</template>
<script>
import MembersData from './MembersData.vue';
import MemberInvitation from './MemberInvitation.vue';
import MembersRequests from './MembersRequests.vue';

export default {
  components: { MembersData, MemberInvitation, MembersRequests },
};
</script>
