<template>
  <div class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5">
    <div class="flex justify-between">
      <Checkbox v-model:checked="checked">
        <h3 class="text-xl text-primary-dark">Reward System (Gamification or fixed benefits)</h3>
      </Checkbox>
    </div>
    <div v-if="checked">
      <div class="mt-8">
        <div class="flex flex-col items-center justify-center">
          <div class="text-center flex items-center justify-center gap-2">
            <span class="font-bold">Max monthly rewards per employee:</span>
            <InputNumber class="w-28" disabled :value="max_monthly_reward" :min="1" prefix="$" />
            <!-- @change="setMaxRewardNum" -->
          </div>
        </div>

        <Spin :spinning="false">
          <div class="my-4 flex items-center justify-center gap-4">
            <div class="w-full max-w-md">
              <RewardCard
                title="Mode based transportation benefits"
                description="Each employee receives points based on the trip mode and allocated points in the system"
                @openModal="handleModalOpen"
                :isViewOnly='isViewOnly'
              >
                <img src="@/assets/icons/reward-1.png" alt="icon" class="w-32 h-16 object-contain" />
              </RewardCard>
            </div>
          </div>
        </Spin>
      </div>
    </div>

    <Modal
      :visible="visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal  "
      width="350px"
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <ModeBasedModal
        title="Specify Points per trip"
        :id="activeId"
        :key="activeId"
        :data="mode_base_vals"
        @close="handleModalClose"
      />
    </Modal>
  </div>
</template>

<script>
import { Checkbox, Modal, InputNumber, Spin } from 'ant-design-vue';
import RewardCard from './shared/RewardCard.vue';
import ModeBasedModal from './shared/ModeBasedModal.vue';

export default {
  components: {
    Checkbox,
    InputNumber,
    RewardCard,
    Modal,
    ModeBasedModal,
    Spin,
  },
  props: {
    mode_base_vals: {
      type: Object,
      default: null,
    },
    max_monthly_reward: {
      type: Number,
      default: 0,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: true,
      activeId: 'mile_based',
      visible: false,
    };
  },

  methods: {
    setInput() {},

    handleModalClose() {
      this.visible = false;
    },
    handleModalOpen() {
      this.visible = true;
    },
  },
  computed: {
    isViewOnly() {
      return this.viewOnly;
    },
  },
};
</script>
