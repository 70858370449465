export const MONTHES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'];

const handleNums = (num) => {
  let bare = num;

  if (num.commuters_count) {
    bare = num.trips_count || num.trips_miles || 0;
  }

  if (bare && typeof bare === 'string' && bare.includes(',')) {
    bare = bare.replace(/,/g, '');
  }

  // eslint-disable-next-line no-restricted-globals
  if (!num || isNaN(bare)) {
    return 0;
  }

  return Number(bare);
};

export const sortAndNameMonthes = (arr) => {
  if (!arr || !arr.length) {
    const emptyData = MONTHES.map((m) => ({ [m]: 0 }));
    return emptyData;
  }
  const filteredArr = [];
  for (let i = 0; i < 12; i += 1) {
    // eslint-disable-next-line eqeqeq
    const crrObj = arr.find((obj) => Object.keys(obj)[0] == i + 1);
    const currMont = MONTHES[i];

    if (crrObj) {
      filteredArr[i] = { [currMont]: handleNums(Object.values(crrObj)[0]) };
    } else {
      filteredArr[i] = { [currMont]: 0 };
    }
  }
  return filteredArr;
};

const handleNumsCommuters = (num) => {
  let bare = num;

  let commuters = 0;

  if (num.commuters_count) {
    bare = num.trips_count || num.trips_miles || 0;
  }

  if (num.commuters_count || num.commuters_count === 0) {
    commuters = num.commuters_count || 0;
  }

  if (bare && typeof bare === 'string' && bare.includes(',')) {
    bare = bare.replace(/,/g, '');
  }

  // eslint-disable-next-line no-restricted-globals
  if (!num || isNaN(bare)) {
    return { count: 0, commuters };
  }

  return { count: Number(bare), commuters };
};

export const sortAndNameMonthesAndCommuters = (arr) => {
  if (!arr || !arr.length) {
    const emptyData = MONTHES.map((m) => ({ [m]: 0 }));
    return emptyData;
  }
  const filteredArr = [];
  for (let i = 0; i < 12; i += 1) {
    // eslint-disable-next-line eqeqeq
    const crrObj = arr.find((obj) => Object.keys(obj)[0] == i + 1);
    const currMont = MONTHES[i];

    if (crrObj) {
      const nums = handleNumsCommuters(Object.values(crrObj)[0]);

      filteredArr[i] = { [currMont]: nums.count, commuters: nums.commuters };
    } else {
      filteredArr[i] = { [currMont]: 0, commuters: 0 };
    }
  }

  return filteredArr;
};
