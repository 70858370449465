<template>
  <AuthLayout>
    <ForgetPasswordMain />
  </AuthLayout>
</template>

<script>
// @ is an alias to /src
import AuthLayout from '@/layouts/AuthLayout.vue';
import ForgetPasswordMain from '@/components/auth/ForgetPasswordMain.vue';

export default {
  components: {
    AuthLayout,
    ForgetPasswordMain,
  },
};
</script>
