<template>
  <DashboardLayout>
    <StatesIndex />
    <div class="pb-16"></div>
  </DashboardLayout>
</template>

<script>
// @ is an alias to /src
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import StatesIndex from '@/components/dashboard/states/StatesIndex.vue';

export default {
  name: 'EmplyeesView',
  components: {
    DashboardLayout,
    StatesIndex,
  },
};
</script>
