<template>
  <section>
    <div v-if="rewardData" class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
      <div class="flex justify-between items-center">
        <div>
          <Popconfirm
                v-if="!isOngoing && rewardData._id"

                title="Are you sure you want to delete?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteProgram"
              >
                <Button
                  type="danger"
                  size="small"
                  title="Decline"
                  class="btn-warning rounded shadow flex items-center gap-2"
                >
                Delete
                  <PhX weight="duotone" :size="16" />
                </Button>
              </Popconfirm>
        </div>
        <div class="flex justify-end items-center">
          <p>Program:&nbsp;</p>
          <CustumSelect
            name="type"
            placeholder="select type "
            :data="rewardAllData"
            :defaultData="rewardData?._id"
            class="min-w-[250px]"
            :rules="{
              required: false,
            }"
            @setInput="setInput"
          />
        </div>
      </div>
      <div class="text-center max-w-xl my-6 flex flex-col gap-2 items-center mx-auto">
        <div>
          <img src="@/assets/icons/group-of-users.png" alt="group of users icon" class="h-28" />
        </div>
        <h2 class="text-primary-dark">Choose Reward System for Your Community</h2>
        <p>
          Gamification is an important element to motivate employees earns rewards in a friendly and
          competitive environment
        </p>
      </div>

      <!-- <CommuteBenefits /> -->
      <IncentivesReward :isOngoing="isOngoing" :hasStarted="hasStarted" />
      <RewardSystem :isOngoing="isOngoing" :hasStarted="hasStarted" />
      <WeeklyCredit :isOngoing="isOngoing" :hasStarted="hasStarted" />
      <MonthlyPrizes :isOngoing="isOngoing" :hasStarted="hasStarted" />
      <EstimatedCost :isOngoing="isOngoing" :hasStarted="hasStarted" />

      <!-- <div
        class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5 flex items-center justify-center"
      >
        <HeaderBalance vertical />
      </div>-->
    </div>
  </section>
</template>

<script>
/* eslint-disable */
// import CommuteBenefits from './reward/CommuteBenefits.vue';
// import HeaderBalance from '@/components/dashboard/CreditDetails/HeaderBalance.vue';
import dayjs from 'dayjs';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import RewardSystem from './reward/RewardSystem.vue';
import IncentivesReward from './reward/IncentivesReward.vue';
import MonthlyPrizes from './reward/MonthlyPrizes.vue';
import EstimatedCost from './reward/EstimatedCost.vue';
import WeeklyCredit from './reward/WeeklyCredit.vue';
import { REWARD } from '@/store/types';
import { ref } from 'vue';
import rewardApi from '@/services/apis/reward.api';
import { Button, message, Popconfirm } from 'ant-design-vue';
import handleResErrors from '@/utils/handleResErrors';
import { PhX } from 'phosphor-vue';

export default {
  data() {
    return {
      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'year', name: 'Yearly' },
        // { id: 'all', name: 'All Data' },
      ],
      type: 'month',
    };
  },
  methods: {
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMM DD, YYYY');
    },
    async setInput(val) {
      if (val.type && val.type.value) {
        await this.$store.commit(REWARD.SET_REWARDS_SEARCH_ID, {
          id: val.type.value,
        });
        this.$emit('close');
      }
    },
    async deleteProgram() {
      try {
        const res = await rewardApi.deleteProgram(this.rewardData._id);
        message.success('Program deleted successfully');
          await this.$store.commit(REWARD.SET_REWARDS_SEARCH_ID, {
          id: '',
        });
        this.$emit('close');
      } catch (error) {
        message.error(handleResErrors(error), 10);
      } finally {
        // this.statusLoading = false;
      }
    }
  },
  components: {
    RewardSystem,
    PhX,
    IncentivesReward,
    Button,
    MonthlyPrizes,
    EstimatedCost,
    Popconfirm,
    WeeklyCredit,
    CustumSelect,
  },
  computed: {
    rewardData() {
      return this.$store.getters.rewardSettingsData;
    },
    rewardAllData() {
      let values = this.$store?.getters?.rewardSettingsAllData?.data
        ?.filter((item) => item)
        ?.map((v) => {
          if (v.start_date && v.end_date) {
            return { id: v._id, name: `${this.getDate(v.start_date)} - ${this.getDate(v.end_date)}` };
          } else {
            return { id: v._id, name: `No defined date` };
          }
        });
      values?.push({ id: 'New program', name: 'New program' });
      console.log(this.isOngoing, this.hasStarted);
      return values;
    },
    isOngoing() {
      const endData = this.rewardData.end_date;
      const startDate = this.rewardData.start_date;

      if (!endData || !startDate) return false;

      const endDateVal = dayjs(endData);
      const startDateVal = dayjs(startDate);

      const today = dayjs();
      console.log(today.isBefore(endDateVal), today.isAfter(startDateVal));
      const isBefore = today.isBefore(endDateVal) || today.isSame(endDateVal);
      const isAfter = today.isAfter(startDateVal) || today.isSame(startDate);

      const isOngoing = isBefore && isAfter;
      const isEnded = isAfter && !isBefore;
      
      if (isOngoing && localStorage.getItem('is_signas')) {
        return false;
      }

      return isOngoing || isEnded;
    },
    hasStarted() {
      /* eslint-disable */
      // const startDate = this.rewardData.start_date;
      // if (!startDate) return true;

      // const startDateVal = dayjs(startDate);
      // const today = dayjs();

      // const isAfter = today.isAfter(startDateVal) || today.isSame(startDate);

      return true;
    }
  },
};
</script>
