<template>
  <section class="mt-3">
    <div class="w-full flex flex-wrap items-center justify-center text-center">
      <h2>Choose Your Subscription Plan</h2>
    </div>
    <Spin :spinning="subscriptionsQuery && subscriptionsQuery.isLoading">
      <div class="mt-4">
        <div class="max-w-lg mx-auto">
          <Alert
            description="You will be notified before we charge you. You will be able to upgrade/ change your subscription plan at any time."
            type="info"
            class="hidden"
            show-icon
          />
          <h3 class="mb-2 mt-4 text-lg text-center text-primary-dark">Number of Employees:</h3>

          <div>
            <p
              class="text-sm text-center bg-bg border border-solid border-bg-form max-w-[8rem] mx-auto py-2"
            >{{totalEmployees}}</p>
          </div>
        </div>

        <!-- <PlasFeatures /> -->
        <AccountPlans />
      </div>
    </Spin>
    <SubscriptionsQuery />
  </section>
</template>

<script>
import { Alert, Spin } from 'ant-design-vue';

import SubscriptionsQuery from '@/services/queries/payments/SubscriptionsQuery.vue';

import { mapMutations } from 'vuex';
import AccountPlans from './oldSubscription/AccountPlans.vue';
// import PlasFeatures from './PlasFeatures.vue';
import { PLANS } from '../../../store/types';

export default {
  components: { Alert, AccountPlans, SubscriptionsQuery, Spin },
  computed: {
    totalEmployees() {
      return this.$store.state.auth.user?.community_size || 'N/A';
    },
    subscriptionsQuery() {
      return this.$store.state.payments.subscriptionsQuery;
    },
  },
  methods: {
    ...mapMutations([PLANS.SET_TOTAL_EMPLOYEES]),
    setInput(val) {
      if (val && val.employees && val.employees.value !== this.totalEmployees) {
        this.SET_TOTAL_EMPLOYEES(val.employees.value);
      }
    },
  },
};
</script>
