<template>
  <CustumLabel
    v-if="isAdded"
    :name="name"
    :label="label"
    :error="touched && error"
    :required="rules && rules.required"
    :hideErrors="hideErrors"
    :labelTooltip="labelTooltip"
  >
    <vue-google-autocomplete
      id="map"
      ref="googleAddress"
      class="address-input"
      :placeholder="placeholder"
      @blur="handleBlur"
      @placechanged="getAddressData"
    ></vue-google-autocomplete>
  </CustumLabel>
</template>

<script>
import validation from '@/services/validation';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import CustumLabel from './CustumLabel.vue';
import CONFIG from '../../../services/config';

export default {
  components: { CustumLabel, VueGoogleAutocomplete },
  props: {
    name: String,
    label: String,
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    defaultError: String,
    hideErrors: Boolean,
    defaultData: String,
    labelTooltip: String,
    rules: {
      type: Object,
      default: null,
    },
    isTouched: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      defaultValue: null,
      error: null,
      touched: false,
      isValid: false,
      isAdded: true,
    };
  },

  created() {
    // this.configMapsApi();
    // if (this.defaultData) {
    //   this.value = this.defaultData;
    //   this.touched = true;
    // }
    this.handleValidate();
    this.setInputValues();
  },
  mounted() {
    if (this.$refs.googleAddress && this.defaultData) {
      this.$refs.googleAddress.autocompleteText = this.defaultData;
      this.touched = true;
      this.handleValidate();
      this.setInputValues();
    }
  },

  watch: {
    isTouched(val) {
      if (val) {
        this.touched = true;
      }
    },
    defaultError(val) {
      if (val) {
        this.touched = true;
        this.error = val[0].length > 1 ? val[0] : val;
      }
    },
  },
  methods: {
    configMapsApi() {
      const isScript = document.querySelector('#places_script');
      if (!isScript && !this.isAdded) {
        const mapsScript = document.createElement('script');
        const { MAPS_KEY } = CONFIG;
        mapsScript.setAttribute(
          'src',
          `https://maps.googleapis.com/maps/api/js?key=${MAPS_KEY}&libraries=places`,
        );
        mapsScript.setAttribute('id', 'places_script');
        document.head.appendChild(mapsScript);
        setTimeout(() => {
          this.isAdded = true;
        }, 300);
      }
    },
    getAddressData(address, place) {
      this.error = null;
      if (address && place) {
        let formatedAddress = place.formatted_address || '';
        const addressComps = place.address_components;
        if (addressComps && addressComps.length) {
          formatedAddress = '';
          addressComps.forEach((el, ix) => {
            if (el && el.long_name) {
              formatedAddress += el.long_name;
              if (ix < addressComps.length - 1) {
                formatedAddress += ', ';
              }
            }
          });
        }

        const data = {
          country: address.country,
          lat: address.latitude,
          lng: address.longitude,
          formated_address: formatedAddress,
        };

        this.value = data;
      }
      this.handleBlur();
    },
    handleBlur() {
      this.touched = true;
      this.handleValidate();
      this.setInputValues();
    },

    handleValidate() {
      const { error, isValid } = validation.validateRules(this.rules, { ...this.value });
      this.error = error;
      this.isValid = isValid;
    },
    setInputValues() {
      const isRequired = this.rules && this.rules.required;
      if (!isRequired && !this.value) {
        return;
      }

      this.$emit('setInput', {
        [this.name]: {
          name: this.name,
          value: this.value,
          error: this.error,
          touched: this.touched,
          isValid: this.isValid,
        },
      });
    },
  },
};
</script>
