export const COLORS = ['#41B883',
  '#bfa531',
  '#963A41',
  '#2F3061',
  '#FF7F51',
  '#3E505B',
  '#95a57c',
  '#241E4E',
  '#9a9343',
  '#bfa531',
  '#963A41',
  '#2F3061',
  '#FF7F51',
  '#3E505B',
  '#95a57c',
  '#241E4E',
  '#9a9343',

];

// drive: '#bfa531',
// carpool: '#963A41',
// transit_walk: '#241E4E',
// transit_bike: '#EC7357',
// transit_drive: '#2F3061',
// walk: '#95a57c',
// bike: '#3E505B',
// telecommute: '#797334',

export const CHART_COLORS = {
  drive: '#BA2025',
  driving: '#BA2025',
  carpool: '#EC7357',
  transit_walk: '#17BEBB',
  transit_bike: '#60BA75',
  transit_drive: '#82277B',
  transit: '#82277B',
  walk: '#3287C8',
  bike: '#048041',
  telecommute: '#797334',
};

export default COLORS;
