<template>
  <div style='overflow-x: hidden;max-width: 100%'>
      <DashboardLayout>
            <div class="container" >
              <div class="card-container">
                <div class="w-full  main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7" style='height: 100vh;'>
                  <div class="fixed-continaer" style="width: 680px;height: 180px;margin: 0 auto;">
                    <div class="terms-and-condition" >
                      <h3 style="text-align: center"> We have updated our {{agreementTitle}} of the employer portal</h3>
                    </div>
                    <br>
                    <div>
                      <div class="form-check" style="text-align: left;margin-bottom: 3px">
                        <input @change="updateAcceptanceBtn($event)" class="form-check-input" type="checkbox" value="" id="CheckedTermsAndCondition"  name='terms_and_conditions_acceptance'>
                        <!--                          eslint-disable -->
                        <label class="form-check-label" for="CheckedTermsAndCondition">
                           I confirm that I have read and agreed to the <a href="#" @click='visible = true' style="color:#0598C2;cursor: pointer;text-decoration: underline;">{{agreementTitle}} </a> of the employer portal
                        </label>
                      </div>
                      <div style='margin-top:16px;text-align: right'>
                        <Button key="submit" type="primary"  @click="acceptAgremeent"  :loading='loading' :disabled='!isConfirmed'>Accept</Button>
                      </div>
                    </div>

                    <Modal
                      :visible="visible"

                      :destroyOnClose="true"
                      wrap-class-name="empty-modal"
                      width="1000px"
                      @ok="visible = false"
                      @cancel="visible = false"
                    >
                      <div>
                        <div v-html='agreement.content'>
                        </div>
                      </div>
                      <template #footer>
                        <Button key="submit" type="primary"   @click="acceptAgremeent" :loading='loading' :disabled='!isConfirmed'>Accept</Button>
                        <Button key="back" @click="visible = false">close</Button>
                      </template>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>

      </DashboardLayout>

  </div>
  <div style='width: 100%;height: 80px;line-height: 160px;text-align: center'>
    Copyright © the Pick Path Group, LLC - 2023 (All Rights Reserved)
  </div>
</template>

<script>
// @ is an alias to /src
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import apiAxios from '@/services/apiAxios';
import { Modal, Button } from 'ant-design-vue';
// eslint-disable-next-line import/no-cycle
// import router from '@/router';

export default {
  components: {
    DashboardLayout,
    Modal,
    Button,
  },
  data() {
    return {
      agreement: {},
      visible: false,
      loading: false,
      isConfirmed: false,
    };
  },
  methods: {
    async getAgreement() {
      const { id } = this.$route.params;
      const res = await apiAxios.get(`/agreements/terms-and-conditions/${id}`);
      this.agreement = res.data.data;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    async acceptAgremeent() {
      this.loading = true;
      const { id } = this.$route.params;
      const res = await apiAxios.put(`/user/agreements/${id}/accept`);
      this.loading = false;
      console.log('res', res);
      // router.push('/');
      document.getElementById('custom-overlay').classList.remove('display-show');
      document.getElementById('custom-overlay').classList.add('display-none');

      window.location.replace('/');
    },
    updateAcceptanceBtn(e) {
      if (e.target.checked) {
        this.isConfirmed = true;
      } else {
        this.isConfirmed = false;
      }
    },
  },
  computed: {
    agreementComp() {
      return this.agreement;
    },
    agreementTitle() {
      if (this.agreementComp.type) {
        if (this.agreementComp.type === 'employer_terms_and_conditions') {
          return 'terms and conditions';
        }
        if (this.agreementComp.type === 'employer_privacy_policy') {
          return 'privacy policy';
        }
        return this.agreementComp.type.replaceAll('_', ' ');
      }
      return '';
    },
  },
  mounted() {
    document.getElementById('custom-overlay').classList.add('display-show');
    document.getElementById('custom-overlay').classList.remove('display-none');

    this.getAgreement();
  },
};
</script>
<style>
.main-card{
  margin:10px 0;
  border-color: transparent;  background-color: rgba(255, 255, 255, 0.5);
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 10001; /* Specify a stack order in case you're using a different order for other elements */
}
.fixed-continaer{
  position: relative;
  z-index: 10002;
  background-color: white;
  border-radius: 15px;
  padding: 20px;
}
.ant-modal-wrap {z-index:10003 !important;}
.empty-modal .ant-modal-body{
  padding:15px !important;
}
.display-show{
  display: block; /* Hidden by default */
}
.display-none{
  display: none; /* Hidden by default */
}
</style>
