<template>
  <!--  eslint-disable -->
  <div>
    <div class="flex items-center gap-3">
      <div style='width: 100%;text-align: end;justify-content: start' class='flex items-end gap-3'>
        <Button type="primary" @click='openAddModal'>Create Admin</Button>
      </div>
    </div>
    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table :data-source="queryData" :columns="columns" :loading="isLoading">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === '_id'">
            <span v-if="record._id" class="whitespace-nowrap">{{record._id}}</span>
          </template>
          <template v-if="column.key === 'user_name'">
            <span v-if="record.username" class="whitespace-nowrap">{{record.username}}</span>
          </template>
          <template v-if="column.key === 'actions'">
            <div class="flex items-center gap-2 justify-start">
              <Button
                type="default"
                @click="() => setCurrentAdmin(record)"
              >
                <template #icon>
                  <div class="flex items-center justify-center">
                    <PhPencil  :size="20" />
                  </div>
                </template>
              </Button>
            </div>
          </template>
        </template>
      </Table>
    </div>
    <Modal
      :visible="addAdminModal"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <CreateAdmin @cancelAddCreditModal='cancelAddAdminModal' />
    </Modal>

    <Modal
      :visible="updateAdminModal"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <UpdateAdmin @cancelAddCreditModal='cancelUpdateAdminModal' :member-admin='currentSelect'  />
    </Modal>
  </div>
</template>

<script>
/* eslint-disable  */
import { Table, Button, Modal, message } from 'ant-design-vue';
import dayjs from 'dayjs';
import { PhFileArrowDown,PhPencil } from 'phosphor-vue';



import apiAxios from '@/services/apiAdminAxios';
import CreateAdmin from '@/components/admin/users/CreateAdmin.vue';
import UpdateAdmin from '@/components/admin/users/UpdateAdmin.vue';

export default {
  components: {
    UpdateAdmin,
    CreateAdmin,
    Table,
    Button,
    PhFileArrowDown,
    Modal,
    PhPencil
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: '_id',
          key: '_id',
        },
        {
          title: 'User name',
          dataIndex: 'user_name',
          key: 'user_name',
        },
        {
          title: 'actions',
          dataIndex: 'actions',
          key: 'actions',
        }
      ],

      tableData: [
        {
          key: '1',
          createdAt: 1664882267,
          amount: 1200,
          type: 'Subscription',
          service: '01/01/23 - 01/31/23',
          remaining: 1000,
          prossesed: 1664882267,
          status: 'Partial payment',
        },
        {
          key: '2',
          createdAt: 1664882267,
          amount: 1500,
          type: 'Program cost',
          service: '01/01/23 - 01/31/23',
          remaining: 0,
          prossesed: 1664882267,
          status: 'Pending',
        },
      ],
      visible: false,
      amount: 0,
      loadingId: null,
      detailsLoading: false,
      keyC:1,
      loading:false,
      addAdminModal:false,
      currentSelect:null,
      updateAdminModal:false
    };
  },
  computed: {
    queryData() {
      return this.tableData;
    },
    isLoading(){
      return this.loading;
    }
  },
  methods: {
    getDate(t) {
      // February 18, 2022, 4:43 am
      return dayjs(t).format('MMMM DD, YYYY');
    },
    async fetchAdmins () {
      this.loading = true
      const { data } = await apiAxios.get('/admin/admins');
      console.log("data => ",data)
      this.tableData = data.data ;
      this.forceUpdate();
      this.loading = false;
    },
    forceUpdate(){
      this.keyC =+ 1
    },
    cancelAddAdminModal() {
      this.addAdminModal = false;
    },
    cancelUpdateAdminModal(){
      this.updateAdminModal=false
    },
    openAddModal(){
      this.addAdminModal = true;
    },
    setCurrentAdmin(member){
      this.currentSelect = member
      this.updateAdminModal=true
      console.log(this.currentSelect);
    }
  },
  mounted() {
    this.fetchAdmins();
  }
};
</script>
