<template>
  <section class="members-data">
    <div class="w-full mt-4 flex flex-wrap items-center justify-between">
      <div class="flex items-center gap-3 flex-wrap">
        <Spin :spinning="employersQuery && employersQuery.isLoading">
          <Select
            class="text-center min-w-[300px]"
            :value="employer_id"
            @change="handleSelectBusiness"
            allowClear
            placeholder="Select business"
          >
            <select-option
              v-for="buss in employersData"
              :key="buss._id"
              :value="buss._id"
            >{{ buss.community_name }} , {{buss.name}}</select-option>
          </Select>
        </Spin>

        <Button type="primary" @click="handleShowCreditModal">Add Credit</Button>
        <Button type="primary" @click="handleShowPaymentModal">Add Payment</Button>
      </div>

      <div class="flex items-center gap-3">
        <div>
          <date-picker v-if="type !== 'all'" :picker="type" v-model:value="dateVal" />
        </div>

        <CustumSelect
          name="type"
          placeholder="select type "
          :data="dateData"
          :defaultData="type"
          :rules="{
                  required: false,
                }"
          @setInput="setInput"
        />
      </div>
    </div>

    <QueryAlert :query="employersQuery" />
    <QueryAlert :query="walletTransactionsQuery" />

    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table
        :data-source="tableFormatedData"
        :columns="columns"
        :loading="walletTransactionsQuery && (walletTransactionsQuery.isFetching || walletTransactionsQuery.isLoading)"
        :pagination="false"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'transaction_date'">
            <span
              v-if="record.transaction_date"
              class="whitespace-nowrap"
            >{{getDate(record.transaction_date)}}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'transaction_type'">
            <span
              v-if="record.transaction_type"
              class="whitespace-nowrap"
            >{{ record.transaction_type }}</span>
            <span v-else class="whitespace-nowrap">N/A</span>
          </template>

          <template v-if="column.key === 'payment_type'">
            <span v-if="record.payment_type" class="whitespace-nowrap">{{ record.payment_type }}</span>
            <span v-else class="whitespace-nowrap">N/A</span>
          </template>

          <template v-if="column.key === 'source_type'">
            <span v-if="record.source_type" class="whitespace-nowrap">{{ record.source_type }}</span>
            <span v-else class="whitespace-nowrap">N/A</span>
          </template>

          <template v-if="column.key === 'payment_status'">
            <span v-if="record.payment_status" class="whitespace-nowrap">{{ record.payment_status }}</span>
            <span v-else class="whitespace-nowrap">N/A</span>
          </template>

          <template v-if="column.key === 'amount'">
            <span v-if="record.amount" class="whitespace-nowrap">${{ record.amount }}</span>
            <span v-else class="whitespace-nowrap">N/A</span>
          </template>
        </template>
      </Table>
      <div
        v-if="walletTransactionsData && walletTransactionsData.meta"
        class="mt-6 flex items-center justify-center"
      >
        <Pagination
          :current="walletTransactionsData?.meta?.current_page"
          :total="walletTransactionsData?.meta?.total"
          show-less-items
          :page-size="walletTransactionsData?.meta?.per_page"
          @change="handlePaginationChange"
        />
      </div>
    </div>

    <!-- query apis  -->
    <WalletTransactionsQuery
      :page="page"
      :per_page="15"
      for_employer="1"
      :employer_id="employer_id"
      :start_date="dates[0]"
      :end_date="dates[1]"
      :key="`${page}-${15}-${employer_id}-${dates[0]}-${dates[1]}`"
    />
    <EmployersQuery />

    <!-- add credit modal  -->
    <Modal
      :visible="creditModal"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="creditModal = false"
      @cancel="creditModal = false"
    >
      <div class="p-4">
        <h2>Add credit for : {{activeEmployer?.name}}</h2>
        <Row>
          <Col :span="24">
            <CustumNumberInput
          name="credit_value"
          label="Amount"
          :rules="{
            required: true,
            min: 1,
          }"
          @setInput="setInput"
        />
          </Col>
        </Row>
        <Button
          type="primary"
          block
          class="mt-4"
          :loading="addCreditLoading"
          @click="handleAddCredit"
        >Add Credit</Button>
      </div>
    </Modal>

<!--    Add Invoice Payment Modal -->
    <Modal
      :visible="paymentModal"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="paymentModal = false"
      @cancel="paymentModal = false"
    >
      <div class="p-4">
        <h2>Add Payment for : {{activeEmployer?.name}}</h2>
        <Row>
          <Col :span="24">
            <CustumCalender
              :label='"Date"'
              name="date"
              defaultFormate="MMM, D, YYYY"
              placeholder="Select Date"
              :rules="{
                required: true,
              }"
              @setInput="setInputDate"
            />
          </Col>
          <Col :span="24">
            <CustumNumberInput
              name="credit_value"
              label="Amount"
              :rules="{
            required: true,
            min: 1,
          }"
              @setInput="setInput"
            />
          </Col>
          <Col :span="24">
            <CustumFileInput
              label='Choose File'
              name="file"
              placeholder="choose file"
              accept=".png, .jpeg, .bmp, .jpg"
              :rules="{
                required: false,
                min: 1,
                max: 5
              }"
              @setInput="setFileInput"

            >
              <!-- :isTouched="isAllTouched"
              @setInput="setInput"-->
              <PhCamera :size="22" weight="duotone" />
            </CustumFileInput>
          </Col>
        </Row>
        <Button
          type="primary"
          block
          class="mt-4"
          :loading="addPaymentLoading"
          @click="handleAddPayment"
        >Add Payment</Button>
      </div>
    </Modal>
  </section>
</template>
<script>
import {
  Table,
  Button,
  Select,
  SelectOption,
  Pagination,
  DatePicker,
  Spin,
  Modal,
  message,
  Row,
  Col,
} from 'ant-design-vue';

import dayjs from 'dayjs';

import EmployersQuery from '@/services/queries/admin/employers/EmployersQuery.vue';
import WalletTransactionsQuery from '@/services/queries/admin/transactions/WalletTransactionsQuery.vue';
import QueryAlert from '@/components/shared/QueryAlert.vue';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import transactionsApi from '@/services/apis/admin/transactions.api';
import CustumCalender from '@/components/shared/form/CustumCalender.vue';
import CustumFileInput from '@/components/shared/form/CustumFileInput.vue';
import { PhCamera } from 'phosphor-vue';

import handleResErrors from '../../../utils/handleResErrors';

export default {
  components: {
    Table,
    Button,
    Modal,
    Select,
    SelectOption,
    Spin,
    QueryAlert,
    EmployersQuery,
    WalletTransactionsQuery,
    Pagination,
    DatePicker,
    CustumSelect,
    CustumNumberInput,
    Row,
    Col,
    CustumCalender,
    CustumFileInput,
    PhCamera,
  },
  data() {
    return {
      columns: [
        // {
        //   title: '#',
        //   key: 'key',
        //   dataIndex: 'key',
        // },
        {
          title: 'Transaction Date',
          dataIndex: 'transaction_date',
          key: 'transaction_date',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.transaction_date).getTime();
          //   const bDate = new Date(b.transaction_date).getTime();
          //   return bDate - aDate;
          // },
        },

        {
          title: 'Transaction Name',
          key: 'name',
          dataIndex: 'name',
          // sorter: (a, b) => {
          //   if (a.name > b.name) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Transaction Type',
          dataIndex: 'transaction_type',
          key: 'transaction_type',
          // sorter: (a, b) => {
          //   if (a.transaction_type > b.transaction_type) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: 'Payment Type',
          dataIndex: 'payment_type',
          key: 'payment_type',
          // sorter: (a, b) => {
          //   if (a.payment_type > b.payment_type) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: 'Payment Status',
          dataIndex: 'payment_status',
          key: 'payment_status',
          // sorter: (a, b) => {
          //   if (a.payment_status > b.payment_status) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          // sorter: (a, b) => {
          //   if (a.amount > b.amount) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: '',
          key: 'action',
          fixed: 'right',
        },
      ],

      tableData: [
        {
          key: '1',
          transaction_date: 1681478087456,
          name: 'Transaction Name',
          transaction_type: 't type',
          payment_type: 'payment type',
          payment_status: 'payment status',
        },

        ...Array(50)
          .fill('dd')
          .map((_, ix) => ({
            key: ix + 2,
            name: `Name ${ix + 2}`,
            transaction_date: this.getRandomInt(1420063200000, 1681478087456),
            transaction_type: 't type',
            payment_type: 'payment type',
            payment_status: 'payment status',
          })),
      ],
      page: 1,
      pageSize: 10,
      employer_id: null,
      type: 'month',
      years: [],
      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'year', name: 'Yearly' },
        // { id: 'all', name: 'All Data' },
      ],
      dateVal: null,
      creditModal: false,
      credit_value: null,
      addCreditLoading: false,
      isInvoiceBalance: false,
      paymentModal: false,
      addPaymentLoading: false,
      date: null,
      file: null,
    };
  },
  computed: {
    dates() {
      if (!this.dateVal) return [null, null];
      if (this.type === 'month') {
        const start = dayjs(this.dateVal)
          .startOf('month')
          .format('YYYY-MM-DD');
        const end = dayjs(this.dateVal)
          .endOf('month')
          .format('YYYY-MM-DD');
        return [start, end];
      }

      if (this.type === 'year') {
        const start = dayjs(this.dateVal)
          .startOf('year')
          .format('YYYY-MM-DD');
        const end = dayjs(this.dateVal)
          .endOf('year')
          .format('YYYY-MM-DD');
        return [start, end];
      }

      return [null, null];
    },
    employersQuery() {
      return this.$store.state.adminEmployers.employersQuery;
    },
    employersData() {
      return this.$store.getters.employersData?.data || [];
    },
    activeEmployer() {
      // eslint-disable-next-line no-underscore-dangle
      return this.employersData.find((e) => e._id === this.employer_id);
    },
    walletTransactionsQuery() {
      return this.$store.state.walletTransactios.walletTransactionsQuery;
    },
    walletTransactionsData() {
      return this.$store.getters.walletTransactionsData;
    },
    tableFormatedData() {
      if (!this.walletTransactionsData || !this.walletTransactionsData.data) return [];

      const data = this.walletTransactionsData.data.map((d, ix) => ({
        // eslint-disable-next-line no-underscore-dangle
        key: d._id || d.id || ix,
        transaction_date: d.datetime,
        name: d.description,
        transaction_type: d.type,
        payment_type: d.source_type,
        payment_status: d.status,
        amount: d.value,
      }));
      return data;
    },
  },
  methods: {
    handlePaginationChange(page, pageSize) {
      // , filters, sorter, data
      this.page = page;
      this.pageSize = pageSize;
    },
    getRandomInt(min, max) {
      const xmin = Math.ceil(min);
      const xmax = Math.floor(max);
      return Math.floor(Math.random() * (xmax - xmin + 1)) + min;
    },
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMM DD, YYYY');
    },

    handleSelectBusiness(val) {
      this.page = 1;
      this.employer_id = val;
    },
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
      if (val.credit_value && val.credit_value.value) {
        this.credit_value = val.credit_value.value;
      }
    },
    handleShowCreditModal() {
      if (!this.employer_id) {
        message.error('Please select a business');
        return;
      }
      this.creditModal = true;
    },
    handleShowPaymentModal() {
      if (!this.employer_id) {
        message.error('Please select a business');
        return;
      }
      this.paymentModal = true;
    },
    async handleAddCredit() {
      if (!this.credit_value || this.credit_value <= 0) {
        message.error('Please enter credit value');
        return;
      }

      if (!this.employer_id) {
        message.error('Please select a business');
        return;
      }
      this.addCreditLoading = true;

      try {
        await transactionsApi.postAddCredit({
          employer_id: this.employer_id,
          value: this.credit_value,
          invoice_balance: false,
        });

        message.success('Credit added successfully');
        if (this.walletTransactionsQuery) {
          this.walletTransactionsQuery.refetch();
        }
        this.addCreditLoading = false;
        this.creditModal = false;
      } catch (error) {
        message.error(handleResErrors(error));
      } finally {
        this.addCreditLoading = false;
      }
    },
    async handleAddPayment() {
      if (!this.credit_value || this.credit_value <= 0) {
        message.error('Please enter credit value');
        return;
      }

      if (!this.employer_id) {
        message.error('Please select a business');
        return;
      }
      if (!this.date) {
        message.error('Please select a date');
        return;
      }

      this.addPaymentLoading = true;

      const data = new FormData();
      data.append('employer_id', this.employer_id);
      data.append('date', this.updateDate(this.date));
      data.append('value', this.credit_value);
      if (this.file !== null) { data.append('images[]', this.file); }

      try {
        await transactionsApi.postAddPayment(data);

        message.success('Credit added successfully');
        if (this.walletTransactionsQuery) {
          this.walletTransactionsQuery.refetch();
        }
        this.addPaymentLoading = false;
        this.paymentModal = false;
      } catch (error) {
        message.error(handleResErrors(error));
      } finally {
        this.addPaymentLoading = false;
      }
    },
    setInputDate(val) {
      this.date = dayjs(val.date.value, 'MMM, D, YYYY');
    },
    updateDate(val) {
      return val ? dayjs(val).format('YYYY-MM-DD') : null;
    },
    setFileInput(val) {
      console.log('val.file');
      console.log(val.file);
      if (!val || !val.file || !val.file.value) return;
      // eslint-disable-next-line prefer-destructuring
      this.file = val.file.value[0] || null;
    },
  },
};
</script>
<style>
.ant-picker-dropdown{
  z-index: 100001 !important;
}
</style>
