<template>
  <div class="mt-8">
    <div class="flex flex-wrap items-center justify-between gap-4">
      <h2>{{title}}</h2>
      <div class="flex items-center gap-3">
        <date-picker :value="year" @change="setDate" picker="year" :allowClear="false" />

        <CustumSelect
          name="type"
          placeholder="select type "
          :data="typeData"
          :defaultData="1"
          :rules="{
                required: false,
              }"
          @setInput="setInput"
        />
      </div>
    </div>

    <div
      v-if="query && (query.isFetching || query.isLoading)"
      class="flex items-center justify-center py-16"
    >
      <Spin spinning size="large" />
    </div>

    <QueryAlert :query="query" />
    <div class="mt-6 min-w-[700px] overflow-x-auto" v-if="chartData">
      <MultiBarsChart
        :chartData="chartData"
        :isPercentage="type === 1"
        :filter="filter"
        :commutersData="commutersData"
      />
    </div>
  </div>
</template>

<script>
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import { DatePicker, Spin } from 'ant-design-vue';

import { MONTHES, sortAndNameMonthesAndCommuters } from '@/utils/monthes';
import QueryAlert from '@/components/shared/QueryAlert.vue';

import MultiBarsChart from '../../shared/MultiBarsChart.vue';

import { COLORS } from '../../shared/COLORS';

export default {
  components: {
    CustumSelect,
    MultiBarsChart,
    DatePicker,

    Spin,
    QueryAlert,
  },
  props: {
    queryname: String,
    gettername: String,
    year: Object,

    title: String,
    filter: String,
    commutersData: Object,
    initialData: Object,
  },

  data() {
    return {
      type: 1,
      typeData: [
        {
          id: 1,
          name: 'Percentage',
        },
        {
          id: 2,
          name: this.filter,
        },
      ],
    };
  },

  methods: {
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
    },
    setDate(y) {
      this.$emit('setDate', y);
    },
  },
  computed: {
    query() {
      return this.$store.state.stats[this.queryname];
    },
    data() {
      return this.$store.getters[this.gettername];
    },
    chartData() {
      if (!this.data) return null;

      const data = {
        labels: [...MONTHES],
        totals: [],
        datasets: [],
      };
      const initialData = this.data.initial;
      if (initialData) {
        data.labels = ['initial', ...data.labels];
      }

      Object.entries(this.data).forEach(([key, val], ix) => {
        if (key !== 'no_commuters' && key !== 'initial') {
          // const monts = sortAndNameMonthes(val.all);

          const monts = sortAndNameMonthesAndCommuters(val.all);
          const monthsVals = monts.map((m) => Object.values(m)[0]);

          let commutersVals = monts.map((m) => Object.values(m)[1] || 0);

          let dataVals = [];

          if (initialData) {
            const initialAll =
              initialData[key]?.all || initialData[key]?.all === 0
                ? initialData[key]?.all
                : initialData[key] || 0;

            dataVals = [initialAll, ...monthsVals];
            commutersVals = [
              initialData[key]?.count ? initialData[key].count : 0,
              ...commutersVals,
            ];
          } else {
            dataVals = [...monthsVals];
          }

          const dataset = {
            label: [key],
            data: [...dataVals],
            defaultData: [...dataVals],
            monthsVals,
            commutersVals,
            backgroundColor: [COLORS[ix]],
            barPercentage: 0.7,
          };
          data.datasets.push(dataset);
          data.totals.push(+val.count || 0);
        }
      });
      if (this.type === 1) {
        const arrLength = initialData ? 13 : 12;
        for (let i = 0; i < arrLength; i += 1) {
          let total = 0;
          data.datasets.forEach((obj) => {
            const xnum = obj.data[i] || 0;
            total += +xnum;
          });

          data.datasets = data.datasets.map((set) => {
            const d = [...set.data];

            d[i] = +set.data[i] ? (+set.data[i] / total) * 100 : 0;

            return {
              ...set,
              data: d,
            };
          });
        }
      }

      return data;
    },
  },
};
</script>
