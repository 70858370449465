<template>
        <TableData />
</template>
<script>
import TableData from './PaymentTable.vue';

export default {
  components: { TableData },
  data() {
    return {};
  },
  created() {},
  computed: {},
};
</script>
