<template>
  <div class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5">
    <div class="flex justify-between">
      <Checkbox v-model:checked="checked" :disabled="isOngoing && hasStarted">
        <h3
          class="text-xl text-primary-dark"
        >Monthly Prizes (Gamification, up to one prize per employee)</h3>
      </Checkbox>

      <SaveBtn
        :disabled="!isShowCheckedSaved"
        :loading="isFetching"
        @click="handleSave"
        class="mt-0"
      />
    </div>

    <div v-if="checked">
      <div class="mt-6">
        <div class="mb-4 flex flex-wrap items-center justify-center gap-4">
          <PricesCard
            text="Most Miles Biked"
            :cost="monthlyPrizes.most_miles_biked"
            color="#245829"
          >
            <PhBicycle size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                :disabled="isOngoing && hasStarted"
                class="w-20"
                :defaultValue="monthlyPrizes.most_miles_biked"
                @change="(val) => setVal(val, 'most_miles_biked')"
                :min="0"
                prefix="$"
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most Miles Walked"
            :cost="monthlyPrizes.most_miles_walked"
            color="#5271FF"
          >
            <PhPersonSimpleWalk size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                :disabled="isOngoing && hasStarted"
                class="w-20"
                :defaultValue="monthlyPrizes.most_miles_walked"
                @change="(val) => setVal(val, 'most_miles_walked')"
                :min="0"
                prefix="$"
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most Transit Trips"
            :cost="monthlyPrizes.most_transit_trips"
            color="#CF572E"
          >
            <PhBus size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                :disabled="isOngoing && hasStarted"
                class="w-20"
                :defaultValue="monthlyPrizes.most_transit_trips"
                @change="(val) => setVal(val, 'most_transit_trips')"
                :min="0"
                prefix="$"
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most Carpool Trips"
            :cost="monthlyPrizes.most_carpool_trips"
            color="#DAA14B"
          >
            <PhCar size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                :disabled="isOngoing && hasStarted"
                class="w-20"
                :defaultValue="monthlyPrizes.most_carpool_trips"
                @change="(val) => setVal(val, 'most_carpool_trips')"
                :min="0"
                prefix="$"
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most trips to the office"
            :cost="monthlyPrizes.biggest_shift_from_driving_to_green_trips"
            color="#FE679F"
          >
            <PhArrowsLeftRight size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                :disabled="isOngoing && hasStarted"
                class="w-20"
                :defaultValue="monthlyPrizes.biggest_shift_from_driving_to_green_trips"
                @change="(val) => setVal(val, 'biggest_shift_from_driving_to_green_trips')"
                :min="0"
                prefix="$"
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most Green Trips"
            :cost="monthlyPrizes.most_green_trips"
            color="#03989E"
          >
            <PhTree size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                :disabled="isOngoing && hasStarted"
                class="w-20"
                :defaultValue="monthlyPrizes.most_green_trips"
                @change="(val) => setVal(val, 'most_green_trips')"
                :min="0"
                prefix="$"
              />
            </template>
          </PricesCard>
        </div>
      </div>

      <div class="w-full flex justify-center md:justify-end">
        <SaveBtn :disabled="!isValueChanged" :loading="isFetching" @click="handleSave" />
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, InputNumber } from 'ant-design-vue';

import {
  PhTree,
  PhPersonSimpleWalk,
  PhBus,
  PhCar,
  PhBicycle,
  PhArrowsLeftRight,
} from 'phosphor-vue';
import { mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import SaveBtn from '../shared/SaveBtn.vue';
import PricesCard from '../shared/PricesCard.vue';
import { REWARD } from '../../../../store/types';

export default {
  components: {
    Checkbox,
    InputNumber,
    PricesCard,
    PhTree,
    PhPersonSimpleWalk,
    PhBus,
    PhCar,
    PhBicycle,
    PhArrowsLeftRight,
    SaveBtn,
  },
  props: {
    isOngoing: {
      type: Boolean,
      default: false,
    },
    hasStarted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,

      monthlyPrizes: {
        most_miles_biked: 25,
        most_miles_walked: 25,
        most_transit_trips: 25,
        most_carpool_trips: 25,
        biggest_shift_from_driving_to_green_trips: 25,
        most_green_trips: 25,
      },
    };
  },

  created() {
    if (this.rewardData && this.rewardData.monthly_incentives_structure) {
      this.monthlyPrizes = this.rewardData.monthly_incentives_structure;
      this.checked = this.rewardData.monthly_incentives_enabled;
    }
  },
  computed: {
    rewardData() {
      return this.$store.getters.rewardSettingsData;
    },
    isFetching() {
      return this.$store.state.reward.rewardSettingsQuery?.isFetching;
    },
    // monthlyPrizes() {
    //   return this.$store.state.reward.monthly_prizes;
    // },
    // checked() {
    //   return this.$store.state.reward.is_monthly_prizes_selected;
    // },
    isValueChanged() {
      if (this.checked && this.checked !== this.rewardData.monthly_incentives_enabled) return true;
      const defaultData = this.rewardData.monthly_incentives_structure;
      let isChanged = false;
      Object.entries(defaultData).forEach(([key, value]) => {
        if (this.monthlyPrizes[key] !== value) {
          isChanged = true;
        }
      });
      return isChanged;
    },
    isShowCheckedSaved() {
      return !this.checked && this.checked !== this.rewardData.monthly_incentives_enabled;
    },
  },
  methods: {
    ...mapMutations([REWARD.SET_MONTHLY_PRIZES_DATA, REWARD.SET_SELECTED_SYSTEM]),
    setVal(value, key) {
      // this.SET_MONTHLY_PRIZES_DATA({ value, key });
      // this.debounceSetVal(value, key, this);
      this.monthlyPrizes = {
        ...this.monthlyPrizes,
        [key]: value,
      };
    },

    debounceSetVal: debounce((value, key, app) => {
      app.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        monthly_incentives_structure: {
          ...app.rewardData.monthly_incentives_structure,
          [key]: value,
        },
        monthly_incentives_enabled: app.checked,
      });
    }, 600),
    handleSelectSystem(e) {
      this.$store.dispatch(REWARD.ACTION_SELECT_REWARD_SYS, {
        monthly_incentives_enabled: e.target.checked,
      });
      // this.SET_SELECTED_SYSTEM({
      //   type: 'is_monthly_prizes_selected',
      //   value: e.target.checked,
      // });
    },
    handleSave() {
      this.$store.dispatch(REWARD.ACTION_UPDATE_REWARD_SYS, {
        monthly_incentives_structure: {
          ...this.rewardData.monthly_incentives_structure,
          ...this.monthlyPrizes,
        },
        monthly_incentives_enabled: this.checked,
      });
    },
  },
};
</script>
