/* eslint-disable */
import { useQuery } from 'vue-query';
import apiAxios from '../../apiAdminAxios';

const fetchAdminInvoices = async ({ page, per_page, keyword, employer_id, start_date, end_date, status }) => {
  let URL = `?page=${page || 1}`;

  if (per_page) {
    URL += `&per_page=${per_page}`;
  }
  if (keyword) {
    URL += `&keyword=${keyword}`;
  }

  if (employer_id) {
    URL += `&employer_id=${employer_id}`;
  }

  if (start_date && end_date) {
    URL += `&start_date=${start_date}&end_date=${end_date}`;
  }
  if (status) {
    URL += `&status=${status}`;

  }
  const { data } = await apiAxios.get(`/admin/tax-payer-approvals${URL}`);
  return data;
};
const changeTaxApprovalStatus = async ( id, status, message = null ) => {

  const { data } = await apiAxios.put(`/admin/tax-payer-approvals/${id}`, {
      status,
      message
  });
  return data;
};

// const viewAdminInvoice = async ({ id, employer_id, details = 0 }) => {
//   // eslint-disable-next-line
//   let params = {};
//   if (details !== 0) params.detailed = details;
//   if (employer_id) params.employer_id = employer_id;

//   const res = await apiAxios.get(`/admin/invoices/${id}`, { params, responseType: 'blob' });

//   readAndDownloadFile(res.data);

//   return res;
// };

// queries
const useAdminInvoicesQuery = ({ page, per_page, keyword, employer_id, start_date, end_date, status }) => useQuery(['admin/tax-payer-approvals', page, per_page, keyword, employer_id, start_date, end_date, status], () => fetchAdminInvoices({ page, per_page, keyword, employer_id, start_date, end_date, status }), { refetchOnMount: false });

export default {
  fetchAdminInvoices,
  useAdminInvoicesQuery,
  changeTaxApprovalStatus

};
