<template>
  <section>
    <div>
      <div class="flex flex-wrap items-center justify-center md:justify-start gap-4 mb-4">
        <h2 class="text-primary-dark">Transportation Costs</h2>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 xl:gap-6 text-center">
        <TransCard color="primary" modal="parking">
          <template v-slot:icon>
            <PhCar weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Parking</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="transit-pass">
          <template v-slot:icon>
            <PhBus weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Transit Pass</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="parking-cash-out">
          <template v-slot:icon>
            <PhProhibit weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Parking Cash Out</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="vanpool-shuttle">
          <template v-slot:icon>
            <PhCarSimple weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Vanpool/Shuttle</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="guaranteed-ride-home">
          <template v-slot:icon>
            <PhHouse weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Guaranteed Ride Home</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="bike">
          <template v-slot:icon>
            <PhBicycle weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Flat Biking Benefits</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="bike">
          <template v-slot:icon>
            <PhBicycle weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Bike Sharing</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="walk">
          <template v-slot:icon>
            <PhPersonSimpleWalk weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Flat Walking Benefits</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="generic-benefits">
          <template v-slot:icon>
            <PhCurrencyDollar weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Generic Benefits</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="ride-sharing">
          <template v-slot:icon>
            <PhCar weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Ride Sharing</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="custom-rewards-using-commutrics">
          <template v-slot:icon>
            <PhCurrencyDollar weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Custom Rewards using Commutrics</h3>
          </div>
        </TransCard>

        <TransCard color="primary" modal="totalcost" noBtn>
          <template v-slot:icon>
            <PhCurrencyDollar weight="duotone" size="40" class="text-primary" />
          </template>
          <div class="text-center mt-4 flex flex-col items-center justify-center">
            <strong class="text-2xl lg:text-3xl text-center text-primary">$123</strong>
            <small class="ml-2">per month</small>
          </div>
          <div class="w-full p-3 pt-5 pb-6 text-center">
            <h3 class="text-xl">Total Cost</h3>
          </div>
        </TransCard>
      </div>
    </div>
  </section>
</template>

<script>
import {
  PhCar,
  PhCarSimple,
  PhBus,
  PhBicycle,
  PhPersonSimpleWalk,
  PhCurrencyDollar,
  PhHouse,
  PhProhibit,
} from 'phosphor-vue';
// import { Tooltip } from 'ant-design-vue';
import TransCard from './shared/TransCard.vue';

export default {
  components: {
    TransCard,
    PhCar,
    PhCarSimple,
    PhBus,
    PhBicycle,
    PhPersonSimpleWalk,
    PhCurrencyDollar,
    PhHouse,
    PhProhibit,
  },
};
</script>
