const REWARD = {
  SET_MODE_DATA: 'SET_MODE_DATA',
  SET_ACTIVE_MODE_DATA: 'SET_ACTIVE_MODE_DATA',
  SET_SIGNUP_INCENTIVES_DATA: 'SET_SIGNUP_INCENTIVES_DATA',
  SET_EMPLOYEES_NUMBER_DATA: 'SET_EMPLOYEES_NUMBER_DATA',
  SET_MAX_REWARD_NUMBER_DATA: 'SET_MAX_REWARD_NUMBER_DATA',
  SET_MONTHLY_PRIZES_DATA: 'SET_MONTHLY_PRIZES_DATA',
  SET_SELECTED_SYSTEM: 'SET_SELECTED_SYSTEM',
  SET_REWARD_SETTING_QUERY: 'SET_REWARD_SETTING_QUERY',
  SET_REWARD_SETTING_ALL_QUERY: 'SET_REWARD_SETTING_ALL_QUERY',
  SET_REWARD_DEFAULTS_QUERY: 'SET_REWARD_DEFAULTS_QUERY',
  ACTION_SELECT_REWARD_SYS: 'ACTION_SELECT_REWARD_SYS',
  ACTION_UPDATE_REWARD_SYS: 'ACTION_UPDATE_REWARD_SYS',
  SET_REWARDS_LIST_QUERY: 'SET_REWARDS_LIST_QUERY',
  SET_REWARDS_SUMMARY_QUERY: 'SET_REWARDS_SUMMARY_QUERY',
  SET_REWARDS_SEARCH_ID: 'SET_REWARDS_SEARCH_ID',
};
export default REWARD;
