<template>
  <section>
    <div>
      <div class="flex flex-wrap items-center gap-4 mb-4">
        <h2 class="text-primary-dark">Member Benefits</h2>
        <small> Since {{ sinceDate }} ~ {{ totalDays }} days</small>
      </div>
      <CardsLoader v-if="query && (query.isFetching || query.isLoading)" />
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 xl:gap-6">
        <HomeCard v-for="(emp , ix) in empData" :key="ix" :data="emp" />
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import HomeCard from './shared/HomeCard.vue';
import CardsLoader from './shared/CardsLoader.vue';

export default {
  components: {
    HomeCard,
    CardsLoader,
  },

  data() {
    return {};
  },
  computed: {
    query() {
      return this.$store.state.dash.dashStatsQuery;
    },
    data() {
      return this.$store.getters.dashStatsData;
    },
    empData() {
      if (!this.data) return null;
      return [
        [
          {
            id: 1,
            img: 'social.png',
            value: this.data.cumulative_social_benefits,
            type: 'Social Hrs.',
            title: 'Social Benefits',
            info: 'Time that commuters spent socializing in carpooling',
          },
        ],
        [
          {
            id: 1,
            img: 'save.png',
            value: this.data.cumulative_cost_savings,
            type: '$',
            title: 'Member commute Savings',
            info: 'Commute cost saved due to reduced SOV trips',
          },
        ],
        [
          {
            id: 1,
            img: 'money.png',
            value: this.data.cumulative_monetary_incentive,
            type: '$',
            title: 'Cumulative Incentives',
          },
          {
            id: 2,
            img: 'stars.png',
            value: this.data.cumulative_community_points,
            type: 'points',
            title: 'Community  Points',
            info: 'Monetary incentives that the community received',
          },
        ],
        [
          {
            id: 1,
            img: 'employee.png',
            // value: 'Excellent',
            value: this.data.cumulative_employee_retention,
            type: '',
            title: 'Employees Retention Level',
          },
        ],
      ];
    },
    sinceDate() {
      console.log(this.data);
      return dayjs(this.$store.state.auth.user?.created_at, 'YYYY-MM-DDTHH:mm:ssZ').format('MMMM YYYY');
    },
    totalDays() {
      const startDate = dayjs(this.$store.state.auth.user?.created_at, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMMM YYYY');
      const currentDate = dayjs();
      return Math.round(currentDate.diff(startDate, 'day', true));
    },
  },
};
</script>
