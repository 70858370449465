<template>
  <header class="w-full bg-bg-light shadow-md">
    <div
      class="upper__header w-full flex items-center justify-between py-3 px-4 border-0 border-b border-solid border-bg-dark"
    >
      <div class="upper__header--logo flex items-center gap-3">
        <!-- <MainSideBar /> -->
        <HeaderLogo :auth="auth" />
      </div>

      <div class="flex flex-wrap items-center gap-4 lg:gap-8">
        <HeaderBalance v-if="user && !isFixedCostPlan" />
        <HeaderDropDown v-if="user" />
      </div>
    </div>
  </header>
</template>
<script>
import HeaderLogo from '@/components/layout/shared/HeaderLogo.vue';
import HeaderDropDown from '@/components/layout/shared/HeaderDropDown.vue';
import HeaderBalance from '@/components/dashboard/CreditDetails/HeaderBalance.vue';

export default {
  components: { HeaderLogo, HeaderDropDown, HeaderBalance },
  props: {
    auth: Boolean,
  },
  data() {
    return {
      fixHeader: false,
      lastScrollTop: 0,
    };
  },
  mounted() {
    document.addEventListener('scroll', this.handleCheckScrollDirection);
  },
  computed: {
    user() {
      return this.$store.state.auth.user || null;
    },
    isFixedCostPlan() {
      return this.$store.state.auth.user.subscription?.type === 'fixed_cost';
    },
  },
  methods: {
    handleCheckScrollDirection() {
      const st = window.pageYOffset || document.documentElement.scrollTop; //
      if (st <= this.lastScrollTop && st > 50) {
        this.fixHeader = true;
      } else {
        this.fixHeader = false;
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleCheckScrollDirection);
  },
};
</script>
