<template>
  <div
    class="bg-bg border-2 border-solid py-5 rounded-md flex flex-col text-center items-center gap-4 hover:border-primary-light cursor-pointer relative"
    :class="`${isActivePlan ? 'border-primary' : 'border-bg-dark'}`"
  >
    <!-- @click="this.handleActivePlan" -->
    <h4 class="text-2xl">{{data.title}}</h4>
    <!-- <p>${{formateNumer(monthlyCost * data.price)}} / employee</p> -->
    <p>${{formateNumer( data.price)}} / employee</p>

    <h5 v-if="data.sale" class="text-xl text-primary">{{Math.round(data.sale)}}% savings</h5>
    <h5 v-else class="text-xl text-primary">{{data.offer}}</h5>
    <slot />

    <div v-if="!isActivePlan && !hasActivePlan" class="w-full bg-green-900 bg-opacity-5 py-4 px-3">
      <Button
        type="primary"
        ghost
        class="px-2 text-sm"
        @click="handleSubscribe"
      >Try Commutrics 1 month for free</Button>
      <p class="text-xs opacity-75 mt-2">No Contract, you can cancel anytime</p>
    </div>
    <!-- <Button type="primary" class="btn-gradient" size="large" @click="handleOpenModal">Customize</Button> -->
    <Button
      v-if="!isActivePlan && !hasActivePlan"
      type="primary"
      class="btn-gradient"
      size="large"
      :loading="loading"
      @click="handleSubscribe"
    >Subscribe</Button>

    <Popconfirm
      v-if="!isActivePlan && hasActivePlan"
      :title="`Are you sure you want to change your plan from (${getPlanName(activePlan)}) to (${getPlanName(data.title)})?`"
      ok-text="Yes"
      cancel-text="No"
      @confirm="handleSubscribe"
    >
      <Button
        type="primary"
        class="btn-gradient"
        size="large"
        :loading="loading"
      >Change to this plan</Button>
    </Popconfirm>

    <Button
      v-if="isActivePlan && !isCancelled"
      type="primary"
      class="btn-light mt-auto"
      size="large"
    >Ongoing</Button>

    <!-- TODO: if cancelled "Current plan will end on (end date)" -->

    <Button
      v-if="isCancelled"
      type="primary"
      class="btn-gradient mt-auto"
      size="large"
      danger
    >Cancelled</Button>

    <small v-if="!hasActivePlan">{{data.note}}</small>
    <small v-if="isCancelled">{{`Current plan will end on (${cancelDate})`}}</small>
    <small v-if="isActivePlan &&  !isCancelled">{{planDate}}</small>

    <Popconfirm
      v-if="isActivePlan && !isCancelled"
      :title="`“Are you sure you want to cancel your current plan” Your current plan will end at the end of the cycle.`"
      ok-text="Cancel"
      cancel-text="No"
      @confirm="handleCancel"
    >
      <Button
        v-if="isActivePlan"
        type="primary"
        class="btn-gradient mt-auto"
        size="large"
        :loading="loading"
        ghost
        danger
      >Cancel</Button>
    </Popconfirm>

    <Radio :checked="isActivePlan" class="absolute scale-150 -bottom-3" />
  </div>
</template>

<script>
import { Button, Radio, message, Popconfirm, notification } from 'ant-design-vue';
import { mapMutations } from 'vuex';
import dayjs from 'dayjs';
import subscriptionsApi from '@/services/apis/subscriptions.api';
import { PLANS, AUTH } from '../../../store/types';
import paymentsApi from '../../../services/apis/payments.api';
import handleResErrors from '../../../utils/handleResErrors';

export default {
  components: { Button, Radio, Popconfirm },
  props: {
    data: Object,
    employeesNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    activePlan() {
      // subscriptions
      const { user } = this.$store.state.auth;
      if (user?.subscriptions) {
        console.log('user?.subscriptions', user?.subscriptions);
        return user.subscriptions;
      }
      return this.$store.state.auth.user?.subscription_plan;
    },
    hasActivePlan() {
      return !!this.activePlan;
    },
    isActivePlan() {
      if (this.data.id === this.activePlan && this.$store.state.auth.user.subscription?.type !== 'fixed_cost') return true;
      return false;
    },
    isCancelled() {
      if (!this.isActivePlan) return false;
      const { user } = this.$store.state.auth;
      if (!user) return false;

      return user?.subscription?.cancel_at_period_end || false;
    },
    cancelDate() {
      if (!this.isCancelled) return null;
      const dateVal = this.user?.subscription?.current_period_end;
      if (!dateVal) return null;
      return dayjs(new Date(dateVal * 1000)).format('MMM DD, YYYY');
    },
    monthlyCost() {
      return this.$store.getters.monthlyCost;
    },
    planDate() {
      if (!this.isActivePlan) return null;
      const { user } = this.$store.state.auth;
      if (user?.subscription.current_period_start && user?.subscription.current_period_end) {
        const start = dayjs(new Date(user.subscription.current_period_start * 1000)).format(
          'MMM DD, YYYY',
        );
        const end = dayjs(new Date(user.subscription.current_period_end * 1000)).format(
          'MMM DD, YYYY',
        );

        return `Current plan:  ${start} to ${end}`;
      }
      return null;
    },
    subscriptionsQuery() {
      return this.$store.state.payments?.subscriptionsQuery;
    },
    userQuery() {
      return this.$store.state.auth?.userQuery;
    },
  },
  methods: {
    ...mapMutations([PLANS.SET_ACTIVE_PLAN]),
    handleOpenModal() {
      this.$emit('openModal', this.id);
    },
    formateNumer(num) {
      return Math.round(num * 100) / 100;
    },
    handleActivePlan() {
      this.SET_ACTIVE_PLAN(this.data.id);
    },
    getPlanName(key) {
      if (key === 'month') return 'Monthly';
      if (key === 'half_year') return 'Semi-annual';
      if (key === 'year') return 'Annual';
      return key;
    },
    async handleSubscribe() {
      this.loading = true;
      try {
        const res = await subscriptionsApi.launchNewProgram({
          subscription_type: 'ongoing',
          billing_interval: this.data.id,
          billing_interval_count: 1,
          number_of_employees: 29,
        });
        if (res && res.url) {
          notification.open({
            type: 'success',
            message: 'Success Operation.',
            description: 'Your subscription has updated.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          // redirect to payment page
          setTimeout(() => { window.location.replace(res.url ?? '/dashboard/account'); }, 2000);
        } else {
          notification.open({
            type: 'success',
            message: 'Success Operation.',
            description: 'Your subscription has updated, Trial period',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          setTimeout(() => { this.refreshData(); }, 1000);
        }
      } catch (error) {
        message.error(handleResErrors(error));
      }
      this.loading = false;
    },
    async handleCancel() {
      this.loading = true;
      try {
        const res = await paymentsApi.postCancel();
        if (res && res.url) {
          // reload page
          window.location.reload();
        }
        this.refreshData();
      } catch (error) {
        message.error(handleResErrors(error));
      }
      this.loading = false;
    },
    refreshData() {
      if (this.subscriptionsQuery) {
        this.subscriptionsQuery.refetch();
      }
      if (this.userQuery) {
        this.userQuery.refetch();
      }

      this.$store.dispatch(AUTH.ACTION_SET_USER_DATA, { refetch: true });
    },
  },
};
</script>
