<template>
  <div class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5">
    <div class="flex justify-between">
      <Checkbox v-model:checked="checked">
        <h3 class="text-xl text-primary-dark">Weekly credit to employees (random selection )</h3>
      </Checkbox>
      <!--
      <SaveBtn
        :disabled="!isShowCheckedSaved"
        :loading="isFetching"
        @click="handleSave"
        class="mt-0"
      />-->
    </div>
    <div v-if="checked">
      <div class="mt-6">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
          <div class="text-center flex flex-wrap items-center justify-center gap-2">
            <span class="font-bold">Amount</span>
            <InputNumber
              disabled
              class="w-20"
              :value="weekly_credit_cost"
              :min="0"
              prefix="$"
              :step="0.5"
            />
            <!-- @change="(val) => setVal(+val, 'value')" -->
            <span class="font-bold">~ 4 winners per month</span>
          </div>

          <div>
            <Spin :spinning="false">
              <RadioGroup
                :value="weekly_credit_structure.type"
                @change="(e) => setVal(e.target.value, 'type')"
                class="flex flex-col gap-1"
              >
                <Radio value="random_reporter">Commuters that report any commute trips</Radio>
                <Radio value="random_green_reporter">Commuters that report only green commute trips</Radio>
                <Radio
                  value="random_full_week_reporter"
                >Commuters that report full week of commute trips</Radio>
              </RadioGroup>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, InputNumber, Radio, RadioGroup, Spin } from 'ant-design-vue';

export default {
  components: {
    Checkbox,
    InputNumber,
    Radio,
    RadioGroup,
    Spin,
  },
  props: {
    weekly_credit_cost: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      checked: true,
      radioValue: 1,
      weekly_credit_structure: {
        value: 20,
        type: 'random_reporter',
      },
    };
  },
  created() {},

  methods: {
    setVal(val, key) {
      // this.SET_SIGNUP_INCENTIVES_DATA(val);

      this.weekly_credit_structure = {
        ...this.weekly_credit_structure,
        [key]: val,
      };
      // this.debounceSetVal(val, key, this);
    },
  },
};
</script>
