import { useQuery } from 'vue-query';
import readAndDownloadFile from '@/utils/downloadFile';
import apiAxios from '../../apiAdminAxios';

const fetchAdminInvoices = async ({ page, per_page, keyword, employer_id, start_date, end_date }) => {
  let URL = `?page=${page || 1}`;

  if (per_page) {
    URL += `&per_page=${per_page}`;
  }
  if (keyword) {
    URL += `&keyword=${keyword}`;
  }

  if (employer_id) {
    URL += `&employer_id=${employer_id}`;
  }

  if (start_date && end_date) {
    URL += `&start_date=${start_date}&end_date=${end_date}`;
  }
  const { data } = await apiAxios.get(`/admin/invoices${URL}`);
  return data;
};

const viewAdminInvoice = async ({ id, employer_id, details = 0 }) => {
  // eslint-disable-next-line
  let params = {};
  if (details !== 0) params.detailed = details;
  if (employer_id) params.employer_id = employer_id;

  const res = await apiAxios.get(`/admin/invoices/${id}`, { params, responseType: 'blob' });

  readAndDownloadFile(res.data);

  return res;
};

// queries
const useAdminInvoicesQuery = ({ page, per_page, keyword, employer_id, start_date, end_date }) => useQuery(['admin/invoices', page, per_page, keyword, employer_id, start_date, end_date], () => fetchAdminInvoices({ page, per_page, keyword, employer_id, start_date, end_date }), { refetchOnMount: false });

export default {
  fetchAdminInvoices,
  viewAdminInvoice,
  // queries
  useAdminInvoicesQuery,

};
