import { PLANS } from '../types';

export default {
  state: {
    plans: [
      {
        id: 1,
        title: 'Monthly',
        price: 1,
        offer: 'Pay as you go',
        note: 'Plan is renewed every month until cancelled ',

      },
      {
        id: 2,
        title: 'Semi-annual',
        price: 4.007,
        offer: '33% savings',
        note: 'Plan is renewed every 6 months until cancelled ',

      },
      {
        id: 3,
        title: 'Annual',
        price: 7.012,
        offer: '42% savings',
        note: 'Plan is renewed every year until cancelled',

      },
    ],
    features: [
      {
        id: 1,
        title:
          'Detailed reporting for employer and employee benefits on cost/saving, time, emissions, and health benefits',
        cost: '1.00',
        selected: true,
      },
      {
        id: 2,
        title: 'Transportation stats for employer and employees',
        cost: '0.5',
        selected: true,
      },
      {
        id: 3,
        title: 'My commute options for employees and self-report trips',
        cost: '0.49',
        selected: true,
      },
      {
        id: 4,
        title: 'Customized reward systems',
        cost: '1.0',
        selected: true,
      },
      {
        id: 5,
        title: 'Top performing members based on employer objectives ',
        cost: '0.25',
        selected: true,
      },
      {
        id: 6,
        title: 'Carpool recommendations and monetary transaction between employees',
        cost: '0.75',
        selected: true,
      },
      {
        id: 7,
        title:
          'Commute recommendations on reducing cost, time, and emissions for employer and employees',
        cost: '1.0',
        selected: true,
      },
    ],

    totalEmployees: 100,
    activePlan: 3,

  },
  getters: {
    monthlyCost(state) {
      let total = 0;
      state.features.forEach((el) => {
        if (el.selected) {
          total += +el.cost;
        }
      });
      return total;
    },
    plansTotalCost(state) {
      let monthCost = 0;
      state.features.forEach((el) => {
        if (el.selected) {
          monthCost += +el.cost;
        }
      });
      let planPercentage = 1;
      state.plans.forEach((p) => {
        if (p.id === state.activePlan) {
          planPercentage = p.price;
        }
      });

      return Math.round(monthCost * planPercentage * state.totalEmployees * 100) / 100;
    },
  },
  mutations: {
    [PLANS.SET_FEATURES_DATA](state, payload) {
      state.features = payload;
    },
    [PLANS.SET_TOTAL_EMPLOYEES](state, payload) {
      state.totalEmployees = payload;
    },
    [PLANS.SET_ACTIVE_PLAN](state, payload) {
      state.activePlan = payload;
    },

  },
  actions: {
  },

};
