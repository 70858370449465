<template>
  <div class="flex flex-col gap-3 p-4 py-6 items-center justify-center rounded">
    <h3 class="text-xl">Notify All Employees</h3>

    <div class="mt-6 bg-bg-dark p-3 rounded text-center md:text-left">
      <div>
        <h4>
          Please Upload your employees
          <strong>email</strong> addresses
        </h4>
        <p>Use the following template to notify all employees of the transportation program.</p>
      </div>
      <div class="flex flex-wrap items-center justify-center md:justify-between gap-3 mb-2">
        <small>(please follow the template format)</small>
        <Button type="link" class="px-0">
          <a class="!flex items-center gap-2" href="/notification_emails_template.xlsx">
            <span>Download email addresses template</span>
            <PhDownload weight="duotone" size="18" />
          </a>
        </Button>
      </div>
      <CustumFileInput
        name="file"
        :defaultData="defaultData? defaultData.file : null"
        placeholder="choose file"
        accept=".csv, .xlsx, .xls, .xlsm, .xltx, .xltm, .xlsb"
        :rules="{
                required: false,
                min: 1,
                max: 1
              }"
        @setInput="setInput"
      >
        <!-- :isTouched="isAllTouched"
        @setInput="setInput"-->
        <PhFileXls :size="22" weight="duotone" />
      </CustumFileInput>
    </div>

    <div>
      <Button
        type="primary"
        class="btn-info w-full md:w-auto px-6"
        size="small"
        @click="handleShowEmailModal"
      >Preview email</Button>
    </div>

    <div class="mt-3 flex flex-wrap items-center justify-center gap-4">
      <Button
        type="primary"
        class="btn-light w-full md:w-auto"
        size="large"
        @click="() => $emit('close')"
      >Cancel</Button>
      <Button
        type="primary"
        class="px-6 w-full md:w-auto"
        size="large"
        :loading="isLoading"
        :disabled="isLoading || !file"
        @click="handleNotifyAll"
      >Notify All Employees</Button>
    </div>

    <div v-if="resData?.count">
      <h4>Number of email addresses: {{resData?.count}}</h4>

      <div
        class="bg-red-300 p-4 mt-3 rounded-sm max-h-[350px] overflow-y-auto"
        v-if="resData?.failed_emails && resData?.failed_emails.length"
      >
        <h4 class="mb-3">Failed email addresses:</h4>
        <ul>
          <li v-for="email in resData?.failed_emails" :key="email">{{email}}</li>
        </ul>
      </div>
    </div>

    <Modal
      :visible="visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      width="1000px"
      @ok="visible = false"
      @cancel="visible = false"
    >
      <div>
        <iframe
          :src="`${apiUrl}/reward-settings/notify-all-view?token=${token}`"
          frameborder="0"
          width="100%"
          style="min-height: 75vh;"
          title="preview email"
        ></iframe>
      </div>
    </Modal>
  </div>
</template>

<script>
import CustumFileInput from '@/components/shared/form/CustumFileInput.vue';
import { PhDownload, PhFileXls } from 'phosphor-vue';
import { Button, message, notification, Modal } from 'ant-design-vue';
import { getToken } from '@/services/jwt.service';
import rewardApi from '@/services/apis/reward.api';

export default {
  components: {
    CustumFileInput,
    PhDownload,
    PhFileXls,
    Button,
    Modal,
  },
  data() {
    return {
      isLoading: false,
      file: null,
      visible: false,
      token: null,
      resData: null,
    };
  },
  created() {
    this.token = getToken();
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API || 'https://dev.api.commutrics.com/api';
    },
  },
  methods: {
    async handleNotifyAll() {
      // this.$emit('close');
      if (!this.file) {
        message.error('Please Upload your employees email addresses file');
        return;
      }
      this.resData = null;
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append('file', this.file);
        const res = await rewardApi.uploadNotifyFile(formData);

        this.resData = res;
        this.file = null;

        // this.$emit('close');
        //
      } catch (error) {
        notification.error({
          message: error.message,
          description: error?.response?.data?.message || error.message || 'Something went wrong',
          duration: 6,
        });
      }
      this.isLoading = false;
    },
    setInput(val) {
      if (!val || !val.file || !val.file.value || !val.file.value.length) return;
      // eslint-disable-next-line prefer-destructuring
      this.file = val.file.value[0];
    },
    handleShowEmailModal() {
      this.visible = true;
    },
  },
};
</script>
