import { useQuery } from 'vue-query';
import apiAxios from '@/services/apiAdminAxios';

const postLogin = async (body) => {
  const { data } = await apiAxios.post('/admin/authenticate', body);
  return data;
};

const updateUser = async (body) => {
  const { data } = await apiAxios.put('/users/me', body);
  return data;
};

const getUser = async () => {
  const { data } = await apiAxios.get('/user');
  return data;
};

const getMembers = async () => {
  const { data } = await apiAxios.get('/members');
  return data;
};

const useUserQuery = () => useQuery(['user'], getUser);

export default {
  postLogin,

  getUser,
  getMembers,
  updateUser,
  // querirs
  useUserQuery,
};
