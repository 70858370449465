<template>
  <div v-if="calcChartData" class="bar-chart min-w-[700px] overflow-x-auto">
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="[  insideTextPlugin , chartLinesPlugin ]"
      :css-classes="cssClasses"
      :styles="styles"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    label: String,
    chartData: Array,
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
            ticks: {},
          },
          y: {
            stacked: true,
            text: ' helll ',
            ticks: {
              callback(value) {
                // for a value (tick) equals to 8
                return `${value}%`;
                // 'junior-dev' will be returned instead and displayed on your chart
              },
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              font: {
                size: 16,
              },
            },
          },
        },
        layout: {
          padding: {
            top: 40,
            bottom: 20,
            left: 40,
          },
        },
      },
    };
  },
  computed: {
    calcChartData() {
      if (!this.chartData || !this.chartData.datasets) {
        return null;
      }

      const dataArrs = this.chartData.datasets.map((d) => d.data);
      if (!dataArrs && !dataArrs[0]) {
        return null;
      }
      const dataLength = dataArrs.length;
      const percArrs = new Array(dataLength).fill([]);
      for (let i = 0; i < dataLength; i += 1) {
        let total = 0;
        dataArrs.forEach((arr) => {
          total += arr[i];
        });
        dataArrs.forEach((arr) => {
          percArrs[i].push((arr[i] / total) * 100);
        });
      }

      const newDataSets = this.chartData.datasets.map((d, ix) => {
        const nd = { ...d };
        nd.data = percArrs[ix];
        return nd;
      });

      return { ...this.chartData, datasets: newDataSets };
    },
    insideTextPlugin() {
      return {
        id: 'insideTextPlugin',
        afterDraw(chart) {
          const {
            ctx,
            // eslint-disable-next-line no-unused-vars
            chartArea: { top, bottom, left, right, width, height },
          } = chart;

          chart.data.datasets.forEach((dataset, ix) => {
            chart.getDatasetMeta(ix).data.forEach((datapoints, inx) => {
              const { x, y } = datapoints.tooltipPosition();
              const topPos = datapoints.height / 2;
              ctx.font = '0.85rem sans-serif';
              ctx.fillStyle = 'white';
              ctx.textBaseline = 'middle';
              ctx.textAlign = 'center';
              ctx.fillText(`${dataset.data[inx]}%`, x, y + topPos);
            });
          });

          ctx.restore();

          ctx.save();
        },
      };
    },
    chartLinesPlugin() {
      return {
        id: 'chartLinesPlugin',
        afterDraw(chart) {
          const {
            ctx,
            // eslint-disable-next-line no-unused-vars
            chartArea: { top, bottom, left, right, width, height },
          } = chart;

          const barsPoints = [];
          chart.data.datasets.forEach((dataset, ix) => {
            const barPoints = [];
            // eslint-disable-next-line no-unused-vars
            chart.getDatasetMeta(ix).data.forEach((datapoints, inx) => {
              const { x, y } = datapoints.tooltipPosition();
              const halfWidth = datapoints.width / 2;

              barPoints.push({ l: [x - halfWidth, y], r: [x + halfWidth, y] });
            });
            barsPoints.push(barPoints);
          });
          // const arrLastIx = barsPoints.length - 1;
          barsPoints.forEach((points) => {
            const pointsLastIndex = points.length - 1;

            points.forEach((point, ix) => {
              if (ix !== pointsLastIndex) {
                ctx.fillStyle = 'gray';
                ctx.fillRect(points[ix].r[0], points[ix].r[1], 2, 2);
                // draw line
                ctx.beginPath();
                ctx.moveTo(points[ix].r[0], points[ix].r[1]);
                ctx.lineTo(points[ix + 1].l[0], points[ix + 1].l[1]);
                ctx.strokeStyle = 'gray';
                ctx.stroke();
                ctx.fillRect(points[ix + 1].l[0], points[ix + 1].l[1], 2, 2);
              }
            });
          });

          ctx.restore();

          ctx.save();
        },
      };
    },
  },
};
</script>

<style scoped>
.bar-chart canvas {
}
</style>
