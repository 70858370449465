import { ADMIN_TAX_APPROVAL } from '../../types';

export default {
  state: {
    adminTaxesApprovalQuery: null,

  },
  getters: {
    adminTaxesApprovalData(state) {
      if (!state.adminTaxesApprovalQuery) {
        return null;
      }
      return state.adminTaxesApprovalQuery.data || null;
    },
  },
  mutations: {
    [ADMIN_TAX_APPROVAL.SET_TAX_APPROVAL](state, payload) {
      state.adminTaxesApprovalQuery = payload;
    },
  },
  actions: {

  },

};
