<template>
  <div v-if='!isLoading'>
    <div class="flex items-center gap-4 justify-between">
      <p
        class="text-sm flex-1"
      >Select and Customize your transportation program. The tool will calculate your monthly cost per employee.</p>
    </div>
    <div class="flex items-center gap-1 mt-5">
      <h3 class="text-base text-center text-primary-dark">Number of Employees:</h3>
      <div>
        <!-- <p class="text-sm text-center bg-bg border border-solid border-bg-form mx-auto py-2 px-4">
          100
        </p>-->
        <InputNumber class="w-20" v-model:value="numberOfEmpData" :min="1"  :disabled="true"/>
      </div>
      <div style='align-self: center;margin-left: 5px' v-if='isProgramStatusActive'>
        <upDownGradeButtons @upgradeEmployees='upgradeEmployeesDialog' @downgradeEmployees='downgradeEmployeesDialog' :program='program' />
      </div>
    </div>
    <div class="flex items-center justify-center md:justify-between gap-4 mt-6">
      <div class="flex items-center gap-2">
        <h3>Monthly program cost per employee:</h3>

        <InputNumber class="w-20" disabled :value="monthly_program_cost_per_employee" :min="1" />
      </div>
      <div>
        <ProgramDates key="simple" :program="program" :radioVal='radioVal' @launchTheProgram='launchTheProgram'/>
      </div>
    </div>

    <div>
      <RewardSystem
        :mode_base_vals="mode_base_vals"
        :max_monthly_reward="max_monthly_reward"
        @setModeBaseVals="vals => mode_base_vals = vals"
        @setMaxMonVal="val => max_monthly_reward = val"
        @isRewardSystemChecked='isRewardSystemChecked'
        :is-view-only='true'
      />
      <WeeklyCredit
        :weekly_credit_cost="weekly_credit_cost"
        @setCreditCostVal="val => weekly_credit_cost = val"
        @isWeeklyCreditChecked='isWeeklyCreditChecked'
        :is-view-only='true'
      />
      <MonthlyPrizes
        :data="monthlyPrizes"
        @setData="vals => monthlyPrizes = vals"
        @isMonthlyPrizesChecked='isMonthlyPrizesChecked'
        :is-view-only='true'
      />

      <div class="text-center flex flex-col items-center gap-2 mt-6">
        <Button
          type="primary"
          class="btn-info px-8"
          size="large"
          @click="handleModalOpen"
        >Notify All Employees</Button>
        <small>Employees are not notified yet</small>
      </div>
    </div>

    <Modal
      :visible="visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <NotifyModal @close="handleModalClose" />
    </Modal>
    <Modal
      :visible="upgradeGradeMode"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <upDownGrade :number_of_employees='numberOfEmpData' @cancelUpDownGrade='cancelUpDownGrade' upgrade='true' />
    </Modal>
    <Modal
      :visible="downGradeMode"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      style='border-radius: 15px; min-width: 650px'
      class='upgrade-downgrade'
    >
      <upDownGrade :number_of_employees='numberOfEmpData' @cancelUpDownGrade='cancelUpDownGrade' downgrade='true' :nextBillingDate='nextBillingDate'/>
    </Modal>
  </div>
  <Skeleton v-else active ></Skeleton>
</template>

<script>
/* eslint-disable */
import { Button, Modal, InputNumber ,Skeleton } from 'ant-design-vue';

import RewardSystem from './RewardSystem.vue';
import WeeklyCredit from './WeeklyCredit.vue';
import MonthlyPrizes from './MonthlyPrizes.vue';
import NotifyModal from './shared/NotifyModal.vue';
import ProgramDates from '../shared/ProgramDates.vue';
import subscriptionsApi from '@/services/apis/subscriptions.api';
import upDownGrade from '@/components/dashboard/account/UpDownGrade.vue';
import upDownGradeButtons from '@/components/dashboard/account/UpDownGradeButtons.vue';

export default {
  components: {
    InputNumber,
    Button,
    RewardSystem,
    WeeklyCredit,
    MonthlyPrizes,
    NotifyModal,
    Modal,
    ProgramDates,
    Skeleton,
    upDownGrade,
    upDownGradeButtons

  },
  props: {
    number_of_employees: {
      type: Number,
      default: 0,
    },
    program: {
      type: Object,
      default: () => ({}),
    },
    radioVal: {
      type: Number,
      default: 0,
    },
    isDisbaled:{
      type:Boolean,
      default: true
    },
    numberOfEmp:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      visible: false,
      loading:false,
      // number_of_employees: 100,
      employer_budget: 1000,
      // prizes_cost: 100,
      weekly_credit_cost: 20,
      max_monthly_reward: 50,
      scoring_policy_enabled:true,
      weekly_credit_enabled:true,
      monthly_incentives_enabled:true,
      mode_base_vals: {
        drive: 25,
        drive_ev: 50,
        transit_drive: 200,
        bike: 50,
        telecommute: 50,
        walk: 50,
        thousand_points_incentives: 10,
        public_transit: 300,
        carpool: 100,
      },

      weekly_credit_structure: {
        value: 20,
        type: 'random_reporter',
      },

      monthlyPrizes: {
        most_miles_biked: 25,
        most_miles_walked: 25,
        most_transit_trips: 25,
        most_carpool_trips: 25,
        biggest_shift_from_driving_to_green_trips: 25,
        most_green_trips: 25,
      },
      sign_up_incentives: 5,
      numberOfEmpData:0,
      downGradeMode: false,
      upgradeGradeMode: false,
      nextBillingDate:null
    };
  },

  computed: {
    cost_per_employee() {
      return 10;
    },
    sign_up_cost() {
      return this.number_of_employees * this.sign_up_incentives;
    },
    averageEmployeeBenefit() {
      const $B$10 = this.mode_base_vals.thousand_points_incentives;
      const $B$22 = this.max_monthly_reward;
      const vals = this.mode_base_vals;
      //   drive: 25,
      const drive = Math.min((((vals.drive * 5 * 2 * $B$10) / 1000) * 30) / 7, $B$22) * 0.6676;
      // drive_ev: 50,
      const drive_ev =
        Math.min((((vals.drive_ev * 5 * 2 * $B$10) / 1000) * 30) / 7, $B$22) * 0.0066;
      // public_transit: 300,
      const public_transit =
        Math.min((((vals.public_transit * 5 * 2 * $B$10) / 1000) * 30) / 7, $B$22) * 0.0462;
      // walk: 50,
      const walk = Math.min((((vals.walk * 5 * 2 * $B$10) / 1000) * 30) / 7, $B$22) * 0.047;
      // bike: 50,
      const bike = Math.min((((vals.bike * 5 * 2 * $B$10) / 1000) * 30) / 7, $B$22) * 0.0206;
      // carpool: 100,
      const carpool = Math.min((((vals.carpool * 5 * 2 * $B$10) / 1000) * 30) / 7, $B$22) * 0.0726;
      // transit_drive: 200,
      const transit_drive =
        Math.min((((vals.transit_drive * 5 * 2 * $B$10) / 1000) * 30) / 7, $B$22) * 0.0154;
      // telecommute: 50,
      const telecommute =
        Math.min((((vals.telecommute * 5 * 2 * $B$10) / 1000) * 30) / 7, $B$22) * 0.124;

      const all =
        drive + drive_ev + public_transit + walk + bike + carpool + transit_drive + telecommute;

      return {
        drive,
        drive_ev,
        public_transit,
        walk,
        bike,
        carpool,
        transit_drive,
        telecommute,
        all,
      };
    },

    rewards_cost() {
      return this.number_of_employees * this.averageEmployeeBenefit.all;
    },
    prizes_cost() {
      return Object.values(this.monthlyPrizes).reduce((acc, val) => acc + val, 0);
    },

    weekly_credit_cost_total() {
      return (this.weekly_credit_cost * 30) / 7;
    },

    estimated_monthly_cost() {
      return this.rewards_cost + this.prizes_cost + this.weekly_credit_cost_total;
    },
    monthly_program_cost_per_employee() {
      return ((this.estimated_monthly_cost / this.number_of_employees) * 1.3).toFixed(2);
    },
    isLoading(){
      return this.loading
    },
    isProgramStatusActive(){
      return this.program.status == 'active'
    }
  },
  methods: {
    handleModalClose() {
      this.visible = false;
    },
    handleModalOpen() {
      this.visible = true;
    },
    launchTheProgram(dates) {
      this.loading=true
      subscriptionsApi.launchNewProgram({
        start_date:dates.start_date,
        end_date:dates.end_date,
        subscription_type:'fixed_cost',
        number_of_employees:this.number_of_employees,
        type_data: {
          fixed_cost_type: "points_based",
          reward_settings_data:{
            scoring_policy_enabled:this.scoring_policy_enabled,
            scoring_policy_type: "mode_based",
            scoring_policies:{
              mode_based:this.mode_base_vals
            },
            points_incentives:this.mode_base_vals.thousand_points_incentives,
            max_reward_per_employee:this.max_monthly_reward,
            signup_incentives_enabled: false,
            signup_incentives: 5,
            monthly_incentives_enabled:this.monthly_incentives_enabled,
            monthly_incentives_structure:this.monthlyPrizes,
            weekly_credit_enabled:this.weekly_credit_enabled,
            weekly_credit_structure:this.weekly_credit_structure
          }
        }
      },'/dashboard/account').then(response=>{
        this.loading=false
        console.log({ response })
        window.location.replace(response.url);
      }).catch(error=>{
        this.loading=false
        console.log({ error })
      })
    },
    isRewardSystemChecked(value){
      this.scoring_policy_enabled = value.available
    },
    isWeeklyCreditChecked(value){
      this.weekly_credit_enabled = value.available
    },
    isMonthlyPrizesChecked(value){
      this.monthly_incentives_enabled = value.available
    },
    upgradeEmployeesDialog() {
      console.log("are we working upgrade ??");
      this.downGradeMode = false;
      this.upgradeGradeMode = true;
    },
    downgradeEmployeesDialog() {
      console.log("are we working downgrade ??");
      this.upgradeGradeMode = false;
      this.downGradeMode = true;
    },
    cancelUpDownGrade() {
      this.upgradeGradeMode = false;
      this.downGradeMode = false;
    },
  },
  created() {
    console.log("this.program.type_data.reward_settings_data",this.program.type_data.reward_settings_data)
    let subscription = this.program.type_data.reward_settings_data;
    this.monthlyPrizes = subscription.monthly_incentives_structure
    console.log("this.monthly_incentives_structure",this.monthly_incentives_structure)
    this.scoring_policy_enabled = subscription.scoring_policy_enabled
    this.weekly_credit_cost = subscription.weekly_credit_structure.value
    this.max_monthly_reward = subscription.max_reward_per_employee
    this.scoring_policy_enabled = subscription.scoring_policy_enabled
    this.weekly_credit_enabled = subscription.weekly_credit_enabled
    this.monthly_incentives_enabled = subscription.monthly_incentives_enabled
    this.mode_base_vals = subscription.scoring_policies.mode_based
    // this.monthlyPrizes = this.monthly_incentives_structure
    // this.cost_per_employee = this.program.type_data.cost_per_employee
    this.numberOfEmpData = this.number_of_employees
    this.nextBillingDate = this.$store.state.auth.user?.subscription?.next_billing_date ?? null;
  }
};
</script>
