<template>
  <div  style='text-align: end'>
      <Select
        ref="select"
        v-model:value="value1"
        style="width: 160px;text-align: start"
        @focus="focus"
        @change="handleChange($event,false,false)"
        class='design-select-box'
      >
        <SelectOption value="Last Week">
          Last Week
        </SelectOption>
        <SelectOption value="Last Four Weeks">
          Last 4 weeks
        </SelectOption >
        <SelectOption value="Last Year">
          Last 12 month
        </SelectOption>
      </Select>
    </div>
  <div  v-if='!loading'>
    <div id="chart" style='margin-bottom: 10px'>
      <h4 style='display: inline-block;margin: 10px 12px'>User Engagement </h4>    <p style='display: inline-block'>{{ userLabelComputed }}</p>
      <apex-chart type="bar" height="350"  :options="chartOptionsUsers" :series="series" ></apex-chart>
      <Row>
        <Col :span='8'>
          <p style='display: flex;justify-content: left;align-items: center'><PhArrowFatLeft style='cursor: pointer' size='32' @click="handleChange('value',true,false)" /> </p>
        </Col>
        <Col :span='8'>
        </Col>
        <Col :span='8' style='text-align: end' v-if='!noForward'>
          <p  :disable='noForward' style='display: flex;justify-content: right;align-items: center'> <PhArrowFatRight style='cursor: pointer' size='32' @click="handleChange('value',false,true)" /></p>
        </Col>
      </Row>
    </div>
    <Divider />
    <div id="chart2" style='margin-bottom: 10px'>
      <h4 style='display: inline-block;margin: 10px 12px'>Commute BreakDown </h4>    <p style='display: inline-block'>{{ userLabelComputed }}</p>
      <apex-chart type="bar" height="350" :options="chartOptionsCommute" :series="series2" :dataLabels='dataLabels' :legend='legend' :colors='colors' :markers='markers'></apex-chart>
      <Row>
        <Col :span='8'>
          <p style='display: flex;justify-content: left;align-items: center'><PhArrowFatLeft style='cursor: pointer' size='32' @click="handleChange('value',true,false)" /></p>
        </Col>
        <Col :span='8'>
        </Col>
        <Col :span='8' style='text-align: end' v-if='!noForward'>
          <p  :disable='noForward' style='display: flex;justify-content: right;align-items: center'><PhArrowFatRight style='cursor: pointer' size='32' @click="handleChange('value',false,true)" /></p>
        </Col>
      </Row>
    </div>
    <Divider />
    <div id="chart3" style='margin-bottom: 10px'>
      <h4 style='display: inline-block;margin: 10px 12px'>Commuter Access </h4>     <p style='display: inline-block'>{{ userLabelComputed }}</p>
      <apex-chart type="bar" height="350" :options="chartOptionsAccess" :series="series3" :dataLabels='dataLabels' :legend='legend' :colors='colors' :markers='markers'></apex-chart>
      <Row>
        <Col :span='8'>
          <p style='display: flex;justify-content: left;align-items: center'><PhArrowFatLeft style='cursor: pointer' size='32' @click="handleChange('value',true,false)" /></p>
        </Col>
        <Col :span='8'>
        </Col>
        <Col :span='8' style='text-align: end' v-if='!noForward'>
          <p  :disable='noForward' style='display: flex;justify-content: right;align-items: center'> <PhArrowFatRight style='cursor: pointer' size='32' @click="handleChange('value',false,true)" /></p>
        </Col>
      </Row>
    </div>
  </div>
<!--  <div v-else>-->
<!--    <Skeleton active ></Skeleton>-->
<!--    <Skeleton active ></Skeleton>-->
<!--    <Skeleton active ></Skeleton>-->
<!--    <Skeleton active ></Skeleton>-->
<!--    <Skeleton active ></Skeleton>-->
<!--    <Skeleton active ></Skeleton>-->
<!--  </div>-->
  <div
    v-if='loading'
    class="flex items-center justify-center py-28 md:py-32 lg:py-36"
  >
    <Spin spinning size="large" />
  </div>
</template>

<script>
/* eslint-disable */
import { Select, SelectOption, Skeleton, Row, Col, Divider, Spin } from 'ant-design-vue';
// import dashApi from '@/services/apis/dash.api';
import dayjs from 'dayjs';
import dashApi from '@/services/apis/dash.api';
import{
  PhArrowFatLeft,
  PhArrowFatRight
} from 'phosphor-vue';
export default {
  name: 'BarChart',
  components: { Select, SelectOption, Skeleton, Row, Col, PhArrowFatLeft, PhArrowFatRight, Divider, Spin  },
  props: {
    chartData: {

    },
  },
  data() {
    return {
      series: [{
        name: 'Active Users',
        data: [44, 55, 41, 67, 22],
      }, {
        name: 'InActive Users',
        data: [13, 23, 20, 8, 13],
      }],
      series2: [{
        name: 'Office Commute',
        data: [44, 55, 41, 67, 22],
      }, {
        name: 'Telecommute',
        data: [13, 23, 20, 8, 13],
      }],
      series3: [{
        name: 'Mobile Apps',
        data: [44, 55, 41, 67, 22],
      }, {
        name: 'Website',
        data: [13, 23, 20, 8, 13],
      }],
      chartOptionsUsers: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        xaxis: {
          categories: ['Aug. 7th', 'Aug. 14th', 'Aug. 21th', 'Aug. 28th', 'Sep 4th'],
        },
        fill: {
          opacity: 1,
          colors: ['#70AD47', '#F4B183'],
        },
        legend: {
          position: 'top',
          offsetX: 0,
          offsetY: 0,
          show: true,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 0,
            customHTML: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        dataLabels: {
          enable: true,
          style: {
            colors: ['white', 'white'],
          },
        },
        colors: ['#70AD47', '#F4B183'],
      },
      chartOptionsCommute: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        xaxis: {
          categories: ['Aug. 7th', 'Aug. 14th', 'Aug. 21th', 'Aug. 28th', 'Sep 4th'],
        },
        fill: {
          opacity: 1,
          colors: ['#70AD47', '#F4B183'],
        },
        legend: {
          position: 'top',
          offsetX: 0,
          offsetY: 0,
          show: true,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 0,
            customHTML: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        dataLabels: {
          enable: true,
          style: {
            colors: ['white', 'white'],
          },
        },

        colors: ['#70AD47', '#F4B183'],
      },
      chartOptionsAccess: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        xaxis: {
          categories: ['Aug. 7th', 'Aug. 14th', 'Aug. 21th', 'Aug. 28th', 'Sep 4th'],
        },
        fill: {
          opacity: 1,
          colors: ['#70AD47', '#F4B183'],
        },
        legend: {
          position: 'top',
          offsetX: 0,
          offsetY: 0,
          show: true,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 0,
            customHTML: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        dataLabels: {
          enable: true,
          style: {
            colors: ['white', 'white'],
          },
        },
        colors: ['#70AD47', '#F4B183'],
      },
      value1: 'Last Week',
      loading:false,
      userLabel:"",
      startedWeek:{},
      startedFourWeeks:{},
      startedLastTwelveMonth:{},
      currentWeek:{},
      currentFourWeek:{},
      currentLastTwelveMonth:{},
      currentState:"Last Week",
      startDateCompare:dayjs().format('YYYY-MM-DD'),
      startDateToCompare:dayjs().format('YYYY-MM-DD'),
      endDateCompare:dayjs().format('YYYY-MM-DD'),
      endDateToCompare:dayjs().format('YYYY-MM-DD'),
    };
  },
  methods: {
    focus: () => {
      // // console.log('focus');
    },
    handleChange: function(value,goBack=false,goForward=false){
      let params = {}
      if(goBack)
        params = this.goBack(value)
      else if(goForward)
        params = this.goBack(value,true)
      else
        params = this.handleSelect(value)


      this.loading =true
      dashApi.fetchRecommendation(params)
        .then(res => {
        // // console.log("res => ", res)
        let data = res ;
        let lastIndex = data.length -1 ;
        let labels =[]
        let labelsData=[]
        let ActiveUsers=[]
        let InActiveUsers=[]
        let headerLabel = ""
        data.map((item,index)=>{
          let startLabelAsDate = dayjs(item.start_date).format('YYYY-MM-DD');
          let endLabelAsDate = dayjs(item.end_date).format('YYYY-MM-DD');

          const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
          let month = months[dayjs(startLabelAsDate).get('month')];
          let day = dayjs(startLabelAsDate).date();
          let year = dayjs(startLabelAsDate).get('year');
          if(value == 'Last Year')
            startLabelAsDate =  month
          else
            startLabelAsDate = day + ', '+ month
          let startHeaderLabel = day + ', '+ month + ', '+year
          month = months[dayjs(endLabelAsDate).get('month')];
          day = dayjs(endLabelAsDate).date();
          year = dayjs(endLabelAsDate).get('year');
          let endHeaderLabel = day + ', '+ month + ', '+year
          if(value == 'Last Year')
            endLabelAsDate = startLabelAsDate
          else
            endLabelAsDate = day + ', '+ month
          let label = ""
          if(startLabelAsDate == endLabelAsDate)
            label =   startLabelAsDate
          else
            label =   startLabelAsDate + " - " + endLabelAsDate

          if(index == 0)
            headerLabel += `${startHeaderLabel}`
          if(index == lastIndex)
            headerLabel += ` - ${endHeaderLabel}`

          day = dayjs(item.start_date).get('day');
          console.log("item.start_date =>",item.start_date)

          if(this.currentState == 'Last Week'){
            console.log("day =>",day)
            if(day == 0 || day == 6){
              // ActiveUsers.push(0)
              // InActiveUsers.push(0)
            }else{
              labels.push(label)
              ActiveUsers.push(item.active_users)
              InActiveUsers.push(item.inactive_users)
            }
          }
          else{
            labels.push(label)
            ActiveUsers.push(item.active_users)
            InActiveUsers.push(item.inactive_users)
          }

        })
        let series =  [{
          name: 'Active Users',
          data: ActiveUsers,
        }, {
          name: 'InActive Users',
          data: InActiveUsers,
        }]
        let xaxis  =  {
          categories: labels,
        }
        // // console.log("labelsData => ", labels)
        this.series = series
        this.chartOptionsUsers.xaxis = xaxis
        // this.chartOptionsUsers
        this.userLabel = headerLabel
        // this.loading =false
      }).then(res=>{
        dashApi.fetchCommuterBreakDown(params)
          .then(res => {
          // // console.log("res => ", res)
          let data = res ;
          let lastIndex = data.length -1 ;
          let labels =[]
          let labelsData=[]
          let telecommute=[]
          let non_telecommute=[]
          let headerLabel = ""
          data.map((item,index)=>{
            let startLabelAsDate = dayjs(item.start_date).format('YYYY-MM-DD');
            let endLabelAsDate = dayjs(item.end_date).format('YYYY-MM-DD');

            const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            let month = months[dayjs(startLabelAsDate).get('month')];
            let day = dayjs(startLabelAsDate).date();
            let year = dayjs(startLabelAsDate).get('year');
            if(value == 'Last Year')
              startLabelAsDate =  month
            else
              startLabelAsDate = day + ', '+ month
            let startHeaderLabel = day + ', '+ month + ', '+year
            month = months[dayjs(endLabelAsDate).get('month')];
            day = dayjs(endLabelAsDate).date();
            year = dayjs(endLabelAsDate).get('year');
            let endHeaderLabel = day + ', '+ month + ', '+year
            if(value == 'Last Year')
              endLabelAsDate = startLabelAsDate
            else
              endLabelAsDate = day + ', '+ month
            let label = ""
            if(startLabelAsDate == endLabelAsDate)
              label =   startLabelAsDate
            else
              label =   startLabelAsDate + " - " + endLabelAsDate

            if(index == 0)
              headerLabel += `${startHeaderLabel}`
            if(index == lastIndex)
              headerLabel += ` - ${endHeaderLabel}`

            day = dayjs(item.start_date).get('day');
            console.log("item.start_date =>",item.start_date)

            if(this.currentState == 'Last Week'){
              console.log("day =>",day)
              if(day == 0 || day == 6){
                // ActiveUsers.push(0)
                // InActiveUsers.push(0)
              }else{
                labels.push(label)
                telecommute.push(item.telecommute_count)
                non_telecommute.push(item.non_telecommute_count)
              }
            }
            else{
              labels.push(label)
              telecommute.push(item.telecommute_count)
              non_telecommute.push(item.non_telecommute_count)
            }
          })
          let series =  [{
            name: 'Office Commute',
            data: non_telecommute,
          }, {
            name: 'Telecommute',
            data: telecommute,
          }]
          let xaxis  =  {
            categories: labels,
          }
          // // console.log("series2 => ", labels)
          this.series2 = series
          this.chartOptionsCommute.xaxis = xaxis
          // this.chartOptionsUsers
          // this.loading =false
        }).then(res=>{
          dashApi.fetchCommuterAccess(params).then(res => {
            // // console.log("res => ", res)
            let data = res ;
            let lastIndex = data.length -1 ;
            let labels =[]
            let labelsData=[]
            let mobile_app=[]
            let website=[]
            let headerLabel = ""
            data.map((item,index)=>{
              let startLabelAsDate = dayjs(item.start_date).format('YYYY-MM-DD');
              let endLabelAsDate = dayjs(item.end_date).format('YYYY-MM-DD');

              const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
              let month = months[dayjs(startLabelAsDate).get('month')];
              let day = dayjs(startLabelAsDate).date();
              let year = dayjs(startLabelAsDate).get('year');
              if(value == 'Last Year')
                startLabelAsDate =  month
              else
                startLabelAsDate = day + ', '+ month
              let startHeaderLabel = day + ', '+ month + ', '+year
              month = months[dayjs(endLabelAsDate).get('month')];
              day = dayjs(endLabelAsDate).date();
              year = dayjs(endLabelAsDate).get('year');
              let endHeaderLabel = day + ', '+ month + ', '+year
              if(value == 'Last Year')
                endLabelAsDate = startLabelAsDate
              else
                endLabelAsDate = day + ', '+ month
              let label = ""
              if(startLabelAsDate == endLabelAsDate)
                label =   startLabelAsDate
              else
                label =   startLabelAsDate + " - " + endLabelAsDate

              if(index == 0)
                headerLabel += `${startHeaderLabel}`
              if(index == lastIndex)
                headerLabel += ` - ${endHeaderLabel}`

              day = dayjs(item.start_date).get('day');
              console.log("item.start_date =>",item.start_date)

              if(this.currentState == 'Last Week'){
                console.log("day =>",day)
                if(day == 0 || day == 6){
                  // ActiveUsers.push(0)
                  // InActiveUsers.push(0)
                }else{
                  labels.push(label)
                  website.push(item.website_count)
                  mobile_app.push(item.mobile_app_count)
                }
              }
              else{
                labels.push(label)
                website.push(item.website_count)
                mobile_app.push(item.mobile_app_count)
              }


            })
            let series =  [ {
              name: 'Website',
              data: website,
            },
              {
                name: 'Mobile Apps',
                data: mobile_app,
              },
            ]
            let xaxis  =  {
              categories: labels,
            }
            // // console.log("series3 => ", labels)
            this.series3 = series
            this.chartOptionsAccess.xaxis = xaxis
            // this.chartOptionsUsers
            this.loading =false
          })
        })
      })
    },
    handleSelect: function(value){
      this.currentState = value;

      let startDate = dayjs().format('YYYY-MM-DD');
      const day = dayjs(startDate).get('day');
      if (day == 0) startDate = dayjs(startDate).subtract(6, 'days');
      if (day == 1) startDate = dayjs(startDate).subtract(7, 'days');
      if (day == 2) startDate = dayjs(startDate).subtract(8, 'days');
      if (day == 3) startDate = dayjs(startDate).subtract(9, 'days');
      if (day == 4) startDate = dayjs(startDate).subtract(10, 'days');
      if (day == 5) startDate = dayjs(startDate).subtract(11, 'days');
      if (day == 6) startDate = dayjs(startDate).subtract(12, 'days');
      this.startDateCompare = dayjs(startDate).format('YYYY-MM-DD')
      this.startDateToCompare = dayjs(startDate).format('YYYY-MM-DD')
      this.startDateCompare = dayjs(startDate).format('YYYY-MM-DD')
      this.startDateToCompare = dayjs(startDate).format('YYYY-MM-DD')
      // console.log("day =>",day)
      // console.log("startDate =>",startDate)
      // let spare = startDate

      const newStartDate = dayjs(startDate).format('YYYY-MM-DD');
      // console.log("newStartDate =>",newStartDate)
      const endDate = dayjs(newStartDate).add(6, 'days').format('YYYY-MM-DD');
      const cloneEndDate = dayjs(endDate).format('YYYY-MM-DD');

      this.endDateCompare = dayjs(endDate).format('YYYY-MM-DD')
      this.endDateToCompare = dayjs(endDate).format('YYYY-MM-DD')
      // console.log("endDate =>",endDate)
      const startFourWeeksBack = dayjs(dayjs(cloneEndDate).subtract(27, 'days')).format('YYYY-MM-DD');
      const endFourWeeksBack = dayjs(dayjs(startFourWeeksBack).add(27, 'days')).format('YYYY-MM-DD');

      this.endDateCompare = dayjs(endFourWeeksBack).week()
      this.endDateToCompare = dayjs(endFourWeeksBack).week()

      const startTwelveMonthBack = dayjs(dayjs().subtract(1, 'year')).format('YYYY-MM-DD');
      const endTwelveMonthBack = dayjs(dayjs(startTwelveMonthBack).add(1, 'year')).format('YYYY-MM-DD');


      this.startedWeek = {
        start_date: newStartDate,
        end_date: endDate,
        group_by: 'day',
      };
      this.currentWeek = this.startedWeek
      this.startedFourWeeks = {
        start_date: startFourWeeksBack,
        end_date: endDate,
        group_by: 'week',
      };

      this.currentFourWeek = this.startedFourWeeks

      this.startedLastTwelveMonth = {
        start_date: startTwelveMonthBack,
        end_date: endTwelveMonthBack,
        group_by: 'month',
      }
      this.currentLastTwelveMonth = this.startedLastTwelveMonth

      if (value == 'Last Week') {
        return {
          start_date: newStartDate,
          end_date: endDate,
          group_by: 'day',
        };
      }
      if (value == 'Last Four Weeks') {
        return {
          start_date: startFourWeeksBack,
          end_date: endDate,
          group_by: 'week',
        };
      }
      if (value == 'Last Year') {
        return {
          start_date: startTwelveMonthBack,
          end_date: endTwelveMonthBack,
          group_by: 'month',
        };
      }
    },
    goBack: function(value,isForward=false) {
      let dateData = {}
      let newStartDate = ""
      if(this.currentState == 'Last Week'){
          dateData = this.currentWeek
          if(isForward)  newStartDate= dayjs(dayjs(dateData.start_date).add(7,'days')).format('YYYY-MM-DD');
          if(!isForward)  newStartDate= dayjs(dayjs(dateData.start_date).subtract(7,'days')).format('YYYY-MM-DD');
      }
      if(this.currentState == 'Last Four Weeks'){
          dateData = this.currentFourWeek

      }
      if(this.currentState == 'Last Year'){
          dateData = this.currentLastTwelveMonth
      }



      value = this.currentState;
      this.startDateToCompare =  dayjs(dateData.start_date).format('YYYY-MM-DD');
      // if(!isForward) newStartDate = dayjs(dateData.start_date).format('YYYY-MM-DD');
      console.log("startDateToCompare =>",this.startDateToCompare)
      console.log("startDateCompare =>",this.startDateCompare)
      console.log("============================================");
      const endDate = dayjs(newStartDate).add(6, 'days').format('YYYY-MM-DD');
      const cloneEndDate = dayjs(endDate).format('YYYY-MM-DD');
      // console.log("endDate =>",endDate)
      let startFourWeeksBack = ""
      if(isForward)  startFourWeeksBack = dayjs(dayjs(dateData.start_date).add(28, 'days')).format('YYYY-MM-DD');
      if(!isForward)  startFourWeeksBack = dayjs(dayjs(dateData.start_date).subtract(28, 'days')).format('YYYY-MM-DD');
      const endFourWeeksBack = dayjs(dayjs(startFourWeeksBack).add(21, 'days')).format('YYYY-MM-DD');

      let startTwelveMonthBack=""
      if(isForward)  startTwelveMonthBack = dayjs(dayjs(dateData.start_date).add(1, 'year')).format('YYYY-MM-DD');
      if(!isForward)  startTwelveMonthBack = dayjs(dayjs(dateData.start_date).subtract(1, 'year')).format('YYYY-MM-DD');

      const endTwelveMonthBack = dayjs(dayjs(startTwelveMonthBack).add(1, 'year')).format('YYYY-MM-DD');

      if(this.currentState == 'Last Year' && dayjs(endTwelveMonthBack).get('year') === dayjs().get('year')){
        this.startDateToCompare = this.startDateCompare
      }
      console.log("dayjs(endFourWeeksBack).week() == this.endDateCompare", dayjs(endFourWeeksBack).week() , this.endDateCompare)
      if(this.currentState == 'Last Four Weeks' && ((dayjs(endFourWeeksBack).week() +1 )== this.endDateCompare) ){
        this.startDateToCompare = this.startDateCompare
      }
      if(this.currentState == 'Last Week' ){
        console.log("are we getting here ? ")
        console.log("this.startDateCompare == newStartDate",this.startDateCompare , newStartDate)
        this.startDateToCompare =  dayjs(newStartDate).format('YYYY-MM-DD');

        if(this.startDateCompare == newStartDate){
          console.log("are we getting here 222? ")
          this.startDateToCompare = this.startDateCompare
        }
      }

      this.currentWeek = {
        start_date: newStartDate,
        end_date: endDate,
        group_by: 'day',
      };

      this.currentFourWeek = {
        start_date: startFourWeeksBack,
        end_date: endFourWeeksBack,
        group_by: 'week',
      };

      this.currentLastTwelveMonth = {
        start_date: startTwelveMonthBack,
        end_date: endTwelveMonthBack,
        group_by: 'month',
      }

      if (value == 'Last Week') {
        return {
          start_date: newStartDate,
          end_date: endDate,
          group_by: 'day',
        };
      }
      if (value == 'Last Four Weeks') {
        return {
          start_date: startFourWeeksBack,
          end_date: endFourWeeksBack,
          group_by: 'week',
        };
      }
      if (value == 'Last Year') {
        return {
          start_date: startTwelveMonthBack,
          end_date: endTwelveMonthBack,
          group_by: 'month',
        };
      }
    },

  },
  mounted() {
    this.handleChange('Last Week')
  },
  computed:{
    noForward(){
      return JSON.stringify(this.startDateCompare) === JSON.stringify(this.startDateToCompare)
    },
    userLabelComputed(){
      return this.userLabel;
    },
    seriesComputed(){
      return this.series
    },
    chartOptionsUsersComputed(){
      return this.chartOptionsUsers
    }
  }
};
</script>
<style>
.apexcharts-legend-marker{
  color: #70AD47 !important;
  background: #70AD47 !important;
}
.font-16{ font-size: 16px;}
.font-18{ font-size: 18px;}
.design-select-box{
  background-color: #6db8ff;
}
.ant-select.design-select-box{
  background-color: #6db8ff !important;
  color: white !important;
}
</style>
