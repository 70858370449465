import axios from 'axios';
import CONFIG from './config';
// import { destroyToken } from './jwt.service';

axios.defaults.baseURL = CONFIG.API_URL;

const apiAxios = axios.create({
  baseURL: CONFIG.API_URL,
  // withCredentials: true,
  // common: {
  //   Accept: 'text/plain, */*',
  //   'Access-Control-Allow-Origin': '*',
  //   'Access-Control-Allow-Methods': '*',
  //   'Access-Control-Allow-Headers': '*',
  //   'Access-Control-Allow-Credentials': 'true',
  //   'Content-Type': 'application/json',
  //   'X-Requested-With': 'XMLHttpRequest',
  // },
});

apiAxios.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status && error?.response?.status === 401) {
    // destroyToken();
    // window.location.reload();
  }
  return Promise.reject(error);
});

// const sendLogin = (data) => {
//   axios.defaults.withCredentials = true;
//   const response = axios.get(apiUrl('sanctum/csrf-cookie',
//  'backend-non-api-route')).then(response => {
//       return axios.post(apiUrl('user/login','backend-non-api-route'),data,{
//           xsrfHeaderName: 'X-CSRF-Token',
//           withCredentials: true
//         });
//   })
//   return response;
// }

// apiAxios.defaults.headers.common.Referer = 'wa-v2.grand-community.com';

export const seAdminAxiosHeader = async (token) => {
  apiAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default apiAxios;

// const ApiService = {
//   init() {
//     Vue.use(VueAxios, axios);
//     Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
//     Vue.axios.defaults.headers.common[
//       "Access-Control-Allow-Origin"
//     ] = "*"
//     Vue.axios.defaults.headers.common[
//       "Content-Type"
//     ] = "text/plain";
//   },

//   setHeader() {
//     Vue.axios.defaults.headers.common[
//       "Authorization"
//     ] = `bearer ${JwtService.getToken()}`;
//   },

//   setHeaderLang(lang: string) {
//     Vue.axios.defaults.headers.common["lang"] = lang;
//   },

//   query(resource: string, params: any) {
//     return Vue.axios.get(resource, { params: params });
//   },

//   get(resource: string) {
//     return Vue.axios.get(`${resource}`);
//   },

//   post(resource: string, params: any, headers?: any) {
//     return Vue.axios.post(`${resource}`, params, headers ? headers : {});
//   },

//   update(resource: string, slug: string, params: any) {
//     return Vue.axios.put(`${resource}/${slug}`, params);
//   },

//   put(resource: string, params: any) {
//     return Vue.axios.put(`${resource}`, params);
//   },

//   delete(resource: string) {
//     return Vue.axios.delete(resource).catch(error => {
//       throw new Error(`[RWV] ApiService ${error}`);
//     });
//   }
// };

// export default ApiService;
