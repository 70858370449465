import authModule from './auth.module';
import layoutModule from './layout.module';
import plansModule from './plans.module';
import membersModule from './members.module';
import scoresModule from './scores.module';
import statsModule from './stats.module';
import dashModule from './dash.module';
import rewardModule from './reward.module';
import paymentsModule from './payments.module';
import invoicesModule from './invoices.module';
import adminauthModule from './adminauth.module';

import adminEmployeesModule from './admin/employees.module';
import adminEmployersModule from './admin/employers.module';
import walletTransactiosModule from './admin/walletTransactios.module';
import adminInvoicesModule from './admin/adminInvoices.module';
import adminTaxesApprovalModule from './admin/taxes.module';

const modules = {
  auth: authModule,
  layout: layoutModule,
  plans: plansModule,
  members: membersModule,
  scores: scoresModule,
  stats: statsModule,
  dash: dashModule,
  reward: rewardModule,
  payments: paymentsModule,
  invoices: invoicesModule,
  adminauth: adminauthModule,
  adminEmployees: adminEmployeesModule,
  adminEmployers: adminEmployersModule,
  walletTransactios: walletTransactiosModule,
  adminInvoices: adminInvoicesModule,
  taxesApproval: adminTaxesApprovalModule,
};

export default modules;
