<template>
  <div class='upgradedowngrade' style='background-color: #C9C9C9'>
    <!--    title -->
    <div class='title mb-3 p-1 title-upgrade' >
      <!--      Changeable titles -->
      <h2 class='upgrade'>Make a payment</h2>
    </div>
    <!--    Numbers -->
    <div >

      <Row class=' mb-5' >
        <!--        Added Number -->
        <Col :md='11' class='align-self-center'>
          <h3 class="text-base text-end text-primary-dark">Pay amount:</h3>
        </Col>
        <Col :md='2'>
        </Col>
        <Col :md='11'>
          <InputNumber   @change='checkCreditFees' class="w-40 apply-align cst-back" v-model:value="inputAmount" :min="0"  :disabled='true'/>
        </Col>
      </Row>
      <Row >
        <!--        Added Number -->
        <Col :md='11' class='align-self-center'>
          <h3 class="text-base text-end text-primary-dark">Payment gateway service fee:</h3>
        </Col>
        <Col :md='2'>
        </Col>
        <Col :md='11'>
          <InputNumber   class="w-40 apply-align cst-back" v-model:value="service_fees" :min="0"  disabled/>
        </Col>
      </Row>
    </div>
    <Divider />
    <!--    Results -->
    <div class='mb-5'>
      <Row  class='mb-5'>
        <Col :md='11' class='align-self-center'>
          <h3 class="text-base text-end text-primary-dark">Total amount:</h3>
        </Col>
        <Col :md='2'>
        </Col>
        <Col :md='11'>
          <InputNumber class="w-40 apply-align"  disabled v-model:value='totalPaidFees' />
        </Col>
      </Row>
    </div>
    <!--    Buttons -->
    <div class='mb-5'>
      <Row >
        <Col :md='11' class='text-end'>
          <Button
            type="primary"
            class="btn-downgrade"
            size="medium"
            @click='cancelAddModal'
          >Cancel</Button>
        </Col>
        <Col :md='2'> </Col>
        <Col :md='11' >
          <Button
            type="primary"
            class="btn-upgrade"
            size="medium"
            @click='payCreditFees'
            :disabled='inputAmountComp == 0 ||inputAmountComp == null '
            :loading='isLoading'
          >Proceed to Payment</Button>
        </Col>
      </Row>
    </div>
    <div>
      <p> .</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Row, Col, Divider, InputNumber, Button, notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import subscriptionsApi from '@/services/apis/subscriptions.api';
import PaymentsApi from '@/services/apis/payments.api';
import paymentsApi from '@/services/apis/payments.api';
import apiAxios from '@/services/apiAxios';

export default {
  components: {
    Row,
    Col,
    Divider,
    InputNumber,
    Button,
    notification,
  },
  mounted() {
  },
  props: {
    inputAmountProp:{type:Number,default:0},
    invoice:{type:Number,default: null}
  },
  data() {
    return {
      inputAmount:0,
      disableAddButton:false,
      service_fees:0,
      loading:false
    };
  },
  created() {
    this.inputAmount = this.inputAmountProp
    this.checkCreditFees()
  },
  computed: {
    currentDate() {
      return dayjs().format('DD MMM YYYY');
    },
    totalPaidFees(){
      return (this.inputAmountComp + this.serviceFeesComp)
    },
    inputAmountComp(){
      return this.inputAmount
    },
    serviceFeesComp(){
      return this.service_fees
    },
    isLoading(){
      return this.loading
    }
  },
  methods: {
    cancelUpDownGrade() {
      this.$emit('cancelAddC');
    },
    validateNewNumber(e) {
      const value = e;
      // eslint-disable-next-line no-unused-expressions
      if (value == null) {
        this.disableAddButton = true;
      } else {
        this.disableAddButton = false;
      }
    },
    payCreditFees(amount=null){
      this.loading = true;
      this.makePayment().then((response) => {
        this.loading = false;
        notification.open({
          type: 'success',
          message: 'Success Operation.',
          description: 'You will be redirect to the payment gateway now.',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
        console.log("Response => ",response)
        setTimeout(()=>{window.location.replace(response.url ?? '/dashboard/account')},1000);
      }).catch((error) => {
        this.loading = false;
        console.log({ error });
      });
    },
    checkCreditFees(){
      this.loading = true;
      if (this.inputAmount != null){
        paymentsApi.checkAddCredit({
          amount: this.inputAmount,
        }).then((response) => {

          this.service_fees = response.service_fees
          console.log("checkAddCredit {response} => ",{ response })
          this.loading = false;
          // setTimeout(()=>{window.location.replace(response.url ?? '/dashboard/account')},1500);
        }).catch((error) => {
          this.loading = false;
          console.log({ error });
        });
      }else{
        this.loading = false;
        this.service_fees = null
      }
    },
    cancelAddModal(){
      this.$emit('cancelAddCreditModal');
    },
    async makePayment() {
      const { data } = await apiAxios.post('/stripe/payment-session',{invoice_id:this.invoice});
      return data;
    },
  },
  watch: {
    inputAmount:function (){
      if(this.inputAmount== null){
        this.service_fees = null
      }
    }
  },

};

</script>

<style>
.title{
  text-align: center;
}
.align-self-center{
  align-self: center;
}
.apply-align .ant-input-number .ant-input-number-input-wrap input.ant-input-number-input{
  background-color: #00b34f;
  text-align: center !important;
}
.apply-align.ant-input-number.ant-input-number-disabled .ant-input-number-input,
.apply-align.ant-input-number .ant-input-number-input{
  text-align: center !important;
}

.apply-align.ant-input-number.cst-back .ant-input-number-input{
  background-color: white;
}
.btn-upgrade.ant-btn-primary{
  background-color: #A5CD93;
  color: black;
  border-color: #A5CD93 !important;
}
.btn-downgrade.ant-btn-primary{
  background-color:#F3A988 ;
  color: black;
  border-color: #DA9678 !important;
  transition: all 0.5s;
}
.btn-downgrade.ant-btn-primary:hover{
  background-color:  #DA9678;
  color: white;
  border-color: #DA9678 !important;
}
.btn-downgrade.ant-btn-primary:focus{
  background-color:  #DA9678;
  color: white;
  border-color: #DA9678 !important;
}
.title ,.title h2{
  /*border-radius: 8px;*/
}
.title-upgrade{
  background-color: #63A537;
  color: white !important;
}

.title-downgrade{
  background-color: #DA9678;
  color: white !important;
}

.title{
  border-radius: 10px 10px 0 0 !important;
}
.msg-adjust{
  padding: 5px 30px 15px 30px;
}
/*.upgradedowngrade{*/
/*  border-radius: 15px;*/
/*}*/
</style>
