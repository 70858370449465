<template>
  <div>
    <div class="flex items-center justify-end gap-4 mb-6">
      <div>
        <CustumSelect
          name="spots"
          placeholder="select type "
          :data="filterData"
          :defaultData="1"
          :rules="{
            required: false,
          }"
          @setInput="setInput"
        />
      </div>
      <div>
        <CustumSelect
          name="year"
          placeholder="select type "
          :data="timeData"
          :defaultData="1"
          :rules="{
        required: false,
      }"
          @setInput="setInput"
        />
      </div>
    </div>
    <div class="flex flex-wrap gap-4 items-center justify-between">
      <h2>Incentives Structure</h2>
      <Button type="primary">Generate Recommended incentives</Button>
    </div>

    <div class="mt-4 flex flex-wrap items-start justify-between gap-4">
      <p class="flex items-center gap-5">
        Free public transit pass to all employees
        <Switch v-model:checked="freePublic" />
      </p>

      <div :class="freePublic ? 'opacity-75' : ''">
        <div class="flex flex-wrap items-center gap-2">
          Employer annual cost per employee
          <CustumNumberInput
            name="cost"
            :defaultData="0"
            placeholder=" "
            prefix="$"
            :rules="{min:0}"
            :disabled="freePublic"
            @setInput="setInput"
          />
        </div>
        <div>Total cost: $$$$</div>
      </div>
    </div>

    <div class="mt-4">
      <CostSlider title="Employee parking cost per spot:" :defaultValue="5" />
    </div>

    <div class="mt-6">
      <table class="w-full">
        <thead>
          <tr>
            <th class="text-center py-1">Fixed incentives per employee</th>
            <th class="text-center py-1">Custom Incentives</th>
            <th class="text-center py-1">Cost</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(data, ix) in incentivesData"
            :key="data.id"
            :class="ix % 2 === 0 ?'bg-bg' :''"
          >
            <td class="p-2">
              <CostSlider :title="data.title" :defaultValue="data.val" :active="!data.active" />
            </td>
            <td class="text-center">
              <Switch :checked="data.active" @change="(c) => handleSwitchChange(data.id , c)" />
            </td>
            <td class="text-center">${{data.cost}}</td>
          </tr>

          <tr>
            <td></td>
            <td class="text-right">Total cost:</td>
            <td class="text-center">$25,632</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-4 flex flex-col items-center justify-center gap-6">
      <PotentialMode />
      <div class="flex flex-wrap items-center gap-4 lg:gap-6 text-lg text-primary-dark">
        <div class="flex flex-col items-center gap-2">
          <p>Employer Savings</p>
          <span class="bg-bg-form p-2 min-w-[6rem] w-full text-center rounded-sm">$55</span>
        </div>

        <div class="flex flex-col items-center gap-2">
          <p>Employee Savings</p>

          <span class="bg-bg-form p-2 min-w-[6rem] w-full text-center rounded-sm">$5</span>
        </div>

        <div class="flex flex-col items-center gap-2">
          <p>Emissions (Kg CO2)</p>

          <span class="bg-bg-form p-2 min-w-[6rem] w-full text-center rounded-sm">111</span>
        </div>
      </div>

      <Button type="primary" size="large" class="mt-4 px-10">Adopt Commute Plan</Button>
    </div>
  </div>
</template>
<script>
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import { Button, Switch } from 'ant-design-vue';

import CostSlider from '../shared/CostSlider.vue';

import PotentialMode from '../shared/PotentialMode.vue';

export default {
  components: { CustumSelect, CustumNumberInput, Button, Switch, CostSlider, PotentialMode },
  data() {
    return {
      filterData: [
        {
          id: 1,
          name: 'Mode',
        },
        {
          id: 2,
          name: 'Mile',
        },
      ],
      timeData: [
        {
          id: 1,
          name: 'Monthly',
        },
        {
          id: 2,
          name: 'Yearly',
        },
      ],

      incentivesData: [
        {
          id: 1,
          title: 'Public transit monetary incentives:',
          val: 5,
          cost: 1234,
          active: true,
        },
        {
          id: 2,
          title: 'Carpooling monetary incentives:',
          val: 5,
          cost: 1234,
          active: false,
        },
        {
          id: 3,
          title: 'Biking monetary incentives:',
          val: 5,
          cost: 1234,
          active: false,
        },
        {
          id: 4,
          title: 'Walking monetary incentives:',
          val: 5,
          cost: 1234,
          active: true,
        },
      ],
      freePublic: true,
    };
  },
  methods: {
    handleSwitchChange(id, checked) {
      const newData = this.incentivesData.map((d) => {
        const nd = { ...d };
        if (d.id === id) {
          nd.active = checked;
        }
        return nd;
      });

      this.incentivesData = newData;
    },
  },
};
</script>
