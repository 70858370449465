import { ADMIN_EMPLOYERS } from '../../types';

export default {
  state: {
    employersQuery: null,

  },
  getters: {
    employersData(state) {
      if (!state.employersQuery) {
        return null;
      }
      return state.employersQuery.data || null;
    },
  },
  mutations: {
    [ADMIN_EMPLOYERS.SET_EMPLOYErS_QUERY](state, payload) {
      state.employersQuery = payload;
    },
  },
  actions: {

  },

};
