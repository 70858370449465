<template>
  <DashboardLayout>
    <MainIndex />
    <div class="pb-16"></div>
  </DashboardLayout>
</template>

<script>
// @ is an alias to /src
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainIndex from '@/components/dashboard/CreditDetails/MainIndex.vue';

export default {
  name: 'CreditDetailsView',
  components: {
    DashboardLayout,
    MainIndex,
  },
};
</script>
