<template>
  <div
    class="border-2 border-solid border-transparent bg-bg-light p-3 py-5 rounded-md flex flex-col text-center items-center gap-3 hover:border-primary-light cursor-pointer relative"
    :class="`${selected ? '!border-primary' : ''}`"
  >
    <h5 class="text-base">{{title}}</h5>
    <slot />
    <Button type="primary" class="btn-light" :disabled="disabled" @click="handleOpenModal">Customize</Button>

    <small>{{description}}</small>

    <Radio :checked="selected" class="absolute scale-150 -bottom-3" :disabled="disabled" />
  </div>
</template>

<script>
import { Button, Radio } from 'ant-design-vue';

export default {
  components: { Button, Radio },
  props: {
    title: String,
    description: String,
    selected: Boolean,
    id: Number,
    disabled: Boolean,
  },
  methods: {
    handleOpenModal() {
      this.$emit('openModal', this.id);
    },
  },
};
</script>
