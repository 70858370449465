<template>
  <div class='upgradedowngrade' style='background-color: #C9C9C9'>
    <!--    title -->
    <div class='title mb-3 p-1 title-upgrade' >
      <!--      Changeable titles -->
      <h2 class='upgrade'>Add admin user </h2>
    </div>
    <!--    Numbers -->
    <div >

      <Row class='mb-3' style='justify-content: center'>
        <Col :md='12'>
          <CustumInput
            style='width: 100%'
            name="username"
            :defaultData="formData.username || null"
            :defaultError="null"
            label="User name"
            placeholder="user name"
            :rules="{
            required: true,
            min: 3,
          }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
        </Col>
      </Row>
      <Row class='mb-3' style='justify-content: center'>
        <Col :md='12'>
          <CustumInputPassword
            name="password"
            :defaultData="formData.password || null"
            :defaultError="responseErrors.password || null"
            label="Password"
            placeholder="At least six characters"
            :rules="{
                required: false,
                min: 6,
                password: true
              }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
        </Col>
      </Row>
    </div>
    <!--    Results -->

      <Row  class='mb-3' style='justify-content: center'>
        <Col :md='12'>
          <CustumSelect
            name="type"
            :defaultData="formData.type || null"
            :defaultError="null"
            placeholder="select type"
            label='Type'
            :data="typesData"
            :rules="{
              required: true,
            }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
        </Col>
      </Row>
    <!--    Buttons -->
    <div class='mb-5'>
      <Row >
        <Col :md='11' class='text-end'>
          <Button
            type="primary"
            class="btn-downgrade"
            size="medium"
            @click='cancelAddModal'
          >Cancel</Button>
        </Col>
        <Col :md='2'> </Col>
        <Col :md='11' >
          <Button
            type="primary"
            class="btn-upgrade"
            size="medium"
            @click='handleSubmit'
            :loading='isLoading'
          >Save</Button>
        </Col>
      </Row>
    </div>
    <div>
      <p> .</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Row, Col, Divider, InputNumber, Button, notification, message } from 'ant-design-vue';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumInputPassword from '@/components/shared/form/CustumInputPassword.vue';
import validation from '@/services/validation';
import handleResErrors from '@/utils/handleResErrors';
import apiAxios from '@/services/apiAdminAxios';

export default {
  components: {
    Row,
    Col,
    Divider,
    InputNumber,
    Button,
    notification,
    CustumSelect,
    CustumInput,
    CustumInputPassword
  },
  created() {
    this.formData.username = this.memberAdmin.username;
    this._id = this.memberAdmin._id
  },
  mounted() {
  },
  props: {
  memberAdmin:{type:Object ,default:{}}
  },
  data() {
    return {
      loading:false,
      formData: {},
      isConfirmed: false,
      isAllTouched: false,
      typesData: [
        { id: 'admin', name: 'Admin' },
        { id: 'superadmin', name: 'Super admin' },
       ],
      responseErrors: {},
      _id:null
    };
  },
  computed: {
    isLoading(){
      return this.loading
    }
  },
  methods: {
    cancelAddModal(){
      this.$emit('cancelAddCreditModal');
    },
    setInput(vals) {
      this.formData = { ...this.formData, ...vals };
      console.log(vals)
      console.log(this.formData)

    },
    async handleSubmit() {
      this.isAllTouched = true;
      this.responseErrors = {};

      const validetionData = validation.validateAllData(this.formData);

      if (!validetionData.isValid) {
        return;
      }

      const formData = { ...validetionData.data };

      this.isLoading = true;

      try {
        const res = await apiAxios.put(`/admin/admins/${this._id}`,formData);
        if (res.status) {
          message.success('Updated successfully');
        }
        this.isLoading = false;
      }
      catch (error) {
        const errorMsg = error.response?.data?.message;
        if (errorMsg) {
          this.responseErrors = errorMsg;
        }
        message.error(handleResErrors(error), 10);
      }
      this.isLoading = false;
    },

  },
  watch: {
  },

};

</script>

<style>
.title{
  text-align: center;
}
.align-self-center{
  align-self: center;
}
.apply-align .ant-input-number .ant-input-number-input-wrap input.ant-input-number-input{
  background-color: #00b34f;
  text-align: center !important;
}
.apply-align.ant-input-number.ant-input-number-disabled .ant-input-number-input,
.apply-align.ant-input-number .ant-input-number-input{
  text-align: center !important;
}

.apply-align.ant-input-number.cst-back .ant-input-number-input{
  background-color: white;
}
.btn-upgrade.ant-btn-primary{
  background-color: #A5CD93;
  color: black;
  border-color: #A5CD93 !important;
}
.btn-downgrade.ant-btn-primary{
  background-color:#F3A988 ;
  color: black;
  border-color: #DA9678 !important;
  transition: all 0.5s;
}
.btn-downgrade.ant-btn-primary:hover{
  background-color:  #DA9678;
  color: white;
  border-color: #DA9678 !important;
}
.btn-downgrade.ant-btn-primary:focus{
  background-color:  #DA9678;
  color: white;
  border-color: #DA9678 !important;
}
.title ,.title h2{
  /*border-radius: 8px;*/
}
.title-upgrade{
  background-color: #63A537;
  color: white !important;
}

.title-downgrade{
  background-color: #DA9678;
  color: white !important;
}

.title{
  border-radius: 10px 10px 0 0 !important;
}
.msg-adjust{
  padding: 5px 30px 15px 30px;
}
/*.upgradedowngrade{*/
/*  border-radius: 15px;*/
/*}*/
</style>
