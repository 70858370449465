<template>
  <div class="container py-12 md:py-16 lg:py-20 xl:py-24">
    <AuthCard title="Admin Login">
      <form @keydown.enter.prevent="handleSubmit">
        <div class="flex flex-col gap-4">
          <CustumInput
            name="username"
            :defaultData="defaultData? defaultData.email : null"
            label="User Name"
            placeholder="user name"
            :rules="{
            required: true,
            min: 3,
          }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumInputPassword
            name="password"
            :defaultData="defaultData? defaultData.password : null"
            label="Password"
            placeholder="password ..."
            :rules="{
            required: true,
            min: 6,
          }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
          <div class="hidden aflex items-center justify-between">
            <Checkbox>Remember Me</Checkbox>

            <router-link to="/forget-password">Forget password</router-link>
          </div>
        </div>

        <div class="mt-6">
          <Button
            type="primary"
            htmlType="submit"
            class="btn-gradient rounded"
            size="large"
            block
            :loading="isLoading"
            @click.prevent="handleSubmit"
          >Login</Button>

          <p class="hidden text-center mt-4 opacity-80">
            Don't have an account?
            <router-link to="/register">register</router-link>
          </p>
        </div>
      </form>
    </AuthCard>

    <Modal
      :visible="visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <AlertModal :warningMessage="warningMessage" @close="handleModalClose" />
    </Modal>
  </div>
</template>

<script>
import validation from '@/services/validation';
import { message, Checkbox, Button, Modal } from 'ant-design-vue';
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumInputPassword from '@/components/shared/form/CustumInputPassword.vue';
import adminauthApi from '@/services/apis/admin/adminauth.api';
import handleResErrors from '@/utils/handleResErrors';
import { saveAdminToken } from '@/services/jwt.service';
import AuthCard from './AuthCard.vue';
import AlertModal from './AlertModal.vue';
import { ADMIN_AUTH } from '../../../store/types';

export default {
  components: {
    AuthCard,
    CustumInput,
    CustumInputPassword,
    Checkbox,
    Button,
    AlertModal,
    Modal,
  },
  props: {
    defaultData: Object,
  },
  data() {
    return {
      isAllTouched: false,
      isLoading: false,
      formData: {},
      visible: false,
      warningMessage: '',
    };
  },
  methods: {
    handleModalClose() {
      this.visible = false;
      this.warningMessage = '';
    },
    setInput(vals) {
      this.formData = { ...this.formData, ...vals };
    },
    async handleSubmit(e) {
      e.target.blur();

      this.isAllTouched = true;

      const validetionData = validation.validateAllData(this.formData);

      if (!validetionData.isValid) {
        return;
      }

      /// submit
      this.isLoading = true;
      try {
        const res = await adminauthApi.postLogin(validetionData.data);

        if (res.user) {
          message.success('logged in successfully');
          console.log('TOKKEN', res.token);
          saveAdminToken(res.token);
          this.$store.commit(ADMIN_AUTH.SET_ADMIN_USER_DATA, res.user);
          this.$router.push('/admin');
        } else {
          // this.visible = true;
          // eslint-disable-next-line operator-linebreak
          this.warningMessage =
            'We are sorry, your account is not active yet. Please contact Commutrics Customer Service.';

          message.error('Something went wrong');
        }

        this.isLoading = false;
      } catch (error) {
        message.error(handleResErrors(error));

        this.isLoading = false;
      }
    },
  },
};
</script>
