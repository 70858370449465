<template>
  <section class="members-invitation mt-6 lg:mt-8">
    <div class="w-full main-card px-3 md:px-4 lg:px-5 py-8">
      <div class="flex items-center justify-center">
        <div class="max-w-md w-full">
          <div>
            <h2 class="text-secondary mb-2">Send Invitation</h2>
            <form @submit.prevent="handleSendInvitation">
              <Input placeholder="Name" v-model:value="name" class="mb-2" />
              <input-group compact class="!flex items-center">
                <Input placeholder="email address" v-model:value="email" />
              </input-group>
              <Button class="block mt-3" htmlType="submit" block type="primary" :loading="loading">
                <div class="flex items-center justify-center gap-2">
                  <PhPaperPlaneTilt weight="duotone" size="18" />Send invitation
                </div>
              </Button>
              <Alert v-if="error" type="error" :message="error" show-icon class="mt-3" />
            </form>
          </div>
          <div class="mt-6">
            <div class="flex flex-wrap items-center justify-between gap-3 mb-2">
              <h4>Upload CSV file</h4>
              <Button type="link" class="px-0">
                <a class="!flex items-center gap-2" href="/notification_emails_template.xlsx">
                  <span>Download email addresses template</span>
                  <PhDownload weight="duotone" size="18" />
                </a>
              </Button>
            </div>
            <div class="flex items-start gap-2">
              <CustumFileInput
                class="flex-1"
                name="file"
                :defaultData="file"
                placeholder="choose file"
                accept=".csv, .xlsx, .xls"
                :rules="{
                required: false,
                min: 1,
                max: 1
              }"
                @setInput="setInput"
              >
                <!-- :isTouched="isAllTouched"
                @setInput="setInput"-->
                <PhFileCsv :size="22" weight="duotone" />
              </CustumFileInput>
              <Button
                type="primary"
                :disabled="!file"
                :loading="fileLoading"
                @click="handleNotifyAll"
              >
                <span class="!flex items-center gap-2">
                  <PhPaperPlaneRight weight="duotone" size="18" />
                </span>
              </Button>
            </div>
            <div v-if="resData?.count" class="mt-3">
              <h4 class="text-lg">Number of email addresses: {{resData?.count}}</h4>

              <div
                class="bg-red-300 p-4 mt-3 rounded-sm max-h-[350px] overflow-y-auto"
                v-if="resData?.failed_emails && resData?.failed_emails.length"
              >
                <h4 class="mb-3">Failed email addresses:</h4>
                <ul>
                  <li v-for="email in resData?.failed_emails" :key="email">{{email}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Input, InputGroup, Button, Alert, message, notification } from 'ant-design-vue';
import { PhPaperPlaneTilt, PhFileCsv, PhDownload, PhPaperPlaneRight } from 'phosphor-vue';

import CustumFileInput from '@/components/shared/form/CustumFileInput.vue';
import membersApi from '../../../services/apis/members.api';
import validation from '../../../services/validation';
import rewardApi from '../../../services/apis/reward.api';

export default {
  components: {
    Input,
    InputGroup,
    Button,
    PhPaperPlaneTilt,
    CustumFileInput,
    PhFileCsv,
    PhDownload,
    Alert,
    PhPaperPlaneRight,
  },
  data() {
    return {
      email: '',
      name: '',
      loading: false,
      error: null,
      file: null,
      fileLoading: false,
      resData: null,
    };
  },
  methods: {
    async handleSendInvitation() {
      if (this.loading) {
        return;
      }
      if (!this.email || !this.email.trim()) {
        this.error = 'Email is required';
        return;
      }

      if (!this.name || !this.name.trim()) {
        this.error = 'Name is required';
        return;
      }
      if (!validation.validateEmail(this.email.trim())) {
        this.error = 'The email must be a valid email address.';
        return;
      }

      this.error = null;
      this.loading = true;

      try {
        const res = await membersApi.postInviteMemberStats({
          email: this.email.trim(),
          name: this.name.trim(),
        });
        message.success(res.result || 'Invitation sent successfully');
        this.loading = false;
        this.name = '';
        this.email = '';
      } catch (error) {
        // eslint-disable-next-line operator-linebreak
        this.error =
          error.response?.data?.message?.email[0] || error?.message || "Someting wen't wrong!";
        this.loading = false;
      }
    },

    async handleNotifyAll() {
      // this.$emit('close');
      if (!this.file) {
        message.error('Please Upload your employees email addresses file');
        return;
      }

      this.fileLoading = true;
      this.resData = null;
      try {
        const formData = new FormData();
        formData.append('file', this.file);
        const res = await rewardApi.uploadNotifyFile(formData);
        message.success('Emails sent successfully');
        this.file = null;
        this.resData = res;
      } catch (error) {
        notification.error({
          message: error.message,
          description: error?.response?.data?.message || error.message || 'Something went wrong',
          duration: 6,
        });
      }
      this.fileLoading = false;
    },

    setInput(val) {
      if (!val || !val.file || !val.file.value) return;
      // eslint-disable-next-line prefer-destructuring
      this.file = val.file.value[0] || null;
    },
  },
};
</script>
