import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

const fetchDashStats = async () => {
  const { data } = await apiAxios.get('/dashboard-stats');
  return data;
};

const fetchRecommendation = async (params) => {
  const { data } = await apiAxios.get('statistics/users-activity', { params });
  return data;
};
const fetchCommuterBreakDown = async (params) => {
  const { data } = await apiAxios.get('statistics/commuter-breakdown', { params });
  return data;
};

const fetchCommuterAccess = async (params) => {
  const { data } = await apiAxios.get('statistics/commuter-access', { params });
  return data;
};
const useDashStatsQuery = () => useQuery(['dashboard-stats'], fetchDashStats);

export default {
  fetchDashStats,
  fetchRecommendation,
  fetchCommuterBreakDown,
  fetchCommuterAccess,
  // queries
  useDashStatsQuery,

};
