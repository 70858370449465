<template>
  <section class="members-requests mt-6 lg:mt-8 secondary">
    <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
      <div class="w-full mt-4 flex flex-wrap items-center justify-between">
        <h2 class>Invitations to join the community</h2>
      </div>
      <QueryAlert :query="pendingMembersQuery" />

      <div class="mt-2 md:mt-3 lg:mt-4">
        <Table
          :data-source="mainTableData"
          :columns="columns"
          class="w-full"
          :loading="pendingMembersQuery && (pendingMembersQuery.isFetching || pendingMembersQuery.isLoading)"
          :pagination="pagination"
          @change="handlePaginationChange"
        >
          <template #headerCell="{ column  }">
            <template v-if="column.key === 'name'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhUser weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'email'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhEnvelopeSimple weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'joined'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhCalendarCheck weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>

            <template v-if="column.key === 'business_name'">
              <span class="font-bold flex items-center gap-2 min-w-[170px]">
                <PhHandshake weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>
            <template v-if="column.key === 'business_address'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhCheckSquare weight="duotone" size="18" class="flex-shrink-0" />
                {{column.title}}
              </span>
            </template>

            <template v-if="column.key === 'action'">
              <span class="font-bold whitespace-nowrap flex items-center gap-2">
                <PhGear weight="duotone" size="18" class="flex-shrink-0" />Actions
              </span>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'name'">
              <TypographyParagraph
                ellipsis
                :content="record.name"
                class="text-text whitespace-nowrap !mb-0"
              />
            </template>

            <template v-if="column.key === 'joined'">
              <span v-if="record.joined" class="whitespace-nowrap">{{getDate(record.joined)}}</span>
              <span v-else class="w-full flex justify-center text-yellow-600">
                <PhHourglassSimpleMedium weight="duotone" size="18" title="pending" />
              </span>
            </template>

            <template v-if="column.key === 'action'">
              <Spin :spinning="updateLoading && updateLoading === record.id">
                <div class="flex items-center gap-2">
                  <Button
                    type="text"
                    size="small"
                    class="rounded shadow flex items-center gap-2"
                    @click="() => handleUpdateMember(record , 1 )"
                  >
                    <PhCheck weight="bold" :size="16" />
                  </Button>
                  <Button
                    type="text"
                    size="small"
                    class="rounded shadow flex items-center gap-2"
                    @click="() => handleUpdateMember(record ,'remove' )"
                  >
                    <PhX weight="bold" :size="16" />
                  </Button>
                  <Button
                    v-if="record.type === 'request_to_employer'"
                    type="text"
                    size="small"
                    class="rounded shadow flex items-center gap-2"
                    @click="() => handleUpdateMember(record , 'accept' )"
                  >
                    <PhProhibit weight="bold" :size="16" />
                  </Button>
                </div>
              </Spin>
            </template>
          </template>
        </Table>
      </div>
    </div>

    <PendingMembersQuery
      :page="page"
      :per_page="pageSize"
      :name="name"
      :email="email"
      :key="`${page}-${pageSize}-${name}-${email}`"
    />
  </section>
</template>
<script>
import PendingMembersQuery from '@/services/queries/members/PendingMembersQuery.vue';
import { Table, TypographyParagraph, Button, message } from 'ant-design-vue';
import QueryAlert from '@/components/shared/QueryAlert.vue';

import {
  PhUser,
  PhHourglassSimpleMedium,
  PhCheckSquare,
  PhHandshake,
  PhGear,
  PhEnvelopeSimple,
  PhCalendarCheck,
  PhX,
  PhProhibit,
  PhCheck,
  // PhChecks,
  // PhProhibit,
} from 'phosphor-vue';
import dayjs from 'dayjs';
import membersApi from '../../../services/apis/members.api';
import handleResErrors from '../../../utils/handleResErrors';

export default {
  components: {
    Table,
    Button,
    TypographyParagraph,
    PhUser,
    PhHourglassSimpleMedium,
    PhCheckSquare,
    PhHandshake,
    PhGear,
    PhEnvelopeSimple,
    PhCalendarCheck,
    PhX,
    PhCheck,
    PhProhibit,
    PendingMembersQuery,
    QueryAlert,
  },
  data() {
    return {
      columns: [
        {
          title: '#',
          key: 'key',
          dataIndex: 'key',
        },

        {
          title: 'Name',
          key: 'name',
          dataIndex: 'name',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Date',
          dataIndex: 'joined',
          key: 'joined',
        },

        // {
        //   title: 'Business Name',
        //   dataIndex: 'business_name',
        //   key: 'business_name',
        // },

        // {
        //   title: 'Business Address',
        //   dataIndex: 'business_address',
        //   key: 'business_address',
        // },

        {
          title: '',
          key: 'action',
          fixed: 'right',
        },
      ],

      tableData: [
        {
          key: '1',
          name: 'Daniel Martin',
          email: 'danielmartin@gmail.com',
          joined: 1664882267,
          business_name: 'BCOS',
          business_address: '1200 Larimer st, 80204, CO, Denver',
        },

        ...Array(50)
          .fill('dd')
          .map((_, ix) => ({
            key: ix + 2,
            name: 'John Doe',
            email: 'johndoe@gmail.com',
            joined: this.getRandomInt(1664882267, 1601810267),
            business_name: 'BCOS',
            business_address: '1200 Larimer st, 80204, CO, Denver',
          })),
      ],
      search: '',
      searchData: [],
      page: 1,
      pageSize: 15,
      name: '',
      email: '',
      updateLoading: null,
    };
  },
  computed: {
    pendingMembersQuery() {
      return this.$store.state.members.pendingMembersQuery;
    },
    pendingMembersData() {
      return this.$store.getters.pendingMembersData;
    },

    mainTableData() {
      const data = this.pendingMembersData?.data || [];

      return data.map((item, ix) => ({
        key: ix + 1,
        name: item?.name,
        email: item?.email,
        joined: item?.created_at,
        business_name: item?.business?.name,
        business_address: item?.business?.address,
        // eslint-disable-next-line no-underscore-dangle
        id: item?._id,
      }));
    },

    pagination() {
      console.log('are we in pagination');
      if (!this.pendingMembersData) {
        return {
          defaultPageSize: this.pageSize,
          total: 0,
        };
      }
      console.log('this.pendingMembersData?.meta?.per_page', this.pendingMembersData?.meta?.per_page);
      console.log(' this.pageSize', this.pageSize);

      return {
        defaultPageSize: this.pendingMembersData?.meta?.per_page || this.pageSize,
        total: this.pendingMembersData?.meta?.total || 0,
      };
    },
  },
  methods: {
    async handleUpdateMember(data, status) {
      if (!data || !data.id) return;
      if (this.updateLoading) return;
      this.updateLoading = data.id;
      try {
        let res = {};
        if (status === 'remove') {
          res = await membersApi.cancelMemberInvitation(data.id);
        } else {
          res = await membersApi.acceptMemberInvitation(data.id);
        }
        if (res?.result && res?.status) {
          message.success(res?.result);
        }
        if (this.pendingMembersQuery) {
          this.pendingMembersQuery.refetch({ refetchPage: (page) => page === this.page });
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
      this.updateLoading = null;
      return;
    },
    handlePaginationChange(pagination) {
      // , filters, sorter, data
      this.page = pagination.current;
      this.pageSize = pagination.pageSize;
    },
    getRandomInt(min, max) {
      const xmin = Math.ceil(min);
      const xmax = Math.floor(max);
      return Math.floor(Math.random() * (xmax - xmin + 1)) + min;
    },
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMMM DD, YYYY, hh:mm a');
    },
    handleSearch(e) {
      const text = e.target.value || '';
      this.search = text;
      this.searchData = this.tableData.filter((d) => {
        if (d.name.toLowerCase().includes(text.trim().toLowerCase())) {
          return true;
        }
        if (d.email.toLowerCase().includes(text.trim().toLowerCase())) {
          return true;
        }
        return false;
      });
    },
  },
};
</script>
