<template>
  <div>
    <!-- alert message  -->
    <div v-if="!showContact" class="p-6 flex flex-col items-center gap-5">
      <PhWarning weight="duotone" size="40" class="text-orange-500" />
      <p class="text-center">{{warningMessage}}</p>

      <div class="flex items-center justify-center gap-3">
        <Button type="primary" class="btn-gradient" @click="handleShowContact">Contact Us</Button>
        <Button @click="() => $emit('close')">Dismiss</Button>
      </div>
    </div>
    <!-- contact form  -->
    <div v-else class="p-6 flex flex-col gap-3">
      <h3>From: 'John Doe (johndoe@mail.com)'</h3>
      <CustumInput
        name="subject"
        :defaultData="defaultData? defaultData.email : null"
        placeholder="Subject"
        :rules="{
            required: true,
            min: 3,
          }"
        :isTouched="isAllTouched"
        @setInput="setInput"
      />
      <CustumTextArea
        name="message"
        :defaultData="defaultData? defaultData.message : null"
        placeholder="Type your message"
        :rules="{
        required: true,
        min: 5,
      }"
        :isTouched="isAllTouched"
        @setInput="setInput"
      />

      <Button type="primary" class="btn-gradient" @click="handleContact">Contact Us</Button>
    </div>
  </div>
</template>
<script>
import { Button } from 'ant-design-vue';
import { PhWarning } from 'phosphor-vue';
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumTextArea from '@/components/shared/form/CustumTextArea.vue';

export default {
  components: {
    Button,
    PhWarning,
    CustumInput,
    CustumTextArea,
  },
  props: {
    warningMessage: String,
  },
  data() {
    return {
      showContact: false,
      isAllTouched: false,
      isLoading: false,
      formData: {},
    };
  },
  methods: {
    handleShowContact() {
      this.showContact = true;
    },
    setInput(vals) {
      this.formData = { ...this.formData, ...vals };
    },
    handleContact() {
      this.isAllTouched = true;
      this.$emit('close');
    },
  },
};
</script>
