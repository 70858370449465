<template>
  <div v-if="plans && plans.length" class="mt-6 pt-6 border-bg-dark border-solid border-0 border-t">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
      <PlanCard v-for="plan in plans" :key="plan.id" :data="plan" />
    </div>

    <div v-if="activePlan" class="mt-4 flex flex-col items-center justify-center gap-3">
      <!-- <h4 class="text-xl text-center">Estimated Plan Cost per Cycle:</h4>

      <div
        class="max-w-xl mb-3 text-lg py-2 px-12 bg-bg-form border border-solid border-bg-dark rounded"
      >

        ${{activePlan?.price}} - {{activePlan?.title}}
      </div>-->
      <Alert
        description="Please note, subscription invoices are generated at the beginning of each month."
        type="warning"
        show-icon
      />
    </div>
    <div v-if="subscriptionsData"></div>
  </div>
</template>

<script>
import { Alert } from 'ant-design-vue';
import PlanCard from '../PlanCard.vue';

export default {
  components: { PlanCard, Alert },

  data() {
    return {};
  },
  computed: {
    plans() {
      return this.$store.getters.plansData;
    },
    plansTotalCost() {
      return this.$store.getters.plansTotalCost;
    },
    // activePlan() {
    //   const actP = this.$store.state.plans.activePlan;
    //   return this.plans.find((p) => p.id === actP);
    // },
    activePlan() {
      // subscriptions
      const { user } = this.$store.state.auth;
      if (user?.subscriptions) {
        return user.subscriptions;
      }
      return this.$store.state.auth.user?.subscription_plan;
      // return this.$store.state.plans.activePlan;
    },
    subscriptionsData() {
      return this.$store.getters.subscriptionsData;
    },
  },
};
</script>
