<template>
<div>
  <MainHeader auth />
  <main class="auth-main">
    <div class="auth-main-content flex flex-col items-center justify-center">
      <slot></slot>
    </div>
  </main>
</div>
</template>
<script>
import MainHeader from '@/components/layout/headers/MainHeader.vue';
// import { pagesGurd } from '@/middleware/gurds';

export default {
  components: { MainHeader },
  async created() {
    // pagesGurd(this);
  },
};
</script>

<style  >
.auth-main {
  background-image: url('@/assets/images/auth-bg.jpg');
  background-size: cover;
  background-position: fixed;
  min-height: calc(100vh - 65px);
}

.auth-main-content {
  width: 100vw;
  min-height: calc(100vh - 65px);
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
