<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { ADMIN_TRANSACTIONS } from '@/store/types';
import transactionsApi from '@/services/apis/admin/transactions.api';

export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    per_page: {
      type: Number,
      default: 10,
    },
    keyword: {
      type: String,
      default: '',
    },
    for_employer: {
      type: String,
      default: null,
    },
    employer_id: {
      type: String,
      default: null,
    },
    for_employee: {
      type: String,
      default: null,
    },
    employee_id: {
      type: String,
      default: null,
    },
    start_date: {
      type: String,
      default: null,
    },
    end_date: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const query = ref(null);
    query.value = transactionsApi.useWalletTransactionsQuery({
      page: props.page,
      per_page: props.per_page,
      keyword: props.keyword,
      employer_id: props.employer_id,
      employee_id: props.employee_id,
      for_employer: props.for_employer,
      for_employee: props.for_employee,
      start_date: props.start_date,
      end_date: props.end_date,
      type: props.type,
    });
    if (query.value) {
      store.commit(ADMIN_TRANSACTIONS.SET_TRANSACTIONS_QUERY, query);
    }
    return { query };
  },
};
</script>
