<template>
  <div class="conract-page py-4">
    <div class="container">
      <div class="w-full main-card px-3 md:px-4 lg:px-5 py-10">
        <div class="max-w-xl mx-auto">
          <div class="w-full flex flex-wrap items-center justify-between max-w-xl mx-auto">
            <h2 class>Contact Us</h2>
          </div>

          <div class="mt-4">
            <form ref="contactForm">
              <div class="flex flex-col gap-4">
                <CustumInput
                  name="subject"
                  :defaultData="defaultData? defaultData.email : null"
                  :defaultError="responseErrors.subject || null"
                  placeholder="Subject"
                  :rules="{
                  required: true,
                  min: 3,
                }"
                  :isTouched="isAllTouched"
                  @setInput="setInput"
                />
                <CustumTextArea
                  name="message"
                  :defaultData="defaultData? defaultData.message : null"
                  :defaultError="responseErrors.message || null"
                  placeholder="Type your message"
                  :rules="{
                  required: true,
                  min: 5,
                }"
                  :isTouched="isAllTouched"
                  @setInput="setInput"
                />

                <Button
                  type="primary"
                  class="btn-gradient"
                  block
                  size="large"
                  :loading="isLoading"
                  @click="handleContact"
                >Submit</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumTextArea from '@/components/shared/form/CustumTextArea.vue';
import { Button, message } from 'ant-design-vue';
import validation from '@/services/validation';

import contactApi from '@/services/apis/contact.api';

export default {
  components: {
    CustumInput,
    CustumTextArea,
    Button,
  },
  data() {
    return {
      isAllTouched: false,
      formData: {},
      responseErrors: {},
      isLoading: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.user || null;
    },
  },
  methods: {
    setInput(vals) {
      this.formData = { ...this.formData, ...vals };
    },
    async handleContact() {
      this.isAllTouched = true;
      this.responseErrors = {};

      const validetionData = validation.validateAllData(this.formData);

      if (!validetionData.isValid) {
        return;
      }

      if (!this.user && !this.user.email) {
        message.error("Some thing wen't wrong!");
        return;
      }

      const formData = { ...validetionData.data };

      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      try {
        const res = await contactApi.postContact({ email: this.user.email, ...formData });

        if (res.status) {
          message.success('Your message has been sent successfully');
          if (this.$refs.contactForm) {
            this.$refs.contactForm.reset();
          }
        }
        this.isLoading = false;
      } catch (error) {
        const errorMsg = error.response?.data?.message;
        if (errorMsg) {
          this.responseErrors = errorMsg;
        } else {
          message.error(error.message);
        }
        this.isLoading = false;
      }
    },
  },
};
</script>
