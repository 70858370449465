<template>
  <div class="account-page py-4 mt-4">
    <div class="container">
      <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
        <h2 class="mb-3 text-lg font-normal">Transaction details for transportation programs</h2>
        <tabs v-model:activeKey="activeKey" class="w-full">
          <tab-pane key="1" tab="Summary">
            <SummaryDataTable :data="creditData" />
          </tab-pane>

          <tab-pane key="2" tab="Detailed">
            <DetailedDataTable :data="creditData" />
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from 'ant-design-vue';
import SummaryDataTable from './SummaryDataTable.vue';
import DetailedDataTable from './DetailedDataTable.vue';

export default {
  components: { Tabs, TabPane, SummaryDataTable, DetailedDataTable },
  data() {
    return {
      activeKey: '1',
      creditData: [
        {
          id: '1',
          date: 1672587404456,
          name: 'John Brown',
          type: 'Reward points',
          mode: 'Transit',
          modes: 'Transit, Bike, Drive',
          amount: 1.5,
          trip_cost: 1.5,
        },
        {
          id: '2',
          date: 1665277404456,
          name: 'John Tyler',
          type: 'Reward points',
          mode: 'drive',
          modes: 'Weekly credit, drive, walk',
          amount: 20,
          trip_cost: 1.5,
        },
        {
          id: '3',
          date: 1675277404456,
          name: 'User Name',
          type: 'Reward points',
          mode: 'walk',
          modes: 'Weekly credit, drive, walk',
          amount: 60,
          trip_cost: 1.5,
        },
        {
          id: '4',
          date: 1615277404456,
          name: 'User Name',
          type: 'Reward points',
          mode: 'Transit',
          modes: 'Weekly credit, drive, walk',
          amount: 22,
          trip_cost: 1.5,
        },
      ],
    };
  },
  computed: {
    isDev() {
      return process.env.VUE_APP_DEV === 'development' || process.env.NODE_ENV === 'development';
    },
  },
};
</script>

<style  >
.account-page .ant-tabs-tab {
  font-size: 1.25rem;
}
</style>
