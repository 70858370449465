/* eslint-disable */
import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';
const convertDaysToNumbers=  (daysString) => {
  console.log('daysString',daysString.target)
  const dayMap = {
      'sunday': 1,
      'monday': 2,
      'tuesday': 3,
      'wednesday': 4,
      'thursday': 5,
      'friday': 6,
      'saturday': 7
  };

  return daysString
      .split(',')
      .map(day => dayMap[day.toLowerCase()])
      .join(',');
}
const fetchEmployerStats = async ({ type, year }) => {
  let URL = '';
  if (type) {
    URL += `?type=${type}`;
  }

  if (year) {
    URL += `&from_year=${year}-01-01&to_year=${+year + 1}-01-01`;
  }
  const { data } = await apiAxios.get(`/employer-stats${URL}`);
  return data;
};

const fetchCommuterBenifitsStats = async ({ type, year }) => {
  let URL = '';
  if (type) {
    URL += `?type=${type}`;
  }
  if (year) {
    URL += `&from_year=${year}-01-01&to_year=${+year + 1}-01-01`;
  }
  const { data } = await apiAxios.get(`/commuter-benefits-stats${URL}`);
  return data;
};

const fetchCommunityFootprintStats = async ({ type, year }) => {
  let URL = '';
  if (type) {
    URL += `?type=${type}`;
  }
  if (year) {
    URL += `&from_year=${year}-01-01&to_year=${+year + 1}-01-01`;
  }
  const { data } = await apiAxios.get(`/community-footprint-stats${URL}`);
  return data;
};

const fetchModeShareStats = async ({ type, from, to, daySearch }) => {
  let URL = '';
  if (type) {
    URL += `?type=${type}`;
  }
  if (from && to) {
    URL += `&from_year=${from}&to_year=${to}`;
  }
  if (daySearch) {
    URL += `&searchDay=${daySearch}`;
  }
  const { data } = await apiAxios.get(`/initial-mode${URL}`);
  return data;
};

const fetchMileShareStats = async ({ type, from, to, daySearch }) => {
  let URL = '';
  if (type) {
    URL += `?type=${type}`;
  }
  if (from && to) {
    URL += `&from_year=${from}&to_year=${to}`;
  }
  if (daySearch) {
    URL += `&searchDay=${daySearch}`;
  }
  const { data } = await apiAxios.get(`/mile-share${URL}`);
  return data;
};

// queries
const useEmployerStatsQuery = ({ type, year }) =>
  useQuery(['employer-stats', type, year], () => fetchEmployerStats({ type, year }));
const useCommuterBenifitsQuery = ({ type, year }) =>
  useQuery(['commuter-benefits-stats', type, year], () =>
    fetchCommuterBenifitsStats({ type, year }),
  );
const useCommunityFootprintQuery = ({ type, year }) =>
  useQuery(['community-footprint-stats', type, year], () =>
    fetchCommunityFootprintStats({ type, year }),
  );

const useModeShareQuery = ({ type, from, to, daySearch }) =>
  useQuery(['initial-mode', type, from, to, daySearch], () => fetchModeShareStats({ type, from, to, daySearch }));

const useMileShareQuery = ({ type, from, to, daySearch }) =>
  useQuery(
    ['mile-share', type, from, to, daySearch],
    () => fetchMileShareStats({ type, from, to, daySearch }),
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );

export default {
  fetchEmployerStats,
  fetchCommuterBenifitsStats,
  fetchCommunityFootprintStats,
  fetchModeShareStats,
  fetchMileShareStats,
  // queries
  useEmployerStatsQuery,
  useCommuterBenifitsQuery,
  useCommunityFootprintQuery,
  useModeShareQuery,
  useMileShareQuery,
};
