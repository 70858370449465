import { MEMBERS } from '../types';

export default {
  state: {
    membersQuery: null,
    membersStatsQuery: null,
    pendingMembersQuery: null,

  },
  getters: {
    membersData(state) {
      if (!state.membersQuery) {
        return null;
      }
      return state.membersQuery.data?.result || null;
    },
    membersStatsData(state) {
      if (!state.membersStatsQuery) {
        return null;
      }
      return state.membersStatsQuery.data?.result || null;
    },
    pendingMembersData(state) {
      if (!state.pendingMembersQuery) {
        return null;
      }
      return state.pendingMembersQuery.data || null;
    },
  },
  mutations: {
    [MEMBERS.SET_MEMBERS_QUERY](state, payload) {
      state.membersQuery = payload;
    },
    [MEMBERS.SET_MEMBERS_STATS_QUERY](state, payload) {
      state.membersStatsQuery = payload;
    },
    [MEMBERS.SET_PENDING_MEMBERS_QUERY](state, payload) {
      state.pendingMembersQuery = payload;
    },
  },
  actions: {

  },

};
