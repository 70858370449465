import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

const postAddCredit = async (payload) => {
  const { data } = await apiAxios.post('/stripe/payment-session', payload);
  return data;
};
const checkAddCredit = async (payload) => {
  const { data } = await apiAxios.post('/stripe/service-fees', payload);
  return data;
};

const postSubscribe = async (body) => {
  const { data } = await apiAxios.post('/stripe/subscribe', body);
  return data;
};

const postCancel = async () => {
  const { data } = await apiAxios.post('/stripe/cancel-subscription');
  return data;
};

const fetchSubscriptions = async () => {
  const { data } = await apiAxios.get('/subscription-plans');
  return data;
};

// queries
const useSubscriptionsQuery = () => useQuery(['subscriptions'], fetchSubscriptions);

export default {
  postAddCredit,
  fetchSubscriptions,
  postSubscribe,
  postCancel,
  // queries
  useSubscriptionsQuery,
  checkAddCredit,
};
