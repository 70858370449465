<template>
<!--   es-lint disable -->
  <section class="mt-3">
    <div
      class="w-full flex flex-wrap items-center justify-center md:justify-between text-center md:text-start"
    >
      <h2 v-if='!isFixedCostPlan'>Choose Your Subscription Plan</h2>
    </div>
    <Spin :spinning="(subscriptionsQuery && subscriptionsQuery.isLoading)" v-if='!loading'>
      <div class="mt-6 flex flex-col gap-3 w-full subs-account-plans">
        <!-- <PlasFeatures /> -->
        <div v-if='!isFixedCostPlan' class="p-2 rounded-sm" :class="radioVal === 1 ? 'bg-stone-50' : ''">
          <Radio :checked="radioVal === 1" @change="radioVal = 1" class="w-full">
            <p>Pay as you go for platform subscription and employee transportation benefits. Transportation program and payment can be set in the Reward System Page.</p>
          </Radio>
          <div class="pl-4">
            <AccountPlans />
          </div>
        </div>

        <Divider class="!py-1 !my-1" />
        <div v-if='!isOngoingPlan' :class="radioVal === 2 ? 'bg-stone-50' : ''">
          <div class="p-2 rounded-sm" :class="`${isFixedCostPlan == true ? 'active-sub' : 'border-bg-dark'}`" >
          <div
            class="w-full flex flex-col items-center justify-center md:flex-row md:justify-between gap-4"
          >
            <Radio :checked="radioVal === 2 || isFixedCostPlan" @change="radioVal = 2"  >
              <p>Fixed monthly cost per employee that include platform subscription and employee transportation benefits.</p>
            </Radio>
            <div style='width: 25%'>
              <Select v-model:value="activeProgram" class="w-60" style='width: 100% !important;'>
                <Select
                  style='width:-webkit-fill-available;'
                  v-for="item in programsComputed"
                  :key="item._id"
                  :value="item._id"
                >{{getProgramTitle(item)}}</Select>
              </Select>
            </div>
          </div>
          <div class="pl-4" >
              <PlanPrograms :number_of_employees="+number_of_employees" :program="currentProgram" :radioVal="radioVal" :view-only='viewOnly' />
          </div>
        </div>
        </div>
        </div>
    </Spin>
    <div v-else></div>
    <SubscriptionsQuery />
  </section>
</template>

<script>
/* eslint-disable */
import { Spin, Radio, Divider, InputNumber, Select } from 'ant-design-vue';

import SubscriptionsQuery from '@/services/queries/payments/SubscriptionsQuery.vue';

import { mapMutations } from 'vuex';
import dayjs from 'dayjs';
import AccountPlans from './subscription/AccountPlans.vue';
import PlanPrograms from './subscription/PlanPrograms.vue';
// import PlasFeatures from './PlasFeatures.vue';
import { PLANS } from '../../../store/types';
import subscriptionsApi from '@/services/apis/subscriptions.api';
import { now } from 'moment';

export default {
  components: {
    AccountPlans,
    SubscriptionsQuery,
    Spin,
    Radio,
    PlanPrograms,
    Divider,
    InputNumber,
    Select,
  },
  data() {
    return {
      radioVal: null,
      number_of_employees: 100,
      // ongoing program (active) => old data - future date
      // Launching new program – not paid yet (new) => no data - no date
      // New program is scheduled – Paid (scheduled) => future date - open
      // Expired program (inactive) => old data - old date
      activeProgram: 1,
      programs: [
      ],
      isloading:false
    };
  },
  computed: {
    totalEmployees() {
      return this.$store.state.auth.user?.community_size || 'N/A';
    },
    subscriptionsQuery() {
      return this.$store.state.payments.subscriptionsQuery;
    },
    currentProgram() {
      let pg = this.programs.find((p) => p._id === this.activeProgram);
      if(pg) pg.custom_program = true
      if (!pg) pg =  {
        // Launching new program – not paid yet (new)
        _id: 1,
        cost: 'Fixed cost',
        type:"fixed_cost",
        status:'new',
        custom_program:true,
        start_date: null,
        end_date: null,
        active_program_end_date:null
      }
      return pg;
    },
    isFixedCostPlan(){
      return  this.$store.state.auth.user.subscription?.type == 'fixed_cost'
    },
    programsComputed(){
      return this.programs
    },
    activeProgramComputed(){
      return this.activeProgram
    },
    loading(){
      return this.isloading
    },
    viewOnly(){
      if(this.currentProgram.status == 'new') return false
      return true
    },
    isOngoingPlan(){
      return  this.$store.state.auth.user.subscription?.type == 'ongoing'
    },
  },
  methods: {
    ...mapMutations([PLANS.SET_TOTAL_EMPLOYEES]),
    setInput(val) {
      if (val && val.employees && val.employees.value !== this.totalEmployees) {
        this.SET_TOTAL_EMPLOYEES(val.employees.value);
      }
    },
    getProgramTitle(p) {
      let title = '';

      if (!p) return title;
      if (!p.start_date && !p.end_date) return 'Lunch New Program';
      const currentYear = dayjs().year();
      const currentMonth = dayjs().month();
      const start = dayjs(p.start_date, 'YYYY-MM-DDTHH:mm:ssZ').valueOf();
      const end = dayjs(p.end_date, 'YYYY-MM-DDTHH:mm:ssZ').valueOf();
      const endYear = dayjs(p.end_date, 'YYYY-MM-DDTHH:mm:ssZ').year();
      const endMonth = dayjs(p.end_date, 'YYYY-MM-DDTHH:mm:ssZ').month()
      const startTitle=dayjs(start).format('MMM YYYY');
      const endTitle = dayjs(end).format('MMM YYYY')
      let status = ''
      if(p.status == 'pending'){
        status = 'Incomplete';
      }else if(p.status == 'active'){
        status = 'Ongoing';
      }else if(p.status == 'future'){
        status = 'Scheduled';
      }
      else if(p.status == 'pending_upgrade'){
        status = 'Pending Upgrade';
      }
      else if(currentYear > endYear){
        status = 'Expired'
      }else if(currentYear >= endYear && currentMonth > endMonth){
        status = 'Expired'
      }
      else{
        status = p.status
      }
      title = `${startTitle} - ${endTitle} - ${status}`
      // if (current > start && current < end) return `${dayjs(start).format('MMM YYYY')} - (${p.status})` ;
      //
      // if (current < start) return `${dayjs(start).format('MMM YYYY')} - (${p.status})`;
      // if (current > end) {
      //   return ` ${dayjs(start).format('MMM YYYY')} - ${dayjs(end).format('MMM YYYY')} - (${p.status})`;
      // }


      return title;
    },
    getSubscriptions(){
      this.isloading=true

      subscriptionsApi.getSubscriptions({
        filter_fixed_cost:true
      }).then((response)=>{
        const {data} = response
        let arrangeSubscriptions=[]
        let active_program_end_date = null
        let futureItem=null
          data.map((item)=>{
            if(item.type == 'fixed_cost'){
              if(item.status == 'active'){
                arrangeSubscriptions[0] = item
                active_program_end_date = item.end_date
                console.log(this.activeProgram , item)
                this.activeProgram = item._id
                console.log(this.activeProgram)
              }
              else if(item.status == 'future'){
                arrangeSubscriptions[2]=item
              }
            }
          })
        arrangeSubscriptions[2] ?  arrangeSubscriptions[2].active_program_end_date = active_program_end_date :'';
        if(arrangeSubscriptions[0] == null){
          arrangeSubscriptions[0]={
            // Launching new program – not paid yet (new)
            _id: 1,
            cost: 'Fixed cost',
            type:"fixed_cost",
            status:'new',
            custom_program:true,
            start_date: null,
            end_date: null,
            active_program_end_date:null
          }
        }else{
          arrangeSubscriptions[1]={
            // Launching new program – not paid yet (new)
            _id: 1,
            cost: 'Fixed cost',
            type:"fixed_cost",
            status:'new',
            custom_program:true,
            start_date: null,
            end_date: null,
            active_program_end_date:active_program_end_date
          }
        }
        if(arrangeSubscriptions[1] == null && arrangeSubscriptions[2] != null){
          arrangeSubscriptions[1] = arrangeSubscriptions[2]
          arrangeSubscriptions.pop()
        }
          data.map((item)=>{
          if(item.type == 'fixed_cost' && item.status != "canceled" && item.status != 'active' && item.status != 'future' ){
            item.dates_disabled = true
            arrangeSubscriptions.push(item)
          }
        })
        this.programs= arrangeSubscriptions
        console.log("response",{arrangeSubscriptions})
        this.isloading = false
      }).catch((err)=>{
        console.log("err",{err})
      })
    }
  },
  async created() {
    await this.getSubscriptions()
  },
  mounted() {
    if( this.$store.state.auth.user.subscription?.type == 'fixed_cost') this.radioVal = 2
    else if( this.$store.state.auth.user.subscription?.type == 'ongoing') this.radioVal =1
  }
};
</script>

<style  >
.subs-account-plans .ant-radio + span {
  width: 100%;
}
.active-sub{
  padding: 12px !important;
  border: 3px solid var(--color-primary);
  border-radius:5px !important;
}
</style>
