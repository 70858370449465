<template>
  <div
    v-if="data && data.length"
    class="bg-bg rounded-lg shadow-lg flex flex-col items-center gap-6"
  >
    <div class="p-4 w-full h-full flex flex-col" v-for="el in data" :key="el.id">
      <strong
        class="text-center block pb-3 border-0 border-b border-bg-dark border-solid mb-3 text-primary text-2xl"
      >
        <span v-if="el.type && el.type === '$'">$</span>
        {{calcVal(el.value)}}
        <span
          v-if="el.type && el.type !== '$'"
          class="text-base text-text-light"
        >{{el.type}}</span>
      </strong>
      <div class="flex items-center gap-3">
        <img
          v-if="el.img && getImg(el.img)"
          :src="getImg(el.img)"
          alt="icon"
          class="w-16 h-12 object-contain flex-shrink-0 opacity-90"
        />
        <p class="flex-1 text-lg">{{el.title}}</p>
      </div>

      <div class="flex-1 flex flex-col justify-end">
        <p v-if="el.info" class="text-sm text-center mt-5">
          {{el.info}}
          <Tooltip v-if="el.tooltip">
            <template #title>
              <p v-if="typeof el.tooltip == 'string'">{{el.tooltip}}</p>
              <ul v-else>
                <li v-for="(txt , ix) in el.tooltip" :key="ix">{{txt}}</li>
              </ul>
            </template>

            <PhInfo weight="fill" class="text-primary cursor-pointer" size="16" />
          </Tooltip>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'ant-design-vue';
import { PhInfo } from 'phosphor-vue';

export default {
  props: {
    data: Array,
  },
  components: { Tooltip, PhInfo },
  methods: {
    getImg(slug) {
      try {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        const img = require(`@/assets/images/dash/${slug}`);
        if (img) {
          return img;
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    calcVal(val) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(val)) {
        return val;
      }

      const newNum = +val;

      if (newNum < 1000) {
        return newNum;
      }

      if (newNum < 1000000) {
        return `${Math.round(newNum / 100) / 10}K`;
      }

      if (newNum < 1000000000) {
        return `${Math.round(newNum / 100000) / 10}M`;
      }

      return `${Math.round(newNum / 100000000) / 10}B`;
    },
  },
};
</script>
