<template>
  <div>
    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table :data-source="search ? searchData : tableData" :columns="columns">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'createdAt'">
            <span v-if="record.createdAt" class="whitespace-nowrap">{{getDate(record.createdAt)}}</span>
          </template>

          <template v-if="column.key === 'expiration'">
            <span v-if="record.expiration" class="whitespace-nowrap">{{getDate(record.expiration)}}</span>
          </template>

          <template v-if="column.key === 'digits'">
            <span v-if="record.digits" class="whitespace-nowrap">--------{{ record.digits }}</span>
          </template>

          <template v-if="column.key === 'action'">
            <div class="flex items-center gap-2">
              <Popconfirm
                title="Are you sure delete this card?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="() => {}"
              >
                <Button
                  type="primary"
                  size="small"
                  class="btn-error rounded shadow flex items-center gap-2"
                >
                  <PhTrash weight="duotone" :size="16" />
                </Button>
              </Popconfirm>
            </div>
          </template>
        </template>
      </Table>
    </div>

    <div class="mt-4 lg:mt-3">
      <h3 class="text-lg">Payment Methods</h3>

      <div class="flex flex-wrap items-center gap-4 mt-5">
        <Button
          type="primary"
          size="large"
          class="btn-gradient"
          @click="() => handleModalOpen(1)"
        >Add a Credit Card</Button>
        <Button
          type="primary"
          size="large"
          class="btn-gradient"
          @click="() => handleModalOpen(2)"
        >Add a Debit Card</Button>
      </div>
    </div>

    <Modal
      :visible="!!visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal  "
      center
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <PaymentCardModal
        :title="cardTitles[visible -1]"
        :id="visible"
        :key="visible"
        @close="handleModalClose"
      />
    </Modal>
  </div>
</template>

<script>
import { Table, Popconfirm, Button, Modal } from 'ant-design-vue';
import { PhTrash } from 'phosphor-vue';
import dayjs from 'dayjs';
import PaymentCardModal from './shared/PaymentCardModal.vue';

export default {
  components: {
    Table,
    Modal,
    Popconfirm,
    Button,
    PhTrash,
    PaymentCardModal,
  },
  data() {
    return {
      columns: [
        // {
        //   title: '#',
        //   key: 'key',
        //   dataIndex: 'key',
        // },

        {
          title: 'Payment Method',
          key: 'method',
          dataIndex: 'method',
        },
        {
          title: 'Last 4 digits',
          dataIndex: 'digits',
          key: 'digits',
        },
        {
          title: 'Added on',
          dataIndex: 'createdAt',
          key: 'createdAt',
        },
        {
          title: 'Expiration date',
          key: 'expiration',
          dataIndex: 'expiration',
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
        },

        {
          title: '',
          key: 'action',
          fixed: 'right',
        },
      ],

      tableData: [
        {
          key: '1',
          method: 'Credit Card',
          digits: '5463',
          createdAt: 1664882267,
          expiration: 1664882267,
          status: 'Active',
        },
        {
          key: '2',
          method: 'Credit Card',
          digits: '1234',
          createdAt: 1664882267,
          expiration: 1664882267,
          status: 'Failed verification',
        },
      ],

      cardTitles: ['Add a Credit Card', 'Add a Debit Card'],
      visible: null,
    };
  },
  methods: {
    getDate(t) {
      // February 18, 2022, 4:43 am
      return dayjs(t * 1000).format('MMMM DD, YYYY');
    },
    handleModalClose() {
      this.visible = null;
    },
    handleModalOpen(num) {
      this.visible = num;
    },
  },
};
</script>
