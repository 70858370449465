<template>
  <!--  eslint-disable -->
  <div>
    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table :data-source="queryData" :columns="columns" :loading="isLoading">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'createdAt'">
            <span v-if="record.created_at" class="whitespace-nowrap">{{getDate(record.created_at)}}</span>
          </template>

          <template v-if="column.key === 'payment_type'">
            <span v-if="record.payment_type" class="whitespace-nowrap">{{record.payment_type}}</span>
          </template>

          <template v-if="column.key === 'amount'">
            <span v-if="record.value" class="whitespace-nowrap">${{ record.value }}</span>
          </template>
          <template v-if="column.key === 'Status'">
            <span v-if="record.amount" class="whitespace-nowrap">${{ record.status }}</span>
          </template>

          <template v-if="column.key === 'receipt'">
            <div class="flex items-center gap-2 justify-start">
              <Button
                type="primary"
                ghost
                @click="() => viewInvoicePdf(record._id)"
                :loading="record.id === loadingId"
              >
                <template #icon>
                  <div class="flex items-center justify-center">
                    <PhFileArrowDown weight="duotone" size="20" />
                  </div>
                </template>
              </Button>
            </div>
          </template>

        </template>
      </Table>
    </div>
  </div>
</template>

<script>
/* eslint-disable  */
import { Table, Button, Modal, message } from 'ant-design-vue';
import dayjs from 'dayjs';
import { PhFileArrowDown } from 'phosphor-vue';
import apiAxios from '@/services/apiAxios';
import { getToken } from '@/services/jwt.service';
import readAndDownloadFile from '@/utils/downloadFile';
import handleResErrors from '@/utils/handleResErrors';
import CONFIG from '@/services/config';

export default {
  components: {
    Table,
    Button,
    PhFileArrowDown,
    Modal,
  },
  data() {
    return {
      columns: [
        {
          title: 'Payment Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
        },
        {
          title: 'Payment Type',
          dataIndex: 'payment_type',
          key: 'payment_type',
        },
        {
          title: 'Amount',
          key: 'amount',
          dataIndex: 'amount',
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
        },
        {
          title: 'Receipt',
          key: 'receipt',
          dataIndex: 'receipt',
        },
      ],

      tableData: [
        {
          key: '1',
          createdAt: 1664882267,
          amount: 1200,
          type: 'Subscription',
          service: '01/01/23 - 01/31/23',
          remaining: 1000,
          prossesed: 1664882267,
          status: 'Partial payment',
        },
        {
          key: '2',
          createdAt: 1664882267,
          amount: 1500,
          type: 'Program cost',
          service: '01/01/23 - 01/31/23',
          remaining: 0,
          prossesed: 1664882267,
          status: 'Pending',
        },
        {
          key: '3',
          createdAt: 1664882267,
          amount: 4500,
          type: 'Subscription',
          service: 'N/A',
          remaining: 0,
          prossesed: 1664882267,
          status: 'Paid',
        },
        {
          key: '4',
          createdAt: 1664882267,
          amount: 3300,
          type: 'Program cost',
          service: '01/01/23 - 01/31/23',
          remaining: 0,
          prossesed: 1664882267,
          status: 'Failed',
        },
      ],
      visible: false,
      amount: 0,
      loadingId: null,
      detailsLoading: false,
      keyC:1,
      loading:false

    };
  },
  computed: {
    queryData() {
      return this.tableData;
    },
    isLoading(){
      return this.loading;
    }
  },
  methods: {
    getDate(t) {
      // February 18, 2022, 4:43 am
      return dayjs(t).format('MMMM DD, YYYY');
    },
    async viewInvoicePdf(id, details = 0) {
      console.log("id =>",id)
      this.loadingId = id;
      if (details) this.detailsLoading = true;
      try {
        await this.viewInvoice( id );
      } catch (error) {
        message.error(handleResErrors(error));
      } finally {
        this.loadingId = null;
        this.detailsLoading = false;
      }
    },
    async fetchPayments () {
      this.loading = true
      const { data } = await apiAxios.get('/external-payments');
      console.log("data => ",data)
      this.tableData = data.data ;
      this.forceUpdate();
      this.loading = false ;
    },
    forceUpdate(){
      this.keyC =+ 1
    },
    async viewInvoice (id) {
      const token = getToken();
      if (!token || !id) {
        throw new Error('Missing token or id');
      }

      console.log("external-payments")
      window.open(CONFIG.API_URL+`/external-payments/${id}/receipt?access_token=${token}`, "_blank") || window.location.replace(CONFIG.API_URL+`/external-payments/${id}/receipt?access_token=${token}`);
      return ;
      // const res = await apiAxios.get(`/external-payments/${id}/receipt?access_token=${token}`, { responseType: 'blob' });

      // readAndDownloadFile(res.data);

      // return res;
    },
  },
  mounted() {
    this.fetchPayments();
  }
};
</script>
