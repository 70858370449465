<script>
/* eslint-disable */ 
import { ref } from 'vue';
import { useStore } from 'vuex';
import { REWARD } from '@/store/types';
import rewardApi from '../../apis/reward.api';

export default {
  props: {
    searchId: {
      type: String,
      default: null
    }
  },

  setup(props) {
    const store = useStore();
    const query = ref(null);
    if (props.searchId) 
      query.value = rewardApi.useRewardDefaultsQuery(props.searchId);
    else
      query.value = rewardApi.useRewardDefaultsQuery();

    if (query.value) {
      store.commit(REWARD.SET_REWARD_DEFAULTS_QUERY, query);
    }
    return { query };
  },
};
</script>
