/* eslint-disable */
<template>
  <div style='overflow-x: hidden;max-width: 100%'>
    <HeaderView>
      <div class="container" >
        <div class="card-container">
          <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
            <div>
              <h1>Commutrics Terms and Conditions for Employers or Schools</h1>

              <h2>1. Description of Service</h2>
              <p>Commutrics, LLC ("Commutrics," "we," "our," or "us") provides businesses, employers, and schools ("you" or "Company") with access to a commuting program dashboard (the "Service") to manage and optimize commuters’ commuting activities. The Service includes features such as route planning, transportation options, reporting, rewards systems, commute scores, and other related tools, accessible through subscription-based pricing plans.</p>
              <h2>2. Company Obligations</h2>
              <h2>2.1. Account Registration</h2>
              <p>Your Company must register for an account with Commutrics to access the Service. You agree to provide accurate, current, and complete information. The Company is responsible for maintaining the confidentiality of its account credentials and is liable for all activities conducted under the account. Commutrics must be notified immediately of any unauthorized access or security breaches.</p>
              <h2>2.2. Compliance with Applicable Laws</h2>
              <p>Your Company is responsible for ensuring that the use of the Service complies with all applicable laws, rules, and regulations, including but not limited to laws regarding employee benefits, privacy, and transportation subsidies.</p>
              <h2>2.3. Prohibited Activities</h2>
              <p>Your Company agrees not to:</p>
              <ul>
                <li>Violate any applicable law or regulation;</li>
                <li>Infringe upon any intellectual property rights;</li>
                <li>Upload or transmit any harmful, unlawful, or objectionable content;</li>
                <li>Interfere with the proper functioning of the Service or its related systems;</li>
                <li>Attempt to gain unauthorized access to any portion of the Service or related accounts.</li>
              </ul>

              <h2>3. Subscription and Payment</h2>
              <h2>3.1. Subscription Plans</h2>
              <p>Commutrics offers subscription plans to businesses, providing various features and services. By selecting a subscription plan, you agree to pay the applicable fees as outlined on the Commutrics website or as otherwise agreed in writing.</p>
              <h2>3.2. Payment and Billing</h2>
              <p>The Company agrees to pay the subscription fees on a recurring basis according to the chosen subscription plan (monthly or annually). You authorize Commutrics to charge the payment method provided for the recurring fees. It is the Company’s responsibility to keep billing information up to date.</p>
              <h2>3.3. Cancellation and Refunds</h2>
              The Company may cancel the subscription at any time, but refunds will not be provided for unused portions of the subscription. Upon cancellation, access to the Service will terminate at the end of the current billing cycle.
              <h2>4. Monetary Incentives and Redemption</h2>
              <p>The Company agrees to provide monetary incentives to commuters (employees or students) in accordance with the transportation program established by the Company. Invoices for these incentives will be generated monthly and made available on the account by the 9th of each month. The Company is required to settle the invoices as they become available. Commutrics reserves the right to withhold payments to commuters until the Company has fulfilled its obligation to pay the transportation benefits.
                Monetary incentives provided through Commutrics are valid for redemption by commuters for a period of one (1) year from the date of issuance. After this period, any unredeemed credits will be repurposed to support other commuting programs in the region, unless the Company specifies otherwise. This repurposing serves to enhance regional transportation initiatives that promote community sustainability and align with broader environmental goals.</p>
              <h2>5. Commuter Status and Account Responsibility</h2>
              <p>The Company is responsible for ensuring that all commuters associated with their account are active employees or students and are eligible to receive transportation benefits. Any changes in the employment or student status of commuters must be promptly updated in the Company’s account. Failure to update commuter status may result in ineligible individuals receiving transportation benefits, for which the Company will remain liable. It is the sole responsibility of the Company to manage and maintain accurate commuter information within the Commutrics platform.</p>
              <h2>6. Data Collection and Privacy</h2>
              <p>Commutrics may collect and process data related to your employees’ commutes in order to provide and improve the Service. The Company is responsible for obtaining any required consent from employees and ensuring compliance with applicable data protection and privacy laws.</p>
              <h2>6. Intellectual Property</h2>
              <p>The Service, including but not limited to the dashboard, software, text, graphics, and other materials, is the property of Commutrics or its licensors. The Company may not reproduce, modify, or distribute any content without prior written authorization from Commutrics.</p>
              <h2>7. Limitation of Liability</h2>
              <p>To the fullest extent permitted by law, Commutrics shall not be liable for any indirect, incidental, consequential, or punitive damages, including but not limited to loss of data, revenue, or employee productivity, arising out of or related to the use or inability to use the Service.</p>
              <h2>8. Indemnification</h2>
              <p>The Company agrees to indemnify and hold Commutrics harmless from any claims, damages, liabilities, or expenses, including reasonable attorneys' fees, arising from the Company’s use of the Service or violation of these Terms.</p>
              <h2>9. Governing Law and Arbitration</h2>
              <p>These Terms are governed by the laws of the State of Colorado. Any disputes arising from or related to the Service shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association, with arbitration proceedings conducted in Denver, Colorado.</p>
              <h2>10. Disclaimer of Warranties</h2>
              <p>Commutrics makes no warranties regarding the availability or performance of the Service. The Service is provided "as is" without any express or implied warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.
              You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or dashboard will be free of viruses or other destructive code. It is your responsibility to implement necessary procedures and safeguards to ensure antivirus protection and accuracy of data input and output. You should also maintain an external backup system to reconstruct any lost data.
              We will not be held liable for any loss or damage resulting from distributed denial-of-service attacks, viruses, or other technologically harmful materials that may infect your computer equipment, programs, data, or other proprietary materials due to use of the dashboard or any services or items obtained through it. This includes the downloading of any material posted on the dashboard or linked applications.
              We do not make any representations or warranties that the dashboard will function at all times and in all locations, or that notifications will be received in a timely manner. Additionally, we do not guarantee that your device will remain undamaged while using the dashboard.</p>
              <h2>11. Entire Agreement</h2>
              <p>The Terms of Use and Privacy Policy serve as the exclusive and comprehensive agreement between you and Commutrics, LLC. They override any previous or concurrent understandings, agreements, representations, and warranties, whether written or verbal, concerning the Commutrics Service.</p>
            </div>
          </div>
        </div>
      </div>
    </HeaderView>

  </div>
  <div style='width: 100%;height: 80px;line-height: 160px;text-align: center'>
    Copyright © the Pick Path Group, LLC - 2023 (All Rights Reserved)
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import HeaderView from '@/views/HeaderView.vue';

export default {
  components: {
    HeaderView,
  },
};
</script>
<style scoped>
.main-card{
  margin:10px 0;
  border-color: transparent;  background-color: rgba(255, 255, 255, 0.5);
}
</style>

<!--<script setup>-->
<!--import { ref, onBeforeMount } from 'vue';-->
<!--import HeaderView from '@/views/HeaderView.vue';-->
<!--import apiAxios from '@/services/apiAxios';-->
<!--import { Skeleton } from 'ant-design-vue';-->

<!--const content = ref('');-->
<!--const loading = ref(true);-->
<!--const getAgreementData = async () => {-->
<!--  const { data } = await apiAxios.get('/agreements/terms-and-conditions');-->
<!--  content.value = data?.data?.content;-->
<!--  loading.value = false;-->
<!--};-->
<!--onBeforeMount(() => {-->
<!--  getAgreementData();-->
<!--});-->
<!--</script>-->

<!--<template>-->
<!--  <div style='overflow-x: hidden;max-width: 100%'>-->

<!--          <HeaderView>-->
<!--            <div class="container" >-->
<!--              <div class="card-container">-->
<!--                <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">-->
<!--                  <div v-if='!loading' v-html='content'>-->
<!--                  </div>-->
<!--                  <Skeleton v-else-if='loading'></Skeleton>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </HeaderView>-->

<!--  </div>-->
<!--  <div style='width: 100%;height: 80px;line-height: 160px;text-align: center'>-->
<!--    Copyright © the Pick Path Group, LLC - 2024 (All Rights Reserved)-->
<!--  </div>-->
<!--</template>-->

<!--<style scoped>-->
<!--.main-card{-->
<!--  margin:10px 0;-->
<!--  border-color: transparent;  background-color: rgba(255, 255, 255, 0.5);-->
<!--}-->
<!--</style>-->
