<template>
    <AdminLayout>
      <MainIndex />
      <div class="pb-16"></div>
    </AdminLayout>
  </template>
<script>
import AdminLayout from '@/layouts/AdminLayout.vue';
import MainIndex from '@/components/admin/taxesPayApproval/MainIndex.vue';

export default {
  name: 'InvoicesView',
  components: {
    AdminLayout,
    MainIndex,
  },
};
</script>
