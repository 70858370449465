<template>
  <div class='upgradedowngrade'>
    <div v-if='program.downgrade_quantity == null'>
          <Button
            type="primary"
            class="btn-upgrade"
            size="medium"
            @click='upgradeEmployees' style='margin-right: 12px'
          >Upgrade</Button>
          <Button
            type="primary"
            class="btn-downgrade"
            size="medium"
            @click='downgradeEmployees'
          >Downgrade</Button>
    </div>
    <div v-else>
      <Button
        type="primary"
        class="btn-downgrade"
        size="medium"
        @click='downgradeNumberOfEmployees'
      >Cancel Downgrade</Button>
      <span style='margin-left:5px'>Downgrade {{program.downgrade_quantity}} employees will be applied from the beggining of next month</span>
    </div>
  </div>
</template>

<script>
import { Button, notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import subscriptionsApi from '@/services/apis/subscriptions.api';

export default {
  components: {
    Button,
  },
  created() {
  },
  mounted() {
  },
  props: {
    number_of_employees: {
      type: Number,
      default: 1,
    },
    upgrade: {
      type: Boolean,
      default: false,
    },
    downgrade: {
      type: Boolean,
      default: false,
    },
    program: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {

    };
  },
  computed: {
    numberOfEmp() {
      return this.number_of_employees;
    },
    currentDate() {
      return dayjs().format('DD MMM YYYY');
    },
  },
  methods: {
    upgradeEmployees() {
      this.$emit('upgradeEmployees');
    },
    downgradeEmployees() {
      this.$emit('downgradeEmployees');
    },
    cancelUpDownGrade() {
      this.$emit('cancelUpDownGrade');
    },
    downgradeNumberOfEmployees() {
      this.loading = true;
      subscriptionsApi.downgradeNumberOfEmployees({
        number_of_employees: null,
      }).then((response) => {
        this.loading = false;
        notification.open({
          type: 'success',
          message: 'Success Operation.',
          description: 'Downgrade employees has canceled Successfully.',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
        setTimeout(() => { window.location.replace(response.url ?? '/dashboard/account'); }, 2000);
      }).catch((error) => {
        this.loading = false;
        console.log({ error });
      });
    },
  },
  watch: {

  },

};

</script>

<style>
.title{
  text-align: center;
}
.align-self-center{
  align-self: center;
}
.apply-align .ant-input-number .ant-input-number-input-wrap input.ant-input-number-input{
  background-color: #00b34f;
  text-align: center !important;
}
.apply-align.ant-input-number.ant-input-number-disabled .ant-input-number-input,
.apply-align.ant-input-number .ant-input-number-input{
  text-align: center !important;
}

.apply-align.ant-input-number.cst-back .ant-input-number-input{
  background-color: white;
}
.btn-upgrade.ant-btn-primary{
  background-color: #A5CD93;
  color: black;
  border-color: #A5CD93 !important;
}
.btn-downgrade.ant-btn-primary{
  background-color:#F3A988 ;
  color: black;
  border-color: #DA9678 !important;
  transition: all 0.5s;
}
.btn-downgrade.ant-btn-primary:hover{
  background-color:  #DA9678;
  color: white;
  border-color: #DA9678 !important;
}
.btn-downgrade.ant-btn-primary:focus{
  background-color:  #DA9678;
  color: white;
  border-color: #DA9678 !important;
}
.title ,.title h2{
  border-radius: 8px;
}
.title h2.upgrade{
  background-color: #63A537;
  color: white !important;
}

.title h2.downgrade{
  background-color: #DA9678;
  color: white !important;
}

.title{
  border-radius: 5px;
}
.upgradedowngrade{
  border-radius: 15px;
}
</style>
