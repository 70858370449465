import { INVOICES } from '../types';

export default {
  state: {
    invoicesQuery: null,

  },
  getters: {
    invoicesData(state) {
      if (!state.invoicesQuery) {
        return null;
      }
      return state.invoicesQuery.data || null;
    },
  },
  mutations: {
    [INVOICES.SET_INVOICES_QUERY](state, payload) {
      state.invoicesQuery = payload;
    },

  },
  actions: {

  },

};
