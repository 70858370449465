<template>
  <CustumLabel
    :name="name"
    :label="label"
    :error="error"
    :required="rules && rules.required"
    :hideErrors="hideErrors"
  >
    <!-- :onlyCountries="countriesCodes" -->
    <vue-tel-input
      mode="international"
      :value="value"
      :placeholder="placeholder"
      @input="handlePhoneChange"
      :autoDefaultCountry="false"
      :preferredCountries="['US']"
      @blur="handleBlur"
    />
  </CustumLabel>
</template>

<script>
import validation from '@/services/validation';

import { VueTelInput } from 'vue3-tel-input';

import CustumLabel from './CustumLabel.vue';
import 'vue3-tel-input/dist/vue3-tel-input.css';

export default {
  components: { CustumLabel, VueTelInput },
  props: {
    name: String,
    label: String,
    placeholder: String,
    hideErrors: Boolean,
    defaultData: String,
    defaultError: String,
    rules: {
      type: Object,
      default: null,
    },
    isTouched: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      error: null,
      touched: false,
      isValid: false,
      defaultCountry: 'KW',
      phoneObj: null,
    };
  },
  created() {
    if (this.defaultData) {
      this.value = `+${this.defaultData}`;
      this.touched = true;
      this.handleValidate();
    }
    this.setInputValues();
  },
  computed: {},
  watch: {
    isTouched(val) {
      if (val) {
        this.touched = true;
      }
    },
    defaultError(val) {
      if (val) {
        this.touched = true;
        this.error = val[0].length > 1 ? val[0] : val;
      }
    },
  },
  methods: {
    handleBlur() {
      this.touched = true;
      this.handleValidate();
      this.setInputValues();
    },

    handleValidate() {
      const { error, isValid } = validation.validateRules(this.rules, this.value);
      this.error = error;
      this.isValid = isValid;
      if (!this.value) {
        this.isValid = false;
        this.error = 'Phone number is required';
      } else if (this.phoneObj && !this.phoneObj.valid) {
        this.isValid = false;
        this.error = 'Phone number is a not valid number';
      } else {
        this.isValid = true;
        this.error = null;
      }
    },
    handlePhoneChange(num, phoneObject) {
      if (num && phoneObject) {
        this.phoneObj = phoneObject;
        if (phoneObject.valid) {
          this.error = null;
        }

        // this.value = this.phoneObj?.number ? this.phoneObj.number : null;
        this.value = this.phoneObj?.nationalNumber ? this.phoneObj.nationalNumber : null;
      } else if (num === '') {
        this.phoneObj = null;
      }
      // this.phone = phoneObject;
    },
    setInputValues() {
      const isRequired = this.rules && this.rules.required;
      if (!isRequired && !this.value) {
        return;
      }

      let clearedNum = this.value;
      if (clearedNum && clearedNum.includes('+')) {
        clearedNum = clearedNum.replace('+', '');
      }

      this.$emit('setInput', {
        [this.name]: {
          name: this.name,
          value: clearedNum,
          error: this.error,
          touched: this.touched,
          isValid: this.isValid,
        },
      });
    },
  },
};
</script>
