/* eslint-disable no-unused-vars */

// Employer Numbers => EmNum
// Sign up thousand_points_incentives => SignSens
// Max rewards per employee => maxRw

// mode min(points * 30 / 7  , maxRw)

// average => average of all modes

// Total signup costs =  EmNum * SignSens
// Total rewards cost =  EmNum * average
// Total prize costs = [+ ... most....]
// + + + = Estimated monethly cost

// const modeBaseCalc = (EmNum = 1) => {
//   const SignSens = 5;
//   const maxRw = 50;
// };

const percentages = {
  drive: 0.6676,
  drive_ev: 0.0066,
  public_transit: 0.0462,
  walk: 0.0470,
  bike: 0.0206,
  carpool: 0.0726,
  transit_drive: 0.0154,
  telecommute: 0.1240,
};

const grtMimMaxReawrdPoints = (pointVal, max_reward, thousand_points_incentives) => {
  const pointDataMonth = +pointVal * 5;
  const pointDataCost = (pointDataMonth * thousand_points_incentives) / 1000;
  const pointDataMinCost = Math.min(((pointDataCost * 30) / 7), max_reward);
  return pointDataMinCost;
};

/// total signup cost
const getTotalSignupCosts = (signupIncentives, employeesNumber) => signupIncentives * employeesNumber;

const getTotalPrizesCosts = (monthly_prizes) => {
  let total = 0;
  Object.values(monthly_prizes).forEach((val) => {
    total += +val;
  });
  return total;
};

const getModeBaseAverage = ({ data, max_reward }) => {
  const { thousand_points_incentives } = data;

  const pointsData = { ...data };
  delete pointsData.thousand_points_incentives;

  const pointsDataCost = {};
  Object.entries(pointsData).forEach(([key, val]) => {
    pointsDataCost[key] = grtMimMaxReawrdPoints(val, max_reward, thousand_points_incentives);
  });

  const finalPointsDataCost = {};
  Object.entries(pointsDataCost).forEach(([key, val]) => {
    finalPointsDataCost[key] = val * percentages[key];
  });

  const arr = Object.values(finalPointsDataCost);
  // const average = arr.reduce((a, b) => a + b, 0) / arr.length;
  const average = arr.reduce((a, b) => a + b, 0);

  return average;
};

const getDistanceBaseAverage = ({ data, max_reward, average_commute_distance_per_trip }) => {
  const { thousand_points_incentives } = data;

  const pointsData = { ...data };
  delete pointsData.thousand_points_incentives;

  const pointsDataCost = {};
  Object.entries(pointsData).forEach(([key, val]) => {
    let extra = 1;
    if (key === 'bike') extra = 0.25;
    if (key === 'walk') extra = 0.15;
    const pointDataMonth = +val * average_commute_distance_per_trip * extra;
    const pointDataCost = (pointDataMonth * 5 * 2 * thousand_points_incentives) / 1000;
    const pointDataMinCost = Math.min(((pointDataCost * 30) / 7), max_reward);
    pointsDataCost[key] = pointDataMinCost;
  });

  const finalPointsDataCost = {};
  Object.entries(pointsDataCost).forEach(([key, val]) => {
    finalPointsDataCost[key] = val * percentages[key];
  });

  const arr = Object.values(finalPointsDataCost);
  const average = arr.reduce((a, b) => a + b, 0);
  // const average = arr.reduce((a, b) => a + b, 0) / arr.length;

  return average;
};

const getFixedBenefitsAverage = ({ data }) => {
  const pointsData = { ...data };

  const finalPointsDataCost = {};
  Object.entries(pointsData).forEach(([key, val]) => {
    finalPointsDataCost[key] = val * percentages[key];
  });

  const arr = Object.values(finalPointsDataCost);
  const average = arr.reduce((a, b) => a + b, 0);
  // const average = arr.reduce((a, b) => a + b, 0) / arr.length;

  return ((average * 5) * 30) / 7;
};

// tax average
const getModeBaseTaxAverage = ({ data, max_reward }) => {
  const { thousand_points_incentives } = data;

  const pointsData = { ...data };
  delete pointsData.thousand_points_incentives;

  const pointsDataCost = {};
  Object.entries(pointsData).forEach(([key, val]) => {
    pointsDataCost[key] = grtMimMaxReawrdPoints(val, max_reward, thousand_points_incentives);
  });

  const avgTaxCreditMon = {};
  Object.entries(pointsDataCost).forEach(([key, val]) => {
    switch (key) {
      case 'drive':
        avgTaxCreditMon[key] = 0;
        break;
      case 'drive_ev':
        avgTaxCreditMon[key] = 0;
        break;
      case 'public_transit':
        avgTaxCreditMon[key] = Math.min(val, (6 * 5 * (30 / 7)));
        break;
      case 'walk':
        avgTaxCreditMon[key] = 0;
        break;
      case 'bike':
        avgTaxCreditMon[key] = Math.min(val, 10);
        break;
      case 'carpool':
        avgTaxCreditMon[key] = val;
        break;
      case 'transit_drive':
        avgTaxCreditMon[key] = Math.min(val, (6 * 5 * (30 / 7)));
        break;
      case 'telecommute':
        avgTaxCreditMon[key] = 0;
        break;

      default:
        break;
    }
  });

  const avgCreditMonEmpMode = {};
  Object.entries(pointsDataCost).forEach(([key, val]) => {
    avgCreditMonEmpMode[key] = avgTaxCreditMon[key] * percentages[key];
  });

  const arr = Object.values(avgCreditMonEmpMode);
  // const average = arr.reduce((a, b) => a + b, 0) / arr.length;
  const average = arr.reduce((a, b) => a + b, 0);

  return average;
};

const getDistanceBaseTaxAverage = ({ data, max_reward, average_commute_distance_per_trip }) => {
  const { thousand_points_incentives } = data;

  const pointsData = { ...data };
  delete pointsData.thousand_points_incentives;

  const pointsDataCost = {};
  Object.entries(pointsData).forEach(([key, val]) => {
    let extra = 1;
    if (key === 'bike') extra = 0.25;
    if (key === 'walk') extra = 0.15;
    const pointDataMonth = +val * average_commute_distance_per_trip * extra;
    const pointDataCost = (pointDataMonth * 5 * 2 * thousand_points_incentives) / 1000;
    const pointDataMinCost = Math.min(((pointDataCost * 30) / 7), max_reward);
    pointsDataCost[key] = pointDataMinCost;
  });

  const avgTaxCreditMon = {};
  Object.entries(pointsDataCost).forEach(([key, val]) => {
    switch (key) {
      case 'drive':
        avgTaxCreditMon[key] = 0;
        break;
      case 'drive_ev':
        avgTaxCreditMon[key] = 0;
        break;
      case 'public_transit':
        avgTaxCreditMon[key] = Math.min(val, (6 * 5 * (30 / 7)));
        break;
      case 'walk':
        avgTaxCreditMon[key] = 0;
        break;
      case 'bike':
        avgTaxCreditMon[key] = Math.min(val, 10);
        break;
      case 'carpool':
        avgTaxCreditMon[key] = val;
        break;
      case 'transit_drive':
        avgTaxCreditMon[key] = Math.min(val, (6 * 5 * (30 / 7)));
        break;
      case 'telecommute':
        avgTaxCreditMon[key] = 0;
        break;

      default:
        break;
    }
  });

  const avgCreditMonEmpMode = {};
  Object.entries(pointsDataCost).forEach(([key, val]) => {
    avgCreditMonEmpMode[key] = avgTaxCreditMon[key] * percentages[key];
  });

  const arr = Object.values(avgCreditMonEmpMode);
  const average = arr.reduce((a, b) => a + b, 0);
  // const average = arr.reduce((a, b) => a + b, 0) / arr.length;

  return average;
};

const getFixedBenefitsTaxAverage = ({ data }) => {
  const pointsData = { ...data };

  // const finalPointsDataCost = {};
  // Object.entries(pointsData).forEach(([key, val]) => {
  //   finalPointsDataCost[key] = val * percentages[key];
  // });

  const avgTaxCreditMon = {};
  Object.entries(pointsData).forEach(([key, val]) => {
    switch (key) {
      case 'drive':
        avgTaxCreditMon[key] = 0;
        break;
      case 'drive_ev':
        avgTaxCreditMon[key] = 0;
        break;
      case 'public_transit':
        avgTaxCreditMon[key] = Math.min(val, (6 * 5 * (30 / 7)));
        break;
      case 'walk':
        avgTaxCreditMon[key] = 0;
        break;
      case 'bike':
        avgTaxCreditMon[key] = Math.min(val, 10);
        break;
      case 'carpool':
        avgTaxCreditMon[key] = val;
        break;
      case 'transit_drive':
        avgTaxCreditMon[key] = Math.min(val, (6 * 5 * (30 / 7)));
        break;
      case 'telecommute':
        avgTaxCreditMon[key] = 0;
        break;

      default:
        break;
    }
  });

  const avgCreditMonEmpMode = {};
  Object.entries(pointsData).forEach(([key, val]) => {
    avgCreditMonEmpMode[key] = avgTaxCreditMon[key] * percentages[key];
  });

  const arr = Object.values(avgCreditMonEmpMode);
  const average = arr.reduce((a, b) => a + b, 0);
  // const average = arr.reduce((a, b) => a + b, 0) / arr.length;

  return average;
};

// /////////////////

const getWeeklyCreditCosts = (values) => {
  let sum = 0;
  // eslint-disable-next-line array-callback-return
  values.map((item) => {
    sum += item.value;
  });
  if (sum === 0) return sum;
  return (sum * 30) / 7;
  // if (!value) return 0;
  // return (value * 30) / 7;
};

const getTotalRewardCosts = ({ employees_number, active_mode, data, max_reward, average_commute_distance_per_trip }) => {
  let average = 0;
  // mode_based
  if (active_mode === 'mode_based') {
    average = getModeBaseAverage({ data, max_reward });
  }
  // mile_based
  if (active_mode === 'mile_based') {
    average = getDistanceBaseAverage({ data, max_reward, average_commute_distance_per_trip });
  }

  // emission_based

  if (active_mode === 'emission_based') {
    average = 14.3;
  }

  // fixed_benfit
  if (active_mode === 'fixed_benfit') {
    average = getFixedBenefitsAverage({ data });
  }

  return employees_number * average;
};

const getTotalTaxsCosts = ({ employees_number, active_mode, data, max_reward, average_commute_distance_per_trip }) => {
  let average = 0;
  // mode_based
  if (active_mode === 'mode_based') {
    average = getModeBaseTaxAverage({ data, max_reward });
  }
  // mile_based
  if (active_mode === 'mile_based') {
    average = getDistanceBaseTaxAverage({ data, max_reward, average_commute_distance_per_trip });
  }

  // emission_based

  if (active_mode === 'emission_based') {
    average = 14.3;
  }

  // fixed_benfit
  if (active_mode === 'fixed_benfit') {
    average = getFixedBenefitsTaxAverage({ data });
  }

  return employees_number * average;
};
export default { getTotalSignupCosts, getTotalPrizesCosts, getTotalRewardCosts, getWeeklyCreditCosts, getTotalTaxsCosts };
