<template>
  <div class="reward-page py-4 mt-4">
    <div class="container">
      <div class="flex items-center gap-4 justify-center md:justify-end mb-6 md:mb-0 flex-wrap">
        <h3>Number of Employees: 111</h3>
        <div>
          <CustumSelect
            name="spots"
            placeholder="select type "
            :data="spacesData"
            :defaultData="1"
            :rules="{
        required: false,
      }"
            @setInput="setInput"
          />
        </div>
      </div>
      <TransportationCosts />
      <div class="my-14"></div>
      <PotentialBenefits />
      <div class="my-14"></div>
      <CompliancePolicies />
    </div>
  </div>
</template>
<script>
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import TransportationCosts from './TransportationCosts.vue';
import PotentialBenefits from './PotentialBenefits.vue';
import CompliancePolicies from './CompliancePolicies.vue';

export default {
  components: {
    TransportationCosts,
    CustumSelect,
    PotentialBenefits,
    CompliancePolicies,
  },
  data() {
    return {
      spacesData: [
        { id: 1, name: 'Monthly' },
        { id: 2, name: 'Quarterly' },
        { id: 3, name: 'Yearly' },
      ],
    };
  },
};
</script>
