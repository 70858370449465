import statsApi from '@/services/apis/stats.api';

export const getModeShareData = async (year, type) => {
  //  [`${year}-01-01`, `${year}-12-31`]
  try {
    let data = null;
    if (type === 'mode_share') {
      data = await statsApi.fetchModeShareStats({
        type: 'mode_share',
        from: `${year}-01-01`,
        to: `${year}-12-31`,
      });
    } else if (type === 'mile_share') {
      data = await statsApi.fetchMileShareStats({
        type: 'mile_share_trips',
        from: `${year}-01-01`,
        to: `${year}-12-31`,
      });
    }

    if (data && data.result) {
      return data.result;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const checkActiveMonth = (val, startMonth) => {
  if (val && startMonth === 1) {
    let firstMonth = 12;
    Object.values(val).forEach((el) => {
      if (el && el.count && el.all && el.all.length) {
        el.all.forEach((elObj) => {
          Object.keys(elObj).forEach((k) => {
            if (parseInt(firstMonth, 10) > parseInt(k, 10)) {
              firstMonth = k;
            }
          });
        });
      }
    });
    if (firstMonth < 12) {
      return firstMonth;
    }
  }
  return null;
};

const currentYear = new Date().getFullYear();
const minYear = 2020;

const getActiveMonth = async (val, startMonth, year = currentYear, type = 'mode_share') => {
  if (year < minYear) { return { firstMonth: null, year }; }

  const mon = checkActiveMonth(val, startMonth);

  if (mon) {
    return { firstMonth: +mon, year };
  }

  const data = await getModeShareData(year - 1, type);
  if (!data) { return { firstMonth: null, year: year - 1 }; }
  // eslint-disable-next-line no-return-await
  return await getActiveMonth(data, startMonth, year - 1, type);
};

export default getActiveMonth;
