<template>
  <div class="container py-12 md:py-16 lg:py-20 xl:py-24">
    <AuthCard title="Register as Employer or Community Leader">
      <div>
        <div class="flex flex-col gap-4">
          <!-- "name" text -->
          <CustumInput
            name="name"
            :defaultData="defaultData? defaultData.name : null"
            :defaultError="responseErrors.name || null"
            label="Name"
            placeholder="Enter full name"
            :rules="{
            required: true,
            min: 3,
          }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
          <!-- "community_type" DDS -->
          <CustumSelect
            name="community_type"
            :defaultData="defaultData? defaultData.community_type : null"
            :defaultError="responseErrors.community_type || null"
            label="Type"
            placeholder="Select"
            :data="typesData"
            :rules="{
              required: true,
            }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
          <!-- "community_leader_name" -->
          <CustumInput
            name="community_name"
            :defaultData="defaultData? defaultData.community_name : null"
            :defaultError="responseErrors.community_name || null"
            label="Employer Name"
            placeholder="e.g. University of Colorado Denver"
            :rules="{
            required: true,
            min: 3,
          }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumAddress
            name="address"
            :defaultData="defaultData? defaultData.address : null"
            :defaultError="responseErrors.address || null"
            label="Employer Address"
            labelTooltip="Address your employees commute to"
            placeholder="Enter your Address"
            :rules="{
              required: true,
            }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumNumberInput
            name="community_size"
            :defaultData="defaultData? defaultData.community_size : null"
            :defaultError="responseErrors.community_size || null"
            label="Business Size (No. of Employees)"
            placeholder="No. of Employees"
            :rules="{
            required: true,
            min: 1,
          }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumInput
            name="email"
            type="email"
            :defaultData="defaultData? defaultData.email : null"
            :defaultError="responseErrors.email || null"
            label="Email Address"
            placeholder="Email"
            :rules="{
            required: true,
            email: true,
            min: 3,
          }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumInputPassword
            name="password"
            :defaultData="defaultData? defaultData.password : null"
            :defaultError="responseErrors.password || null"
            label="Password"
            placeholder="At least six characters"
            :rules="{
                required: true,
                min: 8,
                password: true
              }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumInputPassword
            name="password_confirmation"
            :defaultData="defaultData? defaultData.password_confirmation : null"
            :defaultError="responseErrors.password_confirmation || null"
            label="Confirm Password"
            placeholder="Retype the password"
            :password="mainPassword"
            :rules="{
                required: true,
                confirmPassword: true,
                min: 8,
              }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumPhoneSelect
            name="phone"
            :defaultData="defaultData? defaultData.phone : null"
            :defaultError="responseErrors.phone || null"
            label="Mobile No."
            placeholder="Mobile No."
            :isTouched="isAllTouched"
            :rules="{
              required: true,
            }"
            @setInput="setInput"
          />

          <div class="mt-3">
            <Checkbox
              @change="handleConfirm"
            >By continuing, I confirm that I have read and agreed to the <a href='/terms-and-conditions' target='_blank'>Terms and Conditions</a>.</Checkbox>
          </div>
        </div>

        <div class="mt-6">
          <Button
            type="primary"
            class="btn-gradient rounded"
            size="large"
            block
            :loading="isLoading"
            :disabled="!isConfirmed"
            @click="handleSubmit"
          >Register</Button>

          <p class="text-center mt-4 opacity-80">
            Do you have an account?
            <router-link to="/login">Login here</router-link>
          </p>
        </div>
      </div>
    </AuthCard>
  </div>
</template>

<script>
import validation from '@/services/validation';
import { message, Checkbox, Button } from 'ant-design-vue';
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumInputPassword from '@/components/shared/form/CustumInputPassword.vue';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';

import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import CustumPhoneSelect from '@/components/shared/form/CustumPhoneSelect.vue';
import CustumAddress from '@/components/shared/form/CustumAddress.vue';
import authApi from '@/services/apis/auth.api';
import AuthCard from './AuthCard.vue';

import { saveToken } from '../../services/jwt.service';
import handleResErrors from '../../utils/handleResErrors';

export default {
  components: {
    AuthCard,
    CustumInput,
    CustumInputPassword,
    Checkbox,
    Button,

    CustumSelect,
    CustumNumberInput,
    CustumPhoneSelect,
    CustumAddress,
  },
  props: {
    defaultData: Object,
  },
  data() {
    return {
      isAllTouched: false,
      isLoading: false,
      formData: {},
      isConfirmed: false,
      typesData: [
        { id: 'Business', name: 'Business' },
        { id: 'Government', name: 'Government' },
        { id: 'School', name: 'School' },
        { id: 'Hospital', name: 'Hospital' },
        { id: 'Housing Development', name: 'Housing Development' },
      ],
      responseErrors: {},
    };
  },
  methods: {
    handleConfirm(e) {
      this.isConfirmed = e.target.checked;
    },
    setInput(vals) {
      this.formData = { ...this.formData, ...vals };
    },
    async handleSubmit() {
      this.isAllTouched = true;
      this.responseErrors = {};

      const validetionData = validation.validateAllData(this.formData);

      if (!validetionData.isValid) {
        return;
      }

      const formData = { ...validetionData.data };

      if (formData && formData.address) {
        formData.community_txt_address = formData.address.formated_address;
        formData.community_lat_cord = formData.address.lat.toString();
        formData.community_lng_cord = formData.address.lng.toString();
        delete formData.address;
      } else {
        this.responseErrors.address = 'This address is not invalid ';
        return;
      }

      /// submit
      this.isLoading = true;
      try {
        const res = await authApi.postRegister(formData);
        if (res.status) {
          message.success('Registered successfully');
          if (res.is_active) {
            saveToken(res.accessToken);
            this.$router.push('/dashboard');
          } else {
            this.$router.push('/login');
          }
        }
        this.isLoading = false;
      } catch (error) {
        const errorMsg = error.response?.data?.message;
        if (errorMsg) {
          this.responseErrors = errorMsg;
        }

        message.error(handleResErrors(error), 10);
        this.isLoading = false;
      }
    },
  },
  computed: {
    mainPassword() {
      return this.formData?.password?.value || null;
    },
  },
};
</script>
