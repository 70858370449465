<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { SCORES } from '@/store/types';
import scoresApi from '../../apis/scores.api';

export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    per_page: {
      type: Number,
      default: 10,
    },
    type: {
      type: String,
      default: 'month',
    },

    years: Object,
    custumDate: Object,
  },

  setup(props) {
    const store = useStore();
    const query = ref(null);
    query.value = scoresApi.useScoreQuery({
      page: props.page,
      per_page: props.per_page,
      years: props.years,
      type: props.type,
      custumDate: props.custumDate,
    });
    if (query.value) {
      store.commit(SCORES.SET_SCORES_QUERY, query);
    }
    return { query };
  },
};
</script>
