import { ADMIN_EMPLOYEES } from '../../types';

export default {
  state: {
    employeesQuery: null,
    employeeRewardQuery: null,

  },
  getters: {
    employeesData(state) {
      if (!state.employeesQuery) {
        return null;
      }
      return state.employeesQuery.data || null;
    },
    employeeRewardData(state) {
      if (!state.employeeRewardQuery) {
        return null;
      }
      return state.employeeRewardQuery.data || null;
    },
  },
  mutations: {
    [ADMIN_EMPLOYEES.SET_EMPLOYEES_QUERY](state, payload) {
      state.employeesQuery = payload;
    },
    [ADMIN_EMPLOYEES.SET_EMPLOYEE_QUERY](state, payload) {
      state.employeeRewardQuery = payload;
    },

  },
  actions: {

  },

};
