<template>
  <div class="rounded-lg p-3 lg:p-5 bg-bg border border-bg-dark border-solid mt-5">
    <div class="flex justify-between">
      <Checkbox v-model:checked="checked" @change='isMonthlyPrizesChecked'>
        <h3
          class="text-xl text-primary-dark"
        >Monthly Prizes (Gamification, up to one prize per employee)</h3>
      </Checkbox>
    </div>

    <div v-if="checked">
      <div class="mt-6">
        <div class="mb-4 flex flex-wrap items-center justify-center gap-4">
          <PricesCard
            text="Most Miles Biked"
            :cost="monthlyPrizes.most_miles_biked"
            color="#245829"
          >
            <PhBicycle size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                class="w-20"
                :defaultValue="monthlyPrizes.most_miles_biked"
                @change="(val) => setVal(val, 'most_miles_biked')"
                :min="0"
                prefix="$"
                :disabled='isViewOnly'
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most Miles Walked"
            :cost="monthlyPrizes.most_miles_walked"
            color="#5271FF"
          >
            <PhPersonSimpleWalk size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                class="w-20"
                :defaultValue="monthlyPrizes.most_miles_walked"
                @change="(val) => setVal(val, 'most_miles_walked')"
                :min="0"
                prefix="$"
                :disabled='isViewOnly'
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most Transit Trips"
            :cost="monthlyPrizes.most_transit_trips"
            color="#CF572E"
          >
            <PhBus size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                class="w-20"
                :defaultValue="monthlyPrizes.most_transit_trips"
                @change="(val) => setVal(val, 'most_transit_trips')"
                :min="0"
                prefix="$"
                :disabled='isViewOnly'
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most Carpool Trips"
            :cost="monthlyPrizes.most_carpool_trips"
            color="#DAA14B"
          >
            <PhCar size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                class="w-20"
                :defaultValue="monthlyPrizes.most_carpool_trips"
                @change="(val) => setVal(val, 'most_carpool_trips')"
                :min="0"
                prefix="$"
                :disabled='isViewOnly'
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most trips to the office"
            :cost="monthlyPrizes.biggest_shift_from_driving_to_green_trips"
            color="#FE679F"
          >
            <PhArrowsLeftRight size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                class="w-20"
                :defaultValue="monthlyPrizes.biggest_shift_from_driving_to_green_trips"
                @change="(val) => setVal(val, 'biggest_shift_from_driving_to_green_trips')"
                :min="0"
                prefix="$"
                :disabled='isViewOnly'
              />
            </template>
          </PricesCard>

          <PricesCard
            text="Most Green Trips"
            :cost="monthlyPrizes.most_green_trips"
            color="#03989E"
          >
            <PhTree size="40" weight="duotone" />
            <template v-slot:input>
              <InputNumber
                class="w-20"
                :defaultValue="monthlyPrizes.most_green_trips"
                @change="(val) => setVal(val, 'most_green_trips')"
                :min="0"
                prefix="$"
                :disabled='isViewOnly'
              />
            </template>
          </PricesCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, InputNumber } from 'ant-design-vue';

import {
  PhTree,
  PhPersonSimpleWalk,
  PhBus,
  PhCar,
  PhBicycle,
  PhArrowsLeftRight,
} from 'phosphor-vue';

import PricesCard from './shared/PricesCard.vue';

export default {
  components: {
    Checkbox,
    InputNumber,
    PricesCard,
    PhTree,
    PhPersonSimpleWalk,
    PhBus,
    PhCar,
    PhBicycle,
    PhArrowsLeftRight,
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
    isViewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: true,

      monthlyPrizes: {
        most_miles_biked: 25,
        most_miles_walked: 25,
        most_transit_trips: 25,
        most_carpool_trips: 25,
        biggest_shift_from_driving_to_green_trips: 1,
        most_green_trips: 1,
      },
    };
  },

  created() {
    if (this.data) {
      this.monthlyPrizes = this.data;
    }
  },

  methods: {
    setVal(value, key) {
      // this.SET_MONTHLY_PRIZES_DATA({ value, key });
      // this.debounceSetVal(value, key, this);
      this.monthlyPrizes = {
        ...this.monthlyPrizes,
        [key]: value,
      };

      this.$emit('setData', this.monthlyPrizes);
    },
    isMonthlyPrizesChecked() {
      this.$emit('isMonthlyPrizesChecked', {
        available: this.checked,
      });
    },
  },
};
</script>
