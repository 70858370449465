<template>
  <div class="flex flex-col gap-3 p-4 py-6 items-center justify-center rounded">
    <div class="text-yellow-500">
      <PhWarningCircle :size="64" weight="duotone" />
    </div>
    <h3 class="text-lg text-center px-4">
      You must subscribe and add credit to launch a transportation demand management (TDM) program.
    </h3>

    <div class="mt-3 flex flex-wrap items-center justify-center gap-4">
      <Button
        type="primary"
        class="btn-light w-full md:w-auto"
        size="large"
        @click="() => $emit('close')"
      >Cancel</Button>

      <HeaderBalance vertical noInfo />
    </div>
  </div>
</template>

<script>
import { PhWarningCircle } from 'phosphor-vue';
import { Button } from 'ant-design-vue';
import HeaderBalance from '@/components/dashboard/CreditDetails/HeaderBalance.vue';

export default {
  components: {
    PhWarningCircle,
    Button,
    HeaderBalance,
  },
  data() {
    return {
      isLoading: false,
      file: null,
    };
  },
  methods: {},
};
</script>
