<template>
  <div class="members-page py-4 mt-4">
    <MetricsData />
  </div>
</template>
<script>
import MetricsData from './MetricsData.vue';

export default {
  components: {
    MetricsData,
  },
};
</script>
