<template>
  <div class="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
    <div
      class="flex flex-col items-center gap-3 border border-bg-dark shadow border-solid p-4 rounded-lg bg-bg"
      v-for="ix in cardsNum"
      :key="ix"
    >
      <skeleton-avatar active size="large" />
      <skeleton-input active size="large" class="flex-1 w-full" />
    </div>
  </div>
</template>

<script>
import { SkeletonAvatar, SkeletonInput } from 'ant-design-vue';

export default {
  components: {
    SkeletonAvatar,
    SkeletonInput,
  },
  props: {
    cardsNum: {
      type: Number,
      default: 8,
    },
  },
};
</script>
