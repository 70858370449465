<template>
  <div class="trans-modal-card">
    <div
      class="bg-gradient-to-r from-primary to-primary-light text-white text-lg px-3 py-2 text-center font-bold rounded-t-md"
    >{{title}}</div>
    <div class="p-4">
      <form @submit.prevent="()=>{}" class="flex flex-col gap-3">
        <slot />
        <div class="mt-3 flex flex-wrap items-center justify-center gap-4">
          <Button size="large" class="px-8" @click="() => $emit('close')">Cancel</Button>
          <Button class="btn-gradient px-10" type="primary" size="large">Save</Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Button } from 'ant-design-vue';

export default {
  components: {
    Button,
  },
  props: {
    title: String,
  },
  data() {
    return {};
  },
};
</script>
