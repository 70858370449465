<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { STATS } from '@/store/types';
import statsApi from '../../apis/stats.api';

export default {
  props: {
    type: {
      type: String,
      default: 'community_points',
    },
    year: String,
  },

  setup(props) {
    const store = useStore();
    const query = ref(null);
    query.value = statsApi.useCommunityFootprintQuery({
      type: props.type,
      year: props.year,
    });
    if (query.value) {
      store.commit(STATS.SET_COMMUNITY_FOOTPRINT_QUERY, query);
    }
    return { query };
  },
};
</script>
