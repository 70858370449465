<template>
  <div class="w-full p-2">
    <div class="flex items-center justify-end gap-4">
      <div>
        <CustumSelect
          name="spots"
          placeholder="select type "
          :data="spacesData"
          :defaultData="type"
          :rules="{
            required: false,
          }"
          @setInput="setInput"
        />
      </div>
      <div>
        <!-- <CustumSelect
          name="year"
          placeholder="select type "
          :data="yearsData"
          :defaultData="1"
          :rules="{
        required: false,
      }"
          @setInput="setInput"
        />-->
        <date-picker v-model:value="year" picker="year" :allowClear="false" />
      </div>
    </div>

    <div
      v-if="employerStatsQuery && (employerStatsQuery.isFetching || employerStatsQuery.isLoading)"
      class="flex items-center justify-center py-16"
    >
      <Spin spinning size="large" />
    </div>
    <QueryAlert :query="employerStatsQuery" />
    <div class="mt-6" v-if="employerStatsData && employerStatsData.length">
      <BarChart :key="componentKey" :chartData="chartData"  />
      <p class="text-center font-bold mt-3">{{mainSpot.name}}</p>
    </div>

    <div
      v-if="employerStatsQuery && !employerStatsQuery.isLoading && (!employerStatsData || !employerStatsData.length )"
      class="py-12 lg:py-16"
    >
      <Empty />
    </div>

    <StatsEmployerQuery :type="type" :year="getYear" :key="type+getYear" />
  </div>
</template>

<script>
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import StatsEmployerQuery from '@/services/queries/stats/StatsEmployerQuery.vue';
import { Spin, Empty, DatePicker } from 'ant-design-vue';
import dayjs from 'dayjs';
import QueryAlert from '@/components/shared/QueryAlert.vue';

import BarChart from '../shared/BarChart.vue';
import { sortAndNameMonthes } from '../../../../utils/monthes';

export default {
  components: { CustumSelect, BarChart, StatsEmployerQuery, Spin, Empty, DatePicker, QueryAlert },
  data() {
    return {
      type: 'type_saved_parking_spaces',
      year: dayjs(),
      spacesData: [
        { id: 'type_saved_parking_spaces', name: 'Saved Parking Spaces', label: 'Spots' },
        { id: 'saved_sov_trips', name: 'Saved SOV Trips', label: 'Trips' },
        {
          id: 'saved_vehicle_miles_travelled',
          name: 'Saved Vehicle Miles Traveled',
          label: 'Miles',
        },
        { id: 5, name: 'Cost Savings', label: '$' },
        { id: 'health_benefits', name: 'Health Benefits', label: 'Calories' },
        { id: 'reduced_co2_emissions', name: 'Reduced CO2 Emissions', label: 'Kg CO2' },
        { id: 'reduced_air_pollution', name: 'Reduced Air Pollution', label: 'Kg' },
        { id: 9, name: 'Tax Credit', label: '$' },
        { id: 'social_benefits', name: 'Social Benefits', label: 'Hours' },
      ],
      yearsData: [
        {
          id: 1,
          name: '2022',
        },
        {
          id: 2,
          name: '2023',
        },
        {
          id: 3,
          name: '2024',
        },
      ],
      checkPrevYearMounted: false,
      componentKey: 0,
    };
  },
  computed: {
    mainSpot() {
      return this.spacesData.find((d) => d.id === this.type);
    },
    employerStatsQuery() {
      return this.$store.state.stats.employerStatsQuery;
    },
    employerStatsData() {
      return this.$store.getters.employerStatsData;
    },
    chartData() {
      // this.forceRerender();
      if (!this.employerStatsData || !this.employerStatsData.length) return null;

      const defaultData = sortAndNameMonthes(this.employerStatsData);

      const data = {
        labels: defaultData.map((d) => Object.keys(d)[0]),
        datasets: [
          {
            label: this.mainSpot.label,
            data: defaultData.map((d) => Object.values(d)[0]),
            backgroundColor: ['#278c64'],
          },
        ],
      };
      this.forceRerender();
      return data;
    },
    getYear() {
      return String(dayjs(this.year).year());
    },
  },
  watch: {
    employerStatsData() {
      // check if no data and get previous year data
      const prevYear = dayjs(this.year).subtract(1, 'year');
      if (
        this.employerStatsData &&
        this.employerStatsData.length === 0 &&
        prevYear.year() >= 2020 &&
        !this.checkPrevYearMounted
      ) {
        this.year = prevYear;
        this.checkPrevYearMounted = true;
      }
    },
  },

  methods: {
    setInput(val) {
      if (val && val.spots && val.spots.value) {
        this.type = val.spots.value;
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
