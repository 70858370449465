<template>
  <AuthLayout>
    <RegisterMain />
  </AuthLayout>
</template>

<script>
// @ is an alias to /src
import AuthLayout from '@/layouts/AuthLayout.vue';
import RegisterMain from '@/components/auth/RegisterMain.vue';

export default {
  components: {
    AuthLayout,
    RegisterMain,
  },
};
</script>
