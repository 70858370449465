const COLORS = ['#41B883',
  '#bfa531',
  '#963A41',
  '#2F3061',
  '#FF7F51',
  '#3E505B',
  '#95a57c',
  '#241E4E',
  '#9a9343'];

export default COLORS;
