<template>
  <div class="conract-page py-4">
    <div class="container">
      <div class="w-full main-card px-3 md:px-4 lg:px-5 py-10">
        <div class="w-full">
          <h2 class="text-center">Mode Elasticity Analysis</h2>
        </div>

        <div class="w-full mt-8 metrics_data-tabs">
          <tabs class="w-full">
            <tab-pane key="1">
              <template #tab>
                <span>
                  Commuter Analysis
                  <Tooltip title="This analysis relies on commuter data">
                    <PhInfo weight="fill" class="text-primary cursor-pointer" size="20" />
                  </Tooltip>
                </span>
              </template>
              <CommuterAnalysis />
            </tab-pane>
            <tab-pane key="2">
              <template #tab>
                <span>
                  Generic Analysis
                  <Tooltip
                    title="This analysis relies on addresses provided by employer regardless of commuter preferences"
                  >
                    <PhInfo weight="fill" class="text-primary cursor-pointer" size="20" />
                  </Tooltip>
                </span>
              </template>
              <GenericAnalysis />
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane, Tooltip } from 'ant-design-vue';
import { PhInfo } from 'phosphor-vue';

import CommuterAnalysis from './CommuterAnalysis.vue';
import GenericAnalysis from './GenericAnalysis.vue';

export default {
  components: { Tabs, TabPane, PhInfo, Tooltip, CommuterAnalysis, GenericAnalysis },
  data() {
    return {};
  },
  methods: {},
};
</script>
